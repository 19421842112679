<template>
  <div id="workspace-contacts" class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          Contact Information
        </div>

        <div class="col-sm-4 size-text-13px text-right-not-xs">
          <a href="" class="normal-weight" @click.prevent="startEdit" v-if="!isEditing">Edit</a>

          <span v-if="isEditing">
            <a href="" class="normal-weight right-15" @click.prevent="save">Save</a>
            <a href="" class="dark-gray-text normal-weight" @click.prevent="cancelEdit">Cancel</a>
          </span>
        </div>
      </div>
    </div>

    <div class="box-notification-error" v-if="errors.length">
      {{ errors }}
    </div>

    <div class="box-content">
      <form role="form" name="form" class="a-form" novalidate>
        <div class="row bottom-20">
          <div class="col-sm-4 semibold-weight">
            Primary Contact
          </div>

          <div class="col-sm-8">
            <div v-if="!isEditing">
              <span class="simple-text" v-if="!primaryContact.id">{{ workspace.primaryContactOther || '&mdash;' }}</span>

              <span v-if="primaryContact.id">{{ primaryContact.fullName }}</span>
            </div>

            <div class="top-10-xs" v-if="isEditing">
              <dropdown-select id-label="primary-contact" :initial-label="selectedPrimaryContact.fullName" v-model="selectedPrimaryContact.id" :options="contacts" value-key="id" label-key="fullName" @change="changePrimaryContact"></dropdown-select>

              <textarea name="primaryContactInput" id="primary-contact-input" class="form-control top-10" rows="3" v-model="workspaceCopy.primaryContactOther" v-if="isOtherContact(selectedPrimaryContact)"></textarea>
            </div>
          </div>
        </div>

        <div class="row bottom-20">
          <div class="col-sm-4 semibold-weight">
            Secondary Contact
          </div>

          <div class="col-sm-8">
            <div v-if="!isEditing">
              <span class="simple-text" v-if="!secondaryContact.id">{{ workspace.secondaryContactOther || '&mdash;' }}</span>

              <span v-if="secondaryContact.id">{{ secondaryContact.fullName }}</span>
            </div>

            <div class="top-10-xs" v-if="isEditing">
              <dropdown-select id-label="secondary-contact" :initial-label="selectedSecondaryContact.fullName" v-model="selectedSecondaryContact.id" :options="contacts" value-key="id" label-key="fullName" @change="changeSecondaryContact"></dropdown-select>

              <textarea name="secondaryContactInput" id="secondary-contact-input" class="form-control top-10" rows="3" v-model="workspaceCopy.secondaryContactOther" v-if="isOtherContact(selectedSecondaryContact)"></textarea>
            </div>
          </div>
        </div>

        <div class="row bottom-20">
          <div class="col-sm-4 semibold-weight">
            Primary AP Contact
          </div>

          <div class="col-sm-8">
            <div v-if="!isEditing">
              <span class="simple-text" v-if="!primaryApContact.id">{{ workspace.primaryApContactOther || '&mdash;' }}</span>

              <span v-if="primaryApContact.id">{{ primaryApContact.fullName }}</span>
            </div>

            <div class="top-10-xs" v-if="isEditing">
              <dropdown-select id-label="primary-ap-contact" :initial-label="selectedPrimaryApContact.fullName" v-model="selectedPrimaryApContact.id" :options="contacts" value-key="id" label-key="fullName" @change="changePrimaryApContact"></dropdown-select>

              <textarea name="primaryApContactInput" id="primary-ap-contact-input" class="form-control top-10" rows="3" v-model="workspaceCopy.primaryApContactOther" v-if="isOtherContact(selectedPrimaryApContact)"></textarea>
            </div>
          </div>
        </div>

        <div class="row bottom-20">
          <div class="col-sm-4 semibold-weight">
            Secondary AP Contact
          </div>

          <div class="col-sm-8">
            <div v-if="!isEditing">
              <span class="simple-text" v-if="!secondaryApContact.id">{{ workspace.secondaryApContactOther || '&mdash;' }}</span>

              <span v-if="secondaryApContact.id">{{ secondaryApContact.fullName }}</span>
            </div>

            <div class="top-10-xs" v-if="isEditing">
              <dropdown-select id-label="secondary-ap-contact" :initial-label="selectedSecondaryApContact.fullName" v-model="selectedSecondaryApContact.id" :options="contacts" value-key="id" label-key="fullName" @change="changeSecondaryApContact"></dropdown-select>

              <textarea name="secondaryApContactInput" id="secondary-ap-contact-input" class="form-control top-10" rows="3" v-model="workspaceCopy.secondaryApContactOther" v-if="isOtherContact(selectedSecondaryApContact)"></textarea>
            </div>
          </div>
        </div>

        <div class="bottom-20">
          <textarea-horizontal label="Written Notices" name="physicalAddresses" sub-text="(Physical Address)" :current-value="workspace.physicalAddresses" :edit-mode="isEditing" v-model="workspaceCopy.physicalAddresses" :novalidate="true"></textarea-horizontal>
        </div>

        <textarea-horizontal label="Electronic Notices" name="emailAddresses" sub-text="(Email Address)" :current-value="workspace.emailAddresses" :edit-mode="isEditing" v-model="workspaceCopy.emailAddresses" :novalidate="true"></textarea-horizontal>

        <div class="row top-30" v-if="isEditing">
          <div class="col-sm-4 col-md-3">
            <button type="button" class="nv-button-blue a-button-size" @click="save">Save</button>
          </div>

          <div class="col-sm-4 col-md-3 top-10-xs">
            <button type="button" class="secondary-btn-blue a-button-size" @click="cancelEdit">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import TextareaHorizontal from 'vue-app/shared/components/textarea-horizontal.vue';

export default {
  name: 'WorkspaceContacts',

  components: {
    DropdownSelect,
    TextareaHorizontal
  },

  props: {
    workspace: {
      type: Object,
      default: () => ({})
    },

    workspaceTeam: {
      type: Array,
      default: () => []
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      isEditing: false,
      errors: '',
      workspaceCopy: {},
      selectedPrimaryContact: {},
      selectedSecondaryContact: {},
      selectedPrimaryApContact: {},
      selectedSecondaryApContact: {}
    };
  },

  computed: {
    primaryContact() {
      return this.contactData('primaryContact');
    },

    secondaryContact() {
      return this.contactData('secondaryContact');
    },

    primaryApContact() {
      return this.contactData('primaryApContact');
    },

    secondaryApContact() {
      return this.contactData('secondaryApContact');
    },

    contacts() {
      return [...this.workspaceTeam, { fullName: 'Other', id: 'Other' }];
    }
  },

  methods: {
    contactData(contactLabel) {
      if (this.workspace[contactLabel] == null) {
        return { id: null, fullName: null };
      }

      const id = this.workspace[contactLabel].id;
      const fullName = this.workspace[contactLabel].fullName;

      return { id, fullName };
    },

    startEdit() {
      this.workspaceCopy = Object.assign({}, this.workspace);

      this.startEditPrimaryContact();
      this.startEditSecondaryContact();
      this.startEditPrimaryApContact();
      this.startEditSecondaryApContact();

      this.isEditing = true;
      this.errors = '';
    },

    cancelEdit() {
      this.isEditing = false;
      this.errors = '';
    },

    startEditPrimaryContact() {
      if (this.workspace.primaryContact) {
        this.selectedPrimaryContact.fullName = this.primaryContact.fullName;
      }
      else if (this.workspace.primaryContactOther) {
        this.selectedPrimaryContact.fullName = 'Other';
      }
      else {
        this.selectedPrimaryContact.fullName = null;
      }
    },

    startEditSecondaryContact() {
      if (this.workspace.secondaryContact) {
        this.selectedSecondaryContact.fullName = this.secondaryContact.fullName;
      }
      else if (this.workspace.secondaryContactOther) {
        this.selectedSecondaryContact.fullName = 'Other';
      }
      else {
        this.selectedSecondaryContact.fullName = null;
      }
    },

    startEditPrimaryApContact() {
      if (this.workspace.primaryApContact) {
        this.selectedPrimaryApContact.fullName = this.primaryApContact.fullName;
      }
      else if (this.workspace.primaryApContactOther) {
        this.selectedPrimaryApContact.fullName = 'Other';
      }
      else {
        this.selectedPrimaryApContact.fullName = null;
      }
    },

    startEditSecondaryApContact() {
      if (this.workspace.secondaryApContact) {
        this.selectedSecondaryApContact.fullName = this.secondaryApContact.fullName;
      }
      else if (this.workspace.secondaryApContactOther) {
        this.selectedSecondaryApContact.fullName = 'Other';
      }
      else {
        this.selectedSecondaryApContact.fullName = null;
      }
    },

    changePrimaryContact() {
      if (this.selectedPrimaryContact.id === 'Other') {
        this.workspaceCopy.primaryContact = null;
        this.workspaceCopy.primaryContactId = null;
      }
      else {
        this.workspaceCopy.primaryContact = _.find(this.contacts, (c) => c.id === this.selectedPrimaryContact.id);
        this.workspaceCopy.primaryContactId = this.selectedPrimaryContact.id;
        this.workspaceCopy.primaryContactOther = null;
      }
    },

    changeSecondaryContact() {
      if (this.selectedSecondaryContact.id === 'Other') {
        this.workspaceCopy.secondaryContact = null;
        this.workspaceCopy.secondaryContactId = null;
      }
      else {
        this.workspaceCopy.secondaryContact = _.find(this.contacts, (c) => c.id === this.selectedSecondaryContact.id);
        this.workspaceCopy.secondaryContactId = this.selectedSecondaryContact.id;
        this.workspaceCopy.secondaryContactOther = null;
      }
    },

    changePrimaryApContact() {
      if (this.selectedPrimaryApContact.id === 'Other') {
        this.workspaceCopy.primaryApContact = null;
        this.workspaceCopy.primaryApContactId = null;
      }
      else {
        this.workspaceCopy.primaryApContact = _.find(this.contacts, (c) => c.id === this.selectedPrimaryApContact.id);
        this.workspaceCopy.primaryApContactId = this.selectedPrimaryApContact.id;
        this.workspaceCopy.primaryApContactOther = null;
      }
    },

    changeSecondaryApContact() {
      if (this.selectedSecondaryApContact.id === 'Other') {
        this.workspaceCopy.secondaryApContact = null;
        this.workspaceCopy.secondaryApContactId = null;
      }
      else {
        this.workspaceCopy.secondaryApContact = _.find(this.contacts, (c) => c.id === this.selectedSecondaryApContact.id);
        this.workspaceCopy.secondaryApContactId = this.selectedSecondaryApContact.id;
        this.workspaceCopy.secondaryApContactOther = null;
      }
    },

    isOtherContact(contact) {
      return contact && (contact.id === 'Other' || contact === 'Other');
    },

    save() {
      this.isEditing = false;
      this.errors = '';

      this.onSave({ workspace: this.workspaceCopy });
    }
  }
};
</script>

<template>
  <div>
    <div class="titlebar lawyer-titlebar">
      <div class="row tight-columns bottom-40">
        <div class="col-xs-10">
          <div class="vertical-center-not-xs">
            <span class="medium-page-heading right-15">RFP Detail (Reference #{{ rfq.id }})</span>
            <div class="visible-xs-block"></div>
            <status-pill class="status-pill top-10-xs" :closed="rfq.status === 'closed' ? true : false"></status-pill>
          </div>
        </div>

        <div class="col-xs-2 text-right">
          <dropdown-menu :compact="true">
            <li class="menu-item" role="menuitem">
              <a href="" @click.prevent="openStatusChangeModal">{{ `${statusChangeText} RFP` }}</a>
            </li>
          </dropdown-menu>

          <modal-wrapper name="change-rfq-status">
            <template #default="{ closeModal: closeModal }">
              <div class="notice-modal">
                <div class="notice-header text-uppercase">
                  {{ statusChangeText }} RFP
                </div>

                <div class="notice-regular-text">
                  <div class="size-text-14px semibold-weight bottom-30">
                    {{ confirmationText }}
                  </div>

                  <div class="row tight-columns">
                    <div class="col-sm-5">
                      <button type="button" class="primary-btn-night-blue" @click="handleStatusChange">
                        Yes, {{ statusChangeText }} RFP
                      </button>
                    </div>

                    <div class="col-sm-4 top-20-xs">
                      <button type="button" class="secondary-btn-blue" @click="closeModal">No, Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </modal-wrapper>
        </div>
      </div>

      <div class="titlebar-switcher text-center">
        <a href="" :class="['right-20', { 'selected': selectedTab === 'overview' }]" @click.prevent="onSelect('overview')">Project Overview</a>
        <a href="" :class="{ 'selected': selectedTab === 'responses' }" @click.prevent="onSelect('responses')">Review Responses</a>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div v-if="selectedTab === 'overview'">
            <rfq-overview
              :rfq="rfq"
              :update-project-details="updateProjectDetails"
              :available-firms="availableFirms"
              :add-recipients="addRecipients"
              :only-sent-to-marketplace="onlySentToMarketplace"
              :update-questions="updateQuestions"
              :send-to-marketplace="sendToMarketplace">
            </rfq-overview>
          </div>

          <div v-if="selectedTab === 'responses'">
            <rfq-responses
              :rfq="rfq"
              :selected-quote="selectedQuote"
              :only-sent-to-marketplace="onlySentToMarketplace"
              :update-quote="updateQuote">
            </rfq-responses>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalManager from 'vue-app/shared/services/modal-manager.js';
import ModalWrapper from 'vue-app/shared/components/modals/wrapper.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import StatusPill from 'vue-app/shared/components/status-pill.vue';
import RfqOverview from 'vue-app/scout/client/rfqs/rfq-overview.vue';
import RfqResponses from 'vue-app/scout/client/rfqs/rfq-responses.vue';

export default {
  name: 'ClientRfqShow',

  components: {
    ModalWrapper,
    DropdownMenu,
    StatusPill,
    RfqOverview,
    RfqResponses
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    changeRfqStatus: {
      type: Function,
      default: () => {}
    },

    updateQuote: {
      type: Function,
      default: () => {}
    },

    addRecipients: {
      type: Function,
      default: () => {}
    },

    selectedQuote: {
      type: Object
    },

    selectedTabDefault: {
      type: String,
      default: 'overview'
    },

    availableFirms: {
      type: Array,
      required: true
    },

    updateProjectDetails: {
      type: Function,
      required: true
    },

    updateQuestions: {
      type: Function,
      required: true
    },

    sendToMarketplace: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedTab: this.selectedTabDefault
    };
  },

  computed: {
    statusChangeText() {
      if (this.rfq.status !== 'closed') {
        return 'Close';
      }
      else {
        return 'Reopen';
      }
    },

    confirmationText() {
      return `Are you sure you want to ${this.statusChangeText.toLowerCase()} this RFP?`;
    },

    onlySentToMarketplace() {
      if (!this.rfq.sentToMarketplace) { return false; }

      const numQuotes = this.rfq.lawFirmQuotes.length;
      const numQuotesFromMarketplace = this.rfq.lawFirmQuotes.filter(quote => quote.fromMarketplace).length;
      return numQuotesFromMarketplace === numQuotes;
    }
  },

  methods: {
    onSelect(tabName) {
      this.selectedTab = tabName;
    },

    openStatusChangeModal() {
      ModalManager.open('change-rfq-status');
    },

    handleStatusChange() {
      this.changeRfqStatus(this.rfq);
      ModalManager.close('change-rfq-status');
    }
  }
};
</script>

<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-8 col-sm-7 col-lg-8">
            <a href="/client-app/projects" class="back-link"><svg-icon class="base-icon right-10" name="arrow-left"></svg-icon>All Projects</a>
          </div>

          <div class="col-xs-4 col-sm-5 col-lg-4 semibold-weight size-text-13px">
            <div class="row" v-if="hasPossibleLawyers && !isClosedRequest">
              <div class="col-xs-6 col-lg-7 text-right">
                <div class="icon-link">
                  <a :href="`/proposals/${counselRequest.proposalId}.pdf`" target="_blank"><svg-icon class="base-icon" name="print"></svg-icon><span class="hidden-xs left-10 bold-weight text-uppercase">Print</span></a>
                </div>
              </div>

              <div class="col-xs-6 col-lg-5 text-right">
                <div class="icon-link">
                  <a :href="`/client-app/proposals/${counselRequest.proposalId}`" target="_blank">
                    <svg-icon class="base-icon" name="present"></svg-icon><span class="hidden-xs left-10 bold-weight text-uppercase">Present</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row bottom-30">
            <div class="col-md-8 col-lg-9">
              <div class="large-page-heading bottom-20">
                Proposal: {{ counselRequest.nameForClientsReference }}
              </div>

              <div class="size-text-13px">
                <div>
                  <span class="semibold-weight right-5">Status:</span>{{ displayStatus }}

                  <close-request-confirmation
                    v-if="isActiveRequest"
                    class="inline-block left-10"
                    :has-scheduled-appointments="hasScheduledAppointments"
                    :on-confirm="closeRequest">
                    <template #modal-trigger="{ openModal }">
                      <button type="button" class="pseudo-link-blue semibold-weight" @click="openModal">Close Request</button>
                    </template>
                  </close-request-confirmation>

                  <resubmit-closed-request-modal
                    v-if="isCompletedRequest"
                    class="inline-block left-10"
                    :counsel-request="counselRequest">
                  </resubmit-closed-request-modal>
                </div>

                <div class="top-10">
                  <span class="semibold-weight right-5">Request #:</span> {{ counselRequest.id }}
                </div>

                <div class="top-10" v-if="hasProjectManager">
                  <span class="semibold-weight right-5">Priori Project Manager:</span>
                  <span class="right-10">{{ proposal.projectManager.fullName }}</span>
                  <button type="button" class="pseudo-link-blue semibold-weight" @click="askProjectManager" v-if="!isClosedRequest"><span class="visible-xs-inline bold-link blue-link"><svg-icon name="envelope" class="base-icon"></svg-icon></span><span class="hidden-xs">Send Message</span></button>
                </div>

                <div class="d-flex-not-xs col-gap-10 top-10" v-if="hasRfpDescription">
                  <span class="semibold-weight">Project&nbsp;Description:</span>
                  <collapsable-text :text="proposal.rfpDescription" :char-limit="100" class="inline-block top-5-xs"></collapsable-text>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-lg-3">
              <project-team
                :project-resource="counselRequest"
                :workspace="user.workspace"
                :can-add="isActiveRequest">
              </project-team>
            </div>
          </div>

          <loading-section name="lawyers">
            <div class="size-text-13px bottom-50" v-if="showNextStepsSection">
              <div class="semibold-weight size-text-18px bottom-20">
                Next Steps:
              </div>

              <next-steps :proposal="proposal"></next-steps>
            </div>

            <dismissible-cta
              class="bottom-30"
              context="rfp-details"
              id="engagement-letter-templates"
              :dismissible="false">
              <span>
                Do you need an engagement letter?
                <a href="/client-app/documents/templates" class="semibold-weight">Check out our engagement letter templates!</a>
              </span>
            </dismissible-cta>

            <div class="shadowed-box bottom-30" v-if="isNewRequest || (!hasPossibleLawyers && !isMatterOpen)">
              <div class="box-content">
                <div class="top-20 bottom-20 normal-weight gray-text text-center">
                  <span v-if="isNewRequest">We are reviewing this RFP and will send you a custom proposal with lawyers from our vetted attorney network that match your project requirements. This typically occurs within 1-3 business days and you will be notified by email when your matches are ready.</span>

                  <span v-if="counselRequest.rejectedMax()">We are currently in the process of handpicking new lawyers based on your feedback.</span>

                  <span v-if="isClosedRequest">You closed this request.</span>
                </div>
              </div>
            </div>

            <div class="bottom-30" v-for="(pl, index) in possibleLawyers" :key="pl.id">
              <possible-lawyer-card
                :possible-lawyer="pl"
                :order-number="index + 1"
                :on-message-lawyer="messageUser"
                :init-with-open-question-modal="plAskQuestion === pl.id"
                :on-ask-question="askQuestion"
                :on-schedule-appointment="startSchedulingAppt"
                :on-update-appointment="updateAppointment"
                :on-confirm-appointment="confirmAppointment"
                :on-reschedule-appointment="rescheduleAppointment"
                :on-cancel-appointment="cancelAppointment"
                :on-request-proposal="requestProposal"
                :on-lawyer-rejected="reloadPage"
                :on-sign-engagement-letter="engagementLetterSigned"
                :on-hire-lawyer="hireLawyer">
              </possible-lawyer-card>
            </div>

            <div class="shadowed-box" v-if="hasPossibleLawyers && !isCompletedRequest">
              <div class="box-content symmetrical">
                <div class="row vertical-center-not-xs">
                  <div class="col-sm-8">
                    <div class="size-text-base semibold-weight bottom-10">
                      Not what you are looking for?
                    </div>

                    <div class="dark-gray-text size-text-13px">
                      Please message your Project Manager if you need additional options.
                    </div>
                  </div>

                  <div class="col-sm-4 top-20-xs">
                    <button type="button" class="nv-button-white smaller" @click="askProjectManager">Send Message</button>
                  </div>
                </div>
              </div>
            </div>
          </loading-section>
        </div>
      </div>
    </div>

    <send-message-modal
      ref="messageModal"
      :recipients="messageRecipients"
      :on-send="askQuestionCallback">
    </send-message-modal>

    <scheduling-modal
      v-if="schedulingAppt"
      ref="schedulingModal"
      :appointment="appointment"
      :possible-participants="possibleApptParticipants"
      :selected-participants="selectedApptParticipants"
      :on-submit="scheduleAppointment">
    </scheduling-modal>
  </div>
</template>

<script>
import NextSteps from 'vue-app/marketplace/client/proposals/next-steps.vue';
import ProjectTeam from 'vue-app/marketplace/client/shared/project-team.vue';
import DismissibleCta from 'vue-app/marketplace/shared/dismissible-cta.vue';
import PossibleLawyerCard from 'vue-app/marketplace/client/requests/possible-lawyer-card.vue';
import ResubmitClosedRequestModal from 'vue-app/marketplace/client/requests/resubmit-closed-request-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import CollapsableText from 'vue-app/shared/components/collapsable-text.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SendMessageModal from 'vue-app/shared/components/send-message-modal.vue';
import CloseRequestConfirmation from 'vue-app/marketplace/shared/close-request-confirmation.vue';
import SchedulingModal from 'vue-app/marketplace/shared/appointments/scheduling-modal.vue';

import Proposal from 'resources/marketplace/proposal.js';
import PossibleLawyer from 'resources/marketplace/possible-lawyer.js';
import CounselRequest from 'resources/marketplace/counsel-request.js';
import Matter from 'resources/marketplace/matter.js';
import Appointment from 'resources/marketplace/appointment.js';
import Consultation from 'resources/marketplace/consultation.js';
import MessagingService from 'resources/marketplace/messaging-service.js';

import LoadingService from 'vue-app/shared/services/loading-service.js';
import TimeZoneHelper from 'vue-app/shared/services/time-zone-helper.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import useAppointmentWatcherStore from 'vue-app/stores/marketplace/appointment-watcher.js';
import { mapState } from 'pinia';
import { scrollToTop } from 'misc/ui-helpers.js';

export default {
  name: 'MarketplaceClientRequestsShow',

  components: {
    NextSteps,
    ProjectTeam,
    DismissibleCta,
    PossibleLawyerCard,
    ResubmitClosedRequestModal,
    SvgIcon,
    CollapsableText,
    LoadingSection,
    SendMessageModal,
    CloseRequestConfirmation,
    SchedulingModal
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      counselRequest: this.initData.counselRequest,
      proposal: { projectManager: {} },
      possibleLawyers: [],
      messageRecipients:  [],
      plAskQuestion: parseInt(this.initData.query.question),
      askQuestionCallback: (() => {}),
      schedulingAppt: false,
      appointment: {},
      selectedApptParticipants: []
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    hasPossibleLawyers() {
      return this.possibleLawyers.length > 0;
    },

    isNewRequest() {
      return this.counselRequest.newStatus();
    },

    isMatterOpen() {
      return this.counselRequest.matterOpen();
    },

    isClosedRequest() {
      return this.counselRequest.adminClosed();
    },

    isCompletedRequest() {
      return this.counselRequest.completed();
    },

    isActiveRequest() {
      return !this.isCompletedRequest;
    },

    showNextStepsSection() {
      return !this.isNewRequest && !this.isCompletedRequest;
    },

    displayStatus() {
      return this.counselRequest.displayStatus();
    },

    hasProjectManager() {
      return this.proposal.projectManager && this.proposal.projectManager.fullName;
    },

    hasRfpDescription() {
      return this.proposal.rfpDescription && this.proposal.rfpDescription.length > 0;
    },

    hasScheduledAppointments() {
      return this.counselRequest.scheduledAppointmentsCount > 0;
    },

    possibleApptParticipants() {
      return this.possibleLawyers.reduce((acc, pl) => {
        if (pl.hasAppointment()) { return acc; }

        pl.lawyer.possibleLawyerId = pl.id;
        pl.lawyer.lawFirmName = (pl.lawFirm || {}).name;
        return [...acc, pl.lawyer];
      }, []);
    }
  },

  beforeMount() {
    this.refreshProposal();
  },

  mounted() {
    Proposal.track({ id: this.counselRequest.proposalId });
    useAppointmentWatcherStore().$subscribe(() => { this.refreshProposal(); });
  },

  methods: {
    refreshProposal() {
      LoadingService.around('lawyers', () => {
        return Proposal.get({ id: this.counselRequest.proposalId }).then((proposal) => {
          this.proposal = proposal;

          if (proposal.showClosedProposalLawyers()) {
            this.possibleLawyers = proposal.closedProposalLawyers.map(this.mapLawyer.bind(this));
          }
          else {
            this.possibleLawyers = proposal.possibleLawyers.map(this.mapLawyer.bind(this));
          }
        });
      });
    },

    mapLawyer(lawyer) {
      const pl = new PossibleLawyer(lawyer);

      pl.loadMatter();
      pl.counselRequest = this.counselRequest;

      if (pl.appointmentTime) {
        const appt = TimeZoneHelper.convertToMomentUseRailsTimeZone(pl.appointmentTime, this.counselRequest.requester.timeZone);
        pl.appointmentTime = appt.format('dddd, MMMM D, YYYY [at] h:mm A z');
      }

      return pl;
    },

    closeRequest(description) {
      CounselRequest.clientClose(this.counselRequest.id, { description: description }).then(() => {
        this.reloadPage();
        NotificationService.success('You successfully closed the counsel request.', true);
      });
    },

    askProjectManager() {
      this.messageUser(this.counselRequest.projectManager);
    },

    messageUser(user) {
      this.messageRecipients = [user];
      this.$refs.messageModal.openModal();
    },

    askQuestion(possibleLawyer) {
      possibleLawyer.lawyer.possibleLawyerId = possibleLawyer.id;

      this.askQuestionCallback = () => {
        if (possibleLawyer.status === 'suggested') {
          possibleLawyer.status = 'contacting';
        }
      };

      this.messageUser(possibleLawyer.lawyer);
    },

    startSchedulingAppt(possibleLawyer) {
      if (!possibleLawyer.ableToSchedule()) { return; }

      this.appointment = Appointment.newAppointment();
      this.selectedApptParticipants = [possibleLawyer.lawyer];

      return Consultation.scheduledTimesFor(this.user).then((conflictingTimes) => {
        this.appointment.conflictingTimes.consultations = conflictingTimes;
        this.schedulingAppt = true;
        this.$nextTick(() => { this.$refs.schedulingModal.initModal(true); });
      });
    },

    scheduleAppointment(appointment, availability, selectedParticipants) {
      return appointment.submitSuggestedTimes(this.user, availability, selectedParticipants).then(() => {
        MessagingService.refresh();
        this.refreshProposal();
      });
    },

    updateAppointment(possibleLawyer, appointment, availability) {
      const participant = appointment.participantFor(this.user);

      return appointment.updateSuggestedTimes(participant, availability).then((appt) => {
        MessagingService.refresh();
        possibleLawyer.appointment = appt;
      });
    },

    confirmAppointment(possibleLawyer, timeslot) {
      const params = {
        id: possibleLawyer.appointment.id,
        appointment: {
          consultationsAttributes: [{ startsAt: timeslot.asDate() }]
        }
      };

      return Appointment.update(params).then((appt) => {
        MessagingService.refresh();
        this.counselRequest.scheduledAppointmentsCount += 1;
        possibleLawyer.appointment = appt;
      });
    },

    cancelAppointment(possibleLawyer, appointment, reason) {
      const participant = appointment.participantFor(this.user);

      return appointment.cancel(reason, participant).then((appt) => {
        MessagingService.refresh();
        this.counselRequest.scheduledAppointmentsCount -= 1;
        possibleLawyer.appointment = appt;
      });
    },

    rescheduleAppointment(possibleLawyer, reason, consultation, availability) {
      const appointment = consultation.appointment;
      const participant = appointment.participantFor(this.user);

      return appointment.reschedule(reason, consultation, availability, participant).then((appt) => {
        MessagingService.refresh();
        this.counselRequest.scheduledAppointmentsCount -= 1;
        possibleLawyer.appointment = appt;
      });
    },

    requestProposal(possibleLawyer) {
      const params = {
        id: possibleLawyer.id,
        possibleLawyer: { proposalRequested: true }
      };

      PossibleLawyer.update(params).then(() => {
        possibleLawyer.proposalRequested = true;
        this.refreshProposal();
        NotificationService.success(`You have requested a formal proposal from ${possibleLawyer.lawyer.fullName}. You will be notified by email when the proposal is ready.`);
      });
    },

    reloadPage() {
      window.location.reload();
    },

    engagementLetterSigned(possibleLawyer, response) {
      possibleLawyer.matter.engagementLetter.url = response.url;
      possibleLawyer.matter.engagementLetter.coord = JSON.stringify(response.coord);
    },

    hireLawyer(possibleLawyer, afterFn) {
      return Matter.openWithoutPayment({ id: possibleLawyer.matter.id }).then(
        () => {
          NotificationService.success(`You’ve successfully accepted your proposal from ${possibleLawyer.lawyer.firstName} ${possibleLawyer.lawyer.lastName}. We have notified your lawyer and they will be in touch shortly to get started with the work. You can work directly with your lawyer from here and use the project page below to view project information, including invoices and shared documents.`, true);
          if (afterFn) { afterFn(); }

          window.location = `/client-app/projects/${possibleLawyer.matter.id}`;
        },
        (response) => {
          if ((response.response.data.errors || []).includes('already rejected')) {
            NotificationService.error('That lawyer has declined the project.', true);
          }
          else {
            NotificationService.error('There was a problem hiring the lawyer.', true);
          }
          if (afterFn) { afterFn(); }

          this.reloadPage();
          scrollToTop();
        }
      );
    }
  }
};
</script>

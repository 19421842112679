<template>
  <div class="custom-stepper">
    <div class="step-header">
      <div v-for="step in steps" :key="step.number" :class="['step-item', { 'selected': isActiveStep(step.number) }]">
        <div :class="['vertical-center', { 'add-spacer-after': step.number !== steps.length }]">
          <span :class="`stepper-label status-${stepStatus(step.number)} step-${step.number}`">
            <svg-icon name="checkmark-no-circle" class="base-icon" v-if="stepIsComplete(step.number)"></svg-icon>
            <span v-else>{{ step.number }}</span>
          </span>
          <span class="stepper-label-text">{{ step.label }}</span>
        </div>
      </div>
    </div>

    <slot
      :active-step="activeStep"
      :move-to-next-step="nextStep"
      :move-to-prev-step="previousStep">
    </slot>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RfqStepper',

  components: {
    SvgIcon
  },

  data() {
    return {
      activeStep: 1,
      steps: [
        { number: 1, label: 'Recipients' },
        { number: 2, label: 'Project Details' },
        { number: 3, label: 'Law Firm Questions' },
        { number: 4, label: 'Review & Send' }
      ]
    };
  },

  mounted() {
    this.setSpacerCustomCssProperties();

    window.addEventListener('resize', this.setSpacerCustomCssProperties);
    this.$emit('step-change', this.activeStep);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setSpacerCustomCssProperties);
  },

  methods: {
    previousStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
        this.$emit('step-change', this.activeStep);
      }
    },

    nextStep(rfq) {
      if (this.activeStep < this.steps.length) {
        this.activeStep++;
        this.$emit('step-change', { newActiveStep: this.activeStep, rfq: rfq });
      }
    },

    isActiveStep(stepIndex) {
      return stepIndex === this.activeStep;
    },

    stepStatus(stepIndex) {
      if (stepIndex === this.activeStep) {
        return 'active';
      }
      else if (stepIndex < this.activeStep) {
        return 'complete';
      }
      else {
        return 'incomplete';
      }
    },

    stepIsComplete(stepIndex) {
      return this.stepStatus(stepIndex) === 'complete';
    },

    setSpacerCustomCssProperties() {
      const stepHeader = document.querySelector('.step-header');

      if (stepHeader) {
        const stepHeaderWidth = window.getComputedStyle(stepHeader).width;
        document.documentElement.style.setProperty('--step-header-width', stepHeaderWidth);
      }

      const stepItems = document.querySelectorAll('.step-item');

      let totalWidth = 0;
      stepItems.forEach((stepItem) => {
        const stepItemWidth = stepItem.offsetWidth;

        totalWidth += stepItemWidth;
      });
      document.documentElement.style.setProperty('--step-items-sum', totalWidth + 'px');
      document.documentElement.style.setProperty('--number-of-steps', this.steps.length);
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .step-header {
    margin-bottom: 30px;

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .step-item {
    display: none;
    padding-right: 5px;

    @media (min-width: 768px) {
      display: inline-block;
      padding-left: 5px;
      padding-bottom: 15px;
    }

    .stepper-label-text {
      font-size: 15px;
    }

    &.selected {
      display: inline-block;
      border-bottom: 4px solid $k-blue;
      padding-bottom: 11px;

      .stepper-label-text {
        font-weight: 600;
      }
    }
  }

  .add-spacer-after {
    position: relative;

    &::after {
      @media (min-width: 768px) {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% + 15px);
        height: 1px;
        background: $k-gray;
        // this is the calculation for the space between objects that have
        // display:flex and justify-content: space-between.
        width: calc(((var(--step-header-width) - var(--step-items-sum)) / var(--number-of-steps)));
      }
    }
  }

  .stepper-label {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    color: $white;
    border-radius: 50%;
    font-weight: 700;
    text-align: center;
    line-height: 24px;

    &.status-active {
      display: inline-block;
      background: $k-blue;
    }

    &.status-incomplete {
      background: $pill-gray;
    }

    &.status-complete {
      background: $k-blue;

      .base-icon {
        position: absolute;
        top: 4px;
        left: 4px;
        width: 16px;
        height: 16px;
        fill: $white;
      }
    }
  }
</style>

<template>
  <priori-modal ref="resubmitModal" modal-id="resubmit-modal" title="Resubmit Request">
    <template #modal-trigger="modalProps">
      <button type="button" class="nv-button-blue smaller" @click="modalProps.openModal()">Resubmit Request</button>
    </template>

    <template #default>
      <div v-if="showConfirmModal">
        <div class="size-text-16px bottom-30">
          Thank you for resubmitting your request. A Priori team member will review your request and contact you shortly.
        </div>

        <div class="row">
          <div class="col-sm-6 col-sm-offset-3">
            <button type="button" class="nv-button-white smaller" @click="dismissModal">Close</button>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="fromInteractive" class="dark-gray-text text-italic">
          {{ counselRequest.rfpDescription }}

          <hr>
        </div>

        <div class="bottom-10">
          Please let us know requested changes to your original RFP. Once you&rsquo;re finished, select &ldquo;Submit Request&rdquo; to send your new RFP to the Priori client team.
        </div>

        <form role="form" class="a-form" novalidate @submit.prevent>
          <div class="bottom-30">
            <textarea-vertical
              label=""
              name="note"
              :autoresize="true"
              id="note"
              v-model="note"
              :maxlength="400"
              :with-limit="true">
            </textarea-vertical>
          </div>
        </form>

        <div class="row">
          <div class="col-sm-6 col-lg-5">
            <loading-button name="submitButton" lb-class="nv-button-blue a-button-size" @lb-click="save">Submit Request</loading-button>
          </div>

          <div class="col-sm-4 top-20-xs">
            <button type="button" class="nv-button-white a-button-size" @click="dismissModal">Cancel</button>
          </div>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import TextareaVertical from 'vue-app/shared/components/textarea-vertical.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import CounselRequest from 'resources/marketplace/counsel-request.js';

export default {
  name: 'ResubmitClosedRequestModal',

  components: {
    PrioriModal,
    TextareaVertical,
    LoadingButton
  },

  props: {
    counselRequest: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      note: null,
      showConfirmModal: false
    };
  },

  computed: {
    fromInteractive() {
      return this.counselRequest.fromInteractive();
    }
  },

  methods: {
    save() {
      LoadingService.loading('submitButton');
      CounselRequest.reopen(this.counselRequest.id, { counsel_request: { note: this.note } })
        .then(() => {
          this.showConfirmModal = true;
        })
        .finally(() => {
          LoadingService.done('submitButton');
        });
    },

    dismissModal() {
      this.$refs.resubmitModal.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  .summary-box.no-header .settings-row.expand-links {
    padding: 0;

    a {
      padding: 35px 25px;
      display: inline-block;
      width: 100%;

      &.no-decorate, &.no-decorate:hover {
        text-decoration: none;
      }
    }
  }
</style>

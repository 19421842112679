<template>
  <div :class="['featured-lawyer', { 'alsp-member': isAlspMember }]">
    <profile-photo class="featured-lawyer-item" :user="lawyer" :small="true"></profile-photo>

    <div class="featured-lawyer-item main-info">
      <div class="size-text-15px semibold-weight text-ellipsis">
        {{ fullName }}
      </div>

      <div class="secondary-text">
        <div>
          {{ title }}

          <span v-if="!isAlspMember && yearsExperience">
            <span class="left-5 right-5 bold-weight" v-if="title">&#8231;</span>
            {{ yearsExperience }} years&rsquo; experience
          </span>

          <span v-if="location">
            <span class="left-5 right-5 bold-weight" v-if="title || yearsExperience">&#8231;</span>
            {{ location }}
          </span>
        </div>

        <div v-if="!isAlspMember && bars && bars.length">
          Bars: {{ bars }}
        </div>

        <div class="top-20" v-if="isAlspMember">
          <span class="simple-text">{{ bio }}</span>
        </div>
      </div>
    </div>

    <div class="featured-lawyer-item profile-link" v-if="!isAlspMember">
      <a :href="`/client-app/lawyers/${lawyer.id}`" class="dark-gray-link" target="_blank">View profile</a>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'FeaturedLawyer',

  components: {
    ProfilePhoto
  },

  props: {
    featuredLawyer: {
      type: Object,
      required: true
    }
  },

  computed: {
    lawyer() {
      return this.featuredLawyer.featurable;
    },

    fullName() {
      return this.lawyer.fullName;
    },

    title() {
      return this.lawyer.currentPosition || this.lawyer.title;
    },

    yearsExperience() {
      return this.lawyer.yearsExperience;
    },

    location() {
      return this.lawyer.location;
    },

    bars() {
      return this.lawyer.barAdmissions.map((bar) => [bar.location, this.parseDate(bar.admitDate)].join(', ')).join('; ');
    },

    bio() {
      return this.lawyer.bio;
    },

    isAlspMember() {
      return this.featuredLawyer.featurableType === 'AlspMember';
    }
  },

  methods: {
    parseDate(date) {
      return DateFilter.filter(date, { year: 'numeric' });
    }
  }
};
</script>

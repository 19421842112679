<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Requests
    </div>

    <div class="box-content symmetrical normal-weight">
      <loading-section name="counselRequests">
        <div v-for="(request, idx) in requestsToDisplay" :key="request.id">
          <div class="row">
            <div class="col-md-5 col-lg-6 dash-project-container" @click="navigateTo(request)">
              <div class="dash-project-name text-ellipsis">
                {{ request.nameForClientsReference }}
              </div>

              <div class="text-ellipsis">
                Request Submitted: <span class="semibold-weight">{{ dateSubmitted(request) }}</span>
              </div>
            </div>

            <div class="col-sm-5 col-sm-push-7 col-md-4 col-md-push-0 col-lg-3">
              <div class="dash-btn-container overlap-photos" v-if="request.lawyerPhotos.length">
                <span v-for="(photoUrl) in lawyerPhotos(request)" :key="photoId(photoUrl)">
                  <img :src="photoUrl" alt="Photo" class="user-pic" v-if="hasPhoto(photoUrl)">
                  <div class="missing-photo" v-if="!hasPhoto(photoUrl)">
                    {{ photoUrl }}
                  </div>
                </span>

                <span class="more-photos" v-if="request.lawyerPhotos.length > 3">+{{ request.lawyerPhotos.length - 2 }}</span>
              </div>
            </div>

            <div class="col-sm-7 col-sm-pull-5 col-md-3 col-md-pull-0 col-lg-3">
              <div class="dash-btn-container">
                <button type="button" class="nv-button-white smaller top-10" @click="navigateTo(request)">View Details</button>
              </div>
            </div>
          </div>

          <hr class="kenny-hr" v-if="idx !== requestsToDisplay.length - 1">
        </div>

        <div v-if="requests.length > 3">
          <hr class="kenny-hr">

          <div class="view-all-container">
            <a href="/client-app/projects/filter/request" class="purple-text">View All Requests</a>
          </div>
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import { orderBy, slice, uniqueId } from 'lodash';

export default {
  name: 'Requests',

  components: {
    LoadingSection
  },

  props: {
    requests: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    requestsToDisplay() {
      return slice(orderBy(this.requests, ['updatedAt'], ['desc']), 0, 3);
    }
  },

  methods: {
    dateSubmitted(request) {
      const date = new Date(request.createdAt);
      return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date);
    },

    photoId(photoUrl) {
      return uniqueId(`${photoUrl}-`);
    },

    hasPhoto(photoUrl) {
      // NOTE: photoUrl contains lawyer initials for missing photos
      return photoUrl.includes('http');
    },

    navigateTo(request) {
      window.location.href = '/client-app/requests/' + request.id;
    },

    lawyerPhotos(request) {
      if (request.lawyerPhotos.length === 3) {
        return request.lawyerPhotos;
      }
      else {
        return request.lawyerPhotos.slice(0, 2);
      }
    }
  }
};
</script>

import { filter, includes, orderBy, reduce, some } from 'lodash';

function lowerCaseClientName(invoice) {
  return (invoice.businessName || invoice.clientName).toLowerCase();
}

function lowerCaseLawyerName(invoice) {
  return invoice.lawyerName.toLowerCase();
}

function lowerCaseProjectName(invoice) {
  return invoice.matterName.toLowerCase();
}

function dueOrPaidAt(invoice) {
  return invoice.clientDueOrPaidAt();
}

export default {
  defaultSort:   'Invoice Date',
  defaultFilter: 'All',

  filterOptions: [
    'All',
    'Unpaid',
    'Paid',
    'Cancelled'
  ],

  sortOptions: [
    'Invoice Date',
    'Invoice Number',
    'Due/Paid Date',
    'Lawyer Name',
    'Project Name',
    'Invoice Total'
  ],

  normalizedSearchableData(invoices) {
    return reduce(invoices, (data, invoice) => {
      data[invoice.id] = [
        invoice.referenceNumber.toLowerCase(),
        invoice.lawyerName.toLowerCase(),
        invoice.matterName.toLowerCase()
      ];
      return data;
    }, {});
  },

  filterFunction(invoices, configurableFilter) {
    const value = configurableFilter.filterValue;

    if (value === 'All') {
      return invoices;
    }
    else if (value === 'Unpaid') {
      return filter(invoices, (i) => { return i.status === 'unpaid' || i.status === 'error'; });
    }
    else if (value === 'Paid') {
      return filter(invoices, { status: 'paid' });
    }
    else if (value === 'Cancelled') {
      return filter(invoices, { status: 'cancelled' });
    }
    else if (value === 'Written Off') {
      return filter(invoices, { status: 'written_off' });
    }
    else {
      return [];
    }
  },

  sortFunction(invoices, configurableFilter) {
    const value = configurableFilter.sortValue;

    if (value === 'Invoice Date') {
      return orderBy(invoices, ['sentAt'], ['desc']);
    }
    else if (value === 'Invoice Number') {
      return orderBy(invoices, ['referenceNumber'], ['desc']);
    }
    else if (value === 'Due/Paid Date') {
      return orderBy(invoices, [dueOrPaidAt], ['desc']);
    }
    else if (value === 'Client Name') {
      return orderBy(invoices, [lowerCaseClientName], ['asc']);
    }
    else if (value === 'Project Name') {
      return orderBy(invoices, [lowerCaseProjectName], ['asc']);
    }
    else if (value === 'Client Total' || value === 'Invoice Total') {
      return orderBy(invoices, ['total'], ['desc']);
    }
    else if (value === 'Lawyer Name') {
      return orderBy(invoices, [lowerCaseLawyerName], ['asc']);
    }
    else {
      return [];
    }
  },

  searchFunction(invoices, configurableFilter) {
    return invoices.filter((invoice) => {
      return some(configurableFilter.normalizedData[invoice.id], (str) => includes(str, configurableFilter.searchValue.toLowerCase()));
    });
  }
};

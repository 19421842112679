import BaseResource from './base-resource';

class InternalMessage extends BaseResource {
  static baseUrl = '/internal_messages.json';

  constructor(opts) {
    super(opts);

    _.each(this.parts, (part) => { part.body = part.body || ''; });
  }

  save() {
    if (this.valid()) { return super.save(); }

    const message = this;
    message.errors = ['Must answer required fields'];

    return new Promise((resolve, reject) => { reject(message); });
  }

  attributes() {
    return {
      domain: this.domain,
      parts: this.submittableParts()
    };
  }

  submittableParts() {
    return _.reject(this.parts, function (part) {
      return part.body.length === 0 && !part.required;
    });
  }

  valid() {
    return !_.some(this.parts, (part) => { return part.required && part.body.length === 0; });
  }
}

export default InternalMessage;

<template>
  <div class="pl-section highlighted-section">
    <div>
      <span class="semibold-weight">Review the attorney&rsquo;s proposal for this engagement below.</span> If there are any errors or changes required, please <a href="" class="semibold-weight" @click.prevent="messageLawyer">message {{ lawyer.firstName }}</a> and they can edit their proposal. If the proposal is acceptable, {{ needsToSignEngagementLetter ? 'please e-sign the engagement letter and then' : 'you can' }} click the &ldquo;Hire&rdquo; button to accept the proposal and hire the attorney. The attorney will then be notified and can contact you to get started.
    </div>

    <hr class="kenny-hr">

    <div class="size-text-base semibold-weight bottom-20">
      Attorney Proposal
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="tiny-header-text bottom-5">
          Lawyers
        </div>

        <div class="bottom-20">
          <div>
            {{ lawyer.fullName }}
          </div>

          <div v-for="matterLawyer in matter.matterLawyers" :key="matterLawyer.id">
            {{ matterLawyer.lawyer.fullName }}
          </div>
        </div>

        <div class="tiny-header-text bottom-5">
          Client
        </div>

        <div class="bottom-5">
          <span class="semibold-weight">Company:</span> {{ matter.client.businessName || matter.client.fullName }}
        </div>

        <div class="bottom-5">
          <span class="semibold-weight">Primary Contact:</span> {{ matter.client.fullName }}
        </div>

        <div class="bottom-20">
          <span class="semibold-weight">Email:</span> {{ matter.client.email }}
        </div>

        <div class="tiny-header-text bottom-5">
          Project
        </div>

        <div class="bottom-5">
          <span class="semibold-weight">Project Name:</span> {{ matter.nameForLawyersReference }}
        </div>

        <div class="bottom-20">
          <div>
            <span class="semibold-weight">Project Description:</span> {{ matter.description || '—' }}
          </div>

          <div class="top-5" v-if="matter.clientMatterIdent">
            <span class="semibold-weight">Client Matter ID:</span> {{ matter.clientMatterIdent }}
          </div>

          <div class="top-5" v-if="matter.lowerFeeEstimate || matter.upperFeeEstimate">
            <span class="semibold-weight">Estimated Fees:</span> {{ estimatedFees }}
          </div>
        </div>

        <div class="tiny-header-text bottom-5">
          Proposed Rates
        </div>

        <div>
          <div class="list-spacing-10" v-for="rate in matter.matterRatePackages" :key="rate.id">
            <span class="semibold-weight">{{ rate.rateName }}: <span class="green-text">{{ formatCurrency(rate.clientRateDollars) }}{{ rate.tag() }}</span></span>

            <div class="top-5" v-if="rate.rateDescription">
              {{ rate.rateDescription }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 top-20-xs">
        <div class="bottom-20" v-if="hasLegalArrangementRider || matter.netDaysPayable">
          <div class="tiny-header-text bottom-5">
            Billing Terms
          </div>

          <div v-if="hasLegalArrangementRider">
            <div class="bottom-5" v-for="item in legalArrangementRiderAttributes">
              <span class="semibold-weight">{{ item.label }}:</span> {{ item.value }}
            </div>
          </div>

          <div v-if="matter.netDaysPayable">
            <span class="semibold-weight">Invoices Due:</span> Net {{ matter.netDaysPayable }} Days
          </div>
        </div>

        <div>
          <div class="tiny-header-text bottom-5">
            Opening Invoice
          </div>

          <div>
            <span class="semibold-weight">Opening Invoice:</span> {{ matter.hasInitialInvoice ? 'Yes' : 'No' }}
          </div>

          <div class="top-5" v-if="matter.hasInitialInvoice">
            <a :href="`/invoices/${matter.initialInvoiceId}.pdf`" target="_blank">Download PDF Invoice</a>
          </div>
        </div>

        <div class="top-20" v-if="hasEngagementLetter">
          <div class="tiny-header-text bottom-5">
            Engagement Letter
          </div>

          <div class="bottom-5">
            <a :href="engagementLetter.url" target="_blank">Download</a>

            <span class="left-10 right-10" v-if="needsToSignEngagementLetter">&#8231;</span>

            <review-sign-engagement-letter
              :matter="matter"
              :on-submit="onSignEngagementLetter">
            </review-sign-engagement-letter>
          </div>

          <div class="dark-gray-text">
            <span v-if="needsToSignEngagementLetter">
              Review and e-sign the engagement letter before accepting the proposal. If the engagement letter requires any changes, <a href="" @click.prevent="messageLawyer">message {{ lawyer.firstName }}</a> to request they update their proposal.
            </span>

            <span v-else>
              Note: When you hire the attorney, a copy of the signed engagement letter will be sent to the attorney and saved to the Project Documents section of the project page.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewSignEngagementLetter from 'vue-app/marketplace/client/requests/review-sign-engagement-letter.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';
import { compact } from 'lodash';

export default {
  name: 'FormalProposal',

  components: {
    ReviewSignEngagementLetter
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    matter: {
      type: Object,
      required: true
    },

    onMessageLawyer: {
      type: Function,
      required: true
    },

    onSignEngagementLetter: {
      type: Function,
      required: true
    }
  },

  computed: {
    lowerFeeEstimate() {
      return this.formatCurrency(this.matter.lowerFeeEstimate);
    },

    upperFeeEstimate() {
      return this.formatCurrency(this.matter.upperFeeEstimate);
    },

    estimatedFees() {
      return compact([this.lowerFeeEstimate, this.upperFeeEstimate]).join(' — ');
    },

    legalArrangementRider() {
      return this.matter.legalArrangementRider;
    },

    hasLegalArrangementRider() {
      return !!this.legalArrangementRider;
    },

    legalArrangementRiderAttributes() {
      const attributes = [
        { label: 'Disbursements', value: this.legalArrangementRider.disbursements },
        { label: 'Minimum Amount', value: this.legalArrangementRider.minimumAmount },
        { label: 'Term', value: this.legalArrangementRider.term },
        { label: 'Weekly Time Commitment', value: this.legalArrangementRider.weeklyTimeCommitment },
        { label: 'Termination', value: this.legalArrangementRider.termination },
        { label: 'Billing Frequency', value: this.legalArrangementRider.billingFrequency },
        { label: 'Additional Notes', value: this.legalArrangementRider.additionalNotes }
      ];

      return attributes.filter(attr => !!attr.value);
    },

    engagementLetter() {
      return this.matter.engagementLetter;
    },

    hasEngagementLetter() {
      return this.engagementLetter && this.engagementLetter.url;
    },

    engagementLetterCoordinates() {
      return JSON.parse(this.engagementLetter.coord.toString() || '[]');
    },

    clientSignature() {
      return this.engagementLetterCoordinates.find(coord => coord.name === 'clientSignature');
    },

    needsToSignEngagementLetter() {
      return this.hasEngagementLetter && this.clientSignature && !this.clientSignature.signed;
    }
  },

  methods: {
    formatCurrency(amount) {
      return CurrencyFilter.filter(amount);
    },

    messageLawyer() {
      this.onMessageLawyer(this.lawyer);
    }
  }
};
</script>

<template>
  <div class="hourly-rate-box primary-marketplace-gradient summary-box">
    <div :class="['multiple-content', { 'single-instance': isSingleRate }]">
      <div class="page-status" v-if="!isSingleRate">
        {{ page }} of {{ rates.length }}
      </div>

      <div :class="['size-text-11px bold-weight text-uppercase text-center bottom-5', { 'top-20': !isSingleRate, 'top-30': isSingleRate }]">
        {{ rateType }}
      </div>

      <div class="size-text-32px text-center">
        {{ formattedRate }}
      </div>

      <div class="button-container">
        <button type="button" class="nv-button" @click="openModal">View Details</button>
      </div>

      <div class="page-control-container">
        <card-pager :collection="rates" @page-changed="changePage"></card-pager>
      </div>
    </div>

    <modal v-model="modalOpen" size="md-sm" :transition="0" :header="false" :footer="false" append-to-body>
      <div class="close-modal" @click="dismissModal">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <div class="notice-modal rate-modal">
        <div class="notice-header rate-header">
          <svg-icon name="dollar" class="new-icon right-10"></svg-icon>
          <span class="rate-header-text">{{ rateType }} Details</span>
        </div>

        <div class="notice-regular-text">
          <div class="rate-name semibold-weight bottom-10">
            {{ rate?.rateName }}
          </div>

          <div v-if="hasDescription">
            {{ rate.rateDescription }}
          </div>

          <div class="missing-text" v-if="!hasDescription">
            No description provided
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import CardPager from 'vue-app/shared/components/card-pager.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';

export default {
  name: 'RatesCard',

  components: {
    CardPager,
    SvgIcon
  },

  mixins: [
    modalToggle
  ],

  props: {
    matter: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      modalOpen: false,
      page: 1
    };
  },

  computed: {
    isSingleRate() {
      return this.matter.matterRatePackages.length === 1;
    },

    rates() {
      return this.matter.matterRatePackages;
    },

    rate() {
      return this.rates[this.page - 1];
    },

    rateType() {
      return this.rate ? `${this.rate.rateType} Rate` : '';
    },

    hasDescription() {
      return this.rate && this.rate.rateDescription && this.rate.rateDescription.length;
    },

    formattedRate() {
      return CurrencyFilter.filter(this.rate?.clientRateDollars);
    }
  },

  methods: {
    changePage(page) {
      this.page = page;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .hourly-rate-box {
    border-radius: $border-radius-large;
  }

  :deep(.page-controls) {
    .base-icon {
      fill: $white;
    }

    .circle-icon {
      stroke: $white;
      fill: transparent;

      &.selected {
        fill: $white;
      }
    }
  }

  :deep(.multiple-content .page-status) {
    color: hsla(0, 0%, 100%, .5);
    border: 1px solid $white;
  }
</style>

<template>
  <div class="shadowed-box possible-lawyer-card">
    <div class="box-header less-padded">
      <div class="row size-text-13px">
        <div class="col-xs-4 col-sm-6 text-uppercase">
          <a href="" class="bold-link" @click.prevent="toggleDetails"><svg-icon name="minimize" class="base-icon right-10" v-if="expanded"></svg-icon><svg-icon name="expand" class="base-icon right-10" v-else></svg-icon></a>
          <span class="hidden-xs">Option {{ orderNumber }}</span>
        </div>

        <div class="col-xs-4 visible-xs-block text-center">
          <div class="section-number-container">
            <div class="section-number">
              {{ orderNumber }}
            </div>
          </div>
        </div>

        <div class="col-xs-4 col-sm-6 text-right text-uppercase">
          <reject-lawyer-button
            class="pull-right"
            :possible-lawyer="possibleLawyer"
            :on-lawyer-rejected="onLawyerRejected">
          </reject-lawyer-button>
        </div>
      </div>
    </div>

    <div :class="['pl-section pl-actions', { 'collapsed': !expanded }]">
      <profile-header
        :possible-lawyer="possibleLawyer"
        :lawyer="lawyer"
        :is-preview="true"
        :on-ask-question="askQuestion"
        :on-request-proposal="onRequestProposal"
        :on-schedule-appointment="startSchedulingAppt"
        :on-update-appointment="startUpdatingAppt"
        :on-confirm-appointment="startConfirmingAppt"
        :on-hire-lawyer="hireLawyer">
      </profile-header>
    </div>

    <div v-if="hasFormalProposal" v-show="expanded">
      <formal-proposal
        :lawyer="lawyer"
        :matter="matter"
        :on-message-lawyer="onMessageLawyer"
        :on-sign-engagement-letter="engagementLetterSigned">
      </formal-proposal>
    </div>

    <div class="pl-section purple-red-gradient" v-if="expanded && possibleLawyer.selected()">
      <div class="row vertical-center-not-xs">
        <div class="col-sm-8 col-lg-9 white-text size-text-16px">
          <span class="semibold-weight">Congratulations!</span> You hired {{ possibleLawyer.lawFirm?.name || lawyer.firstName }} for your project.
        </div>

        <div class="col-sm-4 col-lg-3 top-20-xs">
          <a :href="`/client-app/projects/${matter.id}`" class="nv-button smaller">View Project</a>
        </div>
      </div>
    </div>

    <div v-show="expanded">
      <div class="box-content">
        <proposal-lawyer-details
          :possible-lawyer="possibleLawyer"
          :inside-the-gate="true"
          :on-confirm-appointment="startConfirmingAppt"
          :on-reschedule-appointment="startReschedulingAppt"
          :on-cancel-appointment="startCancellingAppt"
          :is-marketplace-rfq="false">
        </proposal-lawyer-details>
      </div>
    </div>

    <scheduling-modal
      v-if="updatingAppt"
      ref="schedulingModal"
      :appointment="appointment"
      :on-cancel-appointment="startCancellingAppt"
      :on-submit="updateAppointment">
    </scheduling-modal>

    <choose-time-modal
      v-if="confirmingAppt"
      ref="chooseTimeModal"
      :appointment="appointment"
      :on-suggest-alternative-times="startUpdatingAppt"
      :on-request-cancellation="startCancellingAppt"
      :on-submit="confirmAppointment">
    </choose-time-modal>

    <rescheduling-modal
      v-if="reschedulingAppt"
      ref="reschedulingModal"
      :consultation="consultation"
      :appointment="appointment"
      :other-participants="otherApptParticipants"
      :on-submit="rescheduleAppointment"
      :on-dismiss-confirmation="dismissApptConfirmation">
    </rescheduling-modal>

    <cancelling-modal
      v-if="cancellingAppt"
      ref="cancellingModal"
      :appointment="appointment"
      :other-participants="otherApptParticipants"
      :on-submit="cancelAppointment"
      :on-dismiss-confirmation="dismissApptConfirmation">
    </cancelling-modal>
  </div>
</template>

<script>
import RejectLawyerButton from 'vue-app/marketplace/client/requests/reject-lawyer-button.vue';
import ProfileHeader from 'vue-app/marketplace/client/requests/profile-header.vue';
import FormalProposal from 'vue-app/marketplace/client/requests/formal-proposal.vue';
import ProposalLawyerDetails from 'vue-app/marketplace/client/proposals/proposal-lawyer-details.vue';
import SchedulingModal from 'vue-app/marketplace/shared/appointments/scheduling-modal.vue';
import ChooseTimeModal from 'vue-app/marketplace/shared/appointments/choose-time-modal.vue';
import ReschedulingModal from 'vue-app/marketplace/shared/appointments/rescheduling-modal.vue';
import CancellingModal from 'vue-app/marketplace/shared/appointments/cancelling-modal.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'PossibleLawyerCard',

  components: {
    RejectLawyerButton,
    ProfileHeader,
    FormalProposal,
    ProposalLawyerDetails,
    SchedulingModal,
    ChooseTimeModal,
    ReschedulingModal,
    CancellingModal
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    orderNumber: {
      type: Number,
      required: true
    },

    onMessageLawyer: {
      type: Function,
      required: true
    },

    initWithOpenQuestionModal: {
      type: Boolean,
      default: false
    },

    onAskQuestion: {
      type: Function,
      required: true
    },

    onScheduleAppointment: {
      type: Function,
      required: true
    },

    onUpdateAppointment: {
      type: Function,
      required: true
    },

    onConfirmAppointment: {
      type: Function,
      required: true
    },

    onRescheduleAppointment: {
      type: Function,
      required: true
    },

    onCancelAppointment: {
      type: Function,
      required: true
    },

    onRequestProposal: {
      type: Function,
      required: true
    },

    onLawyerRejected: {
      type: Function,
      required: true
    },

    onSignEngagementLetter: {
      type: Function,
      required: true
    },

    onHireLawyer: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      expanded: true,
      updatingAppt: false,
      confirmingAppt: false,
      reschedulingAppt: false,
      cancellingAppt: false
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    lawyer() {
      return this.possibleLawyer.lawyer;
    },

    matter() {
      return this.possibleLawyer.matter;
    },

    appointment() {
      return this.possibleLawyer.appointment;
    },

    consultation() {
      let consultation = this.appointment.consultationsAttributes.find((c) => {
        return c.status === 'scheduled';
      }) || {};
      consultation.appointment = this.appointment;
      return consultation;
    },

    otherApptParticipants() {
      return this.appointment.participantsOtherThan(this.user).map(ap => ap.participant);
    },

    hasFormalProposal() {
      return this.matter && this.possibleLawyer.rateSentNotSelectedStatus();
    }
  },

  mounted() {
    if (this.initWithOpenQuestionModal) {
      this.askQuestion(this.possibleLawyer);
    }
  },

  methods: {
    toggleDetails() {
      this.expanded = !this.expanded;
    },

    askQuestion(recipient) {
      this.onAskQuestion(recipient);
    },

    startSchedulingAppt() {
      this.onScheduleAppointment(this.possibleLawyer);
    },

    startUpdatingAppt() {
      this.stopConfirmingAppt();
      this.updatingAppt = true;
      this.$nextTick(() => { this.$refs.schedulingModal.initModal(); });
    },

    stopUpdatingAppt() {
      if (!this.updatingAppt) { return; }
      this.$refs.schedulingModal.closeModal();
    },

    updateAppointment(appointment, availability) {
      return this.onUpdateAppointment(this.possibleLawyer, appointment, availability);
    },

    startConfirmingAppt() {
      this.confirmingAppt = true;
      this.$nextTick(() => { this.$refs.chooseTimeModal.initModal(); });
    },

    stopConfirmingAppt() {
      if (!this.confirmingAppt) { return; }
      this.$refs.chooseTimeModal.closeModal();
    },

    confirmAppointment(timeslot) {
      return this.onConfirmAppointment(this.possibleLawyer, timeslot);
    },

    startReschedulingAppt() {
      this.reschedulingAppt = true;
      this.$nextTick(() => { this.$refs.reschedulingModal.initModal(); });
    },

    rescheduleAppointment(reason, consultation, availability) {
      return this.onRescheduleAppointment(this.possibleLawyer, reason, consultation, availability);
    },

    startCancellingAppt() {
      this.stopUpdatingAppt();
      this.stopConfirmingAppt();
      this.cancellingAppt = true;
      this.$nextTick(() => { this.$refs.cancellingModal.initModal(); });
    },

    cancelAppointment(appointment, reason) {
      return this.onCancelAppointment(this.possibleLawyer, appointment, reason);
    },

    dismissApptConfirmation() {
      this.updatingAppt = false;
      this.confirmingAppt = false;
      this.reschedulingAppt = false;
      this.cancellingAppt = false;
    },

    engagementLetterSigned(response) {
      if (!response) { return; }
      this.onSignEngagementLetter(this.possibleLawyer, response);
    },

    hireLawyer(afterFn) {
      return this.onHireLawyer(this.possibleLawyer, afterFn);
    }
  }
};
</script>

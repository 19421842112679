<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <a href="/client-app/dashboard" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Dashboard</a>
          </div>

          <div class="col-sm-6 text-right-not-xs">
            <a href="/client-app/documents/share" class="back-link"><svg-icon name="plus-circle" class="base-icon right-10"></svg-icon>Share Document</a>
          </div>
        </div>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="top-10 bottom-30">
            <horizontal-sort-filter
              :sort-filter="sortFilter"
              :filter-config="documentFilterConfig"
              @filter="setFilter"
              @sort="setSort"
              @search="setSearch">
            </horizontal-sort-filter>
          </div>

          <div class="shadowed-box">
            <div class="box-content normal-weight">
              <loading-section name="documents">
                <div v-for="(document, index) in visibleDocs" :key="document.id">
                  <document-card
                    :document="document"
                    :connected-to-google-drive="connectedToGoogleDrive"
                    :on-remove="removeDocument">
                  </document-card>

                  <hr class="kenny-hr" v-if="index !== lastDocumentIndex">
                </div>

                <div v-if="!hasDocuments">
                  <div class="top-30 bottom-30 gray-text tight-lines text-center">
                    You have no documents. <a href="/client-app/documents/share" class="dark-gray-text">Click here</a> to start sharing!
                  </div>
                </div>
              </loading-section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentCard from 'vue-app/marketplace/client/documents/document-card.vue';
import HorizontalSortFilter from 'vue-app/marketplace/shared/horizontal-sort-filter.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';

import LoadingService from 'vue-app/shared/services/loading-service.js';
import ConfigurableFilter from 'services/configurable-filter.js';
import DocumentFilterConfig from 'services/marketplace/filter-config/documents.js';
import ShareDocumentService from 'vue-app/shared/services/share-document-service.js';

import User from 'resources/user.js';
import Document from 'resources/document.js';
import DocumentReceipt from 'resources/document-receipt.js';
import DocumentTemplate from 'resources/document-template.js';

import { each, flatten, orderBy } from 'lodash';

export default {
  name: 'DocumentsIndex',

  components: {
    DocumentCard,
    HorizontalSortFilter,
    SvgIcon,
    LoadingSection
  },

  props: {
    initData: {
      type:     Object,
      required: true
    }
  },

  data() {
    const sortFilter = new ConfigurableFilter(DocumentFilterConfig, { filterValue: this.initData.filter });

    return {
      documents: [],
      connectedApis: [],
      sortFilter: sortFilter,
      documentFilterConfig: DocumentFilterConfig
    };
  },

  computed: {
    visibleDocs() {
      return this.sortFilter.filter(this.documents);
    },

    hasDocuments() {
      return this.visibleDocs.length > 0;
    },

    lastDocumentIndex() {
      return this.visibleDocs.length - 1;
    },

    connectedToGoogleDrive() {
      return new ShareDocumentService(this.connectedApis).connectedToGoogleDrive;
    }
  },

  mounted() {
    this.loadDocuments();
    this.loadConnectedApis();
  },

  methods: {
    loadDocuments() {
      LoadingService.loading('documents');

      const documentsPromise = Document.query().then(documents => {
        each(documents, document => document.yourFile = true);
        return documents;
      });

      const documentReceiptsPromise = DocumentReceipt.query();
      const documentTemplatesPromise = DocumentTemplate.query();

      Promise.all([documentsPromise, documentReceiptsPromise, documentTemplatesPromise]).then((values) => {
        this.documents = orderBy(flatten(values), ['updatedAt'], ['desc']);
        LoadingService.done('documents');
      });
    },

    loadConnectedApis() {
      User.connectedApis().then((resp) => { this.connectedApis = resp.data; });
    },

    setFilter(value) {
      this.sortFilter.filterBy(value);
    },

    setSort(value) {
      this.sortFilter.sortBy(value);
    },

    setSearch(value) {
      this.sortFilter.searchBy(value);
    },

    removeDocument(doc) {
      this.documents = this.documents.filter(d => d.id !== doc.id);
    }
  }
};
</script>

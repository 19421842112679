<template>
  <priori-modal modal-id="modalId" modal-size="md-sm" title="Edit Displayed Sections">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="secondary-btn-blue a-button-size" @click="initModal(openModal)">Edit Sections</button>
    </template>

    <template #default="{ closeModal }">
      <div v-for="section in availableSections">
        <div class="row tight-columns">
          <div class="col-xs-9 semibold-weight size-text-base">
            {{ section.label }}
          </div>

          <div class="col-xs-3 text-right">
            <button type="button" :class="['toggle-button blue-toggle smaller', { 'toggle-on': isSelected(section.slug) }]" @click="toggleSection(section.slug)"></button>
          </div>
        </div>

        <hr class="kenny-hr">
      </div>

      <div class="row top-30">
        <div class="col-sm-4">
          <button type="button" class="nv-button-blue a-button-size" @click="save(closeModal)">Save</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import { cloneDeep, without } from 'lodash';

export default {
  name: 'EditSectionsModal',

  components: {
    PrioriModal
  },

  props: {
    modalId: {
      type: String,
      required: true
    },

    selectedSections: {
      type: Array,
      required: true
    },

    availableSections: {
      type: Array,
      required: true
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      displaySections: []
    };
  },

  methods: {
    initModal(openModalFn) {
      this.displaySections = cloneDeep(this.selectedSections);
      openModalFn();
    },

    isSelected(slug) {
      return this.displaySections.includes(slug);
    },

    toggleSection(slug) {
      if (this.isSelected(slug)) {
        this.displaySections = without(this.displaySections, slug);
      }
      else {
        this.displaySections.push(slug);
      }
    },

    save(closeModalFn) {
      closeModalFn();
      this.onChange(this.displaySections);
    }
  }
};
</script>

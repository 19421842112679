<template>
  <div>
    <div class="titlebar vertical-center">
      <div class="medium-page-heading">
        Admin Settings
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-8">
              <loading-section name="lawFirm" class="bottom-30">
                <users-summary
                  v-if="firm"
                  :firm="firm">
                </users-summary>
              </loading-section>

              <loading-section name="lawFirm">
                <diversity-disclosure-settings
                  v-if="firm"
                  :firm="firm"
                  :on-save="saveFirm"
                  :on-removal-request="requestDataRemoval">
                </diversity-disclosure-settings>
              </loading-section>
            </div>

            <div class="col-sm-4 top-30-xs">
              <data-uploads class="bottom-30"></data-uploads>

              <data-locks></data-locks>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import DiversityDisclosureSettings from 'vue-app/scout/firm/settings/diversity-disclosure/diversity-disclosure-settings.vue';
import UsersSummary from 'vue-app/scout/firm/settings/users-summary.vue';
import DataUploads from 'vue-app/scout/firm/settings/data-uploads.vue';
import DataLocks from 'vue-app/scout/firm/settings/data-locks.vue';
import ScoutFirm from 'resources/scout/scout-firm.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';

export default {
  name: 'ScoutFirmSettingsIndex',

  components: {
    LoadingSection,
    DiversityDisclosureSettings,
    DataUploads,
    DataLocks,
    UsersSummary
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      firm: null,
      user: this.initData.lawyer
    };
  },

  mounted() {
    LoadingService.around('lawFirm', () => {
      return ScoutFirm.get({ id: this.user.lawFirmId, view: 'lawyer_admin_settings' }).then((firm) => {
        this.firm = firm;
      });
    });
  },

  methods: {
    requestDataRemoval(firmAttributes, successFn, errorFn) {
      ScoutFirm.requestDataRemoval(firmAttributes).then(
        () => {
          if (successFn) { successFn(); }
        },
        () => {
          NotificationService.error('Unable to request data removal. Please try again.');
          window.scrollTo({ top: 0, behavior: 'smooth' });
          if (errorFn) { errorFn(); }
        }
      );
    },

    saveFirm(firmAttributes, successFn, errorFn) {
      ScoutFirm.update({ ...firmAttributes, view: 'lawyer_admin_settings' }).then(
        (response) => {
          this.firm = response;
          if (successFn) { successFn(); }
        },
        () => {
          NotificationService.error('Unable to save changes. Please try again.');
          window.scrollTo({ top: 0, behavior: 'smooth' });
          if (errorFn) { errorFn(); }
        }
      );
    }
  }
};
</script>

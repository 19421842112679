<template>
  <span>
    <a href="" @click.prevent="signEngagementLetter" v-if="needsToBeSigned">Review & Sign</a>

    <engagement-letter-modal
      ref="engagementLetterModal"
      :engagement-letter-config="engagementLetterConfig"
      :on-submit="onSubmit">
    </engagement-letter-modal>
  </span>
</template>

<script>
import EngagementLetterModal from 'vue-app/marketplace/shared/engagement-letter-modal.vue';

export default {
  name: 'ReviewSignEngagementLetter',

  components: {
    EngagementLetterModal
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  computed: {
    engagementLetter() {
      return this.matter.engagementLetter;
    },

    hasEngagementLetter() {
      return this.engagementLetter && this.engagementLetter.url;
    },

    engagementLetterCoord() {
      return JSON.parse(this.engagementLetter.coord.toString() || '[]');
    },

    clientSignature() {
      return this.engagementLetterCoord.find(coord => coord.name === 'clientSignature');
    },

    needsToBeSigned() {
      if (!this.hasEngagementLetter) { return false; }
      return this.clientSignature && !this.clientSignature.signed;
    },

    engagementLetterConfig() {
      return {
        documentUrl: this.engagementLetter.url,
        coord: this.engagementLetterCoord,
        signature: null,
        role: 'client',
        matterId: this.matter.id
      };
    }
  },

  methods: {
    signEngagementLetter() {
      this.$refs.engagementLetterModal.init();
      this.$refs.engagementLetterModal.openModal();
    }
  }
};
</script>

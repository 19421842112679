<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-11 text-uppercase">
          Project Details
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Scout RFP #
        </div>

        <div class="col-sm-8">
          {{ rfq.id }}
        </div>
      </div>

      <div class="row top-30">
        <div class="col-sm-4 semibold-weight">
          RFP Submitted By
        </div>

        <div class="col-sm-8">
          {{ rfq.creatorFullName }}
        </div>
      </div>

      <div class="row top-30">
        <div class="col-sm-4 semibold-weight">
          Practice Group
        </div>

        <div class="col-sm-8">
          {{ rfq.assignedClientPracticeGroup || '—' }}
        </div>
      </div>

      <div class="row top-30">
        <div class="col-sm-4 semibold-weight">
          Responses Due By
        </div>

        <div class="col-sm-8">
          {{ formatDate(rfq.dueDate) }}
        </div>
      </div>

      <div v-if="hasBudget" class="row top-30">
        <div class="col-sm-4 semibold-weight">
          Budget
        </div>

        <div class="col-sm-8">
          {{ budget }}
        </div>
      </div>

      <div class="row top-30">
        <div class="col-sm-4 semibold-weight">
          Description of Work
        </div>

        <div class="col-sm-8">
          <span class="simple-text">{{ rfq.descriptionOfWork || '—' }}</span>
        </div>
      </div>

      <div v-if="counterpartyDetails" class="row top-30">
        <div class="col-sm-4 semibold-weight">
          Any relevant opposing party/counterparty details, including counsel
        </div>

        <div class="col-sm-8">
          <span class="simple-text">{{ counterpartyDetails }}</span>
        </div>
      </div>

      <div v-if="preferredRateStructure" class="row top-30">
        <div class="col-sm-4 semibold-weight">
          Preferred Rate Structure
        </div>

        <div class="col-sm-8">
          <span class="simple-text">{{ preferredRateStructure }}</span>
        </div>
      </div>

      <div v-if="estimatedTimeline" class="row top-30">
        <div class="col-sm-4 semibold-weight">
          Estimated Timeline
        </div>

        <div class="col-sm-8">
          <span class="simple-text">{{ estimatedTimeline }}</span>
        </div>
      </div>

      <div v-if="additionalInformation" class="row top-30">
        <div class="col-sm-4 semibold-weight">
          Additional Information
        </div>

        <div class="col-sm-8">
          <span class="simple-text">{{ additionalInformation }}</span>
        </div>
      </div>

      <div v-if="supportingDocuments" class="row top-30">
        <div class="col-sm-4 semibold-weight">
          Supporting Documents ({{ rfq.supportingDocuments.length }})
        </div>

        <div class="col-sm-8">
          <ul class="compact no-bullets">
            <li
              v-for="(doc, index) in rfq.supportingDocuments"
              class="list-spacing-2"
              :key="index">
              <a :href="doc.url" class="bold-weight" target="_blank" rel="noopener">{{ doc.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { compact }         from 'lodash';
import CurrencyFilter      from 'vue-app/shared/lib/currency-filter';
import DateFilter          from 'vue-app/shared/lib/date-filter';
import { mapState }        from 'pinia';
import useCurrentUserStore from 'vue-app/stores/current-user.js';

export default {
  name: 'RfqProjectDetails',

  props: {
    rfq: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    hasBudget() {
      return this.rfq.budgetMin || this.rfq.budgetMax;
    },

    budget() {
      return compact([CurrencyFilter.filter(this.rfq.budgetMin), CurrencyFilter.filter(this.rfq.budgetMax)]).join(' - ');
    },

    counterpartyDetails() {
      return this.rfq.counterpartyDetails;
    },

    preferredRateStructure() {
      return this.rfq.preferredRateStructure;
    },

    estimatedTimeline() {
      return this.rfq.estimatedTimeline;
    },

    additionalInformation() {
      return this.rfq.additionalInformation;
    },

    supportingDocuments() {
      return this.rfq.supportingDocuments.length ? this.rfq.supportingDocuments : null;
    }
  },

  methods: {
    formatDate(date) {
      return DateFilter.filterDate(date);
    }
  }
};
</script>

import Timeslot from 'services/marketplace/timeslot.js';
import { each, map, reduce } from 'lodash';
import { timeIntervals, workHours } from 'vue-app/shared/misc/shared-lists/time-intervals';

class AvailabilityScheduler {
  constructor(initialTimeslots = []) {
    this.timeslots = initialTimeslots;
    this.workTimes = map(workHours(), 'hour');

    this.timeslotsByLabel = reduce(this.timeslots, (obj, timeslot) => {
      obj[timeslot.label({ timeZone: 'UTC', format: 'DATETIME_FULL' })] = timeslot;
      return obj;
    }, {});
  }

  hoursByDate(opts) {
    const zone = opts.timeZone || 'UTC';

    return reduce(this.timeslots, (obj, timeslot) => {
      const timeslotInZone = timeslot.inZone(zone);
      const isoDate        = timeslotInZone.date();

      if (opts.boundary === 'workHours' && this.timeOutsideOfWorkHours(timeslotInZone)) {
        return obj;
      }

      obj[isoDate] = obj[isoDate] || [];
      obj[isoDate].push(timeslot);

      return obj;
    }, {});
  }

  timeOutsideOfWorkHours(timeslot) {
    return !this.workTimes.includes(timeslot.hour());
  }

  initializeDate(date) {
    each(timeIntervals(), (time) => {
      const timeComponents = time.hour.split(':');
      const timeForSlot    = date.set({ hour: timeComponents[0], minutes: timeComponents[1] });
      const timeslot       = new Timeslot(timeForSlot);
      const timeslotLabel  = timeslot.label({ timeZone: 'UTC', format: 'DATETIME_FULL' });

      if (this.timeslotsByLabel[timeslotLabel]) { return; }

      this.timeslots.push(timeslot);
      this.timeslotsByLabel[timeslotLabel] = timeslot;
    });
  }
}

export default AvailabilityScheduler;

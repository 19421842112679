<template>
  <div>
    <slot :open-modal="openRejectModal"></slot>

    <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" append-to-body>
      <div class="notice-modal rejection-modal">
        <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

        <div class="notice-regular-text">
          <div class="text-center">
            <div class="bottom-10">
              <profile-photo :user="possibleLawyer.lawyer"></profile-photo>
            </div>

            <div class="medium-headline bottom-30">
              Reject {{ possibleLawyer.lawyer.firstName }}?
            </div>
          </div>

          <div class="tiny-header-text bottom-20">
            Tell us more about this rejection:
          </div>

          <div class="error-container clearfix bottom-20" v-if="errors">
            <div class="icon-container">
              <svg-icon name="exclamation" class="base-icon"></svg-icon>
            </div>

            <div class="error-text">
              Please select at least one explanation and add a comment
              so we can tailor future lawyer suggestions to your needs.
            </div>
          </div>

          <form role="form" name="form" class="a-form" novalidate>
            <div class="checkbox">
              <label>
                <input type="checkbox" id="experience" name="why" value="true" v-model="formData.experience">
                Not enough experience
              </label>
            </div>

            <div class="checkbox">
              <label>
                <input type="checkbox" id="industry" name="why" value="true" v-model="formData.industry">
                Areas of expertise did not align with my needs
              </label>
            </div>

            <div class="checkbox">
              <label>
                <input type="checkbox" id="location" name="why" value="true" v-model="formData.location">
                Not in the right location
              </label>
            </div>

            <div class="checkbox">
              <label>
                <input type="checkbox" id="price" name="why" value="true" v-model="formData.price">
                Too expensive
              </label>
            </div>

            <textarea id="description" class="form-control" placeholder="Please elaborate..." rows="4" v-model="formData.description"></textarea>

            <div class="top-20 red-text" v-if="hasScheduledAppointment">
              <span class="semibold-weight">NOTE:</span> This action will also cancel your {{ possibleLawyer.appointment.type === 'in-person' ? 'meeting' : 'call' }} with {{ possibleLawyer.lawyer.firstName }}.
            </div>

            <div class="row top-30">
              <div class="col-sm-6 top-10">
                <button type="submit" class="nv-button-red a-button-size" @click="submit" :disabled="submitting">Submit</button>
              </div>

              <div class="col-sm-6 top-10">
                <button type="button" class="nv-button-white a-button-size" @click="dismissModal">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import PossibleLawyer from 'resources/marketplace/possible-lawyer';

export default {
  name: 'RejectLawyerModal',

  components: {
    ModalCloseButton,
    ProfilePhoto,
    SvgIcon
  },

  mixins: [
    modalToggle
  ],

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    onLawyerRejected: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      formData: {
        experience: false,
        industry: false,
        location: false,
        price: false,
        description: ''
      },
      submitting: false,
      errors: false
    };
  },

  computed: {
    hasScheduledAppointment() {
      return this.possibleLawyer.appointment &&
        this.possibleLawyer.appointment.scheduledConsultation();
    }
  },

  methods: {
    openRejectModal() {
      this.resetForm();
      this.openModal();
    },

    resetForm() {
      this.formData = {
        experience: false,
        industry: false,
        location: false,
        price: false,
        description: ''
      };
      this.submitting = false;
      this.errors = false;
    },

    submit() {
      this.submitting = true;

      PossibleLawyer.reject(this.possibleLawyer.id, { possibleLawyerRejection: this.formData }).
        then(() => {
          this.onLawyerRejected();
        })
        .catch(() => {
          this.submitting = false;
          this.errors = true;
        });
    }
  }
};
</script>

<template>
  <floating-wrapper
    placement="bottom-start"
    min-width="300px"
    class="filter-button-container btn-group dropdown open"
    @open="$emit('open')">
    <template #reference>
      <button type="button" data-role="trigger" :class="['filter-button', colorPattern, { 'dirty': active }]">
        {{ label }}

        <span v-if="selectedOptionsCount > 0">
          <svg-icon name="circle" class="base-icon dot"></svg-icon>
          {{ selectedOptionsCount }}
        </span>
      </button>
    </template>

    <template #floating>
      <ul class="dropdown-menu">
        <slot></slot>
      </ul>
    </template>
  </floating-wrapper>
</template>

<script>
import FloatingWrapper from 'vue-app/shared/components/floating-wrapper.vue';
export default {
  name: 'FilterButton',

  components: {
    FloatingWrapper
  },

  props: {
    label: {
      type: String,
      required: true
    },

    active: {
      type: Boolean,
      default: false
    },

    colorPattern: {
      type: String,
      default: 'blue'
    },

    selectedOptionsCount: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";
  .filter-button-container {
    .dropdown-menu {
      display: block;
      width: 100%;
      visibility: hidden;
      padding: 20px;
    }

    &.open .dropdown-menu {
      visibility: visible;
    }

    .base-icon {
      &.dot {
        fill: $white;
        width: 5px;
        height: 5px;
      }
    }
  }
</style>

<template>
  <div>
    <div class="size-text-17px bold-weight">
      Apply for this Opportunity
    </div>

    <hr class="top-5 bottom-10">

    <div class="bottom-30">
      Complete the form below to submit your application for this opportunity.
    </div>

    <validation-observer ref="observer" v-slot="{ handleSubmit, failed }">
      <form class="a-form" role="form" novalidate>
        <div class="row bottom-30">
          <div class="col-sm-6">
            <text-input-vertical
              label="First Name"
              v-model="firstName"
              rules="required">
            </text-input-vertical>
          </div>

          <div class="col-sm-6 top-30-xs">
            <text-input-vertical
              label="Last Name"
              v-model="lastName"
              rules="required">
            </text-input-vertical>
          </div>
        </div>

        <div class="row bottom-30">
          <div class="col-sm-6">
            <text-input-vertical
              label="Email"
              input-type="email"
              v-model="email"
              rules="required|email">
            </text-input-vertical>
          </div>

          <div class="col-sm-6 top-30-xs">
            <text-input-vertical
              label="Phone Number"
              input-type="tel"
              v-model="phoneNumber"
              rules="required">
            </text-input-vertical>
          </div>
        </div>

        <div v-for="(question, index) in opportunity.questions" class="bottom-30">
          <textarea-vertical
            v-if="question.questionType === 'text'"
            :key="`question-text-${index}`"
            :label="question.questionText"
            :rules="questionInputRule(question.questionType)"
            v-model="questionnaire[`${index + 1}`]['answer']">
          </textarea-vertical>

          <text-input-vertical
            v-else
            input-type="number"
            :key="`question-number-${index}`"
            :label="question.questionText"
            :rules="questionInputRule(question.questionType)"
            :step="1"
            v-model="questionnaire[`${index + 1}`]['answer']">
          </text-input-vertical>
        </div>

        <div class="form-group file-upload bottom-40">
          <validation-provider rules="required" v-slot="{ errors }" :mode="passiveAggressive">
            <div class="d-flex-not-xs">
              <file-selector
                accept=".pdf"
                @change="addResume"
                class="file-selector right-15"
                :max-size="5">
                <div class="secondary-btn-purple clickable">
                  Upload Resume
                </div>
              </file-selector>

              <div class="size-text-13px top-10">
                <span v-if="addedResume">{{ resume.name }}</span>
                <span v-else class="missing-text">No file chosen. Please upload a PDF.</span>
              </div>
            </div>

            <input type="hidden" v-model="resume">

            <div v-if="errors.length" class="error-text top-5">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

        <div class="row tight-columns">
          <div class="col-sm-4 col-lg-3">
            <loading-button name="opportunity-form" lb-class="primary-btn-purple" @lb-click="handleSubmit(submit)">Submit Application</loading-button>
          </div>

          <div class="col-sm-8 col-lg-9 top-20-xs">
            <button type="button" class="tertiary-button-compact" @click="cancel">Cancel</button>
          </div>
        </div>

        <div v-if="failed" class="error-text top-10">
          All fields required.
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import interactionModes from 'vue-app/shared/mixins/interaction-modes';

import FileSelector from 'vue-app/shared/components/file-selector.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import TextareaVertical from 'vue-app/shared/components/textarea-vertical.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'OpportunityForm',

  components: {
    FileSelector,
    LoadingButton,
    TextareaVertical,
    TextInputVertical,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    opportunity: {
      type: Object,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    },

    onCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return this.initialData();
  },

  computed: {
    addedResume() {
      return !!this.resume;
    }
  },

  methods: {
    initialData() {
      const object = {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        questionnaire: {},
        resume: null
      };

      this.opportunity.questions.forEach((question, index) => {
        object['questionnaire'][`${index + 1}`] = {
          id: question.id,
          question: question.questionText,
          answer: question.questionType === 'text' ? '' : null
        };
      });

      return object;
    },

    questionInputRule(questionType) {
      return questionType === 'numeric' ? 'required|numeric|positive' : 'required';
    },

    addResume(resume) {
      this.resume = resume[0];
    },

    cancel() {
      this.$refs.observer.reset();
      this.onCancel();
    },

    submit() {
      // TODO:
      // replace counselRequestId with counselRequestId: this.opportunity.counselRequestId
      const data = {
        opportunityResponse: {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          questionnaire: this.questionnaire,
          resume: this.resume,
          counselRequestId: this.opportunity.id
        }
      };

      this.onSubmit(data);
    }
  }
};
</script>

<template>
  <div class="box-content">
    <div class="left-border" v-if="displayBorder"></div>

    <div class="row tight-columns">
      <div class="col-sm-7 col-md-5 col-md-push-2">
        <a :href="detailPagePath" :class="['block-anchor darker-gray-text', { disabled: !myProject }]">
          <div class="target text-ellipsis semibold-weight">
            {{ project.nameForClientsReference }}
          </div>

          <div class="text-ellipsis">
            <span :class="[statusClass, 'right-10']">{{ displayStatus }}</span>

            <span class="right-10">&#8231;</span>

            <span v-if="isRequest">Reference #: {{ project.id }}</span>
            <span v-else>Project ID: {{ project.referenceNumber }}</span>
          </div>
        </a>
      </div>

      <div class="col-sm-5 col-md-2 col-md-pull-5">
        <div class="bottom-20 visible-xs-block"></div>

        <div>
          {{ lastActivityHeading }}
        </div>

        <div class="text-ellipsis">
          {{ lastActivityDate }}
        </div>
      </div>

      <div class="col-sm-7 col-md-2">
        <div class="bottom-20 visible-xs-block visible-sm-block"></div>

        <div>
          Lawyer Contact:
        </div>

        <div class="text-ellipsis">
          <span v-if="!isRequest">{{ project.lawyer.fullName }}</span>
          <a :href="detailPagePath" class="darker-gray-text semibold-weight" v-else>View Proposal</a>
        </div>
      </div>

      <div class="col-xs-9 col-sm-3 col-md-2">
        <div class="bottom-20 visible-xs-block visible-sm-block"></div>

        <div>
          <span v-if="isRequest">Requester:</span>
          <span v-else>Team Contact:</span>
        </div>

        <div class="text-ellipsis">
          <span v-if="isRequest">{{ project.requester.fullName }}</span>
          <span v-else>{{ project.client.fullName }}</span>
        </div>
      </div>

      <div class="col-xs-3 col-sm-2 col-md-1 text-right">
        <div class="bottom-20 visible-xs-block visible-sm-block"></div>

        <project-options
          :my-project="myProject"
          :project="project"
          :current-user="currentUser"
          :detail-page-path="detailPagePath"
          :is-request="isRequest"
          :is-closed="isClosed"
          @message-lawyer="$emit('message-lawyer', $event)"
          @request-closed="closeRequest"
          @access-requested="$emit('access-requested', $event)">
        </project-options>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';
import ProjectOptions from 'vue-app/marketplace/client/matters/project-options.vue';

export default {
  name: 'ProjectCard',

  components: {
    ProjectOptions
  },

  props: {
    project: {
      type: Object
    },

    myProject: {
      type: Boolean
    },

    currentUser: {
      type: Object
    }
  },

  emits: ['messageLawyer', 'requestClosed', 'accessRequested'],

  computed: {
    statusClass() {
      switch (this.project.status) {
        case 'sent_to_client':
          return 'pending-status';

        case 'open':
          return 'open-status';

        case 'rejected':
          return 'rejected-status';

        case 'sent':
          return 'sent-status';

        case 'new':
          return 'submitted-status';

        case 'closed':
        case 'admin_closed':
        case 'matter_opened':
          return 'closed-status';
      }
    },

    displayStatus() {
      return this.project.displayStatus();
    },

    detailPagePath() {
      const projectPath = this.isRequest ? 'requests' : 'projects';
      return `/client-app/${projectPath}/${this.project.id}`;
    },

    isRequest() {
      return this.project.isRequest;
    },

    isClosed() {
      return this.project.status === 'closed' ||
        this.project.status === 'admin_closed' ||
        this.project.status === 'matter_opened';
    },

    lastActivityHeading() {
      if (!this.isRequest && !this.isClosed) {
        return 'Opened:';
      }
      else {
        return 'RFP Submitted:';
      }
    },

    lastActivityDate() {
      if (!this.isRequest && !this.isClosed) {
        return DateFilter.filter(this.project.startedDate);
      }
      else if (this.isClosed) {
        return DateFilter.filter(this.project.endedDate || this.project.createdAt);
      }
      else {
        return DateFilter.filter(this.project.createdAt);
      }
    },

    displayBorder() {
      return this.isRequest && !this.isClosed;
    }
  },

  methods: {
    closeRequest(reason) {
      this.$emit('request-closed', this.project, reason);
    }
  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-xs-10 semibold-weight top-2 text-ellipsis">
        {{ label || email }} <span v-if="label" class="dark-gray-text">({{ email }})</span>
      </div>

      <div class="col-xs-2 text-right">
        <a href="" class="delete-recipient" @click.prevent="showEditor" v-if="!editorShown"><svg-icon name="trash" class="base-icon"></svg-icon></a>
      </div>
    </div>

    <div v-if="editorShown">
      <div class="top-20">
        <hr class="kenny-hr no-margin">
      </div>

      <div class="row editor-button-group">
        <div class="col-sm-6 col-md-push-6 top-20">
          <button type="button" class="nv-button-red a-button-size" @click="deleteRecipient">Remove Recipient</button>
        </div>

        <div class="col-sm-6 col-md-pull-6 top-20">
          <button type="button" class="nv-button-white a-button-size" @click="hideEditor">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InvoiceRecipientEditor',

  props: {
    email: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: false
    },

    onDelete: {
      type: Function,
      required: true
    },

    hasRecipients: {
      type: Boolean,
      required: false
    },

    onShowEditor: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      editorShown: false
    };
  },

  methods: {
    showEditor() {
      this.editorShown = this.hasRecipients;
      this.onShowEditor();
    },

    hideEditor() {
      this.editorShown = false;
    },

    deleteRecipient() {
      this.editorShown = false;
      this.onDelete(this.email, 'delete');
    }
  }
};
</script>

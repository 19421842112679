import { compact, filter, get, has, includes, orderBy, reduce, some } from 'lodash';

function theDocument(document) {
  return visibleForCurrentUser(document) ? document : document.document;
}

function lowerCaseFileName(document) {
  return theDocument(document).name.toLowerCase();
}

function lowerCaseProjectName(document) {
  return (theDocument(document).projectName || '').toLowerCase();
}

function documentSharedBy(document) {
  return visibleForCurrentUser(document) ? 'me' : document.user.fullName.toLowerCase();
}

function documentSource(document) {
  return theDocument(document).documentSource;
}

function documentPermission(document) {
  return document.yourFile ? get(document.documentReceipts[0], 'role') : document.role;
}

function visibleForCurrentUser(document) {
  return document.yourFile || has(document, 'visibility');
}

export default {
  defaultSort:   'Most Recent',
  defaultFilter: 'Shared w/ You',

  filterOptions: [
    'Shared w/ You',
    'Templates',
    'Your Files'
  ],

  sortOptions: [
    'Most Recent',
    'File Name',
    'Project Name',
    'Shared By',
    'Shared From',
    'Permission'
  ],

  normalizedSearchableData(documents) {
    return reduce(documents, (data, doc) => {
      const document = doc.document ? doc.document : doc;

      data[doc.id] = compact([
        document.name.toLowerCase(),
        document.projectName ? document.projectName.toLowerCase() : null,
        (document.user && document.user.fullName) ? document.user.fullName.toLowerCase() : null
      ]);
      return data;
    }, {});
  },

  filterFunction(documents, configurableFilter) {
    const valueToIdentifierMap = {
      'Shared w/ You': 'document',
      'Templates': 'visibility',
      'Your Files': 'documentReceipts'
    };

    return filter(documents, (document) => { return has(document, valueToIdentifierMap[configurableFilter.filterValue]); });
  },

  sortFunction(documents, configurableFilter) {
    const value = configurableFilter.sortValue;

    if (value === 'Most Recent') {
      return orderBy(documents, ['updatedAt'], ['desc']);
    }
    else if (value === 'File Name') {
      return orderBy(documents, [lowerCaseFileName], ['asc']);
    }
    else if (value === 'Project Name') {
      return orderBy(documents, [lowerCaseProjectName], ['asc']);
    }
    else if (value === 'Shared By') {
      return orderBy(documents, [documentSharedBy], ['asc']);
    }
    else if (value === 'Shared From') {
      return orderBy(documents, [documentSource], ['asc']);
    }
    else if (value === 'Permission') {
      return orderBy(documents, [documentPermission], ['asc']);
    }
    else {
      return documents;
    }
  },

  searchFunction(documents, configurableFilter) {
    return documents.filter((doc) => {
      return some(configurableFilter.normalizedData[doc.id], (str) => includes(str, configurableFilter.searchValue.toLowerCase()));
    });
  }
};

<template>
  <div>
    <dropdown-menu :disabled="changesDisabled">
      <li v-if="sharedViaGoogleDrive" class="menu-item" role="menuitem">
        <a href="" @click.prevent="changeAccess"><svg-icon name="pencil" class="base-icon"></svg-icon>Make {{ document.readOnly() ? 'Read/Write' : 'Read Only' }}</a>
      </li>

      <li v-if="sharedViaGoogleDrive" class="menu-item" role="menuitem">
        <a href="" @click.prevent="reshare"><svg-icon name="refresh" class="base-icon"></svg-icon>Reshare File</a>
      </li>

      <li class="menu-item" role="menuitem">
        <a href="" @click.prevent="remove"><svg-icon name="trash" class="base-icon"></svg-icon>Remove File</a>
      </li>
    </dropdown-menu>

    <request-confirmation
      ref="confirmChangeAccess"
      message="Are you sure? This will change the permission for everyone you’ve shared this file with."
      @on-modal-yes="proceedToChangeAccess">
    </request-confirmation>

    <request-confirmation
      ref="confirmReshare"
      message="This will share the document with any recently added project users. Would you like to continue?"
      yes-btn-text="Yes, share"
      no-btn-text="No, don’t share"
      @on-modal-yes="proceedToReshare">
    </request-confirmation>

    <request-confirmation
      ref="confirmRemove"
      message="Are you sure? This will remove the file from the project and other project users will no longer have access."
      @on-modal-yes="proceedToRemove">
    </request-confirmation>
  </div>
</template>

<script>
import RequestConfirmation from 'vue-app/shared/components/request-confirmation.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import Document from 'resources/document.js';
import NotificationService from 'vue-app/shared/services/notification-service';
import { scrollToTop } from 'misc/ui-helpers.js';

export default {
  name: 'DocumentOptions',

  components: {
    RequestConfirmation,
    DropdownMenu,
    SvgIcon
  },

  props: {
    document: {
      type: Object,
      required: true
    },

    onChangeAccess: {
      type: Function,
      default: () => {}
    },

    onRemove: {
      type: Function,
      required: true
    },

    onReshare: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      changesDisabled: false
    };
  },

  computed: {
    sharedViaGoogleDrive() {
      return this.document.onGoogleDrive();
    }
  },

  methods: {
    changeAccess() {
      this.$refs.confirmChangeAccess.openModal();
    },

    reshare() {
      this.$refs.confirmReshare.openModal();
    },

    remove() {
      this.$refs.confirmRemove.openModal();
    },

    proceedToChangeAccess() {
      const newRole = this.document.readOnly() ? 'writer' : 'reader';

      this.changesDisabled = true;

      Document.update({ id: this.document.id, role: newRole })
        .then(() => {
          this.document.documentReceipts[0].role = newRole;
          this.document.yourFile = true;
          this.onChangeAccess();
        })
        .catch(() => {
          NotificationService.error('Unable to change permissions on this file.');
        })
        .finally(() => {
          this.changesDisabled = false;
        });
    },

    proceedToRemove() {
      this.changesDisabled = true;

      Document.delete({ id: this.document.id })
        .then(() => {
          this.onRemove(this.document);
        })
        .catch(() => {
          NotificationService.error('Unable to revoke permissions on this file.');
        })
        .finally(() => {
          this.changesDisabled = false;
        });
    },

    proceedToReshare() {
      const params = {
        documents: [{
          id: this.document.documentId,
          permission: this.document.documentReceipts[0].role
        }],
        matterId: this.document.matterId,
        documentSource: this.document.documentSource
      };

      this.changesDisabled = true;

      Document.bulkCreate(params)
        .then(() => {
          NotificationService.success('Successfully reshared the document.');
          this.onReshare();
        })
        .catch(() => {
          NotificationService.error('There were errors resharing the document.');
        })
        .finally(() => {
          scrollToTop();
          this.changesDisabled = false;
        });
    }
  }
};
</script>

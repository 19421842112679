<template>
  <priori-modal
    ref="modal"
    modal-id="scout-tag-editing-modal"
    modal-size="md-sm"
    :title="modalTitle"
    @on-modal-open="reset">
    <template #modal-trigger="modalProps">
      <div>
        <a href="" class="semibold-weight" @click.prevent="modalProps.openModal()">{{ triggerName }}</a>
      </div>
    </template>

    <template #default>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form role="form" name="form" class="a-form" autocomplete="off" novalidate>
          <div class="row tight-columns">
            <div class="col-sm-4 bottom-20">
              <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
                <label for="color">Color</label>

                <dropdown-select-color
                  ref="dropdownSelectColor"
                  :initial-label="scoutTagCopy.color"
                  id-label="color"
                  v-model="scoutTagCopy.color"
                  :colors="colors"
                  :invalid="errors.length > 0">
                </dropdown-select-color>

                <div class="error-text top-5" v-if="errors.length">
                  <error-message :errors="errors"></error-message>
                </div>
              </validation-provider>
            </div>

            <div class="col-sm-8 bottom-30">
              <validation-provider ref="name" rules="required" :mode="passiveAggressive" v-slot="{ errors }">
                <label for="name">Label</label>

                <input type="text" name="name" id="name" :class="['form-control', { 'has-error': errors.length }]" v-model="scoutTagCopy.name" :maxlength="nameMaxlength">

                <div class="size-text-12px dark-gray-text top-5" v-if="showCharactersLimitedMessage">
                  Limited to {{ nameMaxlength }} characters
                </div>

                <div class="error-text top-5" v-if="errors.length">
                  <error-message :errors="errors"></error-message>
                </div>
              </validation-provider>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <loading-button name="save-button" lb-class="primary-btn-blue" @lb-click="handleSubmit(submitForm)">Save</loading-button>
            </div>

            <div class="col-sm-3 top-10-xs">
              <button type="button" class="secondary-btn-blue" @click.prevent="closeModal">Cancel</button>
            </div>
          </div>
        </form>
      </validation-observer>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import DropdownSelectColor from 'vue-app/shared/components/dropdown-select-color.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import ScoutTag from 'vue-app/scout/resources/scout-tag.js';
import NotificationService from 'vue-app/shared/services/notification-service';
import { toLower, trim } from 'lodash';

export default {
  name: 'ScoutTagEditingModal',

  components: {
    ErrorMessage,
    DropdownSelectColor,
    PrioriModal,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    scoutTag: {
      type: Object,
      required: true
    },

    scoutTags: {
      type: Array,
      default: () => []
    },

    onSaved: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      colors: ['blue', 'gray', 'green', 'purple', 'red', 'yellow'],
      scoutTagCopy: {},
      nameMaxlength: 22
    };
  },

  computed: {
    showCharactersLimitedMessage() {
      return this.scoutTagCopy.name?.length === this.nameMaxlength;
    },

    triggerName() {
      return this.scoutTag.id ? 'Edit Tag' : '+ Add a Law Firm Tag';
    },

    modalTitle() {
      return this.scoutTag.id ? 'Edit Law Firm Tag' : 'Add Law Firm Tag';
    }
  },

  methods: {
    submitForm() {
      if (this.isValid()) {
        if (this.scoutTag.id) {
          this.updateScoutTag();
        }
        else {
          this.createScoutTag();
        }
      }
    },

    createScoutTag() {
      LoadingService.loading('save-button');

      ScoutTag.save({ scoutTag: this.scoutTagCopy })
        .then(response => {
          this.onSaved(response);
          NotificationService.success('Law firm tag successfully saved');
        })
        .catch((response) => {
          const errors = response.response.data.errors.join('; ');
          NotificationService.error(`Unable to save changes: ${errors}`);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .finally(() => {
          this.closeModal();
          LoadingService.done('save-button');
        });
    },

    updateScoutTag() {
      LoadingService.loading('save-button');

      ScoutTag.update({ id: this.scoutTag.id, scoutTag: this.scoutTagCopy })
        .then(response => {
          this.onSaved(response);
          NotificationService.success('Law firm tag successfully updated');
        })
        .catch((response) => {
          const errors = response.response.data.errors.join('; ');
          NotificationService.error(`Unable to save changes: ${errors}`);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .finally(() => {
          this.closeModal();
          LoadingService.done('save-button');
        });
    },

    reset() {
      this.$refs.observer.reset();
      this.$refs.dropdownSelectColor.reset();

      this.scoutTagCopy = { ...this.scoutTag };
    },

    closeModal() {
      this.reset();
      this.$refs.modal.dismissModal();
    },

    isValid() {
      const name = this.cleanString(this.scoutTagCopy.name);
      const exist = this.scoutTags.find(scoutTag => this.cleanString(scoutTag.name) === name && scoutTag.id !== this.scoutTagCopy.id);

      if (!exist) { return true; }

      this.$refs.name.applyResult({
        errors: ['* This tag already exists'],
        valid: false,
        failedRules: {}
      });

      return false;
    },

    cleanString(value) {
      return toLower(trim(value));
    }
  }
};
</script>

<template>
  <div>
    <prompt-review-modal
      ref="reviewPrompt"
      :lawyer-name="lawyerName"
      :matter-id="matter.id">
    </prompt-review-modal>

    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <!-- TODO: return based on link - i.e. maybe return to all invoices -->
            <a :href="`/client-app/projects/${matter.id}`" class="back-link" v-if="!invoice.unpaidFromRequest()"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Project Details</a>
            <a :href="`/client-app/requests/${matter.counselRequestId}`" class="back-link" v-if="invoice.unpaidFromRequest()"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Request Details</a>
          </div>

          <div class="col-sm-6 text-right-not-xs semibold-weight size-text-13px">
            <span class="dark-gray-text right-15">Download:</span>

            <a :href="`/invoices/${(invoice.id || 'initial')}.pdf?matterId=${matter.id}&payment_method_id=${paymentMethodId}`" class="darker-gray-text right-10" target="_blank">PDF</a>

            <span class="right-10">&#8231;</span>

            <a :href="`/invoices/${(invoice.id || 'initial')}.ledes?matterId=${matter.id}&payment_method_id=${paymentMethodId}&ledes_format=98bi`" class="darker-gray-text right-10" target="_self">LEDES 98BI</a>

            <span class="right-10">&#8231;</span>

            <a :href="`/invoices/${(invoice.id || 'initial')}.ledes?matterId=${matter.id}&payment_method_id=${paymentMethodId}&ledes_format=98b`" class="darker-gray-text" target="_self">LEDES 98B</a>
          </div>
        </div>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row tight-columns">
            <div class="col-sm-8 col-lg-9 bottom-30">
              <div class="medium-page-heading bottom-10">
                {{ invoice.title() }}
              </div>

              <div class="size-text-13px">
                <span class="purple-text right-10">{{ matter.nameForClientsReference }}</span>

                <span class="right-10">&#8231;</span>

                <span>Lawyer: <span class="semibold-weight">{{ matter.lawyer.fullName }}</span></span>

                <span v-if="invoice.isMonthly()">
                  <span class="left-10 right-10">&#8231;</span> {{ invoice.dateRange() }}
                </span>
              </div>
            </div>

            <div class="col-sm-4 col-lg-3 bottom-30 text-right">
              <div :class="['paid-status-box', { 'paid': isPaid }]">
                {{ invoice.clientDisplayStatus() }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-7 bottom-40">
              <div class="shadowed-box">
                <div class="box-header">
                  <div class="row tight-columns">
                    <div class="col-sm-4 text-uppercase">
                      Invoice Details
                    </div>

                    <div class="col-sm-8 text-right-not-xs">
                      <a href="" class="darker-gray-text" @click.prevent="messageLawyer">Message Lawyer About Invoice<svg-icon name="envelope" class="matter-icon left-5"></svg-icon></a>
                    </div>
                  </div>
                </div>

                <div class="row invoice-detail-summary semibold-weight">
                  <div class="col-sm-4">
                    <div class="size-text-11px dark-gray-text bottom-5">
                      Date Issued
                    </div>

                    <div>
                      {{ issuedDate }}
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="size-text-11px dark-gray-text bottom-5">
                      Date Due
                    </div>

                    <div>
                      {{ dueDate }}
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="size-text-11px dark-gray-text bottom-5">
                      Amount
                    </div>

                    <div class="green-text">
                      {{ totalDollars }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-5 bottom-40">
              <invoice-payer
                v-if="!isPaid && !isVoid && !isAdminViewing"
                :client="user"
                :invoice="invoice"
                :allow-credit-card="allowCreditCard"
                @account-selected="setPaymentMethod"
                @pay-invoice="payInvoice">
              </invoice-payer>

              <div class="shadowed-box client-pay-box" v-if="!isPaid && !isVoid && isAdminViewing">
                <div class="box-content vertical-center">
                  <div class="gray-text">
                    Impersonate the client to make a payment.
                  </div>
                </div>
              </div>

              <div class="green-shadowed-box client-paid-box" v-if="isPaid">
                <div class="box-content">
                  <div class="size-text-24px normal-weight">
                    Thank you for your payment!
                  </div>

                  <div class="top-20 normal-weight size-text-13px" v-if="invoice.paymentMethod">
                    Using {{ invoice.accountUsed() }} on {{ paidDate }}
                  </div>
                </div>
              </div>

              <div class="shadowed-box client-pay-box" v-if="!isPaid && isVoid">
                <div class="box-content vertical-center">
                  <div class="gray-text">
                    This invoice was {{ voidedStatus }} on {{ voidedDate }}.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="shadowed-box bottom-40">
            <div class="box-header text-uppercase">
              Invoice Line Items
            </div>

            <div class="box-content">
              <div class="table-responsive">
                <table id="line-items" class="table full-height">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Activity&nbsp;Date</th>
                      <th>Lawyer</th>
                      <th>Description</th>
                      <th>
                        Rate
                        <popover-tooltip placement="top" custom-class="tooltip-white small-text">
                          <span class="tooltip-question left-5">?</span>
                          <template #tooltip>
                            <span class="semibold-weight">Rate:</span> The rate paid by you for services provided. The Rate is the sum of Your Lawyer&rsquo;s Fees plus the Priori Management Fee.
                          </template>
                        </popover-tooltip>
                      </th>
                      <th>Qty</th>
                      <th class="number">
                        Total
                        <popover-tooltip placement="top" custom-class="tooltip-white small-text">
                          <span class="tooltip-question left-5">?</span>
                          <template #tooltip>
                            <span class="semibold-weight">Total:</span> The total amount paid by you for services provided. The Total is the sum of Your Lawyer&rsquo;s Fees plus the Priori Management Fee.
                          </template>
                        </popover-tooltip>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <invoice-row
                      v-for="lineItem in sortedLineItems"
                      :key="lineItem.id"
                      :line-item="lineItem"
                      :matter-lawyers="lawyers"
                      :invoice="invoice">
                    </invoice-row>

                    <tr id="credit-line-item" v-if="invoice.isLegacyInvoice() && invoice.creditLineItem">
                      <td>Credit</td>
                      <td>&mdash;</td>
                      <td>&mdash;</td>
                      <td>&mdash;</td>
                      <td>&mdash;</td>
                      <td>&mdash;</td>
                      <td class="number">{{ creditTotal }}</td>
                    </tr>

                    <tr id="transaction-fee" v-if="invoice.isLegacyInvoice() && invoice.transactionFeeLineItem">
                      <td>Transaction Fee</td>
                      <td>&mdash;</td>
                      <td>&mdash;</td>
                      <td>&mdash;</td>
                      <td>&mdash;</td>
                      <td>&mdash;</td>
                      <td class="number">{{ transactionFees }}</td>
                    </tr>

                    <tr v-if="!viewableLineItems.length">
                      <td colspan="7">No line items on invoice</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="line-item-totals-container invoice-view">
                <invoice-totals :invoice="invoice" :auto-charging="invoice.toBeAutocharged() && isProjectOwner"></invoice-totals>
              </div>

              <div class="dark-gray-text normal-weight size-text-11px top-20">
                <div class="semibold-weight bottom-15" v-if="invoice.toBeAutocharged() && isProjectOwner">
                  * Your default payment method will be automatically charged on the invoice&rsquo;s due date.
                </div>

                <div class="semibold-weight bottom-5">
                  NOTES
                </div>

                <ul class="dashed">
                  <li><span class="semibold-weight">Disbursements:</span> The amount paid by you to your lawyer for third-party costs and out-of-pocket expenses.</li>
                  <li><span class="semibold-weight">Legal Fees:</span> The amount paid by you to your lawyer for legal services provided.</li>
                  <li><span class="semibold-weight">Priori Management Fee:</span> The amount paid by you to Priori for your use of our platform and services. It is calculated as a percentage of your Legal Fees.</li>
                  <li><span class="semibold-weight">Processing Fee:</span> The cost to transfer money charged by our third-party payment processor.</li>
                  <li><span class="semibold-weight">Rate:</span> The rate paid by you for services provided. The Rate is the sum of your Lawyer Fees plus the Priori Management Fee.</li>
                  <li><span class="semibold-weight">Retainer:</span> An amount paid by you in advance to secure your lawyer&rsquo;s services. The retainer amounts displayed in this invoice include your Lawyer Fees and the Priori Management Fee.</li>
                  <li><span class="semibold-weight">Total:</span> The total amount paid by you for services provided. The Total is the sum of your Lawyer Fees plus the Priori Management Fee.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="semibold-weight size-text-13px text-center">
          <span class="dark-gray-text right-15">Download:</span>

          <a :href="`/invoices/${(invoice.id || 'initial')}.pdf?matterId=${matter.id}&payment_method_id=${paymentMethodId}`" class="darker-gray-text right-10" target="_blank">PDF</a>

          <span class="right-10">&#8231;</span>

          <a :href="`/invoices/${(invoice.id || 'initial')}.ledes?matterId=${matter.id}&payment_method_id=${paymentMethodId}&ledes_format=98bi`" class="darker-gray-text right-10" target="_self">LEDES 98BI</a>

          <span class="right-10">&#8231;</span>

          <a :href="`/invoices/${(invoice.id || 'initial')}.ledes?matterId=${matter.id}&payment_method_id=${paymentMethodId}&ledes_format=98b`" class="darker-gray-text" target="_self">LEDES 98B</a>
        </div>
      </div>
    </div>

    <send-message-modal ref="messageModal" :recipients="messageRecipients"></send-message-modal>
  </div>
</template>

<script>
import InvoicePayer from 'vue-app/marketplace/client/matters/invoice-payer.vue';
import InvoiceTotals from 'vue-app/marketplace/client/matters/invoice-totals.vue';
import InvoiceRow from 'vue-app/marketplace/client/matters/invoice-row.vue';
import SendMessageModal from 'vue-app/shared/components/send-message-modal.vue';
import PromptReviewModal from 'vue-app/marketplace/client/shared/prompt-review-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import PopoverTooltip from 'vue-app/shared/components/popover-tooltip.vue';

import Matter from 'resources/marketplace/matter.js';
import Invoice from 'resources/marketplace/invoice.js';

import DateFilter from 'vue-app/shared/lib/date-filter.js';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';

import { scrollToTop } from 'misc/ui-helpers.js';
import { mapState } from 'pinia';
import { concat, get, map, orderBy } from 'lodash';

export default {
  name: 'MattersInvoice',

  components: {
    InvoicePayer,
    InvoiceTotals,
    InvoiceRow,
    PromptReviewModal,
    SendMessageModal,
    SvgIcon,
    PopoverTooltip
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      invoice: this.initData.invoice,
      paymentMethodId: get(this.initData.invoice, 'paymentMethod.id'),
      messageRecipients: []
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    lawyerName() {
      return this.matter.lawyer.fullName;
    },

    matter() {
      return this.invoice.matter;
    },

    isAdminViewing() {
      return this.user.klass === 'AdminUser';
    },

    isProjectOwner() {
      return this.user.id === this.matter.client.id && this.user.klass === this.matter.client.klass;
    },

    lawyers() {
      return concat([this.matter.lawyer], map(this.matter.matterLawyers, 'lawyer'));
    },

    allowCreditCard() {
      return this.matter.allowCreditCard;
    },

    isPaid() {
      return this.invoice.paid;
    },

    isVoid() {
      return this.invoice.isVoid();
    },

    issuedDate() {
      return DateFilter.filter(this.invoice.issuedAt());
    },

    dueDate() {
      return DateFilter.filter(this.invoice.dueDate) || 'N/A';
    },

    totalDollars() {
      return CurrencyFilter.filter(this.invoice.totalDollars);
    },

    creditTotal() {
      return CurrencyFilter.filter(this.invoice.creditTotal());
    },

    transactionFees() {
      return CurrencyFilter.filter(this.invoice.transactionFees());
    },

    paidDate() {
      return DateFilter.filter(this.invoice.paidAt);
    },

    voidedStatus() {
      return this.invoice.clientDisplayStatus().toLowerCase();
    },

    voidedDate() {
      return DateFilter.filter(this.invoice.voidedAt);
    },

    viewableLineItems() {
      return this.invoice.invoiceLineItems.filter((li) => {
        return !li.renderedService.typePrepaidRealization() &&
          !li.renderedService.typeRetainerTopUp() &&
          !li.renderedService.typePartnerDiscount();
      });
    },

    sortedLineItems() {
      return orderBy(this.viewableLineItems, ['renderedService.dateOfActivity', 'renderedService.createdAt']);
    },

    isOpeningInvoice() {
      return this.invoice.isInitialInvoice() && this.matter.status === 'lawyer_accepted';
    }
  },

  mounted() {
    if (sessionStorage.getItem('displayPrompt') === 'true') {
      this.$refs.reviewPrompt.openModal();
      sessionStorage.removeItem('displayPrompt');
    }

    this.checkForWarnings();
    scrollToTop();
  },

  methods: {
    messageLawyer() {
      this.messageRecipients = [this.matter.lawyer];
      this.$refs.messageModal.openModal();
    },

    setPaymentMethod(id) {
      this.paymentMethodId = id;

      Invoice.get({ matterId: this.matter.id, id: this.invoice.id, payment_method_id: id }).then((invoice) => {
        this.invoice = invoice;
      });
    },

    payInvoice() {
      LoadingService.around('pay', (this.isOpeningInvoice ? this.openMatterAndPay : this.pay));
    },

    openMatterAndPay() {
      return Matter.open({ id: this.matter.id, payment_method_id: (this.paymentMethodId || -1) })
        .then(() => {
          NotificationService.success('Your payment was successful.', true);
          window.location = `/client-app/projects/${this.matter.id}`;
        })
        .catch(() => {
          NotificationService.error('There was a problem with your payment. Please try again.');
        });
    },

    pay() {
      return Invoice.pay({ matterId: this.matter.id, id: this.invoice.id, payment_method_id: (this.paymentMethodId || -1) })
        .then(() => {
          NotificationService.success('Your payment was successful.', true);

          if (!this.matter.review) { sessionStorage.setItem('displayPrompt', true); }
          window.location.reload();
        })
        .catch(() => {
          NotificationService.error('There was a problem with your payment. Please try again.');
        });
    },

    checkForWarnings() {
      let warning = [];

      if (this.invoice.unpaidFromRequest()) {
        warning.push(`Please make an opening payment to begin working with ${this.matter.lawyer.firstName}. Your lawyer will not begin working until you've paid this invoice.`);
      }

      if (!this.invoice.paid && !this.matter.allowCreditCard) {
        warning.push('NOTE: Your lawyer does not allow credit card payments for this project.');
      }

      if (warning.length) {
        NotificationService.warn(warning.join(' '));
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="pill-switcher-container bottom-10">
      <div
        :class="['option', 'option-left', { 'selected': isSelected(optionLeft.value) }]"
        @click="shouldConfirmChange ? askForConfirmation(optionLeft.value) : select(optionLeft.value)">
        {{ optionLeft.label }}
      </div>

      <div
        :class="['option', 'option-right', { 'selected': isSelected(optionRight.value) }]"
        @click="shouldConfirmChange ? askForConfirmation(optionRight.value) : select(optionRight.value)">
        {{ optionRight.label }}
      </div>
    </div>

    <slot name="note"></slot>

    <inline-confirmation
      v-if="shouldConfirmChange && renderConfirmation"
      class="top-15"
      @change="select"
      :buttons="[
        { label: 'Yes', value: optionToConfirm, class: 'secondary-btn-blue' },
        { label: 'No', value: selectedOption, class: 'primary-btn-white' }
      ]">
      <slot name="confirmation-message"></slot>
    </inline-confirmation>
  </div>
</template>

<script>
import InlineConfirmation from 'src/vue-app/scout/shared/inline-confirmation.vue';

export default {
  name: 'PillSwitcher',

  components: {
    InlineConfirmation
  },

  props: {
    optionLeft: {
      type:     Object,
      required: true
    },

    optionRight: {
      type:     Object,
      required: true
    },

    defaultOptionValue: {
      type:     String,
      default:  null
    },

    onSelect: {
      type:     Function,
      required: true
    },

    shouldConfirmChange: {
      type:      Boolean,
      default:   false
    }
  },

  data() {
    return {
      selectedOption: this.defaultOptionValue ? this.defaultOptionValue : this.optionLeft.value,
      optionToConfirm: null,
      renderConfirmation: false
    };
  },

  methods: {
    isSelected(option) {
      return this.selectedOption === option;
    },

    askForConfirmation(option) {
      if (this.isSelected(option)) { return; }

      this.optionToConfirm    = option;
      this.renderConfirmation = true;
    },

    select(option) {
      this.renderConfirmation = false;

      if (this.isSelected(option)) { return; }

      this.selectedOption = option;

      this.onSelect(option);
    },

    reset() {
      this.selectedOption  = this.defaultOptionValue ? this.defaultOptionValue : this.optionLeft.value;
      this.optionToConfirm = null;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .pill-switcher-container {
    display: flex;
    justify-content: center;
    border-radius: 20px;
  }

  .option {
    color: $k-blue;
    font-size: 13px;
    font-weight: 700;
    padding: 8px 15px;
    text-align: center;
    width: fit-content;
    border: 1px solid $k-blue;

    &:hover {
      cursor: pointer;
    }

    &.selected {
      background: $k-blue;
      color: $white;
    }
  }

  .option-left {
    padding-left: 20px;
    border-right: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .option-right {
    padding-right: 20px;
    border-left: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
</style>

import axios        from 'axios';
import Attachment   from 'resources/marketplace/attachment.js';
import BaseResource from 'resources/base-resource';

import { vPull } from 'misc/vue-helpers';

class Message extends BaseResource {
  static baseUrl = '/mailboxer/messages/:id.json';

  static fetchUnreadCount(params) {
    const url = this.transformedCollectionUrl(params);
    return axios.get(url).then(resp => resp.data);
  }

  constructor(data) {
    super(data);

    this.body        = this.body        || '';
    this.draft       = this.draft       || false;
    this.errors      = this.errors      || [];
    this.uploading   = this.uploading   || [];
    this.attachments = this.attachments || [];
  }

  multiUpload(files) {
    return Promise.all(files.map((file) => { return this.uploadFile(file); }));
  }

  uploadFile(file) {
    this.uploading.unshift({ name: file.name, progress: 0 });
    return this._processUpload(file);
  }

  removeFile(conversationId, file) {
    vPull(this.attachments, file);
    return Attachment.delete({ id: file.id, message_id: this.id, conversation_id: conversationId });
  }

  _processUpload(file) {
    return Attachment.upload(this, file)
      .then((attachment) => {
        this.attachments.push(attachment);
        return attachment;
      })
      .finally(() => { vPull(this.uploading, file); });
  }
}

export default Message;

<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a href="/client-app/projects" class="back-link"><svg-icon class="base-icon right-10" name="arrow-left"></svg-icon>All Projects</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="bottom-40">
            <project-header :matter="matter" :workspace="workspace"></project-header>
          </div>

          <div class="row">
            <div class="col-md-4 bottom-30">
              <lawyers-card :matter="matter" @message-lawyer="messageLawyer"></lawyers-card>
            </div>

            <div class="col-sm-6 col-md-4 bottom-30">
              <rates-card :matter="matter"></rates-card>
            </div>

            <div class="col-sm-6 col-md-4 bottom-30">
              <action-card :matter="matter" :current-user="user" :workspace="workspace" :on-client-added="addClient"></action-card>
            </div>
          </div>

          <div class="bottom-30">
            <billing-summary :matter="matter" :invoices="invoices" :payments="payments"></billing-summary>
          </div>

          <div class="bottom-30">
            <project-invoices :matter="matter" :invoices="invoices"></project-invoices>
          </div>

          <div>
            <project-documents
              :matter="matter"
              :documents="documents"
              :connected-to-google-drive="connectedToGoogleDrive"
              :on-remove-document="removeDocument">
            </project-documents>
          </div>
        </div>
      </div>
    </div>

    <send-message-modal ref="messageModal" :recipients="messageRecipients"></send-message-modal>
  </div>
</template>

<script>
import ProjectHeader from 'vue-app/marketplace/client/matters/project-header.vue';
import LawyersCard from 'vue-app/marketplace/client/matters/lawyers-card.vue';
import RatesCard from 'vue-app/marketplace/client/matters/rates-card.vue';
import ActionCard from 'vue-app/marketplace/client/matters/action-card.vue';
import BillingSummary from 'vue-app/marketplace/client/matters/billing-summary.vue';
import ProjectInvoices from 'vue-app/marketplace/client/matters/project-invoices.vue';
import ProjectDocuments from 'vue-app/marketplace/client/matters/project-documents.vue';
import SendMessageModal from 'vue-app/shared/components/send-message-modal.vue';

import User from 'resources/user.js';
import Matter from 'resources/marketplace/matter.js';
import Invoice from 'resources/marketplace/invoice.js';
import Document from 'resources/document.js';
import Workspace from 'resources/workspace.js';
import MatterClient from 'resources/marketplace/matter-client.js';
import DocumentReceipt from 'resources/document-receipt.js';

import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import ShareDocumentService from 'vue-app/shared/services/share-document-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';

import { scrollToTop } from 'misc/ui-helpers.js';
import { mapState } from 'pinia';
import { concat, each, orderBy, without } from 'lodash';

export default {
  name: 'MattersShow',

  components: {
    RatesCard,
    ActionCard,
    LawyersCard,
    ProjectHeader,
    BillingSummary,
    ProjectInvoices,
    ProjectDocuments,
    SendMessageModal
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      matter: this.initData.matter,
      workspace: {},
      connectedApis: [],
      documents: [],
      invoices: [],
      payments: {},
      messageRecipients: []
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    matterClients() {
      return this.matter.matterClients;
    },

    connectedToGoogleDrive() {
      return new ShareDocumentService(this.connectedApis).connectedToGoogleDrive;
    }
  },

  mounted() {
    scrollToTop();

    this.loadConnectedApis();
    this.loadWorkspace();
    this.loadInvoices();
    this.loadTotalAmountsByTypes();
    this.loadDocuments();
  },

  methods: {
    loadConnectedApis() {
      User.connectedApis().then((resp) => {
        this.connectedApis = resp.data;
      });
    },

    loadWorkspace() {
      LoadingService.around('clientWorkspace', () => {
        return Workspace.get({ id: this.user.workspaceId }).then((workspace) => {
          this.workspace = workspace;
        });
      });
    },

    loadInvoices() {
      LoadingService.around('invoices', () => {
        return Invoice.query({ matterId: this.matter.id, clientViewable: true }).then((invoices) => {
          this.invoices = invoices;
        });
      });
    },

    loadTotalAmountsByTypes() {
      Matter.totalAmountsByTypes({ id: this.matter.id }).then((response) => {
        this.payments = response.data;
      });
    },

    loadDocuments() {
      this.documents = [];

      LoadingService.around('documents', () => {
        const myDocsPromise = Document.query({ matterId: this.matter.id }).then((documents) => {
          each(documents, (doc) => { doc.yourFile = true; });
          this.addDocuments(documents);
        });

        const receivedDocsPromise = DocumentReceipt.query({ matterId: this.matter.id }).then((receipts) => {
          this.addDocuments(receipts);
        });

        return Promise.race([myDocsPromise, receivedDocsPromise]);
      });
    },

    addDocuments(documents) {
      this.documents = orderBy(concat(this.documents, documents), ['updatedAt'], ['desc']);
    },

    addClient({ matterClient, successFn, failFn }) {
      if (!this.matter.isOpen()) { return; }

      MatterClient.save({ matterId: this.matter.id, matterClient: matterClient })
        .then((newMatterClient) => {
          if (successFn) { successFn(); }
          this.matter.matterClients.push(newMatterClient);
          NotificationService.success('You have successfully granted a user access to this project. The user will be notified by email and will have access to the project page and project information.');
        })
        .catch(() => {
          if (failFn) { failFn(); }
          NotificationService.error('There was a problem granting the user access to the project.');
        });
    },

    removeDocument(document) {
      this.documents = without(this.documents, document);
    },

    messageLawyer(lawyer) {
      this.messageRecipients = [lawyer];
      this.$refs.messageModal.openModal();
    }
  }
};
</script>

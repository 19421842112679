<template>
  <div class="row">
    <div class="col-md-10 col-md-offset-1">
      <div class="shadowed-box top-30">
        <div class="box-content extra-padded">
          <div class="semibold-weight dark-gray-text bottom-20">
            {{ stepText }}
          </div>

          <inline-notification class="bottom-20" v-if="mfaEnabled && step === 2" type="success">
            You have successfully enabled two-factor authentication!
          </inline-notification>

          <mfa-instructions class="bottom-30" :title="instructions[step].title" :content="instructions[step].content"></mfa-instructions>

          <div v-if="step === 0">
            <div class="bottom-10 semibold-weight">
              Select a method of authentication:
            </div>

            <big-radio-button value="email" :current-value="selectedStrategy" :expanded="selectedStrategy === 'email'" @click="selectStrategy('email')" title="Email" subtitle="Use your email to receive authentication codes" class="bottom-15">
              <email-strategy v-if="currentStrategy === 'email'" :authenticatable="authenticatable" :on-send-one-time-password="sendOneTimePassword" :verify-otp="verifyOtp" :on-valid-otp="nextStep"></email-strategy>
            </big-radio-button>

            <big-radio-button value="authenticator_app" :current-value="selectedStrategy" :expanded="selectedStrategy === 'authenticator_app'" @click="selectStrategy('authenticator_app')" title="Authenticator App" subtitle="Use an authenticator app to generate authentication codes">
              <authenticator-app-strategy v-if="currentStrategy === 'authenticator_app'" :authenticatable="authenticatable" :verify-otp="verifyOtp" :on-valid-otp="nextStep"></authenticator-app-strategy>
            </big-radio-button>
          </div>

          <div v-if="step === 1">
            <div class="bottom-10 semibold-weight">
              Your Backup Codes
            </div>

            <loading-section name="backupCodes">
              <backup-codes class="bottom-20" :backup-codes="otpBackupCodes"></backup-codes>
            </loading-section>

            <button type="button" @click.prevent="enableTwoFactor" class="nv-button-blue">Continue</button>
          </div>

          <div v-if="step === 2" class="text-center">
            <div class="bottom-20">
              <svg-icon class="settings-screen" name="settingsScreen"></svg-icon>
            </div>

            <button type="button" class="nv-button-blue" @click="launchDashboard">Launch Dashboard</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmailStrategy from 'vue-app/shared/components/two-factor-authentication/email-strategy.vue';
import AuthenticatorAppStrategy from 'vue-app/shared/components/two-factor-authentication/authenticator-app-strategy.vue';
import BackupCodes from 'vue-app/shared/components/two-factor-authentication/backup-codes.vue';
import MfaInstructions from 'vue-app/shared/components/two-factor-authentication/mfa-instructions.vue';
import BigRadioButton from 'vue-app/shared/components/big-radio-button.vue';
import InlineNotification from 'vue-app/shared/components/inline-notification.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import TwoFactorAuthenticatable from 'resources/two-factor-authenticatable.js';

export default {
  name: 'TwoFactorSetup',

  components: {
    EmailStrategy,
    AuthenticatorAppStrategy,
    BackupCodes,
    MfaInstructions,
    BigRadioButton,
    InlineNotification,
    LoadingSection,
    SvgIcon
  },

  props: {
    authenticatable: {
      type: Object,
      required: true
    },

    sendOneTimePassword: {
      type: Function,
      required: true
    },

    verifyOtp: {
      type: Function,
      required: true
    },

    saveChanges: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      authenticatableCopy: {},
      step: 0,
      instructions: [
        {
          title: 'Set up authentication method',
          content: 'Your administrator has required two-factor authentication, which will add another layer of security to your account. Each time you sign in, you will need your password and an authentication code.'
        },
        {
          title: 'Save your backup codes',
          content: 'Below is a list of your backup codes. Please store these codes in a safe place. They can be used to sign in to Scout if you lose access to your email or authenticator app.'
        },
        {
          title: 'Two-Factor Authentication Enabled',
          content: 'Now that you have enabled two-factor authentication, you will have an added layer of security for your account. If you would like to change your authentication method, you can do so at any time by visiting your settings page.'
        }
      ],
      otpBackupCodes: []
    };
  },

  computed: {
    currentStrategy() {
      return this.authenticatable?.otpStrategy;
    },

    selectedStrategy() {
      return this.authenticatableCopy?.otpStrategy;
    },

    mfaEnabled() {
      return this.authenticatable?.otpRequiredForLogin;
    },

    validOtp() {
      return this.authenticatable.validOtp;
    },

    stepText() {
      return this.step <= 1 ? `Two-Factor Authentication (step ${ this.step + 1 } of 2)` : 'Two-Factor Authentication';
    }
  },

  created() {
    this.authenticatableCopy = this.authenticatable;
  },

  methods: {
    selectStrategy(strategy) {
      this.authenticatableCopy.otpStrategy = strategy;
    },

    nextStep() {
      this.step += 1;
      window.scrollTo({ top: 0, behavior: 'smooth' });

      if (this.step === 1) {
        this.loadOtpBackupCodes(this.authenticatableCopy.otpBackupCodeToken);
      }
    },

    setUpTwoFactor() {
      this.$refs.setUpTwoFactor.openModal();
    },

    launchDashboard() {
      window.location.href = this.authenticatable.redirectPath || '/';
    },

    enableTwoFactor() {
      if (this.validOtp) {
        this.saveChanges({ otpStrategy: this.selectedStrategy, otpRequiredForLogin: true }, this.nextStep);
      }
    },

    loadOtpBackupCodes(otpBackupCodeToken) {
      const vueInstance = this;

      LoadingService.loading('backupCodes');

      TwoFactorAuthenticatable.otpBackupCodes({ otpBackupCodeToken })
      .then((response) => {
        vueInstance.otpBackupCodes = response.data;

        LoadingService.done('backupCodes');
      })
      .catch(
        () => {
          LoadingService.done('backupCodes');
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
  .nv-button-blue {
    width: unset;
    padding: 0 33px;
  }

  .settings-screen {
    width: 100%;
    height: 100%;
  }
</style>

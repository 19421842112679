<template>
  <div>
    <div class="messaging-center-header-container">
      <div class="messaging-center-header">
        <div class="header-icon icon-left" @click="onHide">
          <svg-icon class="base-icon" name="chat"></svg-icon>
        </div>

        <div class="text-center">
          Participants
        </div>
      </div>
    </div>

    <div class="messaging-center-content-container conversation-details">
      <div class="messaging-center-list-item" v-for="participant in participants" :key="`${participant.klass}-${participant.id}`">
        <div class="item-photo">
          <profile-photo :user="participant" :small="true"></profile-photo>
        </div>

        <div class="item-content">
          <div class="size-text-13px">
            <span class="semibold-weight">{{ participant.fullName }}</span>

            <span class="left-5" v-if="shouldLinkTo(participant)">(<a :href="pathToLawyer(participant)">View Profile</a>)</span>
          </div>
        </div>
      </div>
    </div>

    <div class="messaging-center-action-container">
      <button type="button" class="nv-button-red a-button-size" @click="onArchiveConversation">Archive Conversation</button>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import { orderBy } from 'lodash';

export default {
  name: 'ConversationDetails',

  components: {
    SvgIcon,
    ProfilePhoto
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    userClass: {
      type: String,
      required: true
    },

    onHide: {
      type: Function,
      required: true
    },

    onArchiveConversation: {
      type: Function,
      required: true
    }
  },

  computed: {
    participants() {
      return orderBy(this.conversation.participants, ['lastName', 'firstName']);
    }
  },

  methods: {
    shouldLinkTo(participant) {
      return (this.userClass === 'admin' || this.userClass === 'client') && participant.klass === 'Lawyer';
    },

    pathToLawyer(participant) {
      const root = (this.userClass === 'admin' ? 'admin-app' : 'client-app');
      return `/${root}/lawyers/${participant.id}`;
    }
  }
};
</script>

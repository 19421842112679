import axios from 'axios';

const saveDocument = (file, resource, options) => {
  return resource.save({
    name: file.name,
    document_type: options.type,
    documentable_id: options.entityId,
    documentable_type: options.entityType
  });
};

const uploadFile = (file, doc) => {
  return axios.put(
    `/internal_documents/${doc.id}.json`,
    { internal_document: { file: file } },
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};

const FileUploadService = {
  uploadAsync: async (options, resource) => {
    const doc = await saveDocument(options.file, resource, options);
    return uploadFile(options.file, doc);
  }
};

export default FileUploadService;

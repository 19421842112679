<template>
  <priori-modal ref="modal" modal-id="scout-feedback" title="Send us Feedback" prompt="Send Feedback" @on-modal-close="reset">
    <internal-message-form
      :on-submit="onSubmit"
      :message="internalMessage"
      instructions="Tell us about your Scout experience:">
    </internal-message-form>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import InternalMessage from 'vue-app/shared/resources/internal-message';
import InternalMessageForm from 'vue-app/shared/components/internal-message-form.vue';

export default {
  name: 'ScoutFeedback',

  components: {
    PrioriModal,
    InternalMessageForm
  },

  data: function () {
    return {
      internalMessage: this.generateMessage()
    };
  },

  methods: {
    generateMessage: function () {
      return new InternalMessage({
        domain: 'scout_feedback',
        parts: [
          { title: 'Feedback', required: true, textarea: true }
        ]
      });
    },

    onSubmit: function () {
      this.$refs.modal.dismissModal();
      this.reset();
    },

    reset: function () {
      this.internalMessage = this.generateMessage();
    }
  }
};
</script>

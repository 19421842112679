<template>
  <div>
    <p class="instruction-text" v-if="instructions">
      {{ instructions }}
    </p>

    <div class="red-text size-text-13px semibold-weight bottom-20" v-if="message.errors.length > 0">
      <p v-for="error in message.errors">
        {{ error }}
      </p>
    </div>

    <form role="form" class="a-form" novalidate>
      <div class="bottom-20" v-for="part in message.parts">
        <div v-if="part.textarea">
          <label :for="`${part.title}-part-input`">{{ part.label || part.title }}</label>
          <span class="inline-help required" v-if="part.required">*</span>
          <textarea class="form-control" :id="`${part.title}-part-input`" v-model="part.body" autofocus placeholder="Enter message..." rows="6" :required="part.required"></textarea>
        </div>

        <div v-else>
          <label :for="`${part.title}-part-input`">{{ part.title }}</label>
          <span class="inline-help required" v-if="part.required">*</span>
          <input type="text" v-model="part.body" :name="part.title" :id="`${part.title}-part-input`" class="form-control" :required="part.required">
        </div>
      </div>

      <div class="row top-30">
        <div class="col-sm-5">
          <loading-button :name="formId" lb-class="primary-btn-blue" @lb-click="submit">Submit</loading-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'InternalMessageForm',

  components: {
    LoadingButton
  },

  props: {
    onSubmit: {
      type: Function,
      default: () => {}
    },

    message: {
      type: Object,
      required: true
    },

    instructions: {
      type: String
    }
  },

  computed: {
    formId() {
      return `internal-message-form-${this._uid}`;
    }
  },

  methods: {
    submit() {
      LoadingService.loading(this.formId);

      this.message.save().then(this.onSubmit).catch(() => {}).finally(this.onSubmitCleanup);
    },

    onSubmitCleanup() {
      LoadingService.done(this.formId);
    }
  }
};
</script>

<style lang="scss" scoped>
  .instruction-text {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 1.4;
  }
</style>

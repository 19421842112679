<template>
  <div :class="[{ 'appointment-container': !noPadding }, 'size-text-13px']">
    <div class="row">
      <div class="col-sm-5 dark-gray-text">
        Use the calendar below to select times you&rsquo;re available. You can select times on multiple days.
      </div>

      <div class="col-sm-7 col-md-5 col-md-offset-2 top-20-xs">
        <time-zone-editor></time-zone-editor>
      </div>
    </div>

    <div class="top-20 bottom-30">
      <hr class="kenny-hr no-margin">
    </div>

    <div class="row tight-columns">
      <div class="col-xs-3 col-sm-2">
        <a href="" class="prevnext-button hidden-xs" @click.prevent="movePreviousDates(3)" :disabled="!canMovePrevious"><svg-icon name="arrow-left" class="base-icon"></svg-icon></a>

        <a href="" class="prevnext-button visible-xs-inline-block" @click.prevent="movePreviousDates(1)" :disabled="!canMovePrevious"><svg-icon name="arrow-left" class="base-icon"></svg-icon></a>
      </div>

      <div class="col-xs-6 col-sm-8 size-text-base semibold-weight text-center top-15">
        {{ formatDate(dates[0], 'MMMM d') }}<span class="hidden-xs"> - {{ formatDate(dates[2], 'MMMM d') }}</span>
      </div>

      <div class="col-xs-3 col-sm-2 text-right">
        <a href="" class="prevnext-button hidden-xs" @click.prevent="moveNextDates(3)"><svg-icon name="arrow-right" class="base-icon"></svg-icon></a>

        <a href="" class="prevnext-button visible-xs-inline-block" @click.prevent="moveNextDates(1)"><svg-icon name="arrow-right" class="base-icon"></svg-icon></a>
      </div>
    </div>

    <div class="row top-30 tight-columns">
      <div :class="['col-sm-4', { 'hidden-xs': index > 0 }]" v-for="(date, index) in dates">
        <div class="clear-panel">
          <availability-selection-option
            :date="date"
            :timeslots="timeslotsFor(date)"
            :availability="availability">
          </availability-selection-option>
        </div>
      </div>
    </div>

    <div class="semibold-weight top-10" v-if="canToggleExpanded">
      <a href="" @click.prevent="toggleTimesExpanded">Show {{ expanded ? 'Fewer' : 'More' }} Times</a>
    </div>

    <form role="form" class="a-form top-30" novalidate>
      <label for="appointment-note">Include note with availability: <span class="normal-weight dark-gray-text">(optional)</span></label>

      <textarea name="appointmentNote" id="appointment-note" class="form-control" placeholder="Note" rows="2" v-model="availability.note"></textarea>
    </form>
  </div>
</template>

<script>
import AvailabilitySelectionOption from 'vue-app/marketplace/shared/appointments/availability-selection-option.vue';
import TimeZoneEditor from 'vue-app/marketplace/shared/appointments/time-zone-editor.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import useCurrentUserStore  from 'vue-app/stores/current-user.js';
import { each, map, range } from 'lodash';
import { mapState } from 'pinia';
import { DateTime } from 'luxon';

export default {
  name: 'AvailabilitySelector',

  components: {
    AvailabilitySelectionOption,
    TimeZoneEditor,
    SvgIcon
  },

  props: {
    availability: {
      type: Object,
      required: true
    },

    scheduler: {
      type: Object,
      required: true
    },

    noPadding: Boolean,
    conflictingTimes: Object
  },

  data() {
    return {
      earliestVisibleDate: DateTime.now(),
      expanded: false,
      canToggleExpanded: true
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { ianaTimeZone: 'ianaTimeZone' }),

    today() {
      return DateTime.now().setZone(this.ianaTimeZone);
    },

    dates() {
      return map(range(3), (offset) => {
        return this.earliestVisibleDate.plus({ days: offset });
      });
    },

    canMovePrevious() {
      return this.today.startOf('day') < this.dates[0].startOf('day');
    },

    timesByDate() {
      return this.scheduler.hoursByDate({
        timeZone: this.ianaTimeZone,
        boundary: this.expanded ? null : 'workHours'
      });
    }
  },

  mounted() {
    this.resetDays();
  },

  methods: {
    resetDays() {
      this.earliestVisibleDate = this.today;
      this.initializeDays(this.today);
    },

    initializeDays(startDate) {
      // really only need a range of 0, 1, and 2, but we include
      // -1 and 3 to preload the previous and next days. This allows
      // us to change the timezone and already have the updated timeslots
      // in memory
      each(range(-1, 4), (offset) => {
        this.scheduler.initializeDate(startDate.plus({ days: offset }));
      });
    },

    updateDateRange(offset) {
      this.earliestVisibleDate = this.earliestVisibleDate.plus({ days: offset });
      this.initializeDays(this.earliestVisibleDate);
    },

    movePreviousDates(offset) {
      if (!this.canMovePrevious) { return; }

      this.updateDateRange(offset * -1);
    },

    moveNextDates(offset) {
      this.updateDateRange(offset);
    },

    toggleTimesExpanded() {
      this.expanded = !this.expanded;
    },

    forceAllTimes() {
      this.expanded = true;
      this.canToggleExpanded = false;
    },

    formatDate(date, luxonFormat) {
      return date.toFormat(luxonFormat);
    },

    timeslotsFor(date) {
      return this.timesByDate[date.toFormat('y-LL-dd')] || [];
    }
  }
};
</script>

<template>
  <div class="shadowed-box" id="firm-contact-section">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          Firm Contact
        </div>

        <div class="col-sm-4 size-text-12px text-right-not-xs">
          <law-firm-contact-modal :law-firm="lawFirm" :lawyers="lawyers" :on-submitted="onSubmitted" v-slot="{ openModal }">
            <a href="" class="normal-weight" @click.prevent="openModal">Edit</a>
          </law-firm-contact-modal>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <div class="row tight-columns" v-if="user">
        <div class="col-sm-6">
          <div class="clear-panel compact-panel">
            <div class="row tight-columns">
              <div class="col-md-4">
                <profile-photo :user="user" :small="true"></profile-photo>
              </div>

              <div class="col-md-8 top-10-xs-sm">
                <div class="text-ellipsis">
                  <a :href="userPath" target="_blank">{{ user.fullName }}</a>
                </div>

                <div class="dark-gray-text text-ellipsis">
                  {{ user.firmTitle || '&mdash;' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="gray-text size-text-base" v-else>
        No Contact Assigned
      </div>
    </div>
  </div>
</template>

<script>
import LawFirmContactModal from 'vue-app/admin/law-firms/law-firm-contact-modal.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';

export default {
  name: 'LawFirmContact',

  components: {
    LawFirmContactModal,
    ProfilePhoto
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    lawyers: {
      type: Array,
      default: () => []
    },

    onSubmitted: {
      type: Function,
      required: true
    }
  },

  computed: {
    userPath() {
      return `/admin-app/lawyers/${this.user.id}`;
    },

    user() {
      return this.lawFirm.contact;
    }
  }
};
</script>

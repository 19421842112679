<template>
  <div>
    <div class="semibold-weight bottom-5">
      Priori Network Rates
    </div>

    <div class="bottom-15">
      The average rates at which attorneys are hired on the Priori platform are:
    </div>

    <table class="kenny-table">
      <thead>
        <tr>
          <th colspan="3">Legal Need</th>
          <th>
            Average Client Total<popover-tooltip placement="top" custom-class="tooltip-white small-text"><span class="tooltip-question left-5">?</span><template #tooltip><span class="semibold-weight">Client Total:</span> The total amount paid by the client. It is the sum of Your Rate plus the Priori Management Fee.</template></popover-tooltip>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colspan="3">
            <span class="semibold-weight">Projects</span>
            <br>
            <span class="dark-gray-text">Specialized projects, transactions, local legal expertise and/or litigation.</span>
          </td>
          <td>$220 - $290/hr</td>
        </tr>
        <tr>
          <td colspan="3">
            <span class="semibold-weight">People</span>
            <br>
            <span class="dark-gray-text">Overflow work and temporary on- or off-site placements.</span>
          </td>
          <td>$100 - $150/hr</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import PopoverTooltip from 'vue-app/shared/components/popover-tooltip.vue';

export default {
  name: 'PlatformRates',

  components: {
    PopoverTooltip
  }
};
</script>

<template>
  <div>
    <div class="modal-header">
      <div>
        <div class="bottom-10">
          <participant-photos :participants="selectedParticipants"></participant-photos>
        </div>

        <div>
          Availability for {{ appointment.typeText() }}
        </div>
      </div>
    </div>

    <availability-selector
      ref="availabilitySelector"
      :availability="availability"
      :scheduler="scheduler"
      :conflicting-times="appointment.conflictingTimes">
    </availability-selector>
  </div>
</template>

<script>
import ParticipantPhotos from 'vue-app/marketplace/shared/appointments/participant-photos.vue';
import AvailabilitySelector from 'vue-app/marketplace/shared/appointments/availability-selector.vue';

export default {
  name: 'StepAvailability',

  components: {
    ParticipantPhotos,
    AvailabilitySelector
  },

  props: {
    appointment: {
      type: Object,
      required: true
    },

    availability: {
      type: Object,
      required: true
    },

    scheduler: {
      type: Object,
      required: true
    },

    selectedParticipants: {
      type: Array,
      required: true
    }
  },

  methods: {
    reset() {
      this.$refs.availabilitySelector.resetDays();
    }
  }
};
</script>

<template>
  <div class="row tight-columns vertical-center-not-xs lawyer-profile-panel">
    <div :class="[(showHireButton ? 'col-sm-6' : 'col-sm-7'), 'col-lg-7']">
      <proposal-lawyer-basic-details
        :possible-lawyer="possibleLawyer"
        :lawyer="lawyer"
        :is-preview="isPreview"
        :on-ask-question="askQuestion"
        :is-marketplace-rfq="false">
      </proposal-lawyer-basic-details>
    </div>

    <div :class="[(showHireButton ? 'col-sm-6' : 'col-sm-5'), 'col-lg-5 top-30-xs']">
      <div class="row tight-columns">
        <div :class="[(showRequestProposalButton ? 'col-xs-4' : 'col-xs-6'), { 'col-sm-4': showHireButton }]">
          <scheduling-buttons
            :possible-lawyer="possibleLawyer"
            :on-schedule-appointment="onScheduleAppointment"
            :on-update-appointment="onUpdateAppointment"
            :on-confirm-appointment="onConfirmAppointment">
          </scheduling-buttons>
        </div>

        <div :class="[(showRequestProposalButton ? 'col-xs-4' : 'col-xs-6'), { 'col-sm-4': showHireButton }]">
          <div class="svg-button action-bar-button" @click="askQuestion" v-if="isPreview">
            <svg-icon name="envelope" class="base-icon bottom-10"></svg-icon>
            <div>Send<br>Message</div>
          </div>

          <div :class="['svg-button action-bar-button', { 'disabled': !canRejectLawyer }]" @click="rejectLawyer" v-else>
            <svg-icon name="reject" class="base-icon bottom-10"></svg-icon>
            <div>Reject<br>Option</div>
          </div>
        </div>

        <div class="col-xs-4" v-if="showRequestProposalButton">
          <request-proposal
            :pl="possibleLawyer"
            :on-request-proposal="requestProposal">
          </request-proposal>
        </div>

        <div class="col-xs-12 col-sm-4 top-10-xs" v-if="showHireButton">
          <hire-lawyer
            class="top-10"
            :pl="possibleLawyer"
            :on-hire-lawyer="onHireLawyer">
          </hire-lawyer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProposalLawyerBasicDetails from 'vue-app/marketplace/client/proposals/proposal-lawyer-basic-details.vue';
import SchedulingButtons from 'vue-app/marketplace/client/requests/scheduling-buttons.vue';
import RequestProposal from 'vue-app/marketplace/client/requests/request-proposal.vue';
import HireLawyer from 'vue-app/marketplace/client/requests/hire-lawyer.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ProfileHeader',

  components: {
    ProposalLawyerBasicDetails,
    SchedulingButtons,
    RequestProposal,
    HireLawyer,
    SvgIcon
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    possibleLawyer: {
      type: Object,
      required: true
    },

    isPreview: {
      type: Boolean,
      default: false
    },

    onAskQuestion: {
      type: Function,
      required: true
    },

    onRequestProposal: {
      type: Function,
      required: true
    },

    onScheduleAppointment: {
      type: Function,
      required: true
    },

    onUpdateAppointment: {
      type: Function,
      required: true
    },

    onConfirmAppointment: {
      type: Function,
      required: true
    },

    onHireLawyer: {
      type: Function,
      required: true
    },

    onRejectLawyer: {
      type: Function,
      required: false
    }
  },

  computed: {
    matter() {
      return this.possibleLawyer.matter;
    },

    counselRequest() {
      return this.possibleLawyer.counselRequest;
    },

    hasFormalProposal() {
      return this.possibleLawyer.status === 'sent_price';
    },

    counselRequestCompleted() {
      return this.counselRequest.status === 'matter_opened' ||
        this.counselRequest.status === 'admin_closed';
    },

    showHireButton() {
      return this.hasFormalProposal && this.matter;
    },

    showRequestProposalButton() {
      return !this.hasFormalProposal && !this.counselRequestCompleted &&
        (
          this.possibleLawyer.awaitingProposal() ||
          this.possibleLawyer.messagedStatus() ||
          this.possibleLawyer.hasAppointment()
        );
    },

    canRequestProposal() {
      return this.showRequestProposalButton &&
        !this.possibleLawyer.proposalRequested;
    },

    canRejectLawyer() {
      return !this.possibleLawyer.selected() &&
        !this.possibleLawyer.rejected() &&
        !this.counselRequestCompleted;
    }
  },

  methods: {
    askQuestion() {
      this.onAskQuestion(this.possibleLawyer);
    },

    rejectLawyer() {
      if (this.canRejectLawyer) { this.onRejectLawyer(); }
    },

    requestProposal() {
      if (this.canRequestProposal) { this.onRequestProposal(this.possibleLawyer); }
    }
  }
};
</script>

<template>
  <div>
    <div class="svg-button action-bar-button" @click="confirm" v-if="!pl.proposalRequested">
      <svg-icon name="message" class="base-icon bottom-10"></svg-icon>
      <div>Request<br>Proposal</div>
    </div>

    <div class="svg-button action-bar-button disabled green" v-if="pl.proposalRequested">
      <svg-icon name="checkmark" class="base-icon bottom-10"></svg-icon>
      <div>Proposal<br>Requested</div>
    </div>

    <request-confirmation
      ref="confirmationModal"
      :message="confirmMessage"
      red-note="Existing calls for this matter will be cancelled. You are able to schedule another call with this lawyer via our messaging system if necessary."
      yes-btn-text="Yes, proceed"
      no-btn-text="No, cancel"
      @on-modal-yes="requestProposal">
    </request-confirmation>
  </div>
</template>

<script>
import RequestConfirmation from 'vue-app/shared/components/request-confirmation.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RequestProposal',

  components: {
    RequestConfirmation,
    SvgIcon
  },

  props: {
    pl: {
      type: Object,
      required: true
    },

    onRequestProposal: {
      type: Function,
      required: true
    }
  },

  computed: {
    confirmMessage() {
      return `Please confirm you have connected with ${this.pl.lawyer.fullName} to discuss this project and are ready to move forward.`;
    }
  },

  methods: {
    confirm() {
      this.$refs.confirmationModal.openModal();
    },

    requestProposal() {
      this.onRequestProposal();
    }
  }
};
</script>

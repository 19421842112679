<template>
  <modal id="prompt-review-modal" v-model="modalOpen" size="md-sm" :header="false" :footer="false" append-to-body>
    <modal-close-button :on-dismiss="cancel"></modal-close-button>

    <div class="notice-modal">
      <div class="notice-header">
        Lawyer Review
      </div>

      <div class="notice-regular-text">
        <div class="size-text-base semibold-weight text-center bottom-20">
          Help us keep Priori great!
        </div>

        <div>
          Review your lawyer <span class="semibold-weight">{{ lawyerName }}</span> and help us maintain the quality of Priori&rsquo;s network! It takes fewer than 5 minutes.
        </div>
      </div>

      <div class="notice-action-container">
        <div class="row">
          <div class="col-sm-6 bottom-20">
            <a :href="`/client-app/projects/${matterId}/reviews/new`" class="nv-button-purple-red smaller">Review Your Lawyer</a>
          </div>

          <div class="col-sm-6 bottom-30">
            <button type="button" class="nv-button-white smaller" @click="cancel">Not Now</button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';

export default {
  name: 'PromptReviewModal',

  components: {
    ModalCloseButton
  },

  mixins: [
    modalToggle
  ],

  props: {
    lawyerName: {
      type: String,
      required: true
    },

    matterId: {
      type: Number,
      required: true
    }
  },

  methods: {
    cancel() {
      this.closeModal();
    }
  }
};
</script>

<template>
  <div class="shadowed-box">
    <div class="box-content">
      <validation-observer v-slot="{ handleSubmit }">
        <form role="form" name="form" class="a-form" novalidate>
          <div id="error-message" :class="['pr-error size-text-13px semibold-weight', { 'bottom-20': errorMessage.length }]">
            {{ errorMessage }}
          </div>

          <div>
            <label class="radio-inline">
              <input type="radio" name="type" id="type[bankAccount]" value="bankAccount" v-model="account.accountType">
              Bank Account
            </label>

            <label class="radio-inline">
              <input type="radio" name="type" id="type[card]" value="card" v-model="account.accountType">
              Credit Card
            </label>
          </div>

          <div class="top-30 bottom-30">
            <hr class="kenny-hr no-margin">
          </div>

          <div class="size-text-13px semibold-weight text-center" v-if="account.accountType === 'bankAccount'">
            <div class="bottom-20">
              <button type="button" class="green-btn" @click="plaidLink">Choose Your Bank</button>
            </div>

            <div class="bottom-20">
              OR
            </div>

            <div>
              Don't see your bank? Complete the form below to link your account.
            </div>
          </div>

          <div class="top-30 bottom-30" v-if="account.accountType === 'bankAccount'">
            <hr class="kenny-hr no-margin">
          </div>

          <div class="bottom-20">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <label for="name" v-if="account.accountType === 'card'">Name on Card</label>
              <label for="name" v-if="account.accountType === 'bankAccount'">Name on Account</label>
              <span class="inline-help required">*</span><span v-if="errors.length > 0" class="pr-error size-text-12px semibold-weight">Required</span>
              <input type="text" name="name" id="name" class="form-control" autocomplete="nope" v-model="account.name">
            </validation-provider>
          </div>

          <div class="bottom-20" v-if="account.accountType === 'bankAccount'">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <label for="account-number">Account Number</label> <span class="inline-help required">*</span><span v-if="errors.length > 0" class="pr-error size-text-12px semibold-weight">Required</span>
              <input type="text" name="accountNumber" id="account-number" class="form-control" maxlength="16" autocomplete="nope" v-model="account.accountNumber">
            </validation-provider>
          </div>

          <div class="bottom-20" v-if="account.accountType === 'bankAccount'">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <label for="routing-number">Routing Number</label> <span class="inline-help required">*</span><span v-if="errors.length > 0" class="pr-error size-text-12px semibold-weight">Required</span>
              <input type="text" name="routingNumber" id="routing-number" class="form-control" maxlength="16" autocomplete="nope" v-model="account.routingNumber">
            </validation-provider>
          </div>

          <div class="bottom-20" v-if="account.accountType === 'bankAccount'">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <label class="radio-inline">
                <input type="radio" name="holderType" id="holder-type[company]" value="company" v-model="account.holderType">
                Company
              </label>
              <label class="radio-inline">
                <input type="radio" name="holderType" id="holder-type[individual]" value="individual" v-model="account.holderType">
                Individual
              </label>
              <span class="inline-help required">*</span><span v-if="errors.length > 0" class="pr-error size-text-12px semibold-weight">Required</span>
            </validation-provider>
          </div>

          <div class="bottom-20" v-if="account.accountType === 'card'">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <label for="number">Card Number</label> <span class="inline-help required">*</span><span v-if="errors.length > 0" class="pr-error size-text-12px semibold-weight">Required</span>
              <input type="text" name="number" id="number" class="form-control" maxlength="16" autocomplete="nope" v-model="account.number">
            </validation-provider>
          </div>

          <div class="row" v-if="account.accountType === 'card'">
            <div class="col-sm-6">
              <div class="bottom-20">
                <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
                  <label for="expiration-month">Expiration Month</label> <span class="inline-help required">*</span><span v-if="errors.length > 0" class="pr-error size-text-12px semibold-weight">Required</span>
                  <input type="text" name="expirationMonth" id="expiration-month" class="form-control" placeholder="MM" maxlength="2" autocomplete="nope" v-model="account.expirationMonth">
                </validation-provider>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="bottom-20">
                <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
                  <label for="expiration-year">Expiration Year</label> <span class="inline-help required">*</span><span v-if="errors.length > 0" class="pr-error size-text-12px semibold-weight">Required</span>
                  <input type="text" name="expirationYear" id="expiration-year" class="form-control" placeholder="YYYY" maxlength="4" autocomplete="nope" v-model="account.expirationYear">
                </validation-provider>
              </div>
            </div>
          </div>

          <div class="bottom-20" v-if="account.accountType === 'card'">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <label for="cvv">Card Verification Code (CVV)</label> <span class="inline-help required">*</span><span v-if="errors.length > 0" class="pr-error size-text-12px semibold-weight">Required</span>
              <input type="text" name="cvv" id="cvv" class="form-control" maxlength="4" autocomplete="nope" v-model="account.cvv">
            </validation-provider>
          </div>

          <div class="bottom-20" v-if="account.accountType === 'card'">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <label for="postal-code">Postal Code</label> <span class="inline-help required">*</span><span v-if="errors.length > 0" class="pr-error size-text-12px semibold-weight">Required</span>
              <input type="text" name="address.postal_code" id="postal-code" class="form-control" autocomplete="nope" v-model="account.address.postal_code">
            </validation-provider>
          </div>

          <div v-if="account.accountType === 'bankAccount'">
            <bank-verification-info></bank-verification-info>
          </div>

          <div class="row top-30">
            <div class="col-sm-4">
              <loading-button name="submitAccount" lb-class="nv-button-blue smaller" @lb-click="handleSubmit(onSubmit)">Add</loading-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import BankVerificationInfo from 'vue-app/marketplace/client/settings/bank-accounts/bank-verification-info.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';

export default {
  name: 'PaymentMethodForm',

  components: {
    BankVerificationInfo,
    LoadingButton,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    account: {
      type: Object,
      required: true
    },

    errorMessage: {
      type: String,
      default: ''
    },

    plaidLink: {
      type: Function,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    }
  }
};
</script>

<template>
  <validation-observer v-slot="{ handleSubmit, failed }">
    <form role="form" name="form" class="a-form" novalidate>
      <div class="row">
        <div class="col-sm-6 bottom-20">
          <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
            <label for="first-name">
              First Name <span class="inline-help required">*</span>
            </label>

            <input type="text" name="firstName" id="first-name" :class="['form-control', { 'has-error': errors.length }]" v-model="client.firstName" autocomplete="off">

            <div class="error-text top-5" v-if="errors.length">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

        <div class="col-sm-6 bottom-20">
          <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
            <label for="last-name">
              Last Name <span class="inline-help required">*</span>
            </label>

            <input type="text" name="lastName" id="last-name" :class="['form-control', { 'has-error': errors.length }]" v-model="client.lastName" autocomplete="off">

            <div class="error-text top-5" v-if="errors.length">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
      </div>

      <div v-if="!client.id" class="bottom-20">
        <validation-provider rules="required|email" :mode="passiveAggressive" v-slot="{ errors }">
          <label for="client-email">
            Email  <span class="inline-help required">*</span>
          </label>

          <input type="email" name="email" id="client-email" :class="['form-control', { 'has-error': errors.length }]" v-model="client.email" autocomplete="off">

          <div class="error-text top-5" v-if="errors.length">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </div>
      <div v-else class="bottom-20">
        <label for="client-email">
          Email
        </label>
        <div>
          {{ client.email }}
        </div>
      </div>

      <div class="bottom-20">
        <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
          <label for="client-role">
            Title <span class="inline-help required">*</span>
          </label>

          <input type="text" name="clientRole" id="client-role" :class="['form-control', { 'has-error': errors.length }]" v-model="client.role" autocomplete="off">

          <div class="error-text top-5" v-if="errors.length">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </div>

      <div class="bottom-20">
        <div class="bottom-10">
          <validation-provider :rules="`${isEmployeeIdRequired ? 'required' : ''}`" :mode="passiveAggressive" v-slot="{ errors }">
            <label for="client-employee-id">
              Employee ID <span class="inline-help required" v-if="isEmployeeIdRequired">*</span>
            </label>

            <input type="text" name="clientEmployeeId" id="client-employee-id" :class="['form-control', { 'has-error': errors.length }]" v-model="client.employeeId" autocomplete="off">

            <div class="error-text top-5" v-if="errors.length">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

        <label class="check-option vertical medium-large">
          <span class="normal-weight">Billing ID is the same as Employee ID</span>
          <input type="checkbox" v-model="billingIdIsEmployeeId" name="billingIdIsEmployeeId" id="billing-id-is-employee-id" @change="checkEmployeeId()">
          <span class="check c-box"></span>
        </label>
      </div>

      <div class="bottom-20">
        <div class="bottom-10">
          <label for="client-billing-id">
            Billing ID
          </label>

          <input type="text" name="clientBillingId" id="client-billing-id" class="form-control" v-model="client.billingId" :disabled="disableBillingId">
        </div>

        <label class="check-option vertical medium-large">
          <span class="normal-weight">This user does not have a Billing ID</span>
          <input type="checkbox" v-model="noBillingId" name="noBillingId" id="no-billing-id" @change="checkNoBillingId()">
          <span class="check c-box"></span>
        </label>
      </div>

      <div class="bottom-20" v-if="hasPracticeGroups">
        <label for="client-practie-groups">
          Practice Group
        </label>

        <multi-select-dropdown
          :value="selectedGroupNames"
          :options="groupNames"
          :show-typeahead="false"
          @change="updateClientPracticeGroups">
        </multi-select-dropdown>

        <div class="tag-list top-10" v-if="selectedGroupNames.length > 0">
          <div class="tag-list-item compact blue delete-variant" v-for="group in selectedGroups">
            <span class="right-10">{{ group.name }}</span>
            <span class="tag-list-item-delete" @click="removePracticeGroup(group)"><svg-icon name="x-circle" class="base-icon"></svg-icon></span>
          </div>
        </div>
      </div>

      <div class="bottom-20">
        <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
          <label for="client-user-type">
            User Type <span class="inline-help required">*</span>
          </label>

          <dropdown-select
            ref="userTypeDropdown"
            id-label="client-user-type"
            placeholder="Select One"
            v-model="client.userType"
            :initial-label="client.userType"
            :options="getUserTypes"
            :invalid="errors.length > 0">
          </dropdown-select>

          <div class="error-text top-5" v-if="errors.length">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </div>

      <div class="bottom-30">
        <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
          <label for="client-rfq-permissions">
            RFP Permission <span class="inline-help required">*</span>
          </label>

          <div class="bottom-10 dark-gray-text">
            <i> This setting determines what level of access the user will have to RFPs within your workspace. Users granted "RFP Access" will be able to create, send, and view their own RFPs. Users designated as "RFP Managers" will be able to create and send their own RFPs, and also will be able to view all RFPs submitted by their colleagues. Users with No RFP Access will not have access to the RFP Feature. </i>
          </div>

          <dropdown-select
            ref="rfqPermissionsDropdown"
            id-label="client-rfq-permissions"
            placeholder="Select One"
            v-model="client.hasRfqEnabled"
            :initial-label="selectedRfqPermission"
            :options="['No RFP Access', 'RFP Access', 'RFP Manager']"
            :invalid="errors.length > 0"
            @change="updateRfqPermisssions">
          </dropdown-select>

          <div class="error-text top-5" v-if="errors.length">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <loading-button name="submit" lb-class="primary-btn-blue" @lb-click="handleSubmit(onSave)">Save</loading-button>
        </div>

        <div class="col-sm-4 top-20-xs">
          <button type="button" class="secondary-btn-blue" @click="onCancel">Cancel</button>
        </div>
      </div>

      <div v-if="failed" class="error-text top-5">
        * One or more required fields are empty. Please fill in the required fields indicated above.
      </div>
    </form>
  </validation-observer>
</template>

<script>
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { without } from 'lodash';

export default {
  name: 'ClientForm',

  components: {
    DropdownSelect,
    LoadingButton,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    onSave: {
      type: Function,
      default: () => {}
    },

    onCancel: {
      type: Function,
      default: () => {}
    },

    practiceGroups: {
      type: Array,
      default: () => []
    },

    isUserManager: {
      type: Boolean,
      default: false
    },

    isEmployeeIdRequired: {
      type: Boolean,
      default: true
    },

    client: {
      type: Object
    }
  },

  data: function () {
    return {
      billingIdIsEmployeeId: false,
      noBillingId: false,
      userTypes: []
    };
  },

  computed: {
    clientPracticeGroupIds() {
      return (this.client.practiceGroups || []).map(group => group.id);
    },

    practiceGroupIds() {
      return this.client.practiceGroupIds || this.clientPracticeGroupIds;
    },

    disableBillingId() {
      return this.noBillingId || this.billingIdIsEmployeeId;
    },

    hasPracticeGroups() {
      return this.practiceGroups.length > 0;
    },

    groupNames() {
      return this.practiceGroups.map(group => group.name);
    },

    selectedGroups() {
      return this.practiceGroups.filter(group => this.practiceGroupIds.includes(group.id));
    },

    selectedGroupNames() {
      return this.selectedGroups.map(group => group.name);
    },

    getUserTypes() {
      return this.isUserManager ? ['Admin', 'Client User', 'User Manager'] : ['Admin', 'Client User'];
    },

    selectedRfqPermission() {
      if (!this.client.id) {
        return null;
      }

      if (this.client.hasRfqEnabled && this.client.hasRfqWorkspaceEnabled) {
        return 'RFP Manager';
      }
      else if (this.client.hasRfqEnabled && !this.client.hasRfqWorkspaceEnabled) {
        return 'RFP Access';
      }
      else {
        return 'No RFP Access';
      }
    }
  },

  methods: {
    checkEmployeeId() {
      if (this.billingIdIsEmployeeId) {
        this.client.billingId = null;
        this.noBillingId = false;
      }
    },

    checkNoBillingId() {
      if (this.noBillingId) {
        this.client.billingId = null;
        this.billingIdIsEmployeeId = false;
      }
    },

    updateClientPracticeGroups(groupNames) {
      this.client.practiceGroupIds = groupNames.map((groupName) => {
        return this.practiceGroups.find(group => group.name === groupName).id;
      });
    },

    updateRfqPermisssions(permission) {
      if (permission === 'No RFP Access') {
        this.client.hasRfqEnabled = false;
        this.client.hasRfqWorkspaceEnabled = false;
      }
      else if (permission === 'RFP Access') {
        this.client.hasRfqEnabled = true;
        this.client.hasRfqWorkspaceEnabled = false;
      }
      else {
        this.client.hasRfqEnabled = true;
        this.client.hasRfqWorkspaceEnabled = true;
      }
    },

    removePracticeGroup(group) {
      this.client.practiceGroupIds = without(this.practiceGroupIds, group.id);
    },

    reset() {
      this.$refs.rfqPermissionsDropdown.reset();
      this.$refs.userTypeDropdown.reset();
    }
  }
};
</script>


import BaseResource from '../base-resource';
import axios from 'axios';

class ScoutLawFirmClientNote extends BaseResource {
  static baseUrl = '/scout/law_firm_bridges/:lawFirmBridgeId/law_firm_client_notes/:id.json';
  static urlTransformations = ['lawFirmBridgeId', 'id'];

  static multi(lawFirmBridgeId, data) {
    var url = `/scout/law_firm_bridges/${lawFirmBridgeId}/law_firm_client_notes/multi.json`;

    return axios.patch(url, data);
  }
}

export default ScoutLawFirmClientNote;

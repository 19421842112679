<template>
  <div v-if="isImpersonating" class="impersonation-bubble">
    <button type="button">
      <a href="" class="close-link" @click.prevent="stopImpersonating">x</a>

      <div>
        <div>
          <span class="semibold-weight">{{ user.fullName }}</span> ({{ user.klass }})
        </div>

        <impersonate-time
          :impersonation-ends-at="impersonationEndsAt"
          :stop-impersonate-path="stopImpersonationPath">
        </impersonate-time>
      </div>
    </button>
  </div>
</template>

<script>
import ImpersonateTime from 'vue-app/shared/components/impersonate-time.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'ImpersonationBubble',

  components: {
    ImpersonateTime
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    isImpersonating() {
      return this.user.isImpersonated;
    },

    impersonationEndsAt() {
      return this.user.impersonationEndsAt;
    },

    userPathName() {
      return this.$pluralize(this.user.klass.toLowerCase());
    },

    stopImpersonationPath() {
      return `/${this.userPathName}/${this.user.id}/stop_impersonating`;
    }
  },

  methods: {
    stopImpersonating() {
      window.location.href = this.stopImpersonationPath;
    }
  }
};
</script>

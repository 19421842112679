<template>
  <div class="text-center">
    <div class="center-block bottom-5">
      <button type="button" class="round-button" @click.prevent="openModal">
        <svg-icon name="plus" class="base-icon"></svg-icon>
        <priori-modal title="Add To List" modal-id="add-to-list" ref="modal" :prompt-hidden="true">
          <form-manage-list
            :current-tab="isLawyer ? 'lawyer' : 'law_firms'"
            :resource-lists="resourceLists"
            :on-submit="onSubmit"
            :on-success="formManageListSuccess">
          </form-manage-list>
        </priori-modal>
      </button>
    </div>
    Add to List
    <scout-success-list-modal
      :show-success-modal="showModal"
      :is-lawyer="isLawyer">
    </scout-success-list-modal>
  </div>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import FormManageList from 'vue-app/scout/shared/form-manage-list.vue';
import ScoutSuccessListModal from 'vue-app/scout/client/searches/success-list-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'AddToListButton',

  components: {
    PrioriModal,
    FormManageList,
    ScoutSuccessListModal,
    SvgIcon
  },

  props: {
    resourceLists: {
      type:     Array,
      required: true
    },

    isLawyer: {
      type: Boolean,
      default: true
    },

    onSubmit: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      showModal: false
    };
  },

  methods: {
    formManageListSuccess() {
      this.$refs.modal.dismissModal();
      this.showModal = true;
    },

    openModal() {
      this.$refs.modal.openModal();
    }
  }
};
</script>

<template>
  <div class="sidenav-content">
    <div class="container-fluid">
      <div class="padded-container">
        <div class="medium-page-heading bottom-30">
          Account Settings
        </div>

        <div class="row size-text-13px">
          <div class="col-md-11 col-lg-10">
            <div class="bottom-30">
              <div v-if="!ssoEnabled">
                <two-factor-authentication-settings
                  :save-changes="saveMfaChanges"
                  :verify-otp="verifyOtp"
                  :send-one-time-password="sendOneTimePassword"
                  :password-authentication="passwordAuthentication"
                  :authenticatable="authenticatable">
                </two-factor-authentication-settings>
              </div>
            </div>

            <div class="bottom-30">
              <client-account-info
                :client="user"
                :sso-enabled="ssoEnabled"
                :on-save="updateClient"
                :update-password="updateClientPassword"
                :password-authentication="passwordAuthentication"
                :is-scout="user.isScout">
              </client-account-info>
            </div>

            <div class="bottom-30">
              <workspace-info
                :workspace="workspace"
                :on-save="updateWorkspace"
                :editing-allowed="!workspace.isScout">
              </workspace-info>
            </div>

            <div class="bottom-30">
              <workspace-users
                :current-user="user"
                :workspace="workspace"
                :workspace-users="workspaceUsers"
                :on-invite-sent="onInviteSent"
                :on-message-user="messageUser">
              </workspace-users>
            </div>

            <div class="bottom-30">
              <workspace-billing-info
                :workspace="workspace"
                :on-save="updateWorkspace">
              </workspace-billing-info>
            </div>

            <div class="bottom-30">
              <workspace-documents
                :workspace="workspace"
                :on-save="updateWorkspace">
              </workspace-documents>
            </div>

            <div class="bottom-30">
              <workspace-contacts
                :workspace="workspace"
                :workspace-team="workspaceUsers"
                :on-save="updateWorkspace">
              </workspace-contacts>
            </div>

            <div>
              <workspace-conflicts
                :workspace="workspace"
                :on-save="updateWorkspace">
              </workspace-conflicts>
            </div>
          </div>
        </div>
      </div>
    </div>

    <send-message-modal ref="messageModal" :recipients="messageRecipients"></send-message-modal>
  </div>
</template>

<script>
import ClientAccountInfo from 'vue-app/marketplace/client/settings/client-account-info.vue';
import TwoFactorAuthenticationSettings from 'vue-app/shared/components/two-factor-authentication/two-factor-authentication-settings.vue';
import WorkspaceInfo from 'vue-app/marketplace/client/settings/workspace-info.vue';
import WorkspaceUsers from 'vue-app/marketplace/client/workspaces/workspace-users.vue';
import WorkspaceBillingInfo from 'vue-app/marketplace/client/settings/workspace-billing-info.vue';
import WorkspaceDocuments from 'vue-app/marketplace/client/settings/workspace-documents.vue';
import WorkspaceContacts from 'vue-app/marketplace/client/settings/workspace-contacts.vue';
import WorkspaceConflicts from 'vue-app/marketplace/client/settings/workspace-conflicts.vue';
import SendMessageModal from 'vue-app/shared/components/send-message-modal.vue';

import Client from 'resources/client.js';
import TwoFactorAuthenticatable from 'resources/two-factor-authenticatable.js';
import Workspace from 'resources/workspace.js';
import InternalDocument from 'resources/internal-document.js';

import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';

import { uniq } from 'lodash';
import { mapState, mapActions } from 'pinia';
import { scrollToTop } from 'misc/ui-helpers.js';

export default {
  name: 'SettingsIndex',

  components: {
    TwoFactorAuthenticationSettings,
    ClientAccountInfo,
    WorkspaceInfo,
    WorkspaceUsers,
    WorkspaceBillingInfo,
    WorkspaceDocuments,
    WorkspaceContacts,
    WorkspaceConflicts,
    SendMessageModal
  },

  props: {
    initData: {
      type:     Object,
      required: true
    }
  },

  data() {
    return {
      workspace: this.initData.workspace,
      authenticatable: this.initData.authenticatable,
      workspaceUsers: [],
      messageRecipients: []
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    ssoEnabled() {
      return this.user.workspace && this.user.workspace.ssoEnabled;
    }
  },

  mounted() {
    this.loadWorkspaceUsers();
  },

  methods: {
    ...mapActions(useCurrentUserStore, ['setCurrentUser']),

    saveMfaChanges(authenticatable, successFn) {
      return TwoFactorAuthenticatable.update(authenticatable).then(
        (response) => {
          this.authenticatable = response;
          NotificationService.success('Changes were successfully saved');
          successFn();
        },
        () => {
          NotificationService.error('Changes could not be saved');
        }
      );
    },

    verifyOtp(otpAttempt, otpStrategy) {
      return TwoFactorAuthenticatable.verifyOtp({ otpAttempt, otpStrategy }).then(
        (response) => {
          this.authenticatable = { ...response, validOtp: true };
        },
        () => {
          this.authenticatable.validOtp = false;
        }
      );
    },

    sendOneTimePassword() {
      return TwoFactorAuthenticatable.sendOneTimePassword({}).then(
        () => {
          NotificationService.clear();
        },
        () => {
          NotificationService.error('Could not send one time password');
        }
      );
    },

    passwordAuthentication(password) {
      return TwoFactorAuthenticatable.passwordVerification({ password }).then((resp) => {
        NotificationService.clear();
        return resp.data;
      });
    },

    updateClient(editedClient) {
      if (editedClient) { Object.assign(this.user, editedClient); }

      return Client.update({ id: this.user.id, client: this.user }).then((user) => {
        this.setCurrentUser(user);

        if (user.unconfirmedEmail) {
          scrollToTop();
          NotificationService.success('Account successfully updated. You will receive an email requesting that you confirm the change in your email address. Please click the link in that email to finalize the change.');
        }
      });
    },

    updateClientPassword(editedClient) {
      return Client.updatePassword({ id: this.user.id, client: editedClient }).then(user => {
        this.setCurrentUser(user);
      });
    },

    updateWorkspace({ workspace, documentsToDelete, errorFn, successFn }) {
      if (documentsToDelete && documentsToDelete.length) {
        documentsToDelete = uniq(documentsToDelete);

        InternalDocument.deleteBatch({ ids: documentsToDelete.toString() }).then(() => {
          this.updateWorkspaceData({ workspace, errorFn, successFn });
        });
      }
      else {
        this.updateWorkspaceData({ workspace, errorFn, successFn });
      }
    },

    updateWorkspaceData({ workspace, errorFn, successFn }) {
      Workspace.update({ id: workspace.id, workspace: workspace }).then(
        (workspace) => {
          this.workspace = workspace;

          if (successFn) { successFn(); }
        },
        (response) => {
          if (errorFn) { errorFn(response.response.data.errors); }
        }
      );
    },

    onInviteSent(client) {
      this.workspaceUsers.push(client);
    },

    messageUser(user) {
      this.messageRecipients = [user];
      this.$refs.messageModal.openModal();
    },

    loadWorkspaceUsers() {
      LoadingService.around('workspaceUsers', () => {
        return Client.query().then((users) => {
          this.workspaceUsers = users;
        });
      });
    }
  }
};
</script>

<template>
  <div>
    <div v-show="isCurrentStep('participants')">
      <step-participants
        ref="stepParticipants"
        :possible-participants="possibleParticipants"
        :selected-participants="selectedParticipants">
      </step-participants>
    </div>

    <div v-show="isCurrentStep('type')">
      <step-appointment-type
        ref="stepAppointmentType"
        :appointment="appointment"
        :selected-participants="selectedParticipants"
        :on-type-selected="selectType">
      </step-appointment-type>
    </div>

    <div v-show="isCurrentStep('times')">
      <step-availability
        ref="stepAvailability"
        :appointment="appointment"
        :availability="availability"
        :scheduler="scheduler"
        :selected-participants="selectedParticipants">
      </step-availability>
    </div>

    <div v-show="isCurrentStep('location')">
      <step-location
        ref="stepLocation"
        :appointment="appointment"
        :selected-participants="selectedParticipants">
      </step-location>
    </div>

    <hr class="kenny-hr no-margin">

    <div class="appointment-container">
      <div class="row tight-columns">
        <div :class="['col-sm-3', { 'col-xs-6': !isLastStep, 'col-xs-12 bottom-20': isLastStep }]">
          <button type="button" class="nv-button-white smaller" @click="movePrevious" v-if="!isFirstStep">Back</button>

          <button type="button" class="nv-button-white smaller" @click="onCancelAppointment" v-if="showCancelButton">{{ (!appointment.currentParticipantIsOriginator() && !appointment.hasConsultations()) ? 'Reject' : 'Cancel' }} {{ appointment.type === 'in-person' ? 'Meeting' : 'Call' }}</button>
        </div>

        <div :class="['col-sm-5 col-sm-push-4 col-md-4 col-md-push-5', { 'col-xs-6': !isLastStep, 'col-xs-12': isLastStep }]">
          <button type="button" class="nv-button-blue smaller" @click="moveNext" :disabled="isMoveNextDisabled" v-if="!isLastStep">Next</button>

          <loading-button name="submitAvailability" lb-class="nv-button-blue smaller" @lb-click="submit" :lb-disabled="isSubmitDisabled" v-if="isLastStep">{{ isCurrentStep('location') ? 'Submit Meeting Request' : 'Submit Availability' }}</loading-button>
        </div>

        <div class="col-xs-12 col-sm-4 col-sm-pull-5 col-md-5 col-md-pull-4">
          <div class="size-text-13px semibold-weight text-right top-10 right-10" v-if="isCurrentStep('times')">
            <div>
              # of times selected: <span class="purple-text">{{ selectedTimesCount }}</span>
            </div>

            <div v-if="(selectedParticipants.length > 1) && (selectedTimesCount < selectedParticipants.length)">
              <span class="red-text">Select at least {{ selectedParticipants.length }} times</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepParticipants from 'vue-app/marketplace/shared/appointments/step-participants.vue';
import StepAppointmentType from 'vue-app/marketplace/shared/appointments/step-appointment-type.vue';
import StepAvailability from 'vue-app/marketplace/shared/appointments/step-availability.vue';
import StepLocation from 'vue-app/marketplace/shared/appointments/step-location.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';

import Availability from 'resources/marketplace/availability.js';
import UserSchedule from 'services/marketplace/user-schedule.js';
import AvailabilityScheduler from 'services/marketplace/availability-scheduler.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'Scheduling',

  components: {
    StepParticipants,
    StepAppointmentType,
    StepAvailability,
    StepLocation,
    LoadingButton
  },

  props: {
    appointment: {
      type: Object,
      required: true
    },

    possibleParticipants: {
      type: Array,
      default: () => []
    },

    selectedParticipants: {
      type: Array,
      default: () => []
    },

    onCancelAppointment: {
      type: Function,
      default: null
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    let note;
    let steps;

    if (this.appointment.id) {
      if (!this.appointment.currentParticipantIsSuggesting()) {
        this.appointment.currentParticipant().availability = {};
      }

      note = this.appointment.currentParticipant().note;
    }
    else {
      note = '';
    }

    if ((this.appointment.currentParticipant() || {}).id) {
      steps = ['times'];
    }
    else if (this.possibleParticipants.length === 1) {
      steps = ['type', 'times', 'location'];
    }
    else {
      steps = ['participants', 'type', 'times', 'location'];
    }

    const availability = Availability.load((this.appointment.currentParticipant() || {}).availability, {
      note: note,
      userSchedule: new UserSchedule(this.appointment.conflictingTimes)
    });

    return {
      steps: steps,
      currentStep: steps[0],
      scheduler: new AvailabilityScheduler([...availability.timeslots]),
      availability: availability
    };
  },

  computed: {
    currentStepIndex() {
      return this.steps.indexOf(this.currentStep);
    },

    isFirstStep() {
      return this.currentStep === this.steps[0];
    },

    isLastStep() {
      return this.isCurrentStep('location') ||
        (this.isCurrentStep('times') && !this.appointment.isInPersonMeeting()) ||
        (this.isCurrentStep('times') && (this.appointment.currentParticipant() || {}).id);
    },

    isMoveNextDisabled() {
      return (this.isCurrentStep('participants') && !this.selectedParticipants.length) ||
        (this.isCurrentStep('type') && !this.appointment.type) ||
        (this.isCurrentStep('times') && this.selectedTimesCount === 0);
    },

    selectedTimesCount() {
      return this.availability.timeslots.length;
    },

    isSubmitDisabled() {
      return this.isMoveNextDisabled || (this.selectedTimesCount < this.selectedParticipants.length);
    },

    showCancelButton() {
      return this.isFirstStep && this.isCurrentStep('times') && this.onCancelAppointment !== null;
    }
  },

  methods: {
    reset(full) {
      if (full) { this.availability.timeslots = []; }
      this.availability.note = '';

      this.$refs.stepAppointmentType.reset();
      this.$refs.stepAvailability.reset();
      this.$refs.stepLocation.reset();

      this.currentStep = this.steps[0];
    },

    isCurrentStep(step) {
      return this.currentStep === step;
    },

    goToStep(index) {
      this.currentStep = this.steps[index];
    },

    movePrevious() {
      if (this.isFirstStep) { return; }
      this.goToStep(this.currentStepIndex - 1);
    },

    moveNext() {
      if (this.isLastStep || this.isMoveNextDisabled) { return; }
      this.goToStep(this.currentStepIndex + 1);
    },

    selectType(type) {
      this.appointment.type = type;
    },

    submit() {
      if (this.isSubmitDisabled) { return; }

      LoadingService.around('submitAvailability', () => {
        return this.onSubmit(this.availability, this.selectedParticipants);
      });
    }
  }
};
</script>

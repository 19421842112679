<template>
  <div class="data-disclaimer">
    <div class="row display-flex">
      <div class="col-xs-12 col-md-3 col-lg-2">
        <svg-icon :name="isBetaClient ? 'scout-logo-beta' : 'scout-logo'" :class="['brand-icon', 'top-5', { 'beta': isBetaClient} ]"></svg-icon>
      </div>

      <div class="col-xs-12 col-sm-9 col-md-6 col-lg-7 top-20-xs-sm">
        <div class="tight-lines bold-weight bottom-5">
          {{ isBetaClient ? 'Scout is in Beta – ' : '' }}Some profile data may not yet be available
        </div>

        <div class="tight-lines">
          Please notify your Scout administrator if you encounter missing profile data and would like to see more information about that lawyer or law firm in Scout.
        </div>
      </div>

      <div class="col-xs-12 col-sm-3 text-right-not-xs">
        <div class="h-100 top-10-xs">
          <priori-modal ref="learnMoreModal" modal-id="learn-more-modal">
            <template #modal-trigger>
              <button type="button" class="secondary-btn-blue smaller" @click="openLearnMoreModal">Learn More</button>
            </template>

            <template #title>
              <div class="title-logo">
                <svg-icon :name="isBetaClient ? 'scout-logo-beta' : 'scout-logo'" :class="['brand-icon', { 'beta': isBetaClient} ]"></svg-icon>
              </div>
            </template>

            <template #default>
              <div class="bold-weight bottom-20">
                {{ isBetaClient ? 'Scout is in Beta – ' : '' }}Some profile data may not yet be available
              </div>

              <p>
                Some lawyer and law firm profiles are not yet populated or are missing certain data elements. Profile data may not be available because the law firm wasn’t invited to join Scout, hasn’t yet onboarded to Scout, or hasn’t yet provided  data for a particular lawyer.
              </p>

              <div>
                Please notify your Scout administrator if you encounter missing data on a lawyer or law firm profile and would like more information about that lawyer or law firm.
              </div>
            </template>
          </priori-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'DataDisclaimer',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    isBetaClient() {
      return this.currentUser.isBeta;
    }
  },

  methods: {
    openLearnMoreModal: function () {
      this.$refs.learnMoreModal.openModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .brand-icon {
    max-width: 125px;

    &.beta {
      max-width: 180px;
    }
  }

  .title-logo {
    margin: auto;

    .brand-icon {
      max-width: 110px;

      &.beta {
        max-width: 150px;
      }
    }
  }

  .display-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .secondary-btn-blue.smaller {
    width: fit-content;
    padding: 10px 30px;
    white-space: nowrap;
  }

  .data-disclaimer {
    padding: 15px 30px;
    background: white;
    border-radius: $border-radius-large;

    > [class*='col-'] {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
</style>

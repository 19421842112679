<template>
  <reject-lawyer-modal :possible-lawyer="possibleLawyer" :on-lawyer-rejected="onLawyerRejected" v-slot="{ openModal }">
    <div :class="['svg-button', { 'disabled': shouldDisable }]" @click="tryReject(openModal)">
      <svg-icon name="reject" class="base-icon text-top"></svg-icon>
      <span class="hidden-xs left-5">Reject Option</span>
    </div>
  </reject-lawyer-modal>
</template>

<script>
import RejectLawyerModal from 'vue-app/marketplace/client/requests/reject-lawyer-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RejectLawyerButton',

  components: {
    RejectLawyerModal,
    SvgIcon
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    onLawyerRejected: {
      type: Function,
      required: true
    }
  },

  computed: {
    shouldDisable() {
      return this.possibleLawyer.selected() ||
        this.possibleLawyer.rejected() ||
        this.possibleLawyer.crCompleted();
    }
  },

  methods: {
    tryReject(openModalFn) {
      if (!this.shouldDisable) { openModalFn(); }
    }
  }
};
</script>

<template>
  <div class="lawyer-profile-panel vertical-center-not-xs col-gap-15">
    <profile-photo :user="lawyer"></profile-photo>

    <div>
      <div class="size-text-20px semibold-weight text-ellipsis">
        {{ lawyer.fullName }}
      </div>

      <div class="text-ellipsis">
        <a href="" @click.prevent="onAskQuestion">Send Message</a>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';

export default {
  name: 'LawyerProfileHeader',

  components: {
    ProfilePhoto
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    onAskQuestion: {
      type: Function,
      required: true
    }
  }
};
</script>

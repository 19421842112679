<template>
  <div class="messaging-button">
    <button type="button" @click="onToggleMessaging"><svg-icon name="list" class="base-icon"></svg-icon><span class="left-10 hidden-xs">Messages</span></button>

    <div class="unread-alert bold-weight size-text-13px" v-if="unreadCount > 0">
      {{ unreadCount }}
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'MessagingButton',

  components: {
    SvgIcon
  },

  props: {
    unreadCount: {
      type: Number,
      required: true
    },

    onToggleMessaging: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .messaging-button {
    position: fixed;
    z-index: 2;
    right: 20px;
    bottom: 40px;

    button {
      padding: 20px 30px;
      border: 0;
      border-radius: 30px;
      background-color: $k-darker-gray;
      color: $white;
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;

      .base-icon {
        fill: $white;
      }
    }

    .unread-alert {
      position: absolute;
      top: -5px;
      right: -5px;
      min-width: 30px;
      height: 30px;
      padding: 5px;
      border-radius: 30px;
      background-color: $k-red;
      color: $white;
      text-align: center;
    }
  }
</style>

<template>
  <priori-modal ref="requestDataModal" modal-id="learn-more-modal">
    <template #modal-trigger>
      <button type="button" class="secondary-btn-blue transparent smaller" @click="openrequestDataModal">Request Data</button>
    </template>

    <template #title>
      <div :class="['logo-container', 'title-logo', { 'beta': isBetaClient} ]">
        <svg-icon :name="isBetaClient ? 'scout-logo-beta' : 'scout-logo'" class="brand-icon"></svg-icon>
      </div>
    </template>

    <template #default>
      <form role="form" class="a-form" @submit.prevent="save" novalidate>
        <div class="bold-weight bottom-5">
          Tell your Scout administrator that you’d like to see this data on Scout
        </div>

        <div class="bottom-20">
          Filling out this form will let your Scout administrators know that you’d like to see this profile data.
        </div>

        <div class="bottom-20">
          <label for="firm-name">Law Firm Name</label>
          <input type="text" id="firm-name" name="firmName" class="form-control" :value="firmName" readonly>
        </div>

        <div class="bottom-20" v-if="lawyerName">
          <label for="lawyer-name">Lawyer Name</label>
          <input type="text" id="lawyer-name" name="lawyerName" class="form-control" :value="lawyerName" readonly>
        </div>

        <div class="bottom-30">
          <textarea-vertical
            label="Add a note (optional)"
            name="note"
            :autoresize="true"
            id="note"
            v-model="note"
            :maxlength="400"
            :with-limit="true">
          </textarea-vertical>

          <div class="dark-gray-text size-text-12px top-5">
            Limited to 400 characters
          </div>
        </div>

        <div class="row tight-columns">
          <div class="col-sm-6">
            <loading-button name="loadingRequest" class="primary-button-compact" @lb-click="save">Request Data</loading-button>
          </div>
        </div>
      </form>
    </template>
  </priori-modal>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import ScoutDataRequest from 'resources/scout/scout-data-request.js';

export default {
  name: 'DataDisclaimerModal',

  props: {
    firmName: {
      type: String,
      required: true
    },

    lawyerName: {
      type: String,
      required: false
    },

    isBetaClient: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      note: null
    };
  },

  methods: {
    openrequestDataModal() {
      this.$refs.requestDataModal.openModal();
    },

    save() {
      LoadingService.loading('loadingRequest');

      const dataRequest = {
        firmName: this.firmName,
        lawyerName: this.lawyerName,
        note: this.note
      };

      ScoutDataRequest.save({ dataRequest: dataRequest }).then(
        () => {
          this.note = null;
          this.$refs.requestDataModal.closeModal();
          LoadingService.done('loadingRequest');

          NotificationService.success('Request submitted successfully');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        () => {
          LoadingService.done('loadingRequest');
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
  .logo-container {
    max-width: 110px;

    &.beta {
      max-width: 150px;
    }
  }

  .title-logo {
    margin: auto;
  }
</style>

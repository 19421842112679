<template>
  <div>
    <button
      type="button"
      :disabled="isSelected"
      @click.prevent="openSelectFirmModal"
      :class="['icon-button nv-button-white flex-column', { 'selected': isSelected }]">
      <svg-icon name="checkmark" class="base-icon"></svg-icon>
      <span class="top-5">{{ isSelected ? 'Selected' : 'Select' }}</span>
    </button>

    <modal-wrapper name="select-firm">
      <template #default="{ closeModal: closeModal }">
        <div class="notice-modal">
          <div class="notice-header text-uppercase">
            Confirm Selecting Firm
          </div>

          <div class="notice-regular-text">
            <div class="semibold-weight size-text-14px bottom-20">
              By confirming your selection, you will notify the Firm of their selection for the project.
            </div>

            <div class="semibold-weight size-text-14px bottom-30">
              Are you sure you&rsquo;d like to proceed?
            </div>

            <div class="row tight-columns">
              <div class="col-sm-5">
                <button type="button" class="primary-btn-night-blue" @click="selectFirm">
                  Yes, Select Firm
                </button>
              </div>

              <div class="col-sm-4 top-20-xs">
                <button type="button" class="secondary-btn-blue" @click="closeModal">No, Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal-wrapper>
  </div>
</template>

<script>
import ModalManager from 'vue-app/shared/services/modal-manager';
import ModalWrapper from 'vue-app/shared/components/modals/wrapper.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'SelectFirmButton',

  components: {
    ModalWrapper,
    SvgIcon
  },

  props: {
    selectedQuote: {
      type: Object,
      required: true
    },

    isSelected: {
      type: Boolean,
      default: false
    },

    rfq: {
      type: Object,
      required: true
    }
  },

  methods: {
    openSelectFirmModal() {
      ModalManager.open('select-firm');
    },

    selectFirm() {
      this.$emit('client-selected-firm', this.rfq, this.selectedQuote, 'select_firm');

      ModalManager.close('select-firm');
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 105px;
    height: 100%;

    @media (min-width: $screen-sm-min) {
      width: 90px;
    }

    @media (min-width: 1055px) {
      width: 100px;
    }

    @media (min-width: $screen-lg-min) {
      width: 110px;
    }

    &:disabled, &:disabled .base-icon {
      opacity: 1;
    }
  }

  .selected {
    color: $scout-green;
    fill: $scout-green;
    background-color: $green-10;
    border-color: $scout-green;

    svg {
      fill: $scout-green;
    }
  }
</style>

<template>
  <div>
    <div class="titlebar">
      <div class="row vertical-center-not-xs">
        <div class="col-sm-6 medium-page-heading">
          Dashboard
        </div>

        <div class="col-sm-6 size-text-16px text-right-not-xs">
          Welcome, {{ lawyer.firstName }}
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <!-- TODO: Put this back once we have updated designs and copy. -->
          <!-- <welcome-modal
            :update-viewed-scout-onboarding-modal="updateViewedScoutOnboardingModal"
            v-if="!lawyer.viewedScoutOnboardingModal">
          </welcome-modal> -->

          <div class="row">
            <div class="col-sm-8">
              <rfq-notifications
                v-if="hasRfqEnabled"
                class="bottom-40"
                :notifications="rfqNotifications"
                :is-connecting="false">
              </rfq-notifications>

              <get-started :lawyer="lawyer" :firm="firm"></get-started>
            </div>

            <div class="col-sm-4 top-30-xs">
              <quick-actions :lawyer="lawyer"></quick-actions>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal-wrapper name="suggest-client">
      <suggest-a-client></suggest-a-client>
    </modal-wrapper>

    <modal-wrapper name="create-list" size="md-sm">
      <template #default="{ modalData: modalData }">
        <create-list :workspaces="modalData.workspaces"></create-list>
      </template>
    </modal-wrapper>
  </div>
</template>

<script>
// import WelcomeModal from 'vue-app/scout/firm/dashboard/welcome-modal.vue';
import RfqNotifications from 'vue-app/scout/firm/dashboard/rfq-notifications.vue';
import GetStarted from 'vue-app/scout/firm/dashboard/get-started.vue';
import QuickActions from 'vue-app/scout/firm/dashboard/quick-actions.vue';

import CreateList from 'vue-app/scout/firm/dashboard/create-list.vue';
import ModalWrapper from 'vue-app/shared/components/modals/wrapper.vue';
import SuggestAClient from 'vue-app/scout/firm/dashboard/suggest-a-client.vue';

import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'DashboardConnected',

  components: {
    // WelcomeModal,
    RfqNotifications,
    GetStarted,
    QuickActions,

    CreateList,
    ModalWrapper,
    SuggestAClient
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    rfqNotifications: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { lawyer: 'currentUser' }),

    hasRfqEnabled() {
      return this.lawyer.hasRfqEnabled;
    }
  },

  methods: {
    updateViewedScoutOnboardingModal() {
      this.$emit('update-viewed-scout-onboarding-modal');
    }
  }
};
</script>

<template>
  <div class="shadowed-box" id="lawyer-applications">
    <div class="box-header text-uppercase">
      Your Applications
    </div>

    <div class="box-content">
      <loading-section name="opportunityApplications">
        <div v-for="application in applications">
          <opportunity-application
            :application="application">
          </opportunity-application>

          <hr class="kenny-hr">
        </div>

        <div v-if="!hasApplications">
          <div class="top-30 bottom-50 gray-text tight-lines text-center">
            You have not applied to any opportunities yet.
          </div>

          <hr class="kenny-hr">
        </div>
      </loading-section>

      <div class="view-all-container">
        <a :href="opportunitiesUrl" class="purple-text" target="_blank">View Opportunities</a>
      </div>
    </div>
  </div>
</template>

<script>
import OpportunityApplication from 'vue-app/marketplace/lawyer/dashboard/opportunity-application.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';

export default {
  name: 'OpportunityApplications',

  components: {
    OpportunityApplication,
    LoadingSection
  },

  props: {
    applications: {
      type: Array,
      default: () => []
    },

    cmsUrl: {
      type: String,
      required: true
    }
  },

  computed: {
    hasApplications() {
      return this.applications.length > 0;
    },

    opportunitiesUrl() {
      return `${this.cmsUrl}/lawyer-opportunities`;
    }
  }
};
</script>

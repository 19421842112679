import BaseResource from 'resources/base-resource';

class DocumentReceipt extends BaseResource {
  static baseUrl = '/document_receipts/:id.json';

  readOnly() {
    return this.role === 'reader';
  }

  permissionDisplay() {
    return this.readOnly() ? 'Read Only' : 'Read/Write';
  }

  onGoogleDrive() {
    return this.document.documentSource === 'Google';
  }

  hasNote() {
    return this.document.note && this.document.note.length;
  }
}

export default DocumentReceipt;

<template>
  <div :class="{ 'disabled': !canAdd, 'clickable': canAdd }" @click="openProjectTeamAddModal">
    <div>
      <svg-icon name="invite" class="base-icon right-15"></svg-icon>Add Team Member
    </div>

    <project-team-add-modal
      ref="projectTeamAddModal"
      :project-resource="matter"
      :workspace-users="selectableWorkspaceUsers"
      :allow-adding-workspace-users="allowAddingWorkspaceUsers"
      :on-add-workspace-user="startAddWorkspaceUser"
      :on-add-client="onAddClient">
    </project-team-add-modal>

    <workspace-invite-modal
      ref="workspaceInviteModal"
      :current-user="currentUser"
      :workspace="workspace"
      :on-invite-sent="onInviteSent">
    </workspace-invite-modal>
  </div>
</template>

<script>
import ProjectTeamAddModal from 'vue-app/marketplace/client/workspaces/project-team-add-modal.vue';
import WorkspaceInviteModal from 'vue-app/shared/components/workspaces/workspace-invite-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import Client from 'resources/client';
import LoadingService from 'vue-app/shared/services/loading-service';
import { reject, some } from 'lodash';

export default {
  name: 'AddTeamMember',

  components: {
    ProjectTeamAddModal,
    WorkspaceInviteModal,
    SvgIcon
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    workspace: {
      type: Object,
      required: true
    },

    currentUser: {
      type: Object,
      required: true
    },

    onAddClient: {
      type: Function,
      required: true
    },

    canAdd: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      workspaceUsers: [],
      workspaceUsersLoaded: false
    };
  },

  computed: {
    projectOwner() {
      return this.matter.client || this.matter.requester;
    },

    projectClients() {
      return this.matter.matterClients || this.matter.requestClients || [];
    },

    allowAddingWorkspaceUsers() {
      return !this.currentUser.workspace.isScout;
    },

    selectableWorkspaceUsers() {
      const clientId = this.projectOwner.id;
      const clients = this.projectClients;

      return reject(this.workspaceUsers, (user) => {
        return !!user.deactivatedAt || user.id === clientId || some(clients, { clientId: user.id });
      });
    }
  },

  methods: {
    openProjectTeamAddModal() {
      if (!this.canAdd) { return; }

      this.$refs.projectTeamAddModal.openModal();
      this.loadWorkspaceUsers();
    },

    startAddWorkspaceUser() {
      if (!this.canAdd) { return; }

      this.$refs.projectTeamAddModal.dismissModal();
      this.$refs.workspaceInviteModal.openModal();
    },

    loadWorkspaceUsers() {
      if (this.workspaceUsersLoaded) { return; }

      const vueInstance = this;
      LoadingService.loading('addClientForm');

      Client.query().then((clients) => {
        vueInstance.workspaceUsers = clients;
        LoadingService.done('addClientForm');
        this.workspaceUsersLoaded = true;
      });
    },

    onInviteSent(client) {
      this.onAddClient({ client: client, successFn: () => {}, failFn: () => {} });
    }
  }
};
</script>

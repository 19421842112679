<template>
  <tr>
    <td>
      <span class="semibold-weight">{{ renderedService.displayType() }}</span>
    </td>

    <td>
      {{ activityDate }}
    </td>

    <td>
      {{ lawyerName }}
    </td>

    <td class="inspectlet-sensitive">
      {{ description }}
    </td>

    <td>
      {{ rate }}
    </td>

    <td>
      {{ renderedService.roundedHours() || '&mdash;' }}
    </td>

    <td class="number">
      {{ totalDollars }}
    </td>
  </tr>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';
import { find, startCase } from 'lodash';

export default {
  name: 'InvoiceRow',

  props: {
    lineItem: {
      type: Object,
      required: true
    },

    matterLawyers: {
      type: Array,
      required: true
    },

    invoice: {
      type: Object,
      required: true
    }
  },

  computed: {
    renderedService() {
      return this.lineItem.renderedService;
    },

    activityDate() {
      return DateFilter.filterDate(this.renderedService.dateOfActivity);
    },

    lawyer() {
      return find(this.matterLawyers, { id: this.renderedService.lawyerId });
    },

    lawyerName() {
      if (this.lawyer) {
        return startCase(this.lawyer.firstName[0]) + '. ' + startCase(this.lawyer.lastName);
      }
      else if (this.renderedService.otherLawyerFirstName && this.renderedService.otherLawyerLastName) {
        return startCase(this.renderedService.otherLawyerFirstName[0]) + '. ' + startCase(this.renderedService.otherLawyerLastName);
      }
      else {
        return '—';
      }
    },

    abaDescription() {
      return this.renderedService.aba() ? `(${this.renderedService.aba()}) ` : '';
    },

    description() {
      return `${this.abaDescription}${this.renderedService.description || '—'}`;
    },

    rate() {
      return CurrencyFilter.filter(this.invoice.lineItemRate(this.lineItem)) || '—';
    },

    totalDollars() {
      return CurrencyFilter.filter(this.lineItem.dollars);
    }
  }
};
</script>

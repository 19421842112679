<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form role="form" name="form" class="a-form" novalidate>
      <div class="notice-regular-text">
        <div class="bottom-30">
          <validation-provider rules="required|email" :mode="passiveAggressive" v-slot="{ errors }">
            <label for="client-email">
              Email Address <span class="inline-help required">*</span>
              <error-message :errors="errors"></error-message>
            </label>

            <input type="email" name="email" id="client-email" :class="['form-control', { 'has-error': errors.length }]" :placeholder="'Ex: jane.doe@' + emailDomain" v-model="client.email">
          </validation-provider>
        </div>

        <div class="row">
          <div class="col-sm-6 bottom-30">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <label for="first-name">
                First Name <span class="inline-help required">*</span>
                <error-message :errors="errors"></error-message>
              </label>

              <input type="text" name="firstName" id="first-name" :class="['form-control', { 'has-error': errors.length }]" placeholder="Ex: Jane" v-model="client.firstName">
            </validation-provider>
          </div>

          <div class="col-sm-6 bottom-30">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <label for="last-name">
                Last Name <span class="inline-help required">*</span>
                <error-message :errors="errors"></error-message>
              </label>

              <input type="text" name="lastName" id="last-name" :class="['form-control', { 'has-error': errors.length }]" placeholder="Ex: Doe" v-model="client.lastName">
            </validation-provider>
          </div>
        </div>

        <div>
          <label for="client-role">Job Title</label>
          <input type="text" name="clientRole" id="client-role" class="form-control" placeholder="Ex: Associate GC, Director of Legal Operations, etc." v-model="client.role">
        </div>

        <div class="row top-30">
          <div class="col-sm-6 top-10">
            <loading-button name="sendInvite" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(submit)">
              Add {{ isProposal ? 'Recipient' : 'User' }}
            </loading-button>
          </div>

          <div class="col-sm-6 top-10">
            <button type="button" class="nv-button-white a-button-size" @click="cancel">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';

import Client from 'resources/client';
import LoadingService from 'vue-app/shared/services/loading-service';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  name: 'WorkspaceInviteForm',

  components: {
    ErrorMessage,
    LoadingButton,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    currentUser: {
      type: Object,
      default: () => ({})
    },

    workspace: {
      type: Object,
      required: true
    },

    isProposal: {
      type: Boolean,
      default: false
    },

    onCancel: {
      type: Function
    },

    onSubmit: {
      type: Function
    }
  },

  data() {
    return {
      client: this.buildClient()
    };
  },

  computed: {
    emailDomain() {
      return (this.currentUser?.email || '').split('@')[1];
    }
  },

  methods: {
    buildClient() {
      return new Client({
        workspaceUid: this.workspace.uid,
        invitedByType: this.currentUser.klass,
        invitedById: this.currentUser.id
      });
    },

    cancel() {
      this.client = this.buildClient();
      this.onCancel();
    },

    submit() {
      const vueInstance = this;
      LoadingService.loading('sendInvite');

      this.onSubmit({
        client: this.client,
        afterFn: () => {
          vueInstance.client = vueInstance.buildClient();
          LoadingService.done('sendInvite');
        }
      });
    }
  }
};
</script>

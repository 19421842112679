<template>
  <div class="row project-header">
    <div class="col-md-8 col-lg-9">
      <div class="medium-page-heading bottom-10">
        {{ matter.nameForClientsReference }}
      </div>

      <div class="normal-weight size-text-13px">
        Priori ID: <span class="semibold-weight">{{ matter.referenceNumber }}</span>

        <span v-if="matter.clientMatterIdent">
          <span class="right-10 left-10 hidden-xs">&#8231;</span>
          <div class="visible-xs-block bottom-5"></div>
          Your Matter ID: <span class="semibold-weight">{{ matter.clientMatterIdent }}</span>
        </span>

        <span class="right-10 left-10 hidden-xs">&#8231;</span>
        <div class="visible-xs-block bottom-5"></div>

        <span class="right-10" v-if="matter.notYetOpened()">
          <span class="pending-status size-text-13px">Pending</span> (Requested: {{ createdDate }})
        </span>

        <span class="right-10" v-if="matter.isOpenOrClosed()">
          <span :class="[statusClass, 'size-text-13px']">{{ matter.displayStatus() }}</span>
          <span v-if="matter.isOpen()">(Opened: {{ startedDate }})</span>
          <span v-if="matter.isClosed()">(Closed: {{ endedDate }})</span>
        </span>

        <span v-if="matter.wework">
          <span class="left-10 right-10 hidden-xs">&#8231;</span>
          <div class="visible-xs-block bottom-5"></div>
          <span class="semibold-weight">via WeWork</span>
        </span>
      </div>
    </div>

    <div class="col-md-4 col-lg-3">
      <project-team
        :project-resource="matter"
        :workspace="workspace"
        :can-add="matter.isOpen()">
      </project-team>
    </div>
  </div>
</template>

<script>
import ProjectTeam from 'vue-app/marketplace/client/shared/project-team.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'ProjectHeader',

  components: {
    ProjectTeam
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    workspace: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      statusClasses: {
        open: 'open-status',
        closed: 'closed-status'
      }
    };
  },

  computed: {
    statusClass() {
      return this.statusClasses[this.matter.status];
    },

    createdDate() {
      return DateFilter.filter(this.matter.createdAt);
    },

    startedDate() {
      return DateFilter.filter(this.matter.startedDate);
    },

    endedDate() {
      return DateFilter.filter(this.matter.endedDate);
    }
  }
};
</script>

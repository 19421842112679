<template>
  <div class="d-flex wrap row-gap-8 col-gap-8">
    <scout-tag
      v-for="scoutTag in orderedTags"
      :scout-tag="scoutTag"
      :key="scoutTag.id">
    </scout-tag>
  </div>
</template>

<script>
import ScoutTag from './scout-tag.vue';
import { orderBy } from 'lodash';

export default {
  name: 'ScoutTagsList',

  components: {
    ScoutTag
  },

  props: {
    scoutTags: {
      type: Array,
      required: true
    }
  },

  computed: {
    orderedTags() {
      return orderBy(this.scoutTags, 'position');
    }
  }
};
</script>

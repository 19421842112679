<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-md-6 text-uppercase">
          Project Documents
        </div>

        <div class="col-md-6">
          <div class="dash-btn-container">
            <a :href="'/client-app/documents/' + matter.id + '/share'" class="back-link" v-if="!matter.isClosed()"><svg-icon name="plus-circle" class="base-icon right-10"></svg-icon>Share Document</a>
          </div>
        </div>
      </div>
    </div>

    <div class="box-content compressed normal-weight">
      <loading-section name="documents">
        <div class="size-text-13px" v-for="(document, idx) in firstToTenth" :key="document.id">
          <project-document
            :document="document"
            :connected-to-google-drive="connectedToGoogleDrive"
            :on-remove="onRemoveDocument">
          </project-document>

          <hr class="kenny-hr" v-if="idx !== firstToTenth.length - 1 || documents.length > 10">
        </div>

        <div v-if="expanded">
          <div class="size-text-13px" v-for="(document, idx) in tenthToLast" :key="document.id">
            <project-document
              :document="document"
              :connected-to-google-drive="connectedToGoogleDrive"
              :on-remove="onRemoveDocument">
            </project-document>

            <hr class="kenny-hr" v-if="idx !== tenthToLast.length - 1">
          </div>
        </div>

        <div class="text-center size-text-13px top-30 bottom-20" v-if="documents.length > 10 && !expanded">
          <a href="" class="semibold-weight darker-gray-text" @click.prevent="showAllDocuments">Show All Project Documents</a>
        </div>

        <div v-if="!documents.length">
          <div class="top-30 bottom-30 gray-text tight-lines text-center">
            You have no documents shared on this project.
          </div>
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import ProjectDocument from 'vue-app/marketplace/client/matters/project-document.vue';

export default {
  name: 'ProjectDocuments',

  components: {
    ProjectDocument
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    documents: {
      type: Array,
      default: () => []
    },

    connectedToGoogleDrive: {
      type: Boolean,
      required: true
    },

    onRemoveDocument: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      expanded: false
    };
  },

  computed: {
    firstToTenth() {
      return this.documents.slice(0, 10);
    },

    tenthToLast() {
      return this.documents.slice(10);
    }
  },

  methods: {
    showAllDocuments() {
      this.expanded = true;
    }
  }
};
</script>

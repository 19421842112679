<template>
  <div>
    <div class="dash-btn-container overlap-photos">
      <popover-tooltip placement="bottom">
        <div class="missing-photo">
          {{ initialsFor(projectOwner) }}
        </div>

        <template #tooltip>
          <div class="semibold-weight">
            {{ projectOwner.fullName }}
          </div>
        </template>
      </popover-tooltip>

      <popover-tooltip v-if="projectClients.length" placement="bottom">
        <div :id="'client-' + projectResource.id" class="missing-photo">
          {{ additionalTeamMembers }}
        </div>

        <template #tooltip>
          <div class="semibold-weight" v-for="projectClient in projectClients">
            {{ projectClient.client.fullName }}
          </div>
        </template>
      </popover-tooltip>

      <popover-tooltip v-if="canAdd" placement="bottom">
        <div class="add-invitee-icon" @click="openProjectTeamAddModal">
          <svg-icon name="add-invitee" class="base-icon"></svg-icon>
        </div>

        <template #tooltip>
          <div class="semibold-weight">
            Add Team Members
          </div>
        </template>
      </popover-tooltip>
    </div>

    <div class="top-10" v-if="canAdd">
      <div class="text-right-not-xs">
        <a href="" class="semibold-weight size-text-13px" @click.prevent="openProjectTeamAddModal">Add Team Members</a>
      </div>
    </div>

    <project-team-add-modal
      ref="projectTeamAddModal"
      :project-resource="projectResource"
      :workspace-users="selectableWorkspaceUsers"
      :allow-adding-workspace-users="allowAddingWorkspaceUsers"
      :on-add-workspace-user="startAddWorkspaceUser"
      :on-add-client="addClientFn">
    </project-team-add-modal>

    <workspace-invite-modal
      ref="workspaceInviteModal"
      :current-user="currentUser"
      :workspace="workspace"
      :on-invite-sent="onInviteSent">
    </workspace-invite-modal>
  </div>
</template>

<script>
import ProjectTeamAddModal from 'vue-app/marketplace/client/workspaces/project-team-add-modal.vue';
import WorkspaceInviteModal from 'vue-app/shared/components/workspaces/workspace-invite-modal.vue';
import PopoverTooltip from 'vue-app/shared/components/popover-tooltip.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import Client from 'resources/client.js';
import RequestClient from 'resources/marketplace/request-client.js';
import MatterClient from 'resources/marketplace/matter-client.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { reject, some } from 'lodash';
import { mapState } from 'pinia';

export default {
  name: 'ProjectTeam',

  components: {
    ProjectTeamAddModal,
    WorkspaceInviteModal,
    PopoverTooltip,
    SvgIcon
  },

  props: {
    projectResource: {
      type: Object,
      default: () => ({})
    },

    workspace: {
      type: Object,
      default: () => ({})
    },

    canAdd: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      workspaceUsers: [],
      workspaceUsersLoaded: false
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentUser']),

    projectClientFactory() {
      return this.isMatter ? MatterClient : RequestClient;
    },

    projectOwner() {
      return this.projectResource.client || this.projectResource.requester || {};
    },

    projectClients() {
      return this.projectResource.matterClients || this.projectResource.requestClients || [];
    },

    selectableWorkspaceUsers() {
      const clientId = this.projectOwner.id;
      const clients = this.projectClients;

      return reject(this.workspaceUsers, (user) => {
        return !!user.deactivatedAt || user.id === clientId || some(clients, { clientId: user.id });
      });
    },

    tooltipContent() {
      return this.projectClients.map(c => c.client.fullName).join('<br>');
    },

    additionalTeamMembers() {
      if (this.projectClients.length > 1) {
        return '+' + this.projectClients.length;
      }
      else {
        return this.initialsFor(this.projectClients[0].client);
      }
    },

    isMatter() {
      return this.projectResource.counselRequestId ||
        Object.keys(this.projectResource).includes('matterClients');
    },

    allowAddingWorkspaceUsers() {
      return !this.currentUser.workspace?.isScout;
    },

    addClientFn() {
      if (this.isMatter && this.projectResource.isOpen()) {
        return this.addMatterClient;
      }
      else if (!this.isMatter) {
        return this.addRequestClient;
      }
      else {
        return () => {};
      }
    }
  },

  methods: {
    initialsFor(client) {
      if (client.firstName && client.lastName) {
        return (client.firstName[0] + client.lastName[0]).toUpperCase();
      }
      else {
        return '@';
      }
    },

    openProjectTeamAddModal() {
      if (!this.canAdd) { return; }

      this.$refs.projectTeamAddModal.openModal();
      this.loadWorkspaceUsers();
    },

    loadWorkspaceUsers() {
      if (this.workspaceUsersLoaded) { return; }

      const vueInstance = this;
      LoadingService.loading('addClientForm');

      Client.query().then((clients) => {
        vueInstance.workspaceUsers = clients;
        LoadingService.done('addClientForm');
        this.workspaceUsersLoaded = true;
      });
    },

    startAddWorkspaceUser() {
      if (!this.canAdd) { return; }

      this.$refs.projectTeamAddModal.dismissModal();
      this.$refs.workspaceInviteModal.openModal();
    },

    onInviteSent(client) {
      this.addClientFn({ client: client, successFn: () => {}, failFn: () => {} });
    },

    addMatterClient({ client, successFn, failFn }) {
      const matterClient = new this.projectClientFactory({ clientId: client.id });
      const params = { matterId: this.projectResource.id, matterClient: matterClient };

      this.saveResourceClient(params, this.projectResource.matterClients, successFn, failFn);
    },

    addRequestClient({ client, successFn, failFn }) {
      const requestClient = new this.projectClientFactory({ clientId: client.id, sendConfirmationMailer: true });
      const params = { counselRequestId: this.projectResource.id, requestClient: requestClient };

      this.saveResourceClient(params, this.projectResource.requestClients, successFn, failFn);
    },

    saveResourceClient(clientParams, clientCollection, successFn, failFn) {
      this.projectClientFactory.save(clientParams)
        .then((rc) => {
          clientCollection.push(rc);
          NotificationService.success('You have successfully granted a user access to this project. The user will be notified by email and will have access to the project page and project information.');
          if (successFn) { successFn(); }
        })
        .catch(() => {
          NotificationService.error('There was a problem granting the user access to the project.');
          if (failFn) { failFn(); }
        });
    }
  }
};
</script>


<template>
  <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" append-to-body>
    <div class="notice-modal">
      <div class="close-modal" @click="dismissModal">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <div class="notice-header">
        Add User to Project:
        <span class="purple-text">{{ projectResource.nameForClientsReference }}</span>
      </div>

      <project-team-add-form
        :workspace-users="workspaceUsers"
        :allow-adding-workspace-users="allowAddingWorkspaceUsers"
        :on-add-workspace-user="addWorkspaceUser"
        :on-add-client="addClient">
      </project-team-add-form>
    </div>
  </modal>
</template>

<script>
import ProjectTeamAddForm from 'vue-app/marketplace/client/workspaces/project-team-add-form.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';

export default {
  name: 'ProjectTeamAddModal',

  components: {
    ProjectTeamAddForm,
    SvgIcon
  },

  mixins: [
    modalToggle
  ],

  props: {
    projectResource: {
      type: Object,
      default: () => ({})
    },

    workspaceUsers: {
      type: Array,
      default: () => []
    },

    allowAddingWorkspaceUsers: {
      type: Boolean,
      default: false
    },

    onAddWorkspaceUser: {
      type: Function
    },

    onAddClient: {
      type: Function
    }
  },

  methods: {
    addClient({ client, successFn, failFn }) {
      this.onAddClient({
        client: client,
        successFn: () => {
          this.dismissModal();
          if (successFn) { successFn(); }
        },
        failFn: failFn
      });
    },

    addWorkspaceUser() {
      if (!this.allowAddingWorkspaceUsers) { return; }
      this.onAddWorkspaceUser();
    }
  }
};
</script>

<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-5">
            <a href="/lawyer-app" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Dashboard</a>
          </div>

          <div class="col-sm-7 text-right-not-xs">
            <firm-invite-modal v-if="isLawyer" :law-firm="lawFirm" :on-send-invite="sendInvite" class="inline-block">
              <template #modalTrigger="{ openModal }">
                <a href="" class="back-link right-30" @click.prevent="openModal"><svg-icon name="invite" class="base-icon right-10"></svg-icon>Add New {{ lawFirm.alspAccount ? 'Admin' : 'Firm User' }}</a>
              </template>
            </firm-invite-modal>

            <div class="clearfix visible-xs top-10"></div>

            <a href="" class="back-link" @click.prevent="toggleClientView"><svg-icon name="settings" class="base-icon right-10"></svg-icon>{{ viewAsButtonText }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container size-text-13px">
          <div class="top-10 bottom-40">
            <firm-header
              :law-firm="lawFirm"
              :is-lawyer="isLawyer"
              :selected-tab="selectedTab"
              :on-select-tab="selectTab"
              :on-save="save">
            </firm-header>
          </div>

          <div class="row" v-if="selectedTab === 'details'">
            <div class="col-sm-8">
              <div class="bottom-40">
                <firm-about
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer"
                  :on-save="save">
                </firm-about>
              </div>

              <div class="bottom-40">
                <firm-practice-areas
                  v-if="!lawFirm.alspAccount"
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer"
                  :on-save="save">
                </firm-practice-areas>

                <alsp-profile-practice-areas
                  v-if="lawFirm.alspAccount"
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer"
                  :on-save="save">
                </alsp-profile-practice-areas>
              </div>

              <div>
                <firm-profile-representative-matters
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer"
                  :on-save="save">
                </firm-profile-representative-matters>
              </div>
            </div>

            <div class="col-sm-4 top-40-xs">
              <div class="bottom-40" v-if="!lawFirm.alspAccount">
                <firm-highlights
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer"
                  :on-save="save">
                </firm-highlights>
              </div>

              <div>
                <firm-locations
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer"
                  :on-save="save">
                </firm-locations>
              </div>

              <div class="top-40" v-if="lawFirm.alspAccount">
                <alsp-technology
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer"
                  :on-save="save">
                </alsp-technology>
              </div>
            </div>
          </div>

          <div v-if="selectedTab === 'lawyers'">
            <firm-users
              :firm-users="firmUserData.lawyers"
              :firm-user-count="firmUserData.totalCount"
              :show-invite-link="isLawyer"
              :show-options-column="showOptionsColumn"
              :page="page"
              :on-change-page="changePage"
              :law-firm="lawFirm"
              :on-send-invite="sendInvite">
            </firm-users>

            <div class="top-40" v-if="isLawyer">
              <firm-admin-users
                :admin-users="adminUsers"
                :is-alsp="lawFirm.alspAccount"
                :law-firm="lawFirm"
                :on-send-invite="sendInvite">
              </firm-admin-users>
            </div>
          </div>

          <div v-if="selectedTab === 'people'">
            <alsp-members
              :law-firm="lawFirm"
              :is-lawyer="isLawyer"
              :on-save="save">
            </alsp-members>

            <div class="top-40" v-if="isLawyer">
              <firm-admin-users
                :admin-users="adminUsers"
                :is-alsp="lawFirm.alspAccount"
                :law-firm="lawFirm"
                :on-send-invite="sendInvite">
              </firm-admin-users>
            </div>
          </div>

          <div v-if="selectedTab === 'testimonials'">
            <loading-section name="reviews">
              <div v-if="!lawFirm.alspAccount">
                <firm-testimonials
                  :reviews-with-quotes="reviewsWithQuotes"
                  :is-lawyer="isLawyer">
                </firm-testimonials>
              </div>

              <div v-if="lawFirm.alspAccount">
                <alsp-testimonials
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer"
                  :on-save="save">
                </alsp-testimonials>
              </div>
            </loading-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlspMembers                      from 'vue-app/marketplace/shared/law-firm-profile/alsp-members.vue';
import AlspProfilePracticeAreas         from 'vue-app/marketplace/shared/law-firm-profile/alsp-practice-areas.vue';
import AlspTechnology                   from 'vue-app/marketplace/shared/law-firm-profile/alsp-technology.vue';
import AlspTestimonials                 from 'vue-app/marketplace/shared/law-firm-profile/alsp-testimonials.vue';
import FirmAbout                        from 'vue-app/marketplace/shared/law-firms/firm-about.vue';
import FirmAdminUsers                   from 'vue-app/marketplace/shared/law-firm-profile/admin-users.vue';
import FirmHeader                       from 'vue-app/marketplace/shared/law-firms/firm-header.vue';
import FirmHighlights                   from 'vue-app/marketplace/shared/law-firms/firm-highlights.vue';
import FirmLocations                    from 'vue-app/marketplace/shared/law-firms/firm-locations.vue';
import FirmPracticeAreas                from 'vue-app/marketplace/shared/law-firms/firm-practice-areas.vue';
import FirmProfileRepresentativeMatters from 'vue-app/marketplace/shared/law-firm-profile/representative-matters.vue';
import FirmTestimonials                 from 'vue-app/marketplace/shared/law-firms/firm-testimonials.vue';
import FirmUsers                        from 'vue-app/marketplace/shared/law-firms/firm-users.vue';
import LoadingSection                   from 'vue-app/shared/components/loading-section.vue';
import LoadingService                   from 'vue-app/shared/services/loading-service';
import LawFirm                          from 'resources/marketplace/law-firm.js';
import Lawyer                           from 'resources/lawyer.js';
import Review                           from 'resources/marketplace/review.js';
import useCurrentUserStore              from 'vue-app/stores/current-user.js';
import NotificationService              from 'vue-app/shared/services/notification-service';
import SvgIcon                          from 'vue-app/shared/components/svg-icon.vue';
import FirmInviteModal                  from 'vue-app/marketplace/shared/law-firms/firm-invite-modal.vue';

import { mapState } from 'pinia';

export default {
  name: 'Firm',

  components: {
    FirmHeader,
    FirmAbout,
    FirmPracticeAreas,
    AlspProfilePracticeAreas,
    FirmProfileRepresentativeMatters,
    FirmHighlights,
    FirmLocations,
    AlspTechnology,
    FirmUsers,
    FirmAdminUsers,
    AlspMembers,
    FirmTestimonials,
    AlspTestimonials,
    LoadingSection,
    SvgIcon,
    FirmInviteModal
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      page: 1,
      adminUsers: [],
      adminUsersLoaded: false,
      isLawyer: true,
      firmUserData: {},
      firmUserDataLoaded: false,
      reviewsWithQuotes: [],
      reviewsWithQuotesLoaded: false,
      selectedTab: 'details',
      lawFirm: this.initData.lawFirm
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    showOptionsColumn() {
      return (this.currentUser.accountType === 'admin') && this.isLawyer;
    },

    viewAsButtonText() {
      const organizationType = this.lawFirm.alspAccount ? 'Company' : 'Firm';

      return `View as ${this.isLawyer ? 'Client' : organizationType}`;
    },

    shouldLoadAdminUsers() {
      return ['lawyers', 'people'].includes(this.selectedTab) && !this.adminUsersLoaded;
    },

    shouldLoadLawyers() {
      return this.selectedTab === 'lawyers' && !this.firmUserDataLoaded;
    },

    shouldLoadReviews() {
      return this.selectedTab === 'testimonials' && !this.lawFirm.alspAccount && !this.reviewsWithQuotesLoaded;
    }
  },

  methods: {
    selectTab(tab) {
      this.selectedTab = tab;

      if (this.shouldLoadAdminUsers) { this.loadAdminUsers(); }

      if (this.shouldLoadLawyers) { this.loadLawyers(); }

      if (this.shouldLoadReviews) { this.loadReviews(); }
    },

    loadLawyers() {
      LoadingService.loading('users');

      LawFirm.lawyers({
        id: this.lawFirm.id,
        page: this.page,
        view: 'firm_profile'
      })
      .then((response) => {
        this.firmUserData       = response.data;
        this.firmUserDataLoaded = true;

        LoadingService.done('users');
      });
    },

    loadAdminUsers() {
      LoadingService.loading('admin');

      LawFirm.adminUsers({
        id: this.lawFirm.id,
        view: 'admin_user'
      })
      .then((response) => {
        this.adminUsers       = response.data;
        this.adminUsersLoaded = true;

        LoadingService.done('admin');
      });
    },

    loadReviews() {
      LoadingService.loading('reviews');

      Review.query({
        law_firm_id: this.lawFirm.id
      })
      .then((reviews) => {
        this.reviewsWithQuotes = reviews.filter(review => { return review.hasQuote(); });
        this.reviewsWithQuotesLoaded = true;

        LoadingService.done('reviews');
      });
    },

    toggleClientView() {
      this.isLawyer = !this.isLawyer;
    },

    changePage(page) {
      this.page = page;
      this.loadLawyers();
    },

    sendInvite(lawyer) {
      LoadingService.loading('sendInvite');

      return Lawyer.save({ lawyer })
      .then(response => {
        if (this.firmUserData) {
          this.loadLawyers();
        }

        if (this.adminUsers) {
          this.loadAdminUsers();
        }

        return response;
      })
      .catch(error => {
        throw error;
      })
      .finally(() => {
        LoadingService.done('sendInvite');
      });
    },

    save(editedLawFirm, sectionName) {
      if (!editedLawFirm) { return; }

      NotificationService.clear();

      return LawFirm.update({ id: this.lawFirm.id, lawFirm: editedLawFirm })
        .then((lawFirm) => {
          this.lawFirm = lawFirm;

          return lawFirm;
        })
        .catch(() => {
          NotificationService.error(`There was a problem saving the ${sectionName} information.`);
        });
    }
  }
};
</script>

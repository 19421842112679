<template>
  <section class="uiv">
    <a href="" @click.prevent="openModal()">View Details</a>

    <modal v-model="modalOpen" size="md-sm" :transition="0" :header="false" :footer="false" append-to-body>
      <div class="close-modal" @click="dismissModal()">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <div class="notice-modal">
        <div class="notice-header">
          Credits
        </div>

        <div class="notice-regular-text">
          <div class="row">
            <div class="col-xs-6 semibold-weight">
              Credit Balance:
            </div>

            <div class="col-xs-6 text-right">
              {{ creditDollars }}
            </div>
          </div>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';

export default {
  name: 'CreditsModal',

  components: {
    SvgIcon
  },

  mixins: [
    modalToggle
  ],

  props: {
    credits: {
      type: Number,
      required: true
    }
  },

  computed: {
    creditDollars() {
      return CurrencyFilter.filter(this.credits / 100.0);
    }
  }
};
</script>

<template>
  <div class="dash-banner two-button-height shadowed-box primary-marketplace-gradient">
    <div class="dash-title">
      Welcome back, {{ client.firstName }}!
    </div>

    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-7 size-text-12px">
        <div class="row tight-columns bottom-5">
          <div class="col-xs-3 semitransparent">
            Title:
          </div>

          <div class="col-xs-9 text-ellipsis">
            {{ client.role || '&mdash;' }}
          </div>
        </div>

        <div class="row tight-columns bottom-5">
          <div class="col-xs-3 semitransparent">
            Company:
          </div>

          <div class="col-xs-9 text-ellipsis">
            {{ client.workspace.name || client.businessName || '&mdash;' }}
          </div>
        </div>

        <div class="row tight-columns">
          <div class="col-xs-3 semitransparent">
            Email:
          </div>

          <div class="col-xs-9 text-ellipsis">
            {{ client.email }}
          </div>
        </div>
      </div>

      <div class="col-sm-5 top-20-xs">
        <a href="/client-app/settings" class="nv-button smaller">Account Settings</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashBanner',

  props: {
    client: {
      type: Object,
      required: true
    }
  }
};
</script>

<template>
  <div class="shadowed-box client-pay-box">
    <div class="box-content">
      <div class="row tight-columns" v-if="accounts.length">
        <div class="col-sm-8">
          <form name="form" class="a-form" novalidate>
            <select id="account-select" name="account-select" class="form-control" v-model="model.id" @change="selectAccount()" :disabled="invoice.paid">
              <option v-for="account in accounts" :key="account.id" :value="account.id">
                {{ accountLabel(account) }}
              </option>
            </select>
          </form>
        </div>

        <div class="col-sm-4 top-20-xs">
          <loading-section name="pay">
            <button type="button" id="pay-button" class="nv-button-purple-red a-button-size" :disabled="!payable" @click="pay">Pay</button>
          </loading-section>
        </div>
      </div>

      <div v-if="!accounts.length">
        <div class="size-text-11px red-text">
          <div class="semibold-weight bottom-5">
            Action Required:
          </div>

          <div>
            You do not have a valid payment method on file. Please add one <a :href="managePaymentMethodsPath" class="red-text underlined" target="_blank">here</a> to pay this invoice.<span class="semibold-weight" v-if="!allowCreditCard"> Note your lawyer does not allow credit card payments.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="box-footer">
      <div class="text-center">
        <a :href="managePaymentMethodsPath" class="darker-gray-text">Manage Payment Methods</a>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import { filter, isEmpty } from 'lodash';

export default {
  name: 'InvoicePayer',

  components: {
    LoadingSection
  },

  props: {
    client: {
      type: Object,
      required: true
    },

    invoice: {
      type: Object,
      required: true
    },

    allowCreditCard: {
      type: Boolean,
      default: false
    }
  },

  emits: ['accountSelected', 'payInvoice'],

  data() {
    return {
      model: {},
      payable: false
    };
  },

  computed: {
    accounts() {
      let accounts = filter(this.client.stripeBankAccounts, { verified: true });

      if (this.allowCreditCard) {
        accounts = accounts.concat(this.client.stripeCards);
      }

      return accounts;
    },

    managePaymentMethodsPath() {
      return `/client-app/payment-methods/new?backTo=${window.location.pathname}`;
    }
  },

  beforeMount() {
    let hasDefaultPaymentMethod = this.client.defaultPaymentMethod && (
      (this.client.defaultPaymentMethod.paymentMethodType === 'card' && this.allowCreditCard) ||
      (this.client.defaultPaymentMethod.paymentMethodType !== 'card' && this.client.defaultPaymentMethod.verified)
    );

    if (!isEmpty(this.invoice.paymentMethod)) {
      this.payable = true;
      this.model.id = this.invoice.paymentMethod.id;
    }
    else if (!this.invoice.paid && hasDefaultPaymentMethod) {
      this.model.id = this.client.defaultPaymentMethod.id;
      this.selectAccount();
    }
    else if (!this.invoice.paid && this.accounts.length) {
      this.model.id = this.accounts[0].id;
      this.selectAccount();
    }
  },

  methods: {
    accountLabel(account) {
      let accountType = (account.paymentMethodType === 'card' ? 'Credit Card' : 'Bank Account');
      return `${accountType} - ${account.last4}`;
    },

    selectAccount() {
      if (this.model.id) {
        this.payable = true;
        this.$emit('account-selected', this.model.id);
      }
      else {
        this.payable = false;
      }
    },

    pay() {
      this.$emit('pay-invoice');
    }
  }
};
</script>

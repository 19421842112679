<template>
  <edit-modal title="Editing Practice Areas" :input="firm" :on-save="save" @on-modal-open="onModalOpen">
    <div class="small-bold-header">
      Law Firm Practice Areas
    </div>

    <hr class="hr">

    <div class="text-italic pill-gray-text size-text-12px bottom-30">
      Please use the dropdown menu below to select any practice areas that you want to appear on your law firm profile.
    </div>

    <div class="row bottom-40">
      <div class="col-md-10 col-md-offset-1 text-center">
        <div class="bold-weight bottom-10">
          Data Input Type
        </div>

        <loading-section name="pill-switcher-setup" :render-after-loading="true">
          <pill-switcher
            ref="pillSwitcher"
            :option-left="numericalPillSwitcherOption"
            :option-right="percentPillSwitcherOption"
            :default-option-value="initialDataType"
            :should-confirm-change="true"
            :on-select="changeDataType">
            <template #note>
              <div class="pill-gray-text size-text-12px tight-lines">
                Please note data may be reported as either a numerical value OR percentage, but must be consistent across all fields.
              </div>
            </template>

            <template #confirmation-message>
              <div>
                Are you sure you want to switch data input type?<br>All existing data of this type will be deleted.
              </div>
            </template>
          </pill-switcher>
        </loading-section>
      </div>
    </div>

    <div class="row bottom-15 vertical-center-not-xs tight-columns">
      <div class="col-sm-6 bottom-20-xs">
        <dropdown ref="paDropdown" v-model="dropdownOpen" :not-close-elements="elementsToNotCloseOn" class="k-dropdown">
          <button type="button" role="button" class="dropdown-toggle">
            <span>Select all that apply</span>
            <svg-icon name="caret-down" class="caret-down"></svg-icon>
            <svg-icon name="caret-up" class="caret-up"></svg-icon>
          </button>

          <template #dropdown>
            <div class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
              <li v-for="practiceArea in practiceAreasCopy" :key="`all-lawyers-practice-area-option-${practiceArea.id}`">
                <label class="check-option vertical medium-large">
                  {{ practiceArea.name }}
                  <input type="checkbox" v-model="practiceArea.displayOnProfile" :value="practiceArea.displayOnProfile ? true : false">
                  <span class="check c-box"></span>
                </label>
              </li>
            </div>
          </template>
        </dropdown>
      </div>

      <div class="col-sm-1"></div>

      <div class="col-sm-5">
        <div class="row tight-columns">
          <div class="col-sm-6 bold-weight text-center hidden-xs">
            Total Lawyers
          </div>

          <div class="col-sm-6 bold-weight text-center hidden-xs">
            Total Partners
          </div>
        </div>
      </div>
    </div>

    <div class="row vertical-center-not-xs bottom-10 tight-columns" v-for="(practiceArea, index) in selectedPracticeAreas" :key="`all-lawyers-selected-practice-area-${practiceArea.id}`">
      <div class="col-sm-7 bottom-10">
        <div class="row tight-columns">
          <div class="col-xs-2 col-sm-1">
            <a href="" @click.prevent="hidePracticeAreaOnProfile(practiceArea)"><svg-icon name="minus-circle" class="base-icon"></svg-icon></a>
          </div>

          <div class="col-xs-10 col-sm-11">
            {{ practiceArea.name }}
          </div>
        </div>
      </div>

      <div class="col-sm-5 top-20-xs">
        <div class="row tight-columns">
          <div class="col-sm-6 bottom-10">
            <label class="visible-xs">Total Lawyers</label>

            <input type="text" :id="`total-lawyers-${index}`" v-model="practiceArea.lawyerCount" class="form-control text-center" @keypress="isNumber($event)" v-if="selectedDataTypeIsNumerical">
            <div class="percent-input-container" v-else>
              <input type="text" :id="`total-lawyers-${index}`" v-model="practiceArea.lawyerPercent" class="form-control text-center percent-input" @keypress="isNumber($event)">
              <span class="right-5">%</span>
            </div>
          </div>

          <div class="col-sm-6 bottom-10">
            <label class="visible-xs">Total Partners</label>

            <input type="text" :id="`total-partners-${index}`" v-model="practiceArea.partnerCount" class="form-control text-center" @keypress="isNumber($event)" v-if="selectedDataTypeIsNumerical">
            <div class="percent-input-container" v-else>
              <input type="text" :id="`total-partners-${index}`" v-model="practiceArea.partnerPercent" class="form-control text-center percent-input" @keypress="isNumber($event)">
              <span class="right-5">%</span>
            </div>
          </div>
        </div>

        <text-input-vertical
          v-if="selectedDataType === 'percent'"
          id="total-lawyers"
          name="totalLawyers"
          input-type="hidden"
          label="Total Lawyers"
          autocomplete="nope"
          rules="percentage"
          class="hidden"
          :value="lawyerPercentSum">
        </text-input-vertical>

        <text-input-vertical
          v-if="selectedDataType === 'percent'"
          id="total-partners"
          name="totalPartners"
          input-type="hidden"
          label="Total Partners"
          autocomplete="nope"
          rules="percentage"
          class="hidden"
          :value="partnerPercentSum">
        </text-input-vertical>
      </div>
    </div>

    <template #errorMessage>
      <error-text :errors="percentErrors"></error-text>
    </template>
  </edit-modal>
</template>

<script>
import { cloneDeep, filter, sortBy, sumBy } from 'lodash';
import { matchingArrayValues } from 'misc/resource-helpers';
import DataLock from 'resources/data-lock';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown';

import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import PillSwitcher from 'vue-app/shared/components/pill-switcher.vue';
import ErrorText from 'vue-app/shared/components/error-message.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';

export default {
  name: 'EditFirmPracticeAreas',

  components: {
    EditModal,
    ErrorText,
    LoadingSection,
    PillSwitcher,
    TextInputVertical
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    practiceAreas: {
      type: Array,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      elementsToNotCloseOn: [],
      dropdownOpen: false,
      formattedPracticeAreas: [],
      dataType: null,
      initialDataType: null,
      practiceAreasCopy: []
    };
  },

  computed: {
    selectedDataTypeIsNumerical() {
      return this.dataType === 'numerical';
    },

    numericalPillSwitcherOption() {
      return { label: 'Numerical (#)', value: 'numerical' };
    },

    percentPillSwitcherOption() {
      return { label: 'Percentage (%)', value: 'percent' };
    },

    selectedDataType() {
      return this.lawyerCountAttribute === 'lawyerCount' ? 'numerical' : 'percent';
    },

    selectedPracticeAreas() {
      return filter(this.practiceAreasCopy, { displayOnProfile: true });
    },

    lawyerPercentSum() {
      if (this.selectedDataTypeIsNumerical) { return 0; }

      return sumBy(this.practiceAreasCopy, (practiceArea) => {
        return parseInt(practiceArea['lawyerPercent']) ? parseInt(practiceArea['lawyerPercent']) : 0;
      });
    },

    partnerPercentSum() {
      if (this.selectedDataTypeIsNumerical) { return 0; }

      return sumBy(this.practiceAreasCopy, (practiceArea) => {
        return parseInt(practiceArea['partnerPercent']) ? parseInt(practiceArea['partnerPercent']) : 0;
      });
    },

    percentErrors() {
      const errors = [];

      if (this.lawyerPercentSum > 100) { errors.push('Total Lawyers column must sum to 100%'); }
      if (this.partnerPercentSum > 100) { errors.push('Total Partners column must sum to 100%'); }

      return errors;
    }
  },

  created() {
    this.setupPracticeAreasCopyAndDataType();
  },

  updated() {
    this.setElementsToNotDisplayOn();
  },

  methods: {
    isNumber(event) {
      event = event || window.event;
      // TODO: these are deprecated. Should use event.key
      const charCode = event.which || event.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        event.preventDefault();
      }
      else {
        return true;
      }
    },

    setElementsToNotDisplayOn() {
      if (!this.elementsToNotCloseOnSet && this.$refs['paDropdown']) {
        this.elementsToNotCloseOnSet = true;
        this.elementsToNotCloseOn = [this.$refs['paDropdown'].$refs.dropdown];
      }
    },

    onModalOpen() {
      this.reset();
    },

    changeDataType(option) {
      this.dataType = option;
      this.sanitizeCountAttribute(option);
    },

    dataTypeIsNumerical(dataType) {
      return dataType === this.numericalPillSwitcherOption.value;
    },

    sanitizeCountAttribute(dataType) {
      const sanitizingLawyerAttribute = this.dataTypeIsNumerical(dataType) ? 'lawyerPercent' : 'lawyerCount';
      const sanitizingPartnerAttribute = this.dataTypeIsNumerical(dataType) ? 'partnerPercent' : 'partnerCount';

      this.practiceAreasCopy.forEach(practiceArea => {
        practiceArea[sanitizingLawyerAttribute] = null;
        practiceArea[sanitizingPartnerAttribute] = null;
      });
    },

    lawyerCountAttribute(practiceArea) {
      return this.selectedDataTypeIsNumerical ? practiceArea.lawyerCount : practiceArea.lawyerPercent;
    },

    partnerCountAttribute(practiceArea) {
      return this.selectedDataTypeIsNumerical ? practiceArea.partnerCount : practiceArea.partnerPercent;
    },

    setupPracticeAreasCopyAndDataType() {
      LoadingService.loading('pill-switcher-setup');
      let countAttributeIsCount = false;
      let countAttributeIsPercent = false;

      this.practiceAreasCopy = sortBy(cloneDeep(this.practiceAreas), (practiceArea) => {
        if (!countAttributeIsCount && !countAttributeIsPercent && (practiceArea['lawyerCount'] || practiceArea['partnerCount'])) {
          countAttributeIsCount = true;
        }
        else if (!countAttributeIsCount && !countAttributeIsPercent && (practiceArea['lawyerPercent'] || practiceArea['partnerPercent'])) {
          countAttributeIsPercent = true;
        }

        return practiceArea.name.toLowerCase();
      });

      if ((!countAttributeIsCount && !countAttributeIsPercent) || countAttributeIsCount) {
        this.initialDataType = this.numericalPillSwitcherOption.value;
      }
      else {
        this.initialDataType = this.percentPillSwitcherOption.value;
      }

      this.originallySelectedPracticeAreas = this.selectedPracticeAreas;
      this.dataType = this.initialDataType;
      LoadingService.done('pill-switcher-setup');
    },

    hidePracticeAreaOnProfile(practiceArea) {
      practiceArea.displayOnProfile = false;
    },

    buildData() {
      return { ...this.firm, scoutFirmPracticeAreasAttributes: this.practiceAreasCopy };
    },

    save() {
      let lock = false;

      if (!matchingArrayValues(this.selectedPracticeAreas, this.originallySelectedPracticeAreas)) {
        lock = true;
      }

      LoadingService.loading('firm-practice-areas');

      this.onSave(this.buildData(), () => {
        LoadingService.done('firm-practice-areas');

        if (lock) {
          DataLock.save({
            dataLock: {
              flag:         'practice_areas',
              resourceType: 'law_firm',
              resourceId: this.firm.id
            }
          });
        }
      });
    },

    reset() {
      this.errors = [];
      this.$refs.pillSwitcher.reset();
      this.setupPracticeAreasCopyAndDataType();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .hr {
    margin: 5px 0;
  }

  .menu-item-container {
    padding: 5px;

    li {
      padding: 5px 10px;
    }
  }

  .percent-input-container {
    align-items: center;
    border: 1px solid $k-light-gray;
    border-radius: $border-radius-base;
    display: flex;
  }

  .percent-input {
    border: 0;
  }
</style>

<template>
  <div class="gray-lighter-panel">
    <div class="dark-gray-text size-text-12px text-italic">
      {{ note.createdAt }}
    </div>

    <div>
      <span class="simple-text" v-if="contentIsLong">{{ content }}</span>
      <span class="simple-text" v-else>{{ note.content }}</span>
    </div>

    <!-- TODO: Scout; There is already a component for the "View More" feature, https://github.com/priori-legal/priori_legal/pull/905/commits/4f486526984ed49435625a995378c925a6348fa8 (We could replace this code when it gets merged in.) -->
    <div class="size-text-12px top-5" v-if="contentIsLong">
      <a href="" class="blue-link bold-weight" @click.prevent="toggleFullContent">View {{ showFullContent ? 'Less' : 'More' }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FirmNote',

  props: {
    note: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      longTextLength: 200,
      showFullContent: false
    };
  },

  computed: {
    content: function () {
      if (this.showFullContent || !this.contentIsLong) {
        return this.note.content;
      }
      else {
        let subContent = this.note.content.substring(0, this.longTextLength);
        subContent = subContent.substr(0, Math.min(subContent.length, subContent.lastIndexOf(' ')));

        return `${subContent}...`;
      }
    },

    contentIsLong: function () {
      return (this.note.content.length || 0) > this.longTextLength;
    }
  },

  methods: {
    toggleFullContent: function () {
      this.showFullContent = !this.showFullContent;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>

import axios from 'axios';
import BaseResource from 'resources/base-resource';
import Matter from 'resources/marketplace/matter.js';
import RenderedService from 'resources/marketplace/rendered-service.js';
import { each, isDate } from 'lodash';
import { DateTime } from 'luxon';

class Invoice extends BaseResource {
  static baseUrl = '/invoices/:id.json';

  static pay(params) {
    return axios.put(`/invoices/${params.id}/pay.json`, params);
  }

  constructor(opts) {
    super(opts);

    if (this.matter) { this.matter = new Matter(this.matter); }

    this.invoiceLineItems = this.invoiceLineItems || [];
    each(this.invoiceLineItems, (li) => { li.renderedService = new RenderedService(li.renderedService); });
    this.sortLineItems();
  }

  title() {
    return 'Invoice ' + this.referenceNumber;
  }

  dateRange() {
    if (this.isMonthly() && this.billingPeriod) {
      const startString = DateTime.fromISO(this.billingPeriod.start).toFormat('LL/dd/yyyy');
      const endString = DateTime.fromISO(this.billingPeriod.end).toFormat('LL/dd/yyyy');

      return `For dates ${ startString } - ${ endString }`;
    }
    else {
      return '';
    }
  }

  isLegacyInvoice() {
    const rateType = this.matter.ratePackageType;
    return rateType === 'LegacyHourlyRatePackage' || rateType === 'LegacyStandardFlatRatePackage';
  }

  isInitialInvoice() {
    return this.type === 'initial';
  }

  isMonthly() {
    return this.type === 'monthly';
  }

  unpaidFromRequest() {
    return this.isInitialInvoice() && !this.paid && this.matter.counselRequestId;
  }

  issuedAt() {
    return this.sentAt || this.matter.createdAt;
  }

  isScheduledToSend() {
    return !!(isDate(this.scheduledToSendAt) ||
      (this.scheduledToSendAt && this.scheduledToSendAt.length));
  }

  wasSent() {
    return !!this.sentAt;
  }

  isCancelled() {
    return this.status === 'cancelled';
  }

  isWrittenOff() {
    return this.status === 'written_off';
  }

  isVoid() {
    return this.isCancelled() || this.isWrittenOff();
  }

  toBeAutocharged() {
    return this.autochargingAllowed && this.dueDate && !this.paid && !this.isVoid();
  }

  showReview() {
    return !this.matter.review && (!this.isInitialInvoice() || this.lawyerWasPaid());
  }

  transactionFees() {
    return this.transactionFeeLineItem ? this.transactionFeeLineItem.amount / 100 : 0;
  }

  creditTotal() {
    return this.creditLineItem ? this.creditLineItem.amount / 100 : 0;
  }

  sortLineItems() {
    (this.invoiceLineItems || []).sort((l) => { return l.renderedService.type === 'management' ? 1 : 0; });
  }

  lineItemRate(lineItem) {
    const rs = lineItem.renderedService;
    if (rs.clientRate && rs.type === 'billed_hour') {
      return rs.clientRate / 100;
    }
  }

  lawyerReceiptTransactionFees() {
    if (this.lawyerWasPaid() || this.lawyerReceipt.transactionFee !== 0) {
      return this.lawyerReceipt.transactionFee / 100;
    }
    else {
      return this.lawyerReceipt.estimatedTransactionFee / 100;
    }
  }

  lawyerReceiptClientTotal() {
    return this.lineItemTotal / 100;
  }

  lawyerWasPaid() {
    return this.lawyerPaymentStatus === 'paid';
  }

  lawyerReceiptLawyerTotal() {
    let total = this.lawyerReceipt.total / 100;

    if (this.lawyerPaymentStatus !== 'paid' && this.lawyerReceipt.transactionFee === 0) {
      total += this.lawyerReceiptTransactionFees();
    }

    return total;
  }

  lawyerReceiptTotal() {
    return this.lawyerReceipt.total / 100;
  }

  lawyerReceiptPaidStatus() {
    if (this.lawyerWasPaid()) {
      return 'Paid';
    }
    else if (this.paid) {
      return 'Processing';
    }
    else if (this.isCancelled()) {
      return 'Cancelled';
    }
    else if (this.isWrittenOff()) {
      return 'Written Off';
    }
    else {
      return 'Unpaid';
    }
  }

  lawyerReceiptInvoiceStatus() {
    if (this.lawyerWasPaid()) {
      return 'Paid';
    }
    else if (this.paid) {
      return 'Processing Payment';
    }
    else if (this.isCancelled()) {
      return 'Cancelled';
    }
    else if (this.isWrittenOff()) {
      return 'Written Off';
    }
    else if (this.wasSent()) {
      return 'Sent';
    }
    else if (this.isScheduledToSend()) {
      return 'Scheduled';
    }
    else if (this.isImported) {
      return 'Unsent';
    }
    else {
      return 'Draft';
    }
  }

  lawyerReceiptDueOrPaidAt() {
    if (this.lawyerWasPaid()) {
      return this.paidAt;
    }
    else if (this.wasSent() && !this.paid && !this.isVoid()) {
      return this.dueDate;
    }
  }

  clientDueOrPaidAt() {
    return this.paid ? this.paidAt : this.dueDate;
  }

  clientLatestDate() {
    return this.paidAt || this.voidedAt || this.dueDate || this.sentAt;
  }

  clientDisplayStatus() {
    if (this.paid) {
      return 'Paid';
    }
    else if (this.isCancelled()) {
      return 'Cancelled';
    }
    else if (this.isWrittenOff()) {
      return 'Written Off';
    }
    else {
      return 'Unpaid';
    }
  }

  // TODO: not fully migrated
  // createOrUpdate(renderedServiceIds, successFn, failFn) {
  //   if (this.scheduledToSendAt && !angular.isString(this.scheduledToSendAt)) {
  //     this.scheduledToSendAt = new Date(this.scheduledToSendAt.toDateString());
  //   }

  //   if (this.id) {
  //     Invoice.update({ matterId: this.matterId, id: this.id, invoice: this, renderedServiceIds: this.renderedServiceIds }, successFn, failFn);
  //   }
  //   else {
  //     this.renderedServiceIds = renderedServiceIds;
  //     Invoice.save({ matterId: this.matterId, invoice: this, renderedServiceIds: this.renderedServiceIds }, successFn, failFn);
  //   }
  // }

  accountUsed() {
    const paymentMethod = this.paymentMethod;

    if (paymentMethod && paymentMethod.paymentMethodType === 'card') {
      return paymentMethod.brand + ' card ending ' + paymentMethod.last4;
    }
    else if (paymentMethod && paymentMethod.paymentMethodType === 'bank_account') {
      return 'bank account ending ' + paymentMethod.last4;
    }
  }
}

export default Invoice;

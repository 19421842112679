import BaseResource from 'resources/base-resource';

import { compact, find, get, round, startCase } from 'lodash';

class RenderedService extends BaseResource {
  static baseUrl            = '/matters/:matterId/rendered_services/:id.json';
  static urlTransformations = ['matterId', 'id'];

  typeBilledHour() {
    return this.type === 'billed_hour';
  }

  typeDisbursement() {
    return this.type === 'disbursement';
  }

  typeFlatAmount() {
    return this.type === 'flat_rate';
  }

  typeRetainer() {
    return this.type === 'retainer';
  }

  typeRetainerTopUp() {
    return this.typeRetainer() && this.topUp;
  }

  typePrepaidRealization() {
    return this.type === 'prepaid_realization';
  }

  typeManagementFee() {
    return this.type === 'management';
  }

  typeAdjustment() {
    return this.type === 'adjustment';
  }

  typePartnerDiscount() {
    return this.type === 'partner_discount';
  }

  displayType() {
    if (this.typeBilledHour()) {
      return 'Hours';
    }
    else if (this.typeDisbursement()) {
      return 'Disbursement';
    }
    else if (this.typeFlatAmount()) {
      return 'Flat Amount';
    }
    else if (this.typeRetainer()) {
      return 'Retainer';
    }
    else if (this.typePrepaidRealization()) {
      return 'Prepaid';
    }
    else if (this.typeManagementFee()) {
      return 'Priori Fee';
    }
    else if (this.typeAdjustment()) {
      return 'Adjustment';
    }
  }

  prepareForMatterDisplay(matter) {
    const rs = this;

    rs.dateOfActivity = moment(rs.dateOfActivity, 'YYYY-MM-DD').toDate();
    rs.hours = parseFloat(rs.hours);

    if (rs.typeBilledHour()) {
      rs.matterRatePackage = find(matter.matterRatePackages, { id: rs.matterRatePackageId });
    }
  }

  lawyerName() {
    const rs = this;

    if (rs.lawyer && rs.lawyer.firstName && rs.lawyer.lastName) {
      return startCase(rs.lawyer.firstName[0]) + '. ' + startCase(rs.lawyer.lastName);
    }
    else if (rs.otherLawyerFirstName && rs.otherLawyerLastName) {
      return startCase(rs.otherLawyerFirstName[0]) + '. ' + startCase(rs.otherLawyerLastName);
    }
    else {
      return '—';
    }
  }

  aba() {
    const rs = this;

    const activity = get(rs, 'abaActivity.code');
    const code = get(rs, 'abaCode.code');
    const expense = get(rs, 'abaExpense.code');

    return compact([activity, code, expense]).join(' / ');
  }

  hasWriteOff() {
    return !!this.invoiceAdjustmentAmount;
  }

  lawyerRate() {
    return get(this, 'matterRatePackage.lawyerAmount', 0) / 100;
  }

  adjustedRate() {
    return get(this, 'matterRatePackage.clientRate', 0) / 100;
  }

  roundedHours() {
    return round(this.hours, 2);
  }

  roundedDollars() {
    return round(this.dollars, 2);
  }

  roundedClientDollars() {
    return round(this.clientDollars, 2);
  }

  lawyerDollarsFromClientDollars(multiplier) {
    const rs = this;

    if (rs.typeDisbursement()) {
      return rs.roundedClientDollars();
    }
    else {
      return round(rs.roundedClientDollars() / multiplier, 2);
    }
  }

  adjustmentDollars() {
    const rs = this;
    return (rs.invoiceAdjustmentAmount || 0) / 100;
  }

  calculatedTotal() {
    const rs = this;

    if (rs.free) {
      return 0;
    }
    else if (rs.typeRetainer() || rs.typeDisbursement() || rs.typeFlatAmount()) {
      return rs.roundedClientDollars();
    }
    else {
      let total = get(rs, 'matterRatePackage.clientRate', 0) * rs.roundedHours();
      total = total + (rs.invoiceAdjustmentAmount || 0);
      total = total / 100;

      return total > 0 ? total : 0;
    }
  }

  calculatedLawyerTotal() {
    const rs = this;

    if (rs.free) {
      return 0;
    }
    else if (rs.typeRetainer() || rs.typeDisbursement() || rs.typeFlatAmount()) {
      return rs.roundedDollars();
    }
    else {
      let total = get(rs, 'matterRatePackage.lawyerAmount', 0) * rs.roundedHours();
      total = total + (rs.adjustmentAmount || 0);
      total = total / 100;

      return total > 0 ? total : 0;
    }
  }

  // TODO: not fully migrated
  // createOrUpdate(successFn, failFn) {
  //   const rs = this;

  //   if (rs.dateOfActivity && !angular.isString(rs.dateOfActivity)) {
  //     rs.dateOfActivity = new Date(rs.dateOfActivity.toDateString());
  //   }

  //   if (rs.id) {
  //     this.constructor.update({ matterId: rs.matterId, id: rs.id, renderedService: rs }).then(successFn, failFn);
  //   }
  //   else {
  //     this.constructor.save({ matterId: rs.matterId, renderedService: rs }).then(successFn, failFn);
  //   }
  // }
}

export default RenderedService;

<template>
  <div class="shadowed-box summary-box">
    <div :class="['multiple-content', { 'single-instance': singleLawyer }]">
      <div class="page-status" v-if="!singleLawyer">
        {{ page }} of {{ lawyers.length }}
      </div>

      <div :class="['text-center', { 'bottom-10': !singleLawyer, 'top-10 bottom-20': singleLawyer }]">
        <profile-photo :user="lawyer"></profile-photo>
      </div>

      <div class="semibold-weight text-center">
        {{ lawyer.fullName }}
      </div>

      <div class="button-container">
        <div class="row">
          <div class="col-sm-6 col-sm-offset-3 col-md-12 col-md-offset-0">
            <button type="button" class="nv-button-white" @click="messageLawyer"><svg-icon name="envelope" class="matter-icon right-15"></svg-icon>Send Message</button>
          </div>
        </div>
      </div>

      <div class="page-control-container">
        <card-pager :collection="lawyers" @page-changed="displayLawyer"></card-pager>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import CardPager from 'vue-app/shared/components/card-pager.vue';
import { concat, map } from 'lodash';

export default {
  name: 'LawyersCard',

  components: {
    ProfilePhoto,
    SvgIcon,
    CardPager
  },

  props: {
    matter: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      page: 1,
      lawyer: this.matter.lawyer
    };
  },

  computed: {
    lawyers() {
      return concat([this.matter.lawyer], map(this.matter.matterLawyers, 'lawyer'));
    },

    singleLawyer() {
      return this.lawyers.length === 1;
    }
  },

  methods: {
    displayLawyer(page) {
      this.lawyer = this.lawyers[page - 1];
      this.page = page;
    },

    messageLawyer() {
      this.$emit('message-lawyer', this.lawyer);
    }
  }
};
</script>

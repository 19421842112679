<template>
  <boxed-content title="Your Information">
    <template #links>
      <edit-modal title="Editing Your Information" :input="lawyer" :on-save="save">
        <template #default="{ inputCopy: lawyerCopy }">
          <div class="small-bold-header">
            Your Information
          </div>

          <hr class="top-5 bottom-5">

          <div class="dark-gray-text text-italic bottom-20">
            Please use the fields below to update your personal information. Please contact your system administrator to change your email address or phone number.
          </div>

          <div class="bottom-20">
            <text-input-vertical label="First Name" id="first-name" name="firstName" autocomplete="false" v-model="lawyerCopy.firstName" rules="required"></text-input-vertical>
          </div>

          <div class="bottom-20">
            <text-input-vertical label="Last Name" id="last-name" name="lastName" autocomplete="false" v-model="lawyerCopy.lastName" rules="required"></text-input-vertical>
          </div>

          <div class="bottom-20">
            <text-input-vertical label="Job Title" id="firm-title" name="firmTitle" autocomplete="false" v-model="lawyerCopy.firmTitle" rules="required"></text-input-vertical>
          </div>

          <div class="bottom-20">
            <div class="bold-weight bottom-5">
              Email
            </div>

            <div>
              {{ lawyerCopy.email || '&mdash;' }}
            </div>
          </div>

          <div class="bottom-20">
            <div class="bold-weight bottom-5">
              Phone
            </div>

            <div>
              {{ lawyerCopy.officeNumber || '&mdash;' }}
            </div>
          </div>

          <div>
            <label for="time-zone">Time Zone</label>

            <select v-model="lawyerCopy.timeZone" name="timeZoneSelect" id="time-zone" class="form-control">
              <option v-for="timeZone in timeZones" :value="timeZone.railsName" :key="timeZone.railsName">
                {{ timeZone.railsName }}
              </option>
            </select>
          </div>
        </template>

        <template #errorMessage>
          * One or more required fields are empty. Please fill in the required fields indicated above.
        </template>
      </edit-modal>
    </template>

    <loading-section name="firmAccountInfo">
      <info-item label="First Name" :value="lawyer.firstName" class="bottom-20"></info-item>

      <info-item label="Last Name" :value="lawyer.lastName" class="bottom-20"></info-item>

      <info-item label="Job Title" :value="lawyer.firmTitle" class="bottom-20"></info-item>

      <info-item label="Email" :value="lawyer.email" class="bottom-20"></info-item>

      <info-item label="Phone" :value="lawyer.officeNumber" class="bottom-20"></info-item>

      <info-item label="Time Zone" :value="lawyer.timeZone"></info-item>

      <div class="row top-20" v-if="!ssoEnabled">
        <div class="col-sm-4 bold-weight">
          Password
        </div>

        <div v-if="lawyer.id" class="col-sm-8">
          ******
          <span class="inline-block">
            <change-password-modal
              :resource="lawyer"
              :on-save="savePassword"
              :password-authentication="passwordAuthentication">
            </change-password-modal>
          </span>
        </div>

        <div v-else class="col-sm-8">
          &mdash;
        </div>
      </div>
    </loading-section>
  </boxed-content>
</template>

<script>
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import InfoItem from 'vue-app/shared/components/info-item.vue';
import ChangePasswordModal from 'vue-app/scout/shared/change-password-modal.vue';
import DataLock from 'resources/data-lock.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import TimeZoneHelper from 'vue-app/shared/services/time-zone-helper.js';
import { changes } from 'misc/resource-helpers';
import { includes } from 'lodash';

export default {
  name: 'ScoutFirmAccountInfo',

  components: {
    BoxedContent,
    EditModal,
    TextInputVertical,
    LoadingSection,
    InfoItem,
    ChangePasswordModal
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    ssoEnabled: {
      type: Boolean,
      default: false
    },

    passwordAuthentication: {
      type: Function,
      required: true
    },

    updatePassword: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      lockables: {
        firstName: 'Account Settings - First Name',
        lastName: 'Account Settings - Last Name',
        firmTitle: 'Account Settings - Title'
      }
    };
  },

  computed: {
    timeZones() {
      return TimeZoneHelper.sortedByRailsName;
    }
  },

  methods: {
    save(lawyer) {
      LoadingService.loading('firmAccountInfo');

      const changedKeys = Object.keys(changes(this.lawyer, lawyer));
      const flagsToSet = Object.keys(this.lockables).reduce((flags, key) => {
        if (includes(changedKeys, key)) {
          flags.push(this.lockables[key]);
        }
        return flags;
      }, []);

      return this.onSave(lawyer,
        () => {
          if (flagsToSet.length > 0) {
            DataLock.save({
              dataLock: {
                flag:         flagsToSet,
                resourceId:   this.lawyer.id,
                resourceType: 'lawyer'
              }
            });
          }
          LoadingService.done('firmAccountInfo');
        },
        () => {
          LoadingService.done('firmAccountInfo');
        }
      );
    },

    savePassword(lawyer) {
      LoadingService.loading('firmAccountInfo');

      this.updatePassword({ password: lawyer.password, passwordConfirmation: lawyer.passwordConfirmation }).then(() => {
        window.location.href = '/sign-in';
      })
      .finally(() => {
        LoadingService.done('firmAccountInfo');
      });
    }
  }
};
</script>

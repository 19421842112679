<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a :href="breadcrumbDestination" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>{{ breadcrumbLabel }}</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="top-10 bottom-30">
            <document-sharing-editor
              :is-lawyer="false"
              :project-id="projectId">
            </document-sharing-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentSharingEditor from 'vue-app/marketplace/shared/documents/document-sharing-editor.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'DocumentsShare',

  components: {
    DocumentSharingEditor,
    SvgIcon
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      projectId: this.initData.projectId
    };
  },

  computed: {
    breadcrumbDestination() {
      return this.projectId ? `/client-app/projects/${this.projectId}` : '/client-app/documents';
    },

    breadcrumbLabel() {
      return this.projectId ? 'Project Details' : 'Document Center';
    }
  }
};
</script>

<template>
  <div class="data-disclaimer text-center">
    <div :class="['logo-container', 'center-block', 'bottom-10', { 'beta': isBetaClient} ]">
      <svg-icon :name="isBetaClient ? 'scout-logo-beta' : 'scout-logo'" class="brand-icon"></svg-icon>
    </div>

    <div class="bold-weight bottom-5">
      * Profile data is not yet on Scout
    </div>

    <div class="bottom-15">
      {{ isBetaClient ? 'Scout is in Beta. ' : '' }}Some profile data may not yet be available. Reviews data, matter data and rates data may still be available.
    </div>

    <div class="row">
      <div class="col-md-10 col-md-offset-1">
        <data-disclaimer-modal :firm-name="firmName" :lawyer-name="lawyerName" :is-beta-client="isBetaClient"></data-disclaimer-modal>
      </div>
    </div>
  </div>
</template>

<script>
import DataDisclaimerModal from './data-disclaimer-modal.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'DataDisclaimerPanel',

  components: {
    DataDisclaimerModal
  },

  props: {
    firmName: {
      type: String,
      required: true
    },

    lawyerName: {
      type: String,
      required: false
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    isBetaClient() {
      return this.currentUser.isBeta;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .data-disclaimer {
    height: 203px;
    padding: 20px 25px;
    line-height: 1.3;
    background-color: $k-lighter-gray;

    .logo-container {
      max-width: 110px;

      &.beta {
        max-width: 150px;
      }
    }
  }
</style>

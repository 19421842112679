<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          Recipients
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <rfq-add-recipients
            v-if="canEditRfq && onlySentToMarketplace"
            :rfq="rfq"
            :available-firms="getAvailableFirms"
            :add-recipients="addRecipients">
          </rfq-add-recipients>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <div class="row gutter-30 d-flex-not-xs">
        <div class="col-sm-3 border-right-medium-gray">
          <div class="canvas-container">
            <doughnut-chart :chart-data="chartData" class="bottom-20" width="112px" height="112px" :cutout-percentage="77">
              <div class="quote-count">
                <div v-if="rfq" class="size-text-30px pill-gray-text">
                  {{ quoteCount }}
                </div>
              </div>
            </doughnut-chart>

            <div class="legend">
              <chart-legends
                :legend-data="chartData"
                :column-count="1"
                :initial-max-legends="6">
              </chart-legends>
            </div>
          </div>
        </div>

        <div class="col-sm-9 top-30-xs">
          <div v-if="!onlySentToMarketplace">
            <div class="size-text-15px bold-weight bottom-15 d-flex-not-xs">
              <div class="right-15">
                Your Firms
              </div>

              <rfq-add-recipients
                v-if="canEditRfq"
                class="top-5-xs"
                :rfq="rfq"
                :available-firms="getAvailableFirms"
                :add-recipients="addRecipients">
              </rfq-add-recipients>
            </div>

            <quote-group
              :rfq="rfq"
              :quotes="scoutLawFirmQuotes"
              :status-label-map="statusLabelMap">
            </quote-group>
          </div>

          <rfq-recipients-marketplace
            v-if="isFeatureLab || marketplaceRfqsEnabled"
            :class="{ 'top-30': !onlySentToMarketplace }"
            :rfq="rfq"
            :can-edit-rfq="canEditRfq"
            :marketplace-law-firm-quotes="marketplaceLawFirmQuotes"
            :status-label-map="statusLabelMap"
            :send-to-marketplace="sendToMarketplace">
          </rfq-recipients-marketplace>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from 'vue-app/shared/components/doughnut-chart.vue';
import QuoteGroup from 'vue-app/scout/client/rfqs/quote-group.vue';
import RfqAddRecipients from 'vue-app/scout/client/rfqs/add-recipients.vue';
import RfqRecipientsMarketplace from 'vue-app/scout/client/rfqs/rfq-recipients-marketplace.vue';
import PrioriColors from 'vue-app/shared/misc/priori-colors';
import { filter, map, sortBy } from 'lodash';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'RfqRecipients',

  components: {
    DoughnutChart,
    QuoteGroup,
    RfqAddRecipients,
    RfqRecipientsMarketplace
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    availableFirms: {
      type: Array,
      required: true
    },

    addRecipients: {
      type: Function,
      default: () => {}
    },

    canEditRfq: {
      type: Boolean,
      required: true
    },

    onlySentToMarketplace: {
      type: Boolean,
      required: true
    },

    sendToMarketplace: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      statusOrder: ['receivedScout', 'receivedMarketplace', 'pending', 'declined']
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentProduct: 'currentProduct', user: 'currentUser' }),

    marketplaceRfqsEnabled() {
      return this.user.workspace.marketplaceRfqsEnabled;
    },

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    },

    lawFirmQuotes() {
      return this.rfq.lawFirmQuotes || [];
    },

    aggregatedLawFirmQuotes() {
      return this.lawFirmQuotes.map(quote => {
        return {
          ...quote,
          status: this.quoteStatus(quote)
        };
      });
    },

    hasLawFirmQuotes() {
      return this.lawFirmQuotes.length > 0;
    },

    quoteCount() {
      return this.lawFirmQuotes.length;
    },

    getAvailableFirms() {
      if (this.rfq.lawFirmQuotes && this.rfq.lawFirmQuotes.length < 1) {
        return this.availableFirms;
      }

      const firmIdsSet = new Set(this.rfq.lawFirmQuotes.map(item => item.lawFirmId));
      return this.availableFirms.filter(item => !firmIdsSet.has(item.id));
    },

    statusColorMap() {
      const colorMap = {
        receivedScout:       PrioriColors.scoutBlue(),
        receivedMarketplace: PrioriColors.purple(),
        pending:             PrioriColors.scoutGray(),
        declined:            PrioriColors.scoutRed()
      };

      if (!this.rfq.sentToMarketplace) {
        delete colorMap.receivedMarketplace;
      }

      if (this.onlySentToMarketplace) {
        delete colorMap.receivedScout;
        delete colorMap.pending;
        delete colorMap.declined;
      }

      return colorMap;
    },

    statusLabelMap() {
      const labelMap = {
        receivedScout:       'Quotes from Your Firms',
        receivedMarketplace: 'Quotes from Priori’s Marketplace Network',
        pending:             'Pending Response',
        declined:            'Declined'
      };

      if (!this.rfq.sentToMarketplace) {
        delete labelMap.receivedMarketplace;
      }

      if (this.onlySentToMarketplace) {
        delete labelMap.receivedScout;
        delete labelMap.pending;
        delete labelMap.declined;
      }

      return labelMap;
    },

    chartData() {
      return map(this.statusColorMap, (color, status) => {
        return {
          name: this.statusLabelMap[status],
          color: color,
          value: filter(this.aggregatedLawFirmQuotes, { 'status': status }).length
        };
      });
    },

    scoutLawFirmQuotes() {
      return this.sortByStatus(this.aggregatedLawFirmQuotes.filter(quote => !quote.fromMarketplace));
    },

    marketplaceLawFirmQuotes() {
      return this.sortByDateReceived(this.aggregatedLawFirmQuotes.filter(quote => quote.fromMarketplace));
    }
  },

  methods: {
    quoteStatus(quote) {
      if (['new_rfq', 'draft'].includes(quote.status)) {
        return 'pending';
      }
      else if (['quote_submitted', 'selected_by_client', 'declined_by_client'].includes(quote.status)) {
        return quote.fromMarketplace ? 'receivedMarketplace' : 'receivedScout';
      }
      else if (quote.status === 'declined_by_firm') {
        return 'declined';
      }
    },

    sortByStatus(quotes) {
      return sortBy(quotes, quote => this.statusOrder.indexOf(quote.status));
    },

    sortByDateReceived(quotes) {
      return sortBy(quotes, quote => new Date(quote.dateReceived));
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .border-right-medium-gray {
    @media (min-width: $screen-sm-min) {
      border-right: 1px solid $medium-gray;
    }
  }

  :deep(.doughnut-container) {
    margin: 0 auto;
  }

  .quote-count {
    position: absolute;
    top: 56px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 1.2;
  }
</style>

<template>
  <div>
    <div class="appointment-container size-text-13px">
      <div class="row">
        <div class="col-sm-5 dark-gray-text top-10">
          Please select from the available time slots below.
        </div>

        <div class="col-sm-7 col-md-5 col-md-offset-2 top-20-xs">
          <time-zone-editor></time-zone-editor>
        </div>
      </div>

      <div class="top-30 bottom-30">
        <hr class="kenny-hr no-margin">
      </div>

      <div v-if="!allTimesPast">
        <loading-section name="times">
          <div v-for="(times, date, index) in futureDatesAndTimes" class="list-spacing-20">
            <div class="semibold-weight bottom-15">
              {{ formatDate(date) }}
            </div>

            <div class="row tight-columns">
              <div class="col-sm-3 col-md-2 bottom-10" v-for="time in times">
                <button type="button" :class="['nv-button-white smaller no-transform', { 'disabled-gray-link': anyTimeSelected }]" @click="selectTime(time)" v-if="!isTimeSelected(time)">{{ time.label({ timeZone: ianaTimeZone }) }}</button>

                <button type="button" class="nv-button-blue smaller no-transform" @click="deselectTime" v-if="isTimeSelected(time)">{{ time.label({ timeZone: ianaTimeZone }) }}</button>
              </div>
            </div>
          </div>
        </loading-section>
      </div>

      <div class="missing-text text-center top-50 bottom-20" v-if="allTimesPast">
        All of the times {{ suggesterFirstName }} suggested have passed. Please <button type="button" class="pseudo-link-blue" @click="onSuggestAlternativeTimes">suggest alternative times</button>.
      </div>

      <div v-if="availability.note.length">
        <div class="top-30 bottom-30">
          <hr class="kenny-hr no-margin">
        </div>

        <div class="semibold-weight">
          Note from {{ suggesterFirstName }}:
        </div>

        <div>
          {{ availability.note }}
        </div>
      </div>

      <div v-if="shouldShowLocation">
        <div class="top-20 bottom-30">
          <hr class="kenny-hr no-margin">
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="semibold-weight">
              Meeting Location:
            </div>

            <div v-if="location.title.length">
              {{ location.title }}
            </div>

            <div v-if="location.address?.length">
              {{ location.address }}
            </div>

            <div v-if="location.city?.length || location.state?.length || location.zip?.length">
              <span v-if="location.city?.length">{{ location.city }}, </span>{{ location.state }} {{ location.zip }}
            </div>
          </div>

          <div class="col-sm-6 top-20-xs">
            <div class="semibold-weight">
              Additional Information:
            </div>

            <div>
              {{ location.note || '&mdash;' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="kenny-hr no-margin">

    <div class="appointment-container size-text-13px">
      <div class="row">
        <div class="col-sm-5 col-md-4">
          <loading-button name="chooseTime" lb-class="nv-button-blue smaller" @lb-click="submit">Schedule {{ isInPersonMeeting ? 'Meeting' : 'Call' }}</loading-button>
        </div>

        <div class="col-sm-5 col-sm-offset-2 col-md-4 col-md-offset-4 top-20-xs">
          <button type="button" class="nv-button-white smaller" @click="onRequestCancellation">{{ (!appointment.currentParticipantIsOriginator() && !appointment.hasConsultations()) ? 'Reject' : 'Cancel' }} {{ isInPersonMeeting ? 'Meeting' : 'Call' }}</button>
        </div>
      </div>

      <div v-if="formError" class="error-text top-10">
        {{ formError }}
      </div>

      <div class="semibold-weight top-30">
        None of these times work? <button type="button" class="pseudo-link-blue" @click="onSuggestAlternativeTimes">Suggest alternative times</button>
      </div>
    </div>
  </div>
</template>

<script>
import TimeZoneEditor from 'vue-app/marketplace/shared/appointments/time-zone-editor.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import Availability from 'resources/marketplace/availability.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import UserSchedule from 'services/marketplace/user-schedule.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { isEmpty }  from 'lodash';
import { DateTime } from 'luxon';

export default {
  name: 'ChooseTime',

  components: {
    TimeZoneEditor,
    LoadingSection,
    LoadingButton
  },

  props: {
    appointment: {
      type: Object,
      required: true
    },

    onSuggestAlternativeTimes: {
      type: Function,
      required: true
    },

    onRequestCancellation: {
      type: Function,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      availability: { times: {}, note: '' },
      today: DateTime.now(),
      selectedTimeslot: null,
      formError: null
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { ianaTimeZone: 'ianaTimeZone' }),

    userSchedule() {
      return new UserSchedule(this.appointment.conflictingTimes);
    },

    todayStr() {
      return this.today.toFormat('y-MM-dd');
    },

    nowStr() {
      return this.today.toFormat('HH:mm');
    },

    suggester() {
      return this.appointment.suggester();
    },

    suggesterFirstName() {
      return this.suggester.participant.firstName;
    },

    location() {
      return this.appointment.location();
    },

    isVideoCall() {
      return this.appointment.type === 'video';
    },

    isInPersonMeeting() {
      return this.appointment.isInPersonMeeting();
    },

    allTimesPast() {
      return Object.keys(this.futureDatesAndTimes).length < 1;
    },

    futureDatesAndTimes() {
      const timeslots = this.dynamicAvailability.validAvailability(this.userSchedule).timeslots;
      return Object.groupBy(timeslots, timeslot => timeslot.inZone(this.ianaTimeZone).date());
    },

    shouldShowLocation() {
      return this.isInPersonMeeting && !isEmpty(this.location);
    },

    dynamicAvailability() {
      return Availability.load(this.availability.times);
    },

    anyTimeSelected() {
      return this.selectedTimeslot !== null;
    }
  },

  beforeMount() {
    this.reset();
  },

  methods: {
    reset() {
      this.formError = null;
      this.today = DateTime.now().setZone(this.ianaTimeZone);
      this.availability = {
        times: this.suggester.availability || {},
        note: this.suggester.note || ''
      };
      this.selectedTimeslot = null;
    },

    formatDate(date) {
      return DateTime.fromISO(date).toFormat('EEEE, MMMM d');
    },

    selectTime(timeslot) {
      this.formError = null;
      this.selectedTimeslot = timeslot;
    },

    isTimeSelected(timeslot) {
      return this.selectedTimeslot === timeslot;
    },

    deselectTime() {
      this.selectedTimeslot = null;
    },

    submit() {
      this.formError = null;

      if (!this.anyTimeSelected) {
        this.formError = '* Please select a time above';
        return;
      }

      LoadingService.around('chooseTime', () => {
        return this.onSubmit(this.selectedTimeslot);
      });
    }
  }
};
</script>

<template>
  <validation-provider :rules="validationRules" v-slot="{ errors }" :mode="passiveAggressive">
    <div class="row">
      <div class="col-sm-4">
        <span class="semibold-weight">{{ label }}</span>

        <div class="error-text top-5" v-if="editMode && errors.length">
          {{ errors[0] }}
        </div>
      </div>

      <div class="col-sm-8">
        <div v-if="!editMode">
          {{ currentValue || '&mdash;' }}
        </div>

        <div class="top-10-xs" v-if="editMode">
          <input type="text" :name="name" :class="['form-control', { 'has-error': errors.length }]" @input="onInput($event.target.value)" :value="$attrs.value">
        </div>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import interactionModes from '../mixins/interaction-modes';

export default {
  name: 'TextInputHorizontal',

  components: {
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    label: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: false
    },

    currentValue: {
      type: String,
      default: ''
    },

    editMode: {
      type: Boolean,
      default: false
    },

    novalidate: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    validationRules: function () {
      return this.novalidate ? null : 'required';
    }
  },

  methods: {
    onInput: function (value) {
      this.$emit('input', value);
    }
  }
};
</script>

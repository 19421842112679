import Vue from 'vue';
import vueComponents from './vue-components';

/****************/
/*  COMPONENTS  */
/****************/

import MessagingButton      from 'vue-app/mailboxer/messaging-button.vue';
import ActionBar            from 'vue-app/mailboxer/action-bar.vue';
import ConversationPreview  from 'vue-app/mailboxer/conversation-preview.vue';
import ConversationDetails  from 'vue-app/mailboxer/conversation-details.vue';
import ContactList          from 'vue-app/mailboxer/contact-list.vue';
import ConversationHeader   from 'vue-app/mailboxer/conversation-header.vue';
import ConversationMessages from 'vue-app/mailboxer/conversation-messages.vue';

vueComponents
.directive('ngvMessagingButton', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('MessagingButton', MessagingButton));
}])

.directive('ngvActionBar', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ActionBar', ActionBar));
}])

.directive('ngvConversationPreview', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConversationPreview', ConversationPreview));
}])

.directive('ngvConversationDetails', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConversationDetails', ConversationDetails));
}])

.directive('ngvContactList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ContactList', ContactList));
}])

.directive('ngvConversationHeader', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConversationHeader', ConversationHeader));
}])

.directive('ngvConversationMessages', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ConversationMessages', ConversationMessages));
}]);

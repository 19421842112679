import { vPull } from 'misc/vue-helpers.js';
import { DateTime } from 'luxon';
import { reduce } from 'lodash';
import Timeslot from 'services/marketplace/timeslot.js';

const formatAsDate = (time) => {
  return time.toFormat('y-LL-dd');
};

// Takes the value of the `times` key in the structure below and returns an
// array of timeslots, with luxon times based on the times in the original object
//
// {
//   times : {
//     "2023-12-14": ["22:00", "22:30"],
//     "2023-12-15": ["15:30", "16:00", "16:30", "17:00"],
//     "2023-12-16": ["14:00", "15:00", "15:30", "14:30", "23:00", "22:00", "18:30"]
//   }
// }
//
// The times are assumed to be in UTC

const convertAvailabilityStructTimeslotsArray = (timesByDate) => {
  return reduce(timesByDate, (array, times, date) => {
    const dateObj = DateTime.fromISO(date, { zone: 'UTC' });

    times.forEach((time) => {
      const timeComponents = time.split(':');
      const hour           = parseInt(timeComponents[0]);
      const minutes        = parseInt(timeComponents[1]);

      const timeForSlot    = dateObj.set({ hour: hour, minutes: minutes });

      array.push(new Timeslot(timeForSlot));
    });

    return array;
  }, []);
};

export default class Availability {
  static load(timeslotData, otherData) {
    const timeslots = convertAvailabilityStructTimeslotsArray(timeslotData);
    return new this({ ...otherData, times: timeslots });
  }

  constructor(data = {}) {
    this.timeslots    = [];
    this.note         = data.note || '';
    this.userSchedule = data.userSchedule;

    (data.times || []).forEach((time) => { this.add(time); });
  }

  includes(timeslot) {
    return this.timeslots.includes(timeslot);
  }

  timesByDate() {
    return reduce(this.timeslots, (array, timeslot) => {
      const utcTimeslot = timeslot.inZone('UTC');
      const date = formatAsDate(utcTimeslot.time);

      array[date] = array[date] || [];
      array[date].push(utcTimeslot.hour());

      return array;
    }, {});
  }

  availableTimes() {
    return this.timeslots.filter((timeslot) => {
      if (this.userSchedule) {
        return !timeslot.isPast() && this.userSchedule.availableAt(timeslot);
      }
      else {
        return !timeslot.isPast();
      }
    });
  }

  validAvailability() {
    return new this.constructor({
      times: this.availableTimes(),
      note: this.note,
      userSchedule: this.userSchedule
    });
  }

  add(timeslot) {
    if (this.includes(timeslot)) { return; }
    if (![0, 30].includes(timeslot.time.minute)) { return; }

    this.timeslots.push(timeslot);
  }

  remove(timeslot) {
    vPull(this.timeslots, timeslot);
  }
}

<template>
  <div>
    <button type="button" class="pseudo-link-blue dark-gray-link" @click="openModal">
      Not Relevant
    </button>

    <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" :backdrop="false" append-to-body @hide="reset">
      <modal-close-button :on-dismiss="close"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-header">
          Review Not Relevant
        </div>

        <div class="notice-regular-text">
          <div class="bottom-20 size-text-14px">
            Please provide more detail as to why this reminder for <b>{{ matter.name }}</b> is not relevant. This will help Scout provide better recommendations in the future.
          </div>

          <form-with-radio-buttons
            class="bottom-30"
            :options="options()"
            :on-click="onClick">
          </form-with-radio-buttons>

          <button type="button" class="primary-button-compact" :disabled="!reason" @click.prevent="submit">Submit</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import FormWithRadioButtons from 'vue-app/shared/components/form-with-radio-buttons.vue';

export default {
  name: 'ReviewReminderNotRelevantModal',

  components: {
    FormWithRadioButtons,
    ModalCloseButton
  },

  mixins: [
    modalToggle
  ],

  props: {
    matter: {
      type: Object,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      reason: null
    };
  },

  methods: {
    options() {
      return [
        {
          label: 'I\'m not working on this matter',
          value: 'not_working_on_matter'
        },
        {
          label: 'I do not have enough information to write a review at the moment, but may write a review in the future',
          value: 'not_enough_information'
        },
        {
          label: 'I don\'t plan to review this matter',
          value: 'not_planning_to_review'
        }
      ];
    },

    onClick(value) {
      this.reason = value;
    },

    submit() {
      this.onSubmit({ scoutMatterId: this.matter.id, reason: this.reason }).then(() => {
        this.close();
      });
    },

    close() {
      this.closeModal();
      this.reset();
    },

    reset() {
      this.value = null;
    }
  }
};
</script>

<template>
  <div>
    <button type="button" class="pseudo-link-blue size-text-13px normal-weight" @click="initModal">Add/Edit Invoice Recipients</button>

    <modal id="invoice-recipients-modal" v-model="modalOpen" size="md-sm" :header="false" :footer="false" append-to-body>
      <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-header">
          Invoice Recipients
        </div>

        <div class="notice-action-container bold-weight red-text size-text-13px top-30" v-if="showError">
          You must have at least one invoice recipient listed on this project. Please add another recipient before removing this one.
        </div>

        <div class="notice-regular-text">
          <div class="permission-detail list-spacing-10" v-for="recipient in recipientsManager.defaultRecipients">
            <invoice-recipient-editor
              :email="recipient.email"
              :label="recipient.fullName"
              :on-delete="submitDefaultRecipient"
              :has-recipients="recipientsManager.hasRecipients()"
              :on-show-editor="resetErrorVisibility">
            </invoice-recipient-editor>
          </div>

          <div class="permission-detail list-spacing-10" v-for="recipient in recipientsManager.additionalRecipients">
            <invoice-recipient-editor
              :email="recipient"
              :on-delete="deleteAdditionalRecipient"
              :has-recipients="recipientsManager.hasRecipients()"
              :on-show-editor="resetErrorVisibility">
            </invoice-recipient-editor>
          </div>
        </div>

        <hr class="kenny-hr no-margin">

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form role="form" name="invoiceRecipientsForm" class="form a-form" novalidate>
            <div class="notice-regular-text">
              <div v-if="recipientsManager.possibleRecipients.length" class="bottom-20">
                <div class="bottom-20">
                  <dropdown-select
                    ref="recipientsDropdown"
                    id-label="recipients-select"
                    placeholder="Select Recipients"
                    v-model="recipientsManager.selectedDefaultRecipient"
                    :options="recipientsManager.possibleRecipients"
                    value-key="email"
                    label-key="fullName">
                  </dropdown-select>
                </div>

                <div class="text-center bold-weight">
                  OR
                </div>
              </div>

              <validation-provider rules="email" v-slot="{ errors }" :mode="passiveAggressive">
                <input type="email" name="email" id="recipient-email" :class="['form-control', { 'has-error': errors.length }]" placeholder="Add Email Address" v-model="recipientsManager.additionalRecipient">

                <div class="error-text top-5" v-if="errors.length">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>

            <div class="notice-action-container">
              <div class="row">
                <div class="col-sm-6 bottom-30">
                  <button type="button" class="nv-button-blue a-button-size" @click="handleSubmit(addRecipients)">Save</button>
                </div>

                <div class="col-sm-6 bottom-30">
                  <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
                </div>
              </div>
            </div>

            <div class="notice-action-container semibold-weight size-text-11px bottom-30">
              <span class="bold-weight red-text">IMPORTANT NOTE:</span> As you would with any similar disclosure, consider whether any attorney-client privilege issues or waiver could arise from sharing access to this project with a third party. If you have any questions, please consult your project lawyer before proceeding.
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import InvoiceRecipientEditor from 'vue-app/marketplace/client/matters/invoice-recipient-editor.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import RecipientsManager from 'services/marketplace/matters/recipients-manager.js';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';

export default {
  name: 'InvoiceRecipientModalContent',

  components: {
    ModalCloseButton,
    InvoiceRecipientEditor,
    DropdownSelect,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes,
    modalToggle
  ],

  props: {
    matter: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      recipientsManager: new RecipientsManager(this.matter),
      showError: false
    };
  },

  methods: {
    initModal() {
      this.recipientsManager = new RecipientsManager(this.matter);
      this.showError = false;
      this.$refs.recipientsDropdown?.reset();
      this.openModal();
    },

    resetErrorVisibility() {
      this.showError = !this.recipientsManager.hasRecipients();
    },

    addRecipients() {
      this.$refs.observer.validate().then((valid) => {
        this.recipientsManager.addRecipients({ addEmailRecipient: valid }).then(() => {
          this.showError = false;
        });
      });
    },

    deleteAdditionalRecipient(recipientEmail, action) {
      this.recipientsManager.deleteAdditionalRecipient(recipientEmail, action);
    },

    submitDefaultRecipient(recipientEmail) {
      this.recipientsManager.submitDefaultRecipient(recipientEmail).then(() => {
        this.showError = false;
      });
    }
  }
};
</script>

<template>
  <div class="row tight-columns vertical-center-not-xs">
    <div class="col-md-8">
      <div class="semibold-weight text-ellipsis">
        {{ displayDoc.name }}
      </div>

      <div class="size-text-13px text-ellipsis top-5" v-if="!isDocumentTemplate">
        <span :class="{ 'right-10': onGoogleDrive }">
          Project:
          <a :href="`/client-app/projects/${displayDoc.matterId}`" class="darker-gray-text semibold-weight">{{ displayDoc.projectName || '&mdash;' }}</a>
          <span v-if="hasUser"> by {{ displayDoc.user.fullName }}</span>
        </span>

        <span class="right-10" v-if="onGoogleDrive">&#8231;</span>

        <span class="semibold-weight" v-if="onGoogleDrive">{{ document.permissionDisplay() }}</span>
      </div>
    </div>

    <div class="col-sm-6 col-md-4">
      <div class="row tight-columns dash-btn-container">
        <div class="col-xs-3">
          <div class="top-10" v-if="onGoogleDrive">
            <svg-icon name="google-drive" class="base-icon medium right-10"></svg-icon>
          </div>
        </div>

        <div :class="['top-2', { 'col-xs-6': actionable, 'col-xs-9': !actionable }]">
          <a :href="displayDoc.url" class="nv-button-white smaller" target="_blank">View</a>
        </div>

        <div class="col-xs-3 top-2" v-if="actionable">
          <document-options
            :document="document"
            :on-remove="onRemove">
          </document-options>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentOptions from 'vue-app/marketplace/shared/documents/document-options.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { has } from 'lodash';

export default {
  name: 'DocumentCard',

  components: {
    DocumentOptions,
    SvgIcon
  },

  props: {
    document: {
      type: Object,
      required: true
    },

    connectedToGoogleDrive: {
      type: Boolean,
      required: true
    },

    onRemove: {
      type: Function,
      required: true
    }
  },

  computed: {
    displayDoc() {
      return this.document.yourFile || this.isDocumentTemplate ? this.document : this.document.document;
    },

    actionable() {
      return this.document.yourFile && (!this.onGoogleDrive || this.connectedToGoogleDrive);
    },

    isDocumentTemplate() {
      return has(this.document, 'visibility');
    },

    onGoogleDrive() {
      return !this.isDocumentTemplate && this.document.onGoogleDrive();
    },

    hasUser() {
      return !this.document.yourFile && !this.isDocumentTemplate;
    }
  }
};
</script>

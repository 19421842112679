<template>
  <div>
    <div v-if="myProject || !accessRequested">
      <dropdown-menu>
        <template v-if="showRequestOptions">
          <li class="menu-item" role="menuitem" v-if="sentToClient">
            <a :href="presentProposalPath" target="_blank"><svg-icon name="present" class="base-icon"></svg-icon>Present Proposal</a>
          </li>

          <li class="menu-item" role="menuitem" v-if="sentToClient">
            <a :href="downloadPdfPath" :class="{ 'disabled': !project.proposalSent }" target="_blank"><svg-icon name="print" class="base-icon"></svg-icon>Download PDF</a>
          </li>

          <li class="menu-item" role="menuitem">
            <close-request-confirmation
              :has-scheduled-appointments="hasScheduledAppointments"
              :on-confirm="closeRequest">
            </close-request-confirmation>
          </li>
        </template>

        <template v-else>
          <template v-if="showProjectOptions">
            <li class="menu-item" role="menuitem">
              <a :href="detailPagePath"><svg-icon name="document" class="base-icon"></svg-icon>View Project</a>
            </li>

            <li class="menu-item" role="menuitem">
              <a href="" @click.prevent="messageLawyer"><svg-icon name="envelope" class="base-icon"></svg-icon>Message Lawyer</a>
            </li>

            <li class="menu-item" role="menuitem" v-if="!project.isReviewed()">
              <a :href="reviewLawyerPath"><svg-icon name="ribbon" class="base-icon"></svg-icon>Review Lawyer</a>
            </li>
          </template>

          <li class="menu-item" role="menuitem" v-if="showRequestAccess">
            <a href="" @click.prevent="requestAccess"><svg-icon name="invite" class="base-icon"></svg-icon>Request Access</a>
          </li>

          <li class="menu-item" role="menuitem" v-if="showViewRequestLink">
            <a :href="viewRequestPath" target="_blank"><svg-icon name="resume" class="base-icon"></svg-icon>View Request</a>
          </li>
        </template>
      </dropdown-menu>
    </div>

    <div v-if="!myProject && accessRequested">
      <a href="" tabindex="0" class="nv-button-square-white smaller" type="primary" v-tooltip.top="'Access Requested'" v-tooltip.custom-class="'k-tooltip'" v-tooltip.append-to="body" disabled><svg-icon name="options" class="base-icon"></svg-icon></a>
    </div>
  </div>
</template>

<script>
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import CloseRequestConfirmation from 'vue-app/marketplace/shared/close-request-confirmation.vue';
import { includes } from 'lodash';

export default {
  name: 'ProjectOptions',

  components: {
    DropdownMenu,
    SvgIcon,
    CloseRequestConfirmation
  },

  props: {
    project: {
      type: Object,
      required: true
    },

    myProject: {
      type: Boolean,
      default: false
    },

    currentUser: {
      type: Object,
      required: true
    },

    detailPagePath: {
      type: String,
      required: true
    },

    isRequest: {
      type: Boolean,
      default: false
    },

    isClosed: {
      type: Boolean,
      default: false
    }
  },

  emits: ['messageLawyer', 'requestClosed', 'accessRequested'],

  computed: {
    presentProposalPath() {
      return `/client-app/proposals/${this.project.proposalId}`;
    },

    downloadPdfPath() {
      return `/proposals/${this.project.proposalId}.pdf`;
    },

    reviewLawyerPath() {
      return `/client-app/projects/${this.project.id}/reviews/new`;
    },

    viewRequestPath() {
      return this.isRequest ? `/client-app/requests/${this.project.id}` : `/client-app/requests/${this.project.counselRequestId}`;
    },

    accessRequested() {
      return includes(this.project.clientRequests, this.currentUser.id);
    },

    showProjectOptions() {
      return this.myProject && !this.isRequest;
    },

    showRequestOptions() {
      return this.isRequest && !this.isClosed;
    },

    showRequestAccess() {
      return !this.myProject && !this.isClosed;
    },

    sentToClient() {
      return this.project.status === 'sent_to_client';
    },

    showViewRequestLink() {
      return this.isRequest || this.project.counselRequestId;
    },

    hasScheduledAppointments() {
      return this.project.scheduledAppointmentsCount > 0;
    }
  },

  methods: {
    messageLawyer() {
      this.$emit('message-lawyer', this.project.lawyer);
    },

    closeRequest(reason) {
      if (this.isRequest) {
        this.$emit('request-closed', reason);
      }
    },

    requestAccess() {
      if (!this.myProject && !this.accessRequested) {
        this.$emit('access-requested', this.project);
      }
    }
  }
};
</script>

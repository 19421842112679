<template>
  <div class="notice-modal">
    <div class="notice-header">
      Create a List
    </div>

    <div class="notice-regular-text">
      <form role="form" class="a-form" novalidate>
        <div v-if="page === 1 && hasWorkspaces">
          <label>Select a Client <span class="red-text">*</span></label>

          <div v-if="error" class="error-text bottom-5">
            {{ error }}
          </div>

          <div class="bottom-20">
            <button type="button" :class="['select-button bottom-10', { 'selected': workspaceIsSelected(workspace) }]" @click="selectWorkspace(workspace)" v-for="workspace in scoutWorkspaces" :key="`create-list-workspace-${workspace.id}`">{{ workspace.name }}</button>
          </div>

          <div class="row">
            <div class="col-xs-6 col-sm-4">
              <button type="button" class="primary-btn-blue" @click="goToNextPage">Next</button>
            </div>
          </div>
        </div>

        <div v-else-if="!hasWorkspaces">
          <div class="size-text-16px bottom-30">
            You don’t have any connected clients that are eligible to receive lists.
          </div>

          <div class="row">
            <div class="col-xs-9 col-sm-5">
              <button type="button" class="nv-button-white" @click="cancel">Close Window</button>
            </div>
          </div>
        </div>

        <div v-else>
          <label for="list-name">List Name <span class="red-text">*</span></label>

          <div v-if="error" class="error-text bottom-5">
            {{ error }}
          </div>

          <input type="text" id="list-name" name="listName" class="form-control bottom-30" v-model="listName">

          <div class="row">
            <div class="col-xs-8 col-sm-4">
              <button type="button" class="primary-btn-blue" @click="handleOnSubmit">Save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ResourceList from 'vue-app/scout/resources/resource-list.js';
import ModalManager from 'vue-app/shared/services/modal-manager.js';

export default {
  name: 'CreateList',

  props: {
    workspaces: {
      type:     Array,
      required: true
    }
  },

  data() {
    return {
      page: 1,
      selectedWorkspaceId: null,
      listName: '',
      error: null
    };
  },

  computed: {
    hasWorkspaces() {
      return this.scoutWorkspaces.length > 0;
    },

    scoutWorkspaces() {
      return this.workspaces.filter(w => !w.featureLab);
    }
  },

  methods: {
    selectWorkspace(workspace) {
      this.selectedWorkspaceId = workspace.id;
      this.error = null;
    },

    workspaceIsSelected(workspace) {
      return this.selectedWorkspaceId === workspace.id;
    },

    goToNextPage() {
      if (this.validated()) {
        this.page += 1;
      }
      else {
        this.error = '* Please select a client';
      }
    },

    cancel() {
      ModalManager.close('create-list');
    },

    validated() {
      this.error = null;

      if (this.page === 1 && !this.selectedWorkspaceId) {
        return false;
      }
      else if (this.page === 2 && !this.listName) {
        return false;
      }

      return true;
    },

    handleOnSubmit() {
      if (this.validated()) {
        const data = {
          name: this.listName,
          resourceType: 'LawyerBridge',
          workspaceId: this.selectedWorkspaceId,
          itemIds: []
        };

        ResourceList.save({ resourceList: data }).then(list => {
          ModalManager.close('create-list');
          window.location.href = `/scout-firm/clients/${list.workspaceId}/lists/${list.id}`;
        });
      }
      else {
        this.error = '* Name cannot be blank';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .select-button {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
</style>

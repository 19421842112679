<template>
  <section>
    <div class="row">
      <div class="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
        <div class="shadowed-box top-30">
          <div class="box-content extra-padded">
            <div class="text-center bottom-30 size-text-24px">
              Account Locked
            </div>

            <div class="icon-container bottom-40">
              <svg-icon name="locked-devices"></svg-icon>
            </div>

            <inline-notification type="error">
              <div v-html="message"></div>
            </inline-notification>

            <button class="nv-button-blue top-20" @click.prevent="logOut">Back to Login</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import InlineNotification from 'vue-app/shared/components/inline-notification.vue';

export default {
  name: 'OtpLocked',

  components: {
    SvgIcon,
    InlineNotification
  },

  props: {
    lockedUntil: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      countdown: 0
    };
  },

  computed: {
    lockedUntilDate() {
      return new Date(this.lockedUntil);
    },

    remainingSeconds() {
      return this.recalculateWaitTime();
    },

    lockedTimeSpan() {
      const seconds = this.countdown;
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);

      if (minutes > 60) {
        return `<b>${hours}</b> hours and <b>${minutes % 60}</b> minutes`;
      }
      else if (seconds > 60) {
        return `<b>${minutes}</b> minutes and <b>${seconds % 60}</b> seconds`;
      }
      else {
        return `<b>${seconds}</b> seconds`;
      }
    },

    message() {
      return `We have temporarily locked your account for security purposes. You have reached the maximum number of sign-in attempts. You will be able to sign in again in ${this.lockedTimeSpan}. If you need immediate access, please email admin@priorilegal.com`;
    }
  },

  mounted() {
    this.countdown = this.remainingSeconds;
    this.startCountdown();
  },

  methods: {
    recalculateWaitTime() {
      return Math.ceil((this.lockedUntilDate - new Date()) / 1000);
    },

    startCountdown() {
      let interval = setInterval(() => {
        this.countdown = this.recalculateWaitTime();
        if (this.countdown <= 0) {
          clearInterval(interval);
          this.countdown = 0;
          this.logOut();
        }
      }, 1000);
    },

    logOut() {
      $('#hidden-logout-button').click();
    }
  }
};
</script>

<style lang="scss" scoped>
  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .nv-button-blue {
    width: unset;
    padding: 0 20px;
  }

  .icon-container {
    text-align: center;

    svg {
      width: 100px;
    }
  }
</style>

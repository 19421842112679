import useNotificationStore from 'vue-app/stores/notification';

class NotificationService {
  static show(message, sticky) {
    this.info(message, sticky);
  }

  static info(message, sticky) {
    this.setMessage(message, sticky, 'info');
  }

  static warn(message, sticky) {
    this.setMessage(message, sticky, 'warn');
  }

  static success(message, sticky) {
    this.setMessage(message, sticky, 'success');
  }

  static error(message, sticky) {
    this.setMessage(message, sticky, 'error');
  }

  static clear() {
    useNotificationStore().clearNotification();
  }

  static setMessage(message, sticky, severity) {
    if (sticky) {
      sessionStorage.setItem('notificationMessage', JSON.stringify({ message: message, severity: severity }));
    }
    else {
      sessionStorage.removeItem('notificationMessage');
    }

    useNotificationStore().setNotification({
      message: message,
      severity: severity
    });
  }

  static keepAlive() {
    useNotificationStore().setKeepAlive(true);
  }
}

export default NotificationService;

<template>
  <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" append-to-body>
    <div class="notice-modal">
      <div class="close-modal" @click="dismiss">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <div class="notice-header">
        <div class="row">
          <div class="col-xs-5 text-left">
            Add User
          </div>

          <div class="col-xs-7 text-right">
            <copy-to-clipboard :text-to-copy="inviteUrl" tooltip-text="Link copied to clipboard" v-if="showingForm && !ssoEnabled">
              Get Shareable Link
            </copy-to-clipboard>
          </div>
        </div>
      </div>

      <div v-if="showingForm && !clientWorkspaceisLoading">
        <workspace-invite-form :current-user="currentUser" :workspace="workspace" :on-cancel="dismiss" :on-submit="sendInvite"></workspace-invite-form>
      </div>

      <div v-if="showingSuccessMessage">
        <div class="notice-text">
          <div class="text-left">
            You successfully added a new user to your team.
            <span v-if="!addingToRequest">The user will be notified by email and can request access to your team&rsquo;s projects.</span>
            <span v-if="addingToRequest">The user will be notified by email and now has access to this proposal.</span>
          </div>
        </div>

        <div class="notice-action-container bottom-30">
          <div class="row">
            <div class="col-sm-6 col-sm-offset-3">
              <button type="button" class="nv-button-white a-button-size" @click="dismiss">Close Window</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showingFailMessage">
        <div class="notice-text">
          <div class="text-left">
            The selected person already has an account with Priori and cannot be added as a new user. Please contact Priori for further information.
          </div>
        </div>

        <div class="notice-action-container bottom-30">
          <div class="row">
            <div class="col-sm-6 col-sm-offset-3">
              <button type="button" class="nv-button-white a-button-size" @click="dismiss">Close Window</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import WorkspaceInviteForm from 'vue-app/shared/components/workspaces/workspace-invite-form.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import CopyToClipboard from 'vue-app/shared/components/copy-to-clipboard.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import Client from 'resources/client';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';

export default {
  name: 'WorkspaceInviteModal',

  components: {
    WorkspaceInviteForm,
    SvgIcon,
    CopyToClipboard
  },

  mixins: [
    modalToggle
  ],

  props: {
    currentUser: {
      type: Object,
      default: () => ({})
    },

    workspace: {
      type: Object,
      required: true
    },

    onInviteSent: {
      type: Function
    }
  },

  data() {
    return {
      showingSuccessMessage: false,
      showingFailMessage: false
    };
  },

  computed: {
    showingForm() {
      return !this.showingSuccessMessage && !this.showingFailMessage;
    },

    currentPath() {
      return window.location.pathname;
    },

    addingToRequest() {
      return (this.currentPath || '').includes('client-app/requests');
    },

    adminInvite() {
      return (this.currentPath || '').includes('admin-app/workspaces');
    },

    inviteUrl() {
      return window.location.origin + '/join-workspace/' + this.workspace.uid;
    },

    ssoEnabled() {
      return this.workspace.ssoEnabled;
    },

    clientWorkspaceisLoading() {
      return LoadingService.isLoading('clientWorkspace');
    }
  },

  methods: {
    showSuccessMessage() {
      this.showingSuccessMessage = true;
      this.showingFailMessage = false;
    },

    showFailMessage() {
      this.showingSuccessMessage = false;
      this.showingFailMessage = true;
    },

    dismiss() {
      this.dismissModal();
      this.showingSuccessMessage = false;
      this.showingFailMessage = false;
    },

    sendInvite({ client, afterFn }) {
      if (this.addingToRequest || this.adminInvite) {
        client.skipConfirmation = true;
      }

      Client.save({ client: client, completing_sign_up: true })
        .then((savedClient) => {
          if (this.adminInvite) {
            this.dismissModal();
          }
          else {
            this.showSuccessMessage();
          }
          afterFn();

          this.onInviteSent(savedClient);
        })
        .catch(() => {
          this.showFailMessage();
          afterFn();
        });
    }
  }
};
</script>

<template>
  <div>
    <div class="titlebar">
      <div class="row vertical-center-not-xs">
        <div class="col-sm-6 medium-page-heading">
          Dashboard
        </div>

        <div class="col-sm-6 size-text-16px text-right-not-xs">
          Welcome, {{ lawyer.firstName }}
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-8">
              <rfq-notifications
                v-if="hasRfqEnabled"
                :notifications="rfqNotifications"
                :is-connecting="true">
              </rfq-notifications>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfqNotifications from 'vue-app/scout/firm/dashboard/rfq-notifications.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'DashboardConnecting',

  components: {
    RfqNotifications
  },

  props: {
    rfqNotifications: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { lawyer: 'currentUser' }),

    hasRfqEnabled() {
      return this.lawyer.hasRfqEnabled;
    }
  }
};
</script>

import { filter, uniq } from 'lodash';

export default class RecipientsManager {
  constructor(matter) {
    this.matter = matter;

    this.allRecipients        = this.matter.allInvoiceRecipients();
    this.defaultRecipients    = this.matter.defaultInvoiceRecipients();
    this.possibleRecipients   = this.matter.possibleInvoiceRecipients();
    this.additionalRecipients = this.matter.invoiceRecipients;

    this.additionalRecipient         = null;
    this.selectedDefaultRecipient    = null;
    this.requesterIsInvoiceRecipient = false;
  }

  hasRecipients() {
    return (this.additionalRecipients.length + this.defaultRecipients.length) > 1;
  }

  findDefaultRecipient(recipientEmail) {
    return this.matter.matterClients.find((mc) => (mc.client.email === recipientEmail));
  }

  addRecipients(opts) {
    const updatePromises = [];

    if (this.additionalRecipient && opts.addEmailRecipient) {
      const defaultRecipient = this.findDefaultRecipient(this.additionalRecipient);
      const promise = defaultRecipient ? this.submitDefaultRecipient(this.additionalRecipient, 'add') : this.addAdditionalRecipients(this.additionalRecipient);
      updatePromises.push(promise);
    }
    if (this.selectedDefaultRecipient) {
      const promise = this.submitDefaultRecipient(this.selectedDefaultRecipient, 'add');
      updatePromises.push(promise);
    }

    return Promise.all(updatePromises);
  }

  addAdditionalRecipients(newAdditionalRecipients) {
    let newRecipients = this.additionalRecipients.concat(newAdditionalRecipients);
    newRecipients = uniq(newRecipients);
    return this.submitAdditionalRecipients(newRecipients, 'add');
  }

  submitAdditionalRecipients(recipients, action) {
    this.matter.invoiceRecipients = recipients;
    this.matter.save().then(() => {
      this.additionalRecipients = recipients;

      if (action === 'add') {
        this.additionalRecipient = null;
      }
    });
  }

  submitDefaultRecipient(recipientEmail, action) {
    const adding = (action === 'add');

    if (!adding && !this.hasRecipients()) { return; }

    if (this.matter.client.email === recipientEmail) {
      this.matter.requesterIsInvoiceRecipient = adding;
    }
    else {
      const matterClient = this.findDefaultRecipient(recipientEmail);
      matterClient.isInvoiceRecipient = adding;
      this.matter.matterClientsAttributes = [matterClient];
    }

    return this.matter.save().then(() => {
      if (adding) {
        this.selectedDefaultRecipient = null;
      }
      this.defaultRecipients = this.matter.defaultInvoiceRecipients();
      this.possibleRecipients = this.matter.possibleInvoiceRecipients();
    });
  }

  deleteAdditionalRecipient(recipient) {
    if (!this.hasRecipients()) { return; }

    var newRecipients = filter(this.additionalRecipients, (rec) => {
      return rec !== recipient;
    });
    return this.submitAdditionalRecipients(newRecipients, 'delete');
  }
}

<template>
  <priori-modal ref="contactScoutAdmins" modal-id="contact-scout-admins" modal-size="md-sm" :title="`Confirm ${displayText}`">
    <template #modal-trigger="{ openModal }">
      <div v-if="contactBtnDisabled" class="pill-gray-text bold-weight">{{ displayText }}</div>
      <a v-else href="" @click.prevent="openModal" class="bold-weight">{{ displayText }}</a>
    </template>

    <div class="semibold-weight size-text-14px bottom-30">
      Do you want to send an email to your {{ isFeatureLab ? '' : 'Scout' }} administrators requesting this firm be approved to receive RFPs?
    </div>

    <div class="row tight-columns">
      <div class="col-sm-5">
        <button type="button" class="primary-btn-night-blue" @click="confirm">Yes, Confirm</button>
      </div>

      <div class="col-sm-4 top-20-xs">
        <button type="button" class="secondary-btn-blue" @click="cancel">No, Cancel</button>
      </div>
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'src/vue-app/shared/components/priori-modal.vue';
import NotificationService from 'src/vue-app/shared/services/notification-service';
import RFQLawFirmQuote from 'src/resources/scout/rfq-law-firm-quote.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'RfqContactScoutAdmins',

  components: {
    PrioriModal
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    rfqLawFirmQuote: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      contactBtnDisabled: false
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentProduct']),

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    },

    displayText() {
      if (this.isFeatureLab) {
        return 'Contact Admins';
      }
      return 'Contact Scout Admins';
    }
  },

  methods: {
    confirm() {
      RFQLawFirmQuote.scoutAdminsContacted({ law_firm_id: this.rfqLawFirmQuote.lawFirmId })
        .then(() => {
          this.contactBtnDisabled = true;
          this.$emit('contact-admins', { rfq: this.rfq });

          this.$refs.contactScoutAdmins.closeModal();
          NotificationService.success('Admins have been contacted.');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },

    quoteIsNotPersisted() {
      return this.rfqLawFirmQuote.id === null;
    },

    cancel() {
      this.$refs.contactScoutAdmins.closeModal();
    }
  }
};
</script>

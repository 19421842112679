<template>
  <div>
    <loading-button name="hireLawyerBtn" class="nv-button-dark-green hire-btn" :lb-disabled="pl.selected()" @lb-click="confirm" v-if="pl.rateSentStatus()">Hire</loading-button>

    <request-confirmation
      ref="confirmationModal"
      :message="confirmMessage"
      :note="confirmNote"
      :yes-btn-text="confirmAcceptButton"
      :no-btn-text="confirmCancelButton"
      @on-modal-yes="hireLawyer">
    </request-confirmation>
  </div>
</template>

<script>
import RequestConfirmation from 'vue-app/shared/components/request-confirmation.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'HireLawyer',

  components: {
    RequestConfirmation,
    LoadingButton
  },

  props: {
    pl: {
      type: Object,
      required: true
    },

    onHireLawyer: {
      type: Function,
      required: true
    }
  },

  computed: {
    confirmMessage() {
      if (this.shouldShowEngagementLetterMessage) {
        return `${this.pl.lawyer.fullName} included an engagement letter with their proposal. Have you reviewed and signed the engagement letter?`;
      }
      else {
        return `Are you ready to hire ${this.pl.lawyer.firstName}?`;
      }
    },

    confirmNote() {
      if (this.shouldShowEngagementLetterMessage) {
        return 'The lawyer may elect not to provide legal services until an engagement letter has been signed.';
      }
      else {
        return null;
      }
    },

    confirmAcceptButton() {
      if (this.shouldShowEngagementLetterMessage) {
        return 'Yes, Proceed With Hire';
      }
      else {
        return 'Yes';
      }
    },

    confirmCancelButton() {
      if (this.shouldShowEngagementLetterMessage) {
        return 'No, Cancel';
      }
      else {
        return 'No';
      }
    },

    hasEngagementLetter() {
      return this.pl.matter && this.pl.matter.engagementLetter && this.pl.matter.engagementLetter.url;
    },

    engagementLetterCoord() {
      return JSON.parse(this.pl.matter.engagementLetter.coord.toString() || '[]');
    },

    clientSignature() {
      return this.engagementLetterCoord.find(c => c.name === 'clientSignature');
    },

    needsToBeSigned() {
      return this.hasEngagementLetter && this.engagementLetterCoord && this.clientSignature && !this.clientSignature.signed;
    },

    engagementLetterHasClientSignatureFields() {
      return !!this.clientSignature;
    },

    shouldShowEngagementLetterMessage() {
      return this.needsToBeSigned || (this.hasEngagementLetter && !this.engagementLetterHasClientSignatureFields);
    },

    requiresOpeningPayment() {
      return this.pl.matter.hasInitialInvoice && this.pl.counselRequest.requireOpeningPayment;
    }
  },

  methods: {
    confirm() {
      if (!this.pl.rateSentStatus()) { return; }

      if (this.requiresOpeningPayment) {
        window.location.href = `/client-app/projects/${this.pl.matter.id}/invoices/initial`;
      }
      else {
        this.$refs.confirmationModal.openModal();
      }
    },

    hireLawyer() {
      LoadingService.around('hireLawyerBtn', () => {
        return this.onHireLawyer();
      });
    }
  }
};
</script>

import Opportunity from 'src/resources/opportunity.js';
import { without, isEmpty } from 'lodash';
import Vue from 'vue';

class OpportunitiesSearchService {
  constructor() {
    this.initialize();
  }

  initialize() {
    this.searchLogic = {
      filters: {}
    };

    this.selectedFilterCounts = {};

    this.dirty = false;
  }

  applySearch() {
    this.updateSelectedFilterCounts();

    if (!isEmpty(this.searchLogic.filters)) { this.dirty = true; }

    return Opportunity.query({ search_params: JSON.stringify(this.searchLogic) });
  }

  addSearchParam(field, searchParam) {
    const previousValues = this.searchLogic.filters[field] || [];
    Vue.set(this.searchLogic.filters, field, [...previousValues, searchParam]);
  }

  removeSearchParam(field, searchParam) {
    Vue.set(this.searchLogic.filters, field, without(this.searchLogic.filters[field], searchParam));

    if (this.searchLogic.filters[field].length === 0) {
      this.unsetSearchParam(field);
    }
  }

  unsetSearchParam(field) {
    Vue.delete(this.searchLogic.filters, field);
  }

  resetSearch() {
    this.initialize();
  }

  updateSelectedFilterCounts() {
    Object.keys(this.searchLogic.filters).forEach(field => {
      Vue.set(this.selectedFilterCounts, field, this.searchLogic.filters[field].length);
    });

    Object.keys(this.selectedFilterCounts).forEach(field => {
      if (!this.searchLogic.filters[field]) {
        Vue.delete(this.selectedFilterCounts, field);
      }
    });
  }

  selectedOptionsCount(field) {
    return this.selectedFilterCounts[field] || 0;
  }
}

export default new OpportunitiesSearchService;

<template>
  <div>
    <priori-modal
      ref="form-modal"
      modal-id="submit-upload"
      title="New Data Upload">
      <template #modal-trigger="{ openModal }">
        <tooltip text="You won't be able to submit a new file until the last one is complete." :enable="uploadInProgress">
          <div>
            <button type="button" :class="['secondary-btn-blue a-button-size', { 'disabled': uploadInProgress }]" @click="openModalIfPermitted(openModal)">Upload Scout Firm Data</button>
          </div>
        </tooltip>
      </template>

      <template #default>
        <div class="bold-weight bottom-5">
          Instructions for Upload
        </div>

        <p>
          Please make sure you are uploading a file in JSON format. We do not support any other type of file.
        </p>

        <form role="form" class="a-form" @submit.prevent="uploadFile" novalidate>
          <div v-if="file.name" class="row top-30 bottom-50">
            <div class="col-xs-8 semibold-weight">
              {{ file.name }}
            </div>

            <div class="col-xs-4 text-right">
              <a href="" @click.prevent="deleteFile">Delete</a>
            </div>
          </div>

          <div v-else>
            <div class="top-30 bottom-30">
              <file-selector class="dash-container" accept=".json" @change="setFile">
                <div class="primary-button-compact clickable">
                  Select a File<span class="hidden-xs"> From Your Device</span>
                </div>
              </file-selector>
            </div>

            <p class="size-text-12px">
              <span class="semibold-weight">NOTE:</span> Some data fields that have been manually updated by Scout users may not be overwritten by the JSON upload.
            </p>
          </div>

          <div class="row top-30">
            <div class="col-sm-4">
              <loading-button :name="loadingTitle" class="primary-btn-blue a-button-size" @lb-click="uploadFile">Submit</loading-button>
            </div>
          </div>

          <div class="error-text top-5" v-if="showErrors">
            * Please select a file to upload.
          </div>
        </form>
      </template>
    </priori-modal>

    <pop-up-modal ref="pop-up-modal">
      <template #title>
        {{ popUpModalContent.title }}
      </template>

      <template #message>
        <p>{{ popUpModalContent.message }}</p>
      </template>
    </pop-up-modal>
  </div>
</template>

<script>
import PopUpModal from 'vue-app/shared/components/pop-up-modal.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';

import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'UploadModal',

  components: { PrioriModal, PopUpModal },

  props: {
    uploader: {
      type: Object,
      required: true
    },

    onCreation: {
      type: Function,
      default: () => {}
    },

    uploadInProgress: {
      type: Boolean,
      default: false
    },

    uploadInitializer: {
      type: Function,
      required: true
    },

    lawFirmId: {
      type: Number
    },

    currentUpload: {
      type: Function,
      required: true
    },

    uploadType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      file: {},
      newUpload: null,
      loadingTitle: 'submitUpload',
      showErrors: false,
      popUpModalContent: {
        title: '',
        message: ''
      }
    };
  },

  computed: {
    fileSelected() {
      return this.file instanceof File;
    }
  },

  methods: {
    setFile(files) {
      this.file = files[0];

      if (this.fileSelected) {
        this.showErrors = false;
      }
    },

    deleteFile() {
      this.file = {};
    },

    uploadFile() {
      if (!this.fileSelected) {
        this.showErrors = true;
      }
      else {
        this.showErrors = false;

        LoadingService.loading(this.loadingTitle);

        new this.uploadInitializer(this.file, this.uploader, this.currentUpload, this.uploadType, this.lawFirmId)
          .process()
          .finally(this.resolveUpload)
          .then(this.resolveSuccessfully, this.showFailureModal);
      }
    },

    resolveUpload() {
      LoadingService.done(this.loadingTitle);
      this.$refs['form-modal'].closeModal();
      this.file = {};
    },

    resolveSuccessfully(initializer) {
      if (initializer.upload) { this.onCreation(initializer.upload); }
      initializer.enqueue().then(this.showSuccessModal);
    },

    showSuccessModal() {
      this.openPopUpModal({
        title: 'Import Success',
        message: 'We have received your data. We will be processing soon. Check the status on the dashboard for more information.'
      });
    },

    showFailureModal(initializer) {
      if (initializer.upload) { this.onCreation(initializer.upload); }
      this.openPopUpModal({
        title: 'File Upload Error',
        message: initializer.error
      });
    },

    openPopUpModal(content) {
      this.popUpModalContent = content;
      this.$refs['pop-up-modal'].openModal();
    },

    openModalIfPermitted(openModalFunc) {
      if (this.uploadInProgress) { return; }

      openModalFunc();
    }
  }
};
</script>

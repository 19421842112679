<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a :href="`/client-app/requests/${possibleLawyer.counselRequest.id}`" class="back-link" v-if="possibleLawyer"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Back to Request: {{ possibleLawyer.counselRequest.nameForClientsReference }}</a>

        <a href="/client-app" class="back-link" v-else><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Dashboard</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container size-text-13px">
          <div class="top-10 bottom-40">
            <firm-header
              :law-firm="lawFirm"
              :is-lawyer="isLawyer"
              :selected-tab="selectedTab"
              :on-select-tab="selectTab">
            </firm-header>
          </div>

          <div class="row" v-if="selectedTab === 'details'">
            <div class="col-sm-8">
              <div class="bottom-40">
                <firm-about
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer">
                </firm-about>
              </div>

              <div class="bottom-40">
                <firm-practice-areas
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer"
                  v-if="!isAlsp">
                </firm-practice-areas>

                <alsp-practice-areas
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer"
                  v-if="isAlsp">
                </alsp-practice-areas>
              </div>

              <div>
                <firm-representative-matters
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer">
                </firm-representative-matters>
              </div>
            </div>

            <div class="col-sm-4 top-40-xs">
              <div class="bottom-40" v-if="!isAlsp">
                <firm-highlights
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer">
                </firm-highlights>
              </div>

              <div>
                <firm-locations
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer">
                </firm-locations>
              </div>

              <div class="top-40" v-if="isAlsp">
                <alsp-technology
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer">
                </alsp-technology>
              </div>
            </div>
          </div>

          <div v-if="selectedTab === 'lawyers'">
            <firm-users
              :firm-users="firmUserData.lawyers"
              :firm-user-count="firmUserData.totalCount"
              :show-invite-link="isLawyer"
              :show-options-column="false"
              :page="page"
              :on-change-page="changePage">
            </firm-users>
          </div>

          <div v-if="selectedTab === 'people'">
            <alsp-members
              :law-firm="lawFirm"
              :is-lawyer="isLawyer">
            </alsp-members>
          </div>

          <div v-if="selectedTab === 'testimonials'">
            <loading-section name="reviews">
              <div v-if="isAlsp">
                <alsp-testimonials
                  :law-firm="lawFirm"
                  :is-lawyer="isLawyer">
                </alsp-testimonials>
              </div>

              <div v-else>
                <firm-testimonials
                  :reviews-with-quotes="reviewsWithQuotes"
                  :is-lawyer="isLawyer">
                </firm-testimonials>
              </div>
            </loading-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

import FirmUsers from 'vue-app/marketplace/shared/law-firms/firm-users.vue';
import FirmHeader from 'vue-app/marketplace/shared/law-firms/firm-header.vue';
import FirmAbout from 'vue-app/marketplace/shared/law-firms/firm-about.vue';
import FirmLocations from 'vue-app/marketplace/shared/law-firms/firm-locations.vue';
import FirmHighlights from 'vue-app/marketplace/shared/law-firms/firm-highlights.vue';
import FirmTestimonials from 'vue-app/marketplace/shared/law-firms/firm-testimonials.vue';
import FirmPracticeAreas from 'vue-app/marketplace/shared/law-firms/firm-practice-areas.vue';
import FirmRepresentativeMatters from 'vue-app/marketplace/shared/law-firm-profile/representative-matters.vue';

import AlspMembers from 'vue-app/marketplace/shared/law-firm-profile/alsp-members.vue';
import AlspTechnology from 'vue-app/marketplace/shared/law-firm-profile/alsp-technology.vue';
import AlspTestimonials from 'vue-app/marketplace/shared/law-firm-profile/alsp-testimonials.vue';
import AlspPracticeAreas from 'vue-app/marketplace/shared/law-firm-profile/alsp-practice-areas.vue';

import Review from 'resources/marketplace/review.js';
import LawFirm from 'resources/marketplace/law-firm.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'FirmsShow',

  components: {
    LoadingSection,
    SvgIcon,

    FirmUsers,
    FirmAbout,
    FirmHeader,
    FirmLocations,
    FirmHighlights,
    FirmTestimonials,
    FirmPracticeAreas,
    FirmRepresentativeMatters,

    AlspMembers,
    AlspTechnology,
    AlspTestimonials,
    AlspPracticeAreas
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      lawFirm:           this.initData.lawFirm,
      possibleLawyer:    this.initData.possibleLawyer,
      page:              1,
      firmUserData:      {},
      isLawyer:          false,
      selectedTab:       'details',
      reviewsWithQuotes: [],
      lawyersLoaded:     false,
      reviewsLoaded:     false
    };
  },

  computed: {
    isAlsp() {
      return this.lawFirm.alspAccount;
    }
  },

  methods: {
    selectTab(tab) {
      this.selectedTab = tab;

      if (this.selectedTab === 'lawyers' && !this.lawyersLoaded) {
        this.loadLawyers();
      }
      else if (tab === 'testimonials' && !this.isAlsp && !this.reviewsLoaded) {
        this.loadReviews();
      }
    },

    loadLawyers() {
      LoadingService.around('users', () => {
        return LawFirm.lawyers({ id: this.lawFirm.id, page: this.page, view: 'firm_profile' })
          .then((userData) => {
            this.firmUserData = userData.data;
            this.lawyersLoaded = true;
          });
      });
    },

    loadReviews() {
      LoadingService.around('reviews', () => {
        return Review.query({ law_firm_id: this.lawFirm.id })
          .then((reviews) => {
            this.reviewsWithQuotes = reviews.filter((review) => review.hasQuote());
            this.reviewsLoaded = true;
          });
      });
    },

    changePage(page) {
      this.page = page;
      this.loadLawyers();
    }
  }
};
</script>

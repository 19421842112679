<template>
  <boxed-content title="Quick Actions">
    <a v-if="lawyer.hasVisibleProfile" href="/scout-firm/my-profile" class="secondary-btn-blue bottom-15">Edit Profile</a>

    <loading-button name="loadWorkspaces" v-if="lawyerIsPartner" lb-class="secondary-btn-blue bottom-15" @lb-click="openCreateList">Create a List</loading-button>

    <button type="button" class="secondary-btn-blue text-ellipsis" @click="openSuggestClient">Suggest a New Client</button>
  </boxed-content>
</template>

<script>
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import ScoutWorkspace from 'resources/scout/scout-workspace.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import ModalManager from 'vue-app/shared/services/modal-manager.js';

export default {
  name: 'QuickActions',

  components: {
    BoxedContent,
    LoadingButton
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      workspaces: [],
      workspacesLoaded: false
    };
  },

  computed: {
    lawyerIsPartner() {
      return this.lawyer.partner;
    }
  },

  methods: {
    openSuggestClient() {
      ModalManager.open('suggest-client');
    },

    openCreateList() {
      if (!this.workspacesLoaded && this.lawyerIsPartner) {
        LoadingService.around('loadWorkspaces', () => {
          return ScoutWorkspace.query({ view: 'firm_show' }).then(workspaces => {
            this.workspaces = workspaces;
            this.workspacesLoaded = true;
            ModalManager.open('create-list', { workspaces: this.workspaces });
          });
        });
      }
      else {
        ModalManager.open('create-list', { workspaces: this.workspaces });
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="shadowed-box">
      <div class="box-content extra-padded">
        <div class="size-text-17px bold-weight bottom-5">
          Review & Send
        </div>

        <div class="bottom-30">
          Preview your RFP before sending.
        </div>

        <div v-if="quotesWithActivePartner.length" class="rfq-recipients-wrapper">
          <div class="size-text-15px bold-weight bottom-5">
            Your Firms
          </div>

          <div class="bottom-5">
            Your RFP will be sent to designated RFP managers at each firm.
          </div>

          <div class="bottom-20">
            Click the mail icon(s) below to contact a firm’s RFP managers.
          </div>

          <div v-for="lawFirmQuote in quotesWithActivePartner" :key="lawFirmQuote.id" class="rfq-recipient-item">
            <div class="row">
              <div class="col-sm-9 semibold-weight">
                <span class="right-5">{{ lawFirmQuote.firmName }}</span>

                <rfq-contact-firm-modal
                  class="inline-block"
                  :firm-name="lawFirmQuote.firmName"
                  :emails="emailAddresses(lawFirmQuote)">
                </rfq-contact-firm-modal>
              </div>

              <div class="col-sm-3 text-right-not-xs">
                <priori-modal ref="previewRfq" modal-id="preview-rfq" modal-size="lg" :title="previewModalTitle(lawFirmQuote)">
                  <template #modal-trigger="{ openModal }">
                    <a href="" class="bold-weight" @click.prevent="openModal">Preview RFP</a>
                  </template>

                  <rfq-preview :rfq="rfq" :law-firm-quote="lawFirmQuote"></rfq-preview>
                </priori-modal>
              </div>
            </div>
          </div>
        </div>

        <div v-if="rfq.sentToMarketplace" :class="['sent-to-marketplace-wrapper', { 'with-scout-firms': quotesWithActivePartner.length }]">
          <div class="row vertical-center-not-xs">
            <div class="col-sm-9">
              <div class="size-text-15px bold-weight bottom-5">
                Priori’s Marketplace Network
              </div>
              Your RFP will be sent to Priori’s Marketplace. A Priori representative will reach out with information about next steps.
            </div>

            <div class="col-sm-3 text-right-not-xs">
              <priori-modal ref="previewRfq" modal-id="preview-rfq" modal-size="lg" :title="previewModalTitle(marketplaceLawFirmQuote)">
                <template #modal-trigger="{ openModal }">
                  <a href="" class="bold-weight" @click.prevent="openModal">Preview RFP</a>
                </template>

                <rfq-preview :rfq="rfq" :law-firm-quote="marketplaceLawFirmQuote"></rfq-preview>
              </priori-modal>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row top-30">
      <div class="col-sm-4 col-md-3">
        <button type="button" class="secondary-btn-blue" @click="previous">Previous</button>
      </div>

      <div class="col-sm-4 col-sm-offset-4 col-md-3 col-md-offset-6 top-20-xs">
        <button type="button" class="primary-btn-blue" @click="submit">Send RFP</button>
      </div>
    </div>
  </div>
</template>

<script>
import RfqPreview from 'src/vue-app/scout/client/rfqs/preview.vue';
import RFQ from 'src/resources/scout/rfq.js';
import RfqContactFirmModal from 'vue-app/scout/client/rfqs/contact-firm-modal.vue';

export default {
  name: 'RfqStepReview',

  components: {
    RfqPreview,
    RfqContactFirmModal
  },

  props: {
    nextStep: {
      type: Object
    },

    prevStep: {
      type: Object
    },

    rfq: {
      type: Object,
      required: true
    }
  },

  computed: {
    quotesWithActivePartner() {
      return this.rfq.lawFirmQuotes.filter(quote => quote.firmHasRfqEnabled);
    },

    marketplaceLawFirmQuote() {
      return {
        firmName: 'Priori’s Marketplace'
      };
    }
  },

  methods: {
    submit() {
      RFQ.submit({ rfq: this.rfq })
        .then((response) => {
          window.location.href = `/scout-company/rfps?newRfq=true&rfqId=${response.data.id}&rfqSentCount=${response.data.rfqSentCount}&sentToMarketplace=${response.data.sentToMarketplace}`;
        });
    },

    previous() {
      this.prevStep.moveToPrevStep();
    },

    previewModalTitle(lawFirmQuote) {
      return `Preview RFP for ${lawFirmQuote.firmName}`;
    },

    emailAddresses(lawFirmQuote) {
      return lawFirmQuote.emailsToReceiveUpdates?.map(email => email.email);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .rfq-recipients-wrapper {
    padding: 20px 30px;
    border: 1px solid $medium-gray;
    border-radius: $border-radius-large;
    border-left: 7px solid $k-blue;
  }

  .sent-to-marketplace-wrapper {
    padding: 20px 30px;
    border: 1px solid $medium-gray;
    border-radius: $border-radius-large;
    border-left: 7px solid $purple-5;

    &.with-scout-firms {
      margin-top: 20px;
    }
  }

  .rfq-recipient-item {
    border-top: 1px solid $medium-gray;
    padding-top: 20px;
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }

    .base-icon {
      fill: $k-dark-gray;

      &:hover {
        fill: $k-darker-gray;
      }
    }
  }

  :deep(.mail-icon) {
    width: 13px;
    height: 13px;
  }
</style>

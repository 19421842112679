<template>
  <validation-observer v-slot="{ handleSubmit, failed: formInvalid }">
    <form role="form" name="rateForm" class="a-form" novalidate>
      <div class="row tight-columns">
        <div class="col-md-3 bottom-20">
          <label>Fee Type</label>

          <dropdown-select
            id-label="fee-type"
            v-model="rateCopy.rateType"
            :options="rateTypes"
            label-key="text"
            value-key="value"
            :initial-label="rateTypeText"
            :is-disabled="isEditing"
            v-if="isProjectRate">
          </dropdown-select>

          <input type="text" class="form-control" value="Hourly" disabled v-else>
        </div>

        <div class="col-sm-4 col-md-3 bottom-20">
          <label for="client-rate-dollars">Client Total</label><popover-tooltip placement="top" custom-class="tooltip-white small-text"><span class="tooltip-question left-5">?</span><template #tooltip><span class="semibold-weight">Client Total:</span> The total amount paid by the client. It is the sum of Your Rate plus the Priori Management Fee.</template></popover-tooltip>

          <validation-provider rules="required|minValue:0.01" mode="eager" v-slot="{ errors, failed }" :custom-messages="{ minValue: '* Rate must be greater than 0' }">
            <div class="input-with-icon">
              <input type="number" id="client-rate-dollars" name="clientRateDollars" :class="['form-control', { 'has-error': failed }]" min="0.01" step="0.01" required v-model="rateCopy.clientRateDollars" @input="clientRateDollarsChanged">
              <div class="form-icon">$</div>

              <div class="error-text top-5" v-if="errors.length">
                <error-message :errors="errors"></error-message>
              </div>
            </div>
          </validation-provider>
        </div>

        <div class="col-sm-4 col-md-3 bottom-20">
          <label for="lawyer-amount-dollars">Your Rate</label><popover-tooltip placement="top" custom-class="tooltip-white small-text"><span class="tooltip-question left-5">?</span><template #tooltip><span class="semibold-weight">Your Rate:</span> The amount paid by the client to you for your legal services.</template></popover-tooltip>

          <validation-provider rules="required|minValue:0.01" mode="eager" v-slot="{ errors, failed }" :custom-messages="{ minValue: '* Rate must be greater than 0' }">
            <div class="input-with-icon">
              <input type="number" id="lawyer-amount-dollars" name="lawyerAmountDollars" :class="['form-control', { 'has-error': failed }]" min="0.01" step="0.01" required v-model="rateCopy.lawyerAmountDollars" @input="lawyerAmountDollarsChanged">
              <div class="form-icon">$</div>

              <div class="error-text top-5" v-if="errors.length">
                <error-message :errors="errors"></error-message>
              </div>
            </div>
          </validation-provider>
        </div>

        <div class="col-sm-4 col-md-3 bottom-20">
          <label for="management-fee">Priori Management Fee</label><popover-tooltip placement="top" custom-class="tooltip-white small-text"><span class="tooltip-question left-5">?</span><template #tooltip><span class="semibold-weight">Priori Management Fee:</span> The amount paid by the client to Priori for the client&rsquo;s use of our platform and service. It is calculated as a percentage of Your Rate. There is no management fee payable on disbursements.</template></popover-tooltip>

          <div class="input-with-icon">
            <input type="number" id="management-fee" name="managementFee" class="form-control" :value="managementFee" disabled>
            <div class="form-icon">$</div>
          </div>
        </div>
      </div>

      <div class="size-text-12px dark-gray-text bottom-20">
        <div class="semibold-weight bottom-5">
          Important Note:
        </div>

        <div class="bottom-5">
          Priori does not share fees with attorneys. We always make clear to clients that the Client Total includes both your legal fees and our separate management fee. On payment of the Client Total, your legal fees are processed and deposited in your account and our management fee is separately processed and deposited in our account.
        </div>

        <div>
          Our payment processor (Stripe) charges processing fees to transfer money paid by clients via credit card. The processing fees are 2.9% + 30c per credit card transaction and are automatically deducted from the amount deposited in your account. Stripe does not deduct any processing fees from amounts paid by clients via bank transfer or ACH. Please factor this in when creating your rate.
        </div>
      </div>

      <div class="bottom-20" v-if="isProjectRate">
        <label for="rate-name">Rate Name</label>

        <validation-provider :rules="{ required: true, excluded: existingRateNames }" mode="eager" v-slot="{ errors, failed }" :custom-messages="{ excluded: '* There’s already a rate with that name in this project. Please choose a different name for this rate.' }">
          <input type="text" id="rate-name" name="rateName" :class="['form-control', { 'has-error': failed }]" placeholder="Ex: Project Rate" required v-model="rateCopy.rateName">

          <div class="error-text top-5" v-if="errors.length">
            <error-message :errors="errors"></error-message>
          </div>
        </validation-provider>
      </div>

      <div class="bottom-30" v-if="isProjectRate">
        <label for="rate-description">Description <span class="normal-weight">(optional)</span></label>

        <input type="text" id="rate-description" name="rateDescription" class="form-control" placeholder="Enter description of this rate" v-model="rateCopy.rateDescription">
      </div>

      <div class="bottom-20">
        <hr class="kenny-hr no-margin">
      </div>

      <div class="bottom-40">
        <platform-rates></platform-rates>
      </div>

      <div class="row">
        <div class="col-sm-5 col-md-4">
          <button type="button" class="nv-button-blue a-button-size" @click="handleSubmit(submit)">{{ isEditing || !isProjectRate ? 'Save Changes' : 'Add Rate' }}</button>
        </div>

        <div class="col-sm-5 col-md-4 top-20-xs">
          <button type="button" class="nv-button-white a-button-size" @click="cancel">Cancel</button>
        </div>
      </div>

      <div class="error-text top-10" v-if="formInvalid">
        * One or more fields are invalid. Please fix errors as indicated above.
      </div>
    </form>
  </validation-observer>
</template>

<script>
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import PopoverTooltip from 'vue-app/shared/components/popover-tooltip.vue';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import PlatformRates from './platform-rates.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { clone, find, floor, map, round } from 'lodash';

export default {
  name: 'RateForm',

  components: {
    DropdownSelect,
    PopoverTooltip,
    ErrorMessage,
    PlatformRates,
    ValidationObserver,
    ValidationProvider
  },

  props: {
    rate: {
      type: Object,
      required: true
    },

    managementFeeMultiplier: {
      type: Number,
      required: true
    },

    isProjectRate: {
      type: Boolean,
      default: false
    },

    rateTypes: {
      type: Array,
      default: () => []
    },

    allProjectRates: {
      type: Array,
      default: () => []
    },

    isEditing: {
      type: Boolean,
      default: false
    },

    onCancel: {
      type: Function,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      rateCopy: clone(this.rate)
    };
  },

  computed: {
    rateTypeText() {
      return find(this.rateTypes, { value: this.rateCopy.rateType })?.text;
    },

    managementFee() {
      return round(this.rateCopy.clientRate - this.rateCopy.lawyerAmount) / 100;
    },

    otherProjectRates() {
      return this.allProjectRates.filter((rate) => rate.uid !== this.rateCopy.uid);
    },

    existingRateNames() {
      return map(this.otherProjectRates, 'rateName');
    }
  },

  methods: {
    clientRateDollarsChanged() {
      const dollars = floor(this.rateCopy.clientRateDollars, 2);

      if (!dollars) {
        this.clearRates();
        return;
      }

      this.rateCopy.clientRateDollars = dollars;
      this.rateCopy.clientRate = dollars * 100;

      this.rateCopy.lawyerAmount = round(this.rateCopy.clientRate / this.managementFeeMultiplier);
      this.rateCopy.lawyerAmountDollars = this.rateCopy.lawyerAmount / 100;
    },

    lawyerAmountDollarsChanged() {
      const dollars = floor(this.rateCopy.lawyerAmountDollars, 2);

      if (!dollars) {
        this.clearRates();
        return;
      }

      this.rateCopy.lawyerAmountDollars = dollars;
      this.rateCopy.lawyerAmount = dollars * 100;

      this.rateCopy.clientRate = round(this.rateCopy.lawyerAmount * this.managementFeeMultiplier);
      this.rateCopy.clientRateDollars = this.rateCopy.clientRate / 100;
    },

    clearRates() {
      this.rateCopy.clientRate = null;
      this.rateCopy.clientRateDollars = null;
      this.rateCopy.lawyerAmount = null;
      this.rateCopy.lawyerAmountDollars = null;
    },

    cancel() {
      this.onCancel();
    },

    submit() {
      this.onSubmit(this.rateCopy);
    }
  }
};
</script>

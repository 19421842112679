<template>
  <span v-if="name" class="user-pic-container">
    <span :class="['missing-photo text-uppercase', missingPhotoClass]">{{ nameInitials }}</span>
  </span>

  <span v-else :class="['user-pic-container', { 'editing': showEdit }]" @mouseenter="toggleEditOn()" @mouseleave="toggleEditOff()">
    <span class="edit-text" v-if="showEdit">Edit</span>

    <img :src="user.photoLarge || user.photoMedium" alt="Photo" :class="['user-pic', photoClass]" v-if="user.photoLarge || user.photoMedium">

    <span :class="['missing-photo with-icon', missingPhotoClass]" v-if="!user.photoLarge && !user.photoMedium"><svg-icon name="default-profile"></svg-icon></span>
  </span>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { keys, pickBy } from 'lodash';

export default {
  name: 'ScoutProfilePhoto',

  components: {
    SvgIcon
  },

  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    name: String,
    smallest: Boolean,
    small: Boolean,
    medium: Boolean,
    larger: Boolean,
    editable: Boolean
  },

  data() {
    return {
      showEdit: false
    };
  },

  computed: {
    nameInitials() {
      return this.name[0].toUpperCase();
    },

    missingPhotoClass() {
      const classMap = {
        'smallest': this.smallest,
        'small': this.small,
        'medium': this.medium,
        'larger': this.larger
      };

      return keys(pickBy(classMap))[0];
    },

    photoClass() {
      const classMap = {
        'smallest-pic': this.smallest,
        'small-pic': this.small,
        'medium-pic': this.medium,
        'larger-pic': this.larger
      };

      return keys(pickBy(classMap))[0];
    }
  },

  methods: {
    // TODO: haven't tested the editing yet, not sure this works
    toggleEditOn() {
      if (this.editable) {
        this.showEdit = true;
      }
    },

    toggleEditOff() {
      if (this.editable) {
        this.showEdit = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .missing-photo {
    box-shadow: 0 0 8px 0 rgba($black, .1);
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $bg-color;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    cursor: default;
    user-select: none;
    vertical-align: middle;

    &.with-icon {
      padding: 0;
    }

    &.larger {
      width: 80px;
      height: 80px;
      font-size: 30px;
      line-height: 30px;
    }

    &.medium {
      width: 50px;
      height: 50px;
    }

    &.small {
      width: 40px;
      height: 40px;
      font-size: 17px;
      line-height: 18px;
    }

    &.smallest {
      width: 30px;
      height: 30px;
      font-size: 8px;
      font-weight: 600;
      line-height: 11px;
    }
  }
</style>

<template>
  <div>
    <div class="titlebar">
      <div class="medium-page-heading">
        Admin Settings
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-8">
              <div class="bottom-30">
                <users-summary :workspace="workspace"></users-summary>
              </div>

              <div>
                <scout-tag-editing-panel
                  :scout-tags="scoutTags"
                  :load-scout-tags="loadScoutTags"
                  :on-order-updated="reorderScoutTags">
                </scout-tag-editing-panel>
              </div>

              <div class="top-30" v-if="!isFeatureLab">
                <rfp-settings
                  :workspace="workspace"
                  :update-workspace="updateWorkspace">
                </rfp-settings>
              </div>

              <div class="top-30" v-if="!isFeatureLab">
                <marketplace-search-settings :allow-marketplace="workspace.allowMarketplace"></marketplace-search-settings>
              </div>

              <div class="top-30" v-if="!isFeatureLab">
                <review-reminder-email-settings
                  :allow-emails="workspace.hasReviewReminderEmailsEnabled"
                  :allow-disable-emails="workspace.canDisableReviewReminderEmails">
                </review-reminder-email-settings>
              </div>

              <div class="top-30" v-if="!isFeatureLab">
                <search-filters
                  :lawyer-filters="lawyerFilters"
                  :firm-filters="firmFilters">
                </search-filters>
              </div>
            </div>

            <div class="col-sm-4 top-30-xs" v-if="!isFeatureLab">
              <e-billing-upload></e-billing-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy }                 from 'lodash';
import { mapActions }              from 'pinia';
import EBillingUpload              from 'vue-app/scout/client/settings/e-billing-upload.vue';
import LoadingService              from 'vue-app/shared/services/loading-service';
import MarketplaceSearchSettings   from 'vue-app/scout/client/settings/marketplace-search-settings.vue';
import ReviewReminderEmailSettings from 'vue-app/scout/client/settings/review-reminder-email-settings.vue';
import RfpSettings                 from 'vue-app/scout/client/settings/rfp-settings.vue';
import ScoutTag                    from 'vue-app/scout/resources/scout-tag.js';
import ScoutTagEditingPanel        from 'vue-app/scout/client/settings/scout-tag-editing-panel.vue';
import ScoutWorkspace              from 'resources/scout/scout-workspace.js';
import SearchFilters               from 'vue-app/scout/client/settings/search-filters.vue';
import UsersSummary                from 'vue-app/scout/client/settings/users-summary.vue';

import useCurrentUserStore  from 'vue-app/stores/current-user.js';

export default {
  name: 'ScoutClientSettingsIndex',

  components: {
    EBillingUpload,
    MarketplaceSearchSettings,
    ReviewReminderEmailSettings,
    RfpSettings,
    ScoutTagEditingPanel,
    SearchFilters,
    UsersSummary
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      firmFilters: this.initData.firmFilters,
      lawyerFilters: this.initData.lawyerFilters,
      workspace: this.initData.workspace,
      scoutTags: this.initData.scoutTags
    };
  },

  computed: {
    isFeatureLab() {
      return this.workspace.isFeatureLab;
    }
  },

  methods: {
    ...mapActions(useCurrentUserStore, { refetchCurrentUser: 'refetchCurrentUser' }),

    loadScoutTags() {
      LoadingService.loading('scoutTags');

      return ScoutTag.query().then((scoutTags) => {
        this.scoutTags = orderBy(scoutTags, 'position');
        LoadingService.done('scoutTags');
      });
    },

    reorderScoutTags(scoutTags) {
      return ScoutTag.order({ order: scoutTags }).then((scoutTags) => {
        this.scoutTags = scoutTags;
      });
    },

    updateWorkspace(workspace) {
      LoadingService.loading('workspace');
      return ScoutWorkspace.update({ id: workspace.id, workspace: workspace, view: 'settings' }).then(workspace => {
        this.workspace = workspace;
        this.refetchCurrentUser();
        LoadingService.done('workspace');
      });
    }
  }
};
</script>

<template>
  <div class="messaging-action-bar size-text-11px">
    <div class="row">
      <div class="col-xs-7 col-sm-8">
        <div v-show="!isRefreshing" class="pointer svg-button" @click="onRefreshMessaging">
          <div class="text-left size-text-11px">
            <svg-icon name="refresh" class="base-icon smaller right-5"></svg-icon><span class="semibold-weight">Refresh Messages</span>
          </div>
        </div>

        <div v-show="isRefreshing">
          <svg-icon name="refresh" class="base-icon smaller right-5"></svg-icon><span class="semibold-weight">Loading...</span>
        </div>
      </div>

      <div class="col-xs-5 col-sm-4">
        <div class="svg-button">
          <div class="text-right">
            <span class="size-text-11px" @click="onCloseMessaging">Close Window</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ActionBar',

  components: {
    SvgIcon
  },

  props: {
    isRefreshing: {
      type: Boolean,
      required: true
    },

    onRefreshMessaging: {
      type: Function,
      required: true
    },

    onCloseMessaging: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .messaging-action-bar {
    padding: 15px 20px;
    background: $k-lighter-gray;

    @media (min-width: $screen-sm-min) {
      padding: 15px 30px;
    }
  }
</style>

<template>
  <div class="vertical-center scout-tag-row">
    <div class="scout-tag-editing-col">
      <div class="row tight-columns semibold-weight">
        <div class="col-xs-7 col-sm-8">
          <span class="vertical-middle right-10"><svg-icon name="drag-and-drop" class="base-icon"></svg-icon></span>
          <span class="vertical-middle">{{ scoutTag.position }}.<scout-tag class="left-5" :scout-tag="scoutTag"></scout-tag></span>
        </div>

        <div class="col-xs-5 col-sm-4 text-right">
          <scout-tag-editing-modal :scout-tag="scoutTag" :scout-tags="scoutTags" :on-saved="onSaved"></scout-tag-editing-modal>
        </div>
      </div>
    </div>

    <scout-tag-delete-modal class="left-20" :scout-tag="scoutTag" :on-delete="onDelete"></scout-tag-delete-modal>
  </div>
</template>

<script>
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';
import ScoutTagEditingModal from './scout-tag-editing-modal.vue';
import ScoutTagDeleteModal from 'vue-app/scout/client/settings/scout-tag-delete-modal.vue';

export default {
  name: 'ScoutTagEditingRow',

  components: {
    ScoutTag,
    ScoutTagEditingModal,
    ScoutTagDeleteModal
  },

  props: {
    scoutTag: {
      type:     Object,
      required: true
    },

    scoutTags: {
      type: Array,
      default: () => []
    },

    onSaved: {
      type: Function,
      required: true
    },

    onDelete: {
      type:     Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .scout-tag-row {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
  }

  .scout-tag-editing-col {
    width: 100%;
    padding: 15px 10px;
    border: 1px solid $k-gray;
    border-radius: $border-radius-base;
    line-height: 1.3;

    @media (min-width: 768px) {
      padding: 15px 20px 15px 15px;
    }
  }
</style>

<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Project Billing Details
    </div>

    <div :class="{ 'box-content': isLoading }">
      <loading-section name="invoices">
        <div class="row matter-detail-summary semibold-weight">
          <div class="col-sm-3">
            <div class="size-text-11px dark-gray-text bottom-5">
              Estimated Project Cost
            </div>

            <div v-if="matter.lowerFeeEstimate">
              {{ formatCurrency(matter.lowerFeeEstimate) }}
              <span v-if="showUpperEstimate()"> &mdash; {{ formatCurrency(matter.upperFeeEstimate) }}</span>
            </div>

            <div v-if="!matter.lowerFeeEstimate">
              N/A
            </div>
          </div>

          <div class="col-sm-3">
            <div class="size-text-11px green-text bottom-5">
              Written Off
            </div>

            <div class="green-text">
              {{ formatCurrency(summaryTotals.writtenOff / 100.0) }}
            </div>
          </div>

          <div class="col-sm-3">
            <div class="size-text-11px dark-gray-text bottom-5">
              Retainer Remaining
            </div>

            <div>
              {{ formatCurrency(summaryTotals.retainerRemaining / 100.0) }}
            </div>
          </div>

          <div class="col-sm-3">
            <div class="size-text-11px dark-gray-text bottom-5">
              Total Paid
            </div>

            <div>
              <span class="right-5">{{ formatCurrency(summaryTotals.totalPaid / 100.0) }}</span>
              <project-payments-modal :payments="payments" class="inline-block"></project-payments-modal>
            </div>
          </div>
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import ProjectPaymentsModal from 'vue-app/marketplace/client/matters/project-payments-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';
import { each } from 'lodash';

export default {
  name: 'BillingSummary',

  components: {
    ProjectPaymentsModal,
    LoadingSection
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    invoices: {
      type: Array,
      default: () => []
    },

    payments: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      summaryTotals: {
        writtenOff: 0,
        retainerRemaining: 0,
        totalPaid: 0
      }
    };
  },

  computed: {
    isLoading() {
      return LoadingService.isLoading('invoices');
    }
  },

  watch: {
    invoices() {
      this.computeSummaryTotals();
    }
  },

  methods: {
    formatCurrency(amount) {
      return CurrencyFilter.filter(amount);
    },

    computeSummaryTotals() {
      each(this.invoices, (invoice) => {
        if (invoice.isCancelled()) {
          return;
        }
        else if (invoice.isWrittenOff()) {
          each(invoice.invoiceLineItems, (li) => {
            this.summaryTotals.writtenOff += li.renderedService.invoiceAmount;
          });
        }
        else {
          if (invoice.paid) {
            this.summaryTotals.totalPaid += invoice.total;
          }

          each(invoice.invoiceLineItems, (li) => {
            if ((invoice.paid && li.renderedService.type === 'retainer') || li.renderedService.type === 'prepaid_realization') {
              this.summaryTotals.retainerRemaining += li.amount;
            }

            if (li.renderedService.free) {
              this.summaryTotals.writtenOff += li.renderedService.invoiceAmount;
            }
            else if (li.renderedService.invoiceAdjustmentAmount) {
              this.summaryTotals.writtenOff -= li.renderedService.invoiceAdjustmentAmount;
            }
          });
        }
      });
    },

    showUpperEstimate() {
      return this.matter.upperFeeEstimate && this.matter.lowerFeeEstimate !== this.matter.upperFeeEstimate;
    }
  }
};
</script>

<template>
  <loading-section name="lawyerSidebar">
    <div class="shadowed-box lawyer-profile-sidebar">
      <div class="box-content">
        <div v-if="address">
          <div class="row tight-columns">
            <div class="col-xs-2 col-sm-1 col-md-2">
              <svg-icon name="location" class="base-icon"></svg-icon>
            </div>

            <div class="col-xs-10 col-sm-11 col-md-10 semibold-weight text-ellipsis">
              {{ addressDisplay }}
            </div>
          </div>

          <hr class="kenny-hr">
        </div>

        <div v-if="shouldShowYearsExperience">
          <div class="row tight-columns">
            <div class="col-xs-2 col-sm-1 col-md-2">
              <svg-icon name="experience" class="base-icon"></svg-icon>
            </div>

            <div class="col-xs-10 col-sm-11 col-md-10 text-ellipsis">
              <span class="semibold-weight">Years&rsquo; Experience:</span> {{ yearsExperience }}
            </div>
          </div>

          <hr class="kenny-hr">
        </div>

        <div class="row tight-columns">
          <div class="col-xs-2 col-sm-1 col-md-2">
            <svg-icon name="ribbon" class="base-icon"></svg-icon>
          </div>

          <div class="col-xs-10 col-sm-11 col-md-10 text-ellipsis">
            <span id="bars-list">
              <span class="semibold-weight">Bars:</span> {{ barAdmissionsList }}
              <court-admissions-modal :court-admissions="courtAdmissions" v-if="courtAdmissions.length"></court-admissions-modal>
            </span>

            <tooltip :text="barAdmissionsList" target="#bars-list" placement="bottom" custom-class="k-tooltip" append-to="body"></tooltip>
          </div>
        </div>

        <div v-if="shouldShowRate">
          <hr class="kenny-hr">

          <div class="row tight-columns">
            <div class="col-xs-2 col-sm-1 col-md-2">
              <svg-icon name="rate" class="base-icon"></svg-icon>
            </div>

            <div class="col-xs-10 col-sm-11 col-md-10 text-ellipsis">
              <span class="semibold-weight">{{ shouldUseMatterRate ? 'Project Rate:' : 'Priori Rate:' }}</span>

              <span class="green-text" v-if="viewingAsClient">{{ profileRateDollars }}{{ profileRate.tag() }}</span>
              <span v-else>
                <button type="button" class="pseudo-link-blue green-text" @click="openRateModal">
                  <span v-if="profileRate.dollars">{{ profileRateDollars }}{{ profileRate.tag() }}</span>
                  <span v-else>Add Rate</span>
                </button>

                <popover-tooltip custom-class="tooltip-white small-text">
                  <span class="tooltip-question left-5">?</span>

                  <template #tooltip>
                    <span class="semibold-weight">Priori Rate:</span> This is the indicative rate that will be displayed to prospective clients on your Priori profile. It is the sum of your rate plus the Priori Management Fee.
                  </template>
                </popover-tooltip>
              </span>
            </div>
          </div>
        </div>

        <div v-if="shouldShowSection('malpractice_insurance') || !viewingAsClient">
          <hr class="kenny-hr">

          <div class="row tight-columns">
            <div class="col-xs-2 col-sm-1 col-md-2">
              <svg-icon name="documents" class="base-icon"></svg-icon>
            </div>

            <div class="col-xs-10 col-sm-11 col-md-10 text-ellipsis">
              <span class="semibold-weight">Malpractice Insurance:</span>
              <span v-if="viewingAsClient">{{ malpracticeInsurancePresenceCopy }}</span>
              <a href="/lawyer-app/malpractice-insurance" v-else>{{ malpracticeInsurancePresenceCopy }}</a>
            </div>
          </div>
        </div>

        <hr class="kenny-hr">

        <div class="row tight-columns">
          <div class="col-xs-2 col-sm-1 col-md-2">
            <svg-icon name="clients" class="base-icon"></svg-icon>
          </div>

          <div class="col-xs-10 col-sm-11 col-md-10 text-ellipsis">
            <span id="languages-list">
              <span class="semibold-weight">Languages:</span> {{ languagesList }}
            </span>

            <tooltip :text="languagesList" target="#languages-list" placement="bottom" custom-class="k-tooltip" append-to="body"></tooltip>
          </div>
        </div>
      </div>

      <div class="box-footer text-center" v-if="!viewingAsClient">
        <button type="button" class="pseudo-link-blue semibold-weight size-text-13px" @click="onEditProfile('sidebar')">Edit Section</button>
      </div>
    </div>
  </loading-section>
</template>

<script>
import CourtAdmissionsModal from './court-admissions-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import PopoverTooltip from 'vue-app/shared/components/popover-tooltip.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ModalManager from 'vue-app/shared/services/modal-manager.js';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';
import SharedLists from 'vue-app/shared/services/shared-lists-service';
import RatePackage from 'resources/marketplace/rate-package';
import { map } from 'lodash';

export default {
  name: 'LawyerProfileSidebar',

  components: {
    CourtAdmissionsModal,
    LoadingSection,
    PopoverTooltip,
    SvgIcon
  },

  props: {
    lawyerProfile: {
      type: Object,
      required: true
    },

    possibleLawyer: {
      type: Object,
      required: false
    },

    baseRateDollars: {
      type: Number,
      required: true
    },

    isLawyerAccount: {
      type: Boolean,
      required: true
    },

    viewingAsClient: {
      type: Boolean,
      required: true
    },

    onEditProfile: {
      type: Function,
      required: true
    },

    onSaveRate: {
      type: Function,
      required: true
    },

    // TODO: this is only included to keep the Angular version of the profile working (used for the lawyer app). Not needed and can be removed when we switch the lawyer side to Vue.
    onOpenRateModal: {
      type: Function,
      required: false
    }
  },

  computed: {
    address() {
      return (this.lawyerProfile.addressesAttributes || [])[0];
    },

    stateOrCountry() {
      return this.address.country === 'United States' ? SharedLists.stateAbbreviationFromName(this.address.state) : this.address.country;
    },

    addressDisplay() {
      return [this.address.city, this.stateOrCountry].join(', ');
    },

    shouldShowYearsExperience() {
      return this.isLawyerAccount;
    },

    yearsExperience() {
      return this.lawyerProfile.yearsExperience;
    },

    barAdmissionsList() {
      return map(this.lawyerProfile.barAdmissionsAttributes, (ba) => {
        return SharedLists.stateAbbreviationFromName(ba.state) || ba.country;
      }).join(', ');
    },

    courtAdmissions() {
      return  this.lawyerProfile.courtAdmissionsAttributes || [];
    },

    shouldShowRate() {
      return this.shouldShowSection('rates') &&
        (this.profileRate.dollars !== 0 || !this.viewingAsClient);
    },

    shouldUseMatterRate() {
      return this.possibleLawyer &&
        (this.possibleLawyer.rateSent() || this.possibleLawyer.selected());
    },

    matterRate() {
      const matterRatePackage = this.possibleLawyer.matter.matterRatePackages[0];

      return new RatePackage({
        type: matterRatePackage.rateType,
        dollars: matterRatePackage.clientRateDollars
      });
    },

    proposalRate() {
      return this.possibleLawyer.visibleRate();
    },

    lawyerBaseRate() {
      return new RatePackage({ type: 'hourly', dollars: this.baseRateDollars });
    },

    profileRate() {
      if (this.shouldUseMatterRate) {
        return this.matterRate;
      }
      else if (this.possibleLawyer) {
        return this.proposalRate;
      }
      else {
        return this.lawyerBaseRate;
      }
    },

    profileRateDollars() {
      return CurrencyFilter.filter(this.profileRate.dollars);
    },

    malpracticeInsurancePresenceCopy() {
      return this.lawyerProfile.malpracticeInsurancePolicy?.hasSome ? 'Yes' : 'No';
    },

    languagesList() {
      const languageNames = map(this.lawyerProfile.languagesAttributes, 'name');
      return ['English'].concat(languageNames).join(', ');
    }
  },

  methods: {
    shouldShowSection(section) {
      return !this.possibleLawyer || this.possibleLawyer.displaySections.indexOf(section) >= 0;
    },

    openRateModal() {
      // TODO: remove the 'if' part of this conditional when we remove the Angular version of the profile
      if (this.onOpenRateModal) {
        this.onOpenRateModal();
      }
      else {
        ModalManager.open('indicativeRate', {
          indicativeRate: this.lawyerProfile.indicativeRate || 0,
          onSaveRate: this.onSaveRate
        });
      }
    }
  }
};
</script>

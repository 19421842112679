<template>
  <div class="row tight-columns vertical-center-not-xs">
    <div class="col-sm-10">
      <div class="inline-block right-20-not-xs">
        <profile-photo :user="lawyer" :larger="true"></profile-photo>
      </div>

      <div class="text-img-adjacent">
        <div class="medium-page-heading text-ellipsis">
          {{ lawyer.fullName }}
        </div>

        <div :class="['size-text-16px text-ellipsis', { 'text-italic': isGhost }]">
          {{ lawyer.firmTitle }}
          <span v-if="hasUsableFirmName"><span v-if="hasFirmTitle">at </span><a :href="`/scout-company/law-firms/${lawyer.lawFirmBridgeId}`">{{ lawyer.firmName }}</a></span>
        </div>

        <div class="top-5">
          <rating-count-plate
            :score="recommendationScore"
            :max-score="10"
            :count="lawyer.reviewsSummary.count"
            unit="review">
          </rating-count-plate>
        </div>
      </div>
    </div>

    <div v-if="lawyer.hasVisibleProfile" class="col-sm-2 top-30-xs text-center d-flex flex-end-not-xs">
      <div class="text-center">
        <div class="center-block bottom-5">
          <button type="button" class="round-button" @click.prevent="openModal">
            <svg-icon name="plus" class="base-icon"></svg-icon>
            <priori-modal title="Add To List" modal-id="add-to-list" ref="modal" :prompt-hidden="true">
              <form-manage-list
                :resource-lists="resourceLists"
                :on-submit="onManageList"
                :on-success="formManageListSuccess">
              </form-manage-list>
            </priori-modal>
          </button>
        </div>
        Add to List
      </div>

      <div class="text-center left-15">
        <div class="center-block bottom-5">
          <favorite-button
            :toggle-func="toggleFavorite"
            :is-favorite="isFavorited">
          </favorite-button>
        </div>
        Favorite
      </div>
    </div>

    <scout-success-list-modal
      :show-success-modal="showModal"
      :is-lawyer="true">
    </scout-success-list-modal>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/scout/shared/profile-photo.vue';
import RatingCountPlate from 'vue-app/scout/shared/rating-count-plate.vue';
import FavoriteButton from 'vue-app/scout/shared/favorite-button.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import FavoriteLawyer from 'vue-app/scout/resources/favorite-lawyer.js';
import FormManageList from 'vue-app/scout/shared/form-manage-list.vue';
import ScoutSuccessListModal from 'vue-app/scout/client/searches/success-list-modal.vue';
import { includes, without } from 'lodash';

export default {
  name: 'LawyerHeader',

  components: {
    ProfilePhoto,
    RatingCountPlate,
    FavoriteButton,
    PrioriModal,
    FormManageList,
    ScoutSuccessListModal
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    currentClient: {
      type: Object,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    },

    onManageList: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      showModal: false
    };
  },

  computed: {
    isGhost() {
      return this.lawyer.isBillingLawyer();
    },

    hasUsableFirmName() {
      return this.lawyer.firmName &&
        this.lawyer.firmName.length &&
        this.lawyer.firmName !== this.lawyer.fullName;
    },

    hasFirmTitle() {
      return this.lawyer.firmTitle && this.lawyer.firmTitle.length;
    },

    recommendationScore() {
      return this.lawyer.reviewsSummary.scoreAverages.find(score => score.field === 'recommendation')?.score || 0;
    },

    isFavorited() {
      return includes(this.currentClient.favoriteLawyers, this.lawyer.id);
    }
  },

  methods: {
    toggleFavorite() {
      if (this.isFavorited) {
        FavoriteLawyer.delete({ lawyer_bridge_id: this.lawyer.id });
        this.currentClient.favoriteLawyers = without(this.currentClient.favoriteLawyers, this.lawyer.id);
      }
      else {
        FavoriteLawyer.save({ lawyer_bridge_id: this.lawyer.id });
        this.currentClient.favoriteLawyers.push(this.lawyer.id);
      }
    },

    formManageListSuccess() {
      this.$refs.modal.dismissModal();
      this.showModal = true;
    },

    openModal() {
      this.$refs.modal.openModal();
    }
  }
};
</script>

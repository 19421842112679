<template>
  <div>
    <div class="shadowed-box">
      <div class="box-content extra-padded">
        <div class="size-text-17px bold-weight bottom-5">
          Customize Questions for Recipients
        </div>

        <div class="bottom-30">
          Select which questions you would like RFP recipients to answer regarding your project.
        </div>

        <div class="list-spacing-30" v-for="group in orderedQuestionGroups" :key="group.id">
          <div class="size-text-15px bold-weight bottom-10">
            {{ group.name }}
          </div>

          <rfq-question-item
            v-for="question in orderedQuestionsFor(group)"
            :key="question.id"
            class="list-spacing-10"
            :question="question"
            :rfq="rfq"
            :on-update-question="updateQuestion"
            :on-delete-question="deleteQuestion">
          </rfq-question-item>

          <rfq-question-form
            class="top-10"
            :rfq="rfq"
            :question-group-id="group.id"
            :is-editing="false"
            :on-add-question="addQuestion">
          </rfq-question-form>
        </div>
      </div>
    </div>

    <div class="row top-30">
      <div class="col-sm-4 col-md-3">
        <button type="button" class="secondary-btn-blue" @click="previous">Previous</button>
      </div>

      <div class="col-sm-4 col-sm-offset-4 col-md-3 col-md-offset-6 top-20-xs">
        <button type="button" class="primary-btn-blue" :disabled="!formIsValid" @click="next">Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
import RfqQuestionItem from 'vue-app/scout/client/rfqs/create/question-item.vue';
import RfqQuestionForm from 'vue-app/scout/client/rfqs/create/question-form.vue';
import RFQQuestion from 'resources/scout/rfq-question.js';
import { orderBy } from 'lodash';

export default {
  name: 'RfqStepQuestions',

  components: {
    RfqQuestionItem,
    RfqQuestionForm
  },

  props: {
    nextStep: {
      type: Object
    },

    prevStep: {
      type: Object
    },

    rfq: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  computed: {
    orderedQuestionGroups() {
      return orderBy(this.rfq.questionGroups, 'position');
    },

    formIsValid() {
      return this.isAtLeastOneQuestionIncluded;
    },

    isAtLeastOneQuestionIncluded() {
      // Check if at least one question has isIncluded set to true
      return this.rfq.questionGroups.some(group => {
        if (group.questions && Array.isArray(group.questions)) {
          return group.questions.some(question => question.isIncluded === true && !question.prerequisiteQuestionId);
        }
        return false;
      });
    }
  },

  methods: {
    orderedQuestionsFor(group) {
      return orderBy(group.questions.filter(q => !q.prerequisiteQuestionId), 'position');
    },

    groupFor(question) {
      return this.rfq.questionGroups.find(g => g.id === question.scoutRfqQuestionGroupId);
    },

    addQuestion(question) {
      return RFQQuestion.save({ rfqId: this.rfq.id, rfqQuestion: question }).then((response) => {
        this.groupFor(question).questions.push(response);
        return response;
      });
    },

    updateQuestion(question) {
      return RFQQuestion.update({ rfqId: this.rfq.id, id: question.id, rfqQuestion: question }).then((response) => {
        const group = this.groupFor(question);
        const index = group.questions.findIndex(q => q.id === question.id);
        group.questions.splice(index, 1, response);
      });
    },

    deleteQuestion(question) {
      return RFQQuestion.delete({ rfqId: this.rfq.id, id: question.id }).then(() => {
        const group = this.groupFor(question);
        const index = group.questions.findIndex(q => q.id === question.id);
        group.questions.splice(index, 1);
      });
    },

    next() {
      this.nextStep.moveToNextStep();
    },

    previous() {
      this.prevStep.moveToPrevStep();
    }
  }
};
</script>

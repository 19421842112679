<template>
  <dropdown ref="dropdown" id="idLabel" tag="div" class="k-dropdown" v-model="dropdownOpen" :not-close-elements="dropdownElements">
    <button type="button" role="button" class="dropdown-toggle placeholder">
      <span>Select all that apply</span>
      <svg-icon name="caret-down" class="caret-down"></svg-icon>
      <svg-icon name="caret-up" class="caret-up"></svg-icon>
    </button>

    <template #dropdown>
      <div class="menu-item-container">
        <div class="keyword-search-container bottom-20" v-if="showTypeahead">
          <input type="text" name="filter-query" class="form-control keyword-search-input" placeholder="Search..." autocomplete="off" v-model="query">

          <a href="" ref="clearButton" class="keyword-search-icon clear-icon" @click.prevent="resetQuery" v-show="query.length"><svg-icon name="x3" class="base-icon"></svg-icon></a>

          <svg-icon name="search" class="base-icon keyword-search-icon" v-if="!query.length"></svg-icon>
        </div>

        <div v-uiv-scrollable-dropdown="dropdownOpen">
          <div v-show="query.length > 0">
            <label
              role="menuitem"
              class="check-option vertical medium-large"
              :for="`options-${index}-${idLabel}`"
              v-for="(option, index) in queryMatches"
              :key="option">
              <span :class="optionClassFn(option)">{{ option }}</span>
              <input
                :id="`options-${index}-${idLabel}`"
                type="checkbox"
                :name="`options-${index}-${idLabel}`"
                :value="option"
                v-model="cachedSelections"
                @change="emitChanges()">
              <span class="check c-box"></span>
            </label>
          </div>

          <div v-show="query.length === 0">
            <div v-if="queryMatches.length > 0">
              <label
                role="menuitem"
                class="check-option vertical medium-large"
                :for="`selected-${index}-${idLabel}`"
                v-for="(option, index) in cachedSelections"
                :key="option">
                <span :class="optionClassFn(option)">{{ option }}</span>
                <input
                  :id="`selected-${index}-${idLabel}`"
                  type="checkbox"
                  :name="`selected-${index}-${idLabel}`"
                  :value="option"
                  v-model="cachedSelections"
                  @change="emitChanges()">
                <span class="check c-box"></span>
              </label>
            </div>

            <div class="top-15" v-if="value.length > 0 && unselectedOptions.length > 0">
              <hr class="no-margin">
            </div>

            <div :class="{ 'top-15': value.length }" v-if="unselectedOptions.length > 0">
              <label
                role="menuitem"
                class="check-option vertical medium-large"
                :for="`available-${index}-${idLabel}`"
                v-for="(option, index) in unselectedOptions"
                :key="option">
                <span :class="optionClassFn(option)">{{ option }}</span>
                <input
                  :id="`available-${index}-${idLabel}`"
                  type="checkbox"
                  :name="`available-${index}-${idLabel}`"
                  :value="option"
                  v-model="cachedSelections"
                  @change="emitChanges()">
                <span class="check c-box"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>
  </dropdown>
</template>

<script>
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown.js';
import { clone, filter, includes, isEqual, uniqueId } from 'lodash';

export default {
  name: 'MultiSelectDropdown',

  directives: {
    uivScrollableDropdown
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    options: {
      type: Array,
      required: true
    },

    showTypeahead: {
      type: Boolean,
      default: true
    },

    optionClassFn: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      query: '',
      idLabel: uniqueId(),
      dropdownOpen: false,
      dropdownElements: [],
      cachedSelections: clone(this.value)
    };
  },

  computed: {
    queryMatches() {
      return filter(this.options, (option) => { return includes(option.toLowerCase(), this.query.toLowerCase()); });
    },

    unselectedOptions() {
      return filter(this.options, (option) => { return !includes(this.cachedSelections, option); });
    }
  },

  watch: {
    dropdownOpen() {
      if (!this.dropdownOpen) {
        this.resetQuery();
        this.$emit('blur');
      }
    }
  },

  mounted() {
    this.dropdownElements.push(this.$refs.dropdown.$el);

    if (this.$refs.clearButton) {
      this.dropdownElements.push(this.$refs.clearButton);
    }
  },

  updated() {
    // Only reset if the value and the cached value are different.
    // Otherwise, this can enter into an infinite loop.
    if (!isEqual(this.cachedSelections, this.value)) {
      this.cachedSelections = clone(this.value);
    }
  },

  methods: {
    resetQuery() {
      this.query = '';
    },

    emitChanges() {
      this.$emit('change', this.cachedSelections);
    }
  }
};
</script>

<style lang="scss" scoped>
  .menu-item-container {
    padding: 20px;

    label {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>

import BaseResource from 'resources/base-resource';

class LawyerProfile extends BaseResource {
  static baseUrl = '/lawyer_profiles/:id.json';

  concatRepresentativeClientsAndMatters() {
    const repClients = this.representativeClientsAttributes;
    const repMatters = this.representativeMattersAttributes;

    this.representativeClientsAndMatters = repClients.concat(repMatters);
    this.hasRepresentativeClientsAndMatters = !!this.representativeClientsAndMatters.length;
  }
}

export default LawyerProfile;

<template>
  <label :class="['check-option' ,'vertical', `${size}`]" :for="id">
    <span class="normal-weight"><slot></slot></span>
    <input type="checkbox" :id="id" :name="name" :checked="value" @change="$emit('input', $event.target.checked)">
    <span class="check c-box"></span>
  </label>
</template>

<script>
export default {
  name: 'InputCheckbox',

  props: {
    id: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: false
    },

    value: {
      type: Boolean,
      required: true
    },

    size: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <div id="workspace-documents" class="shadowed-box">
    <validation-observer v-slot="{ handleSubmit }">
      <div class="box-header">
        <div class="row">
          <div class="col-sm-8 text-uppercase">
            Company Documents
          </div>

          <div class="col-sm-4 size-text-13px text-right-not-xs">
            <a href="" class="normal-weight" @click.prevent="startEdit" v-if="!isEditing && workspace.id">Edit</a>

            <span v-if="isEditing">
              <a href="" class="normal-weight right-15" @click.prevent="handleSubmit(save)" :disabled="isSaving">Save</a>
              <a href="" class="dark-gray-text normal-weight" @click.prevent="cancelEdit">Cancel</a>
            </span>
          </div>
        </div>
      </div>

      <div class="box-notification-error" v-if="backendErrors.length">
        <div v-for="backendError in backendErrors" :key="backendError">
          {{ backendError }}
        </div>
      </div>

      <div class="box-content">
        <form role="form" name="form" class="a-form" novalidate>
          <div class="row bottom-20">
            <div class="col-sm-4 semibold-weight">
              Outside Counsel Guidelines
            </div>

            <div class="col-sm-8">
              <div v-if="!isEditing">
                {{ boolOptionLabel(workspace.hasGuidelines) }}
              </div>

              <div class="top-10-xs" v-if="isEditing">
                <true-false-select name="outsideCounselGuidelines" id-attr="tf-outside-counsel-guidelines" v-model="workspaceCopy.hasGuidelines"></true-false-select>
              </div>
            </div>
          </div>

          <div class="row bottom-20" v-if="workspaceCopy.hasGuidelines">
            <div class="col-sm-4 semibold-weight">
              Attach Guidelines
            </div>

            <div class="col-sm-8">
              <div v-if="!isEditing">
                <a :href="documentUrl(workspace.guidelines)" target="_blank" v-if="workspace.guidelines">{{ workspace.guidelines.name }}</a>

                <span v-else>&mdash;</span>
              </div>

              <loading-section name="guidelinesEditing">
                <div class="top-10-xs" v-if="isEditing">
                  <validation-provider :rules="rulesFor(workspaceCopy.guidelines)" v-slot="{ errors: fieldErrors }" :mode="passiveAggressive">
                    <file-input v-model="workspaceCopy.guidelines" @input="documentSelected('guidelines')" @delete="deleteDocument('guidelines')" button-text="Attach Guidelines" button-class="empty-button smaller" accept=".pdf,.doc,.docx">
                      {{ fieldErrors[0] }}
                    </file-input>
                  </validation-provider>
                </div>
              </loading-section>
            </div>
          </div>

          <div class="row bottom-20">
            <div class="col-sm-4">
              <div class="semibold-weight">
                Malpractice Insurance Required
              </div>

              <div class="dark-gray-text size-text-12px">
                (Outside Counsel)
              </div>
            </div>

            <div class="col-sm-8">
              <div v-if="!isEditing">
                {{ boolOptionLabel(workspace.requireMalpracticeInsurance) }}
              </div>

              <div class="top-10-xs" v-if="isEditing">
                <true-false-select name="requireMalpracticeInsurance" id-attr="tf-require-malpractice-insurance" v-model="workspaceCopy.requireMalpracticeInsurance"></true-false-select>
              </div>
            </div>
          </div>

          <div class="bottom-20">
            <textarea-horizontal label="Limits" name="limits" sub-text="(Outside Counsel)" placeholder="Enter note setting out limits of malpractice insurance requirements" :current-value="workspace.limits" :edit-mode="isEditing" v-model="workspaceCopy.limits" :novalidate="true"></textarea-horizontal>
          </div>

          <div class="row bottom-20">
            <div class="col-sm-4">
              <div class="semibold-weight">
                Malpractice Insurance Required
              </div>

              <div class="dark-gray-text size-text-12px">
                (Temp Placements)
              </div>
            </div>

            <div class="col-sm-8">
              <div v-if="!isEditing">
                {{ boolOptionLabel(workspace.requireMalpracticeTempPlacements) }}
              </div>

              <div class="top-10-xs" v-if="isEditing">
                <true-false-select name="requireMalpracticeTempPlacements" id-attr="tf-require-malpractice-temp-placements" v-model="workspaceCopy.requireMalpracticeTempPlacements"></true-false-select>
              </div>
            </div>
          </div>

          <div class="bottom-20">
            <textarea-horizontal label="Limits" name="limitsTempPlacements" sub-text="(Temp Placements)" placeholder="Enter note setting out limits of malpractice insurance requirements" :current-value="workspace.limitsTempPlacements" :edit-mode="isEditing" v-model="workspaceCopy.limitsTempPlacements" :novalidate="true"></textarea-horizontal>
          </div>

          <div class="row bottom-20">
            <div class="col-sm-4 semibold-weight">
              Client Engagement Letter
            </div>

            <div class="col-sm-8">
              <div v-if="!isEditing">
                {{ boolOptionLabel(workspace.hasEngagementLetter) }}
              </div>

              <div class="top-10-xs" v-if="isEditing">
                <true-false-select name="hasEngagementLetter" id-attr="tf-has-engagementLetter" v-model="workspaceCopy.hasEngagementLetter"></true-false-select>
              </div>
            </div>
          </div>

          <div class="row bottom-20" v-if="workspaceCopy.hasEngagementLetter">
            <div class="col-sm-4 semibold-weight">
              Attach Engagement Letter
            </div>

            <div class="col-sm-8">
              <loading-section name="engagementLetterEditing">
                <div v-if="!isEditing">
                  <a :href="documentUrl(workspace.engagementLetter)" target="_blank" v-if="workspace.engagementLetter">{{ workspace.engagementLetter.name }}</a>
                  <span v-if="!workspace.engagementLetter">&mdash;</span>
                </div>

                <div class="top-10-xs" v-if="isEditing">
                  <validation-provider :rules="rulesFor(workspaceCopy.engagementLetter)" v-slot="{ errors: fieldErrors }" :mode="passiveAggressive">
                    <file-input v-model="workspaceCopy.engagementLetter" @input="documentSelected('engagementLetter')" @delete="deleteDocument('engagementLetter')" button-text="Attach Engagement Letter" button-class="empty-button smaller" accept=".pdf,.doc,.docx">
                      {{ fieldErrors[0] }}
                    </file-input>
                  </validation-provider>
                </div>
              </loading-section>
            </div>
          </div>

          <div class="row bottom-20">
            <div class="col-sm-4 semibold-weight">
              Additional Documents
            </div>

            <div class="col-sm-8">
              <loading-section name="additionalDocumentEditing">
                <div v-if="!isEditing && workspace.additionalDocuments">
                  <div v-for="(additionalDocument, index) in workspace.additionalDocuments" :key="additionalDocument.id" :class="{ 'bottom-10': index != workspace.additionalDocuments.length - 1 }">
                    <a :href="documentUrl(additionalDocument)" target="_blank">{{ additionalDocument.name }}</a>
                  </div>

                  <div v-if="!workspace.additionalDocuments.length">
                    &mdash;
                  </div>
                </div>

                <div class="top-10-xs" v-if="isEditing">
                  <div class="bottom-20" v-for="(doc, index) in workspaceCopy.additionalDocuments" :key="doc.name + index">
                    <validation-provider :rules="rulesFor(workspaceCopy.additionalDocuments[index])" v-slot="{ errors: fieldErrors }" :mode="passiveAggressive">
                      <file-input v-model="workspaceCopy.additionalDocuments[index]" @input="replaceAdditionalDocument(doc)" @delete="deleteAdditionalDocument(doc)" accept=".pdf,.doc,.docx">
                        {{ fieldErrors[0] }}
                      </file-input>
                    </validation-provider>
                  </div>

                  <input ref="multipleFileInput" type="file" :multiple="true" @change="additionalDocumentsSelected($event.target.files)" accept=".pdf,.doc,.docx" class="invisible-input">

                  <button type="button" class="empty-button smaller" @click="addDocuments">Add Documents</button>
                </div>
              </loading-section>
            </div>
          </div>

          <textarea-horizontal label="Additional Notes" name="additionalNotes" placeholder="e.g., Company outside counsel guidelines and diversity requirements apply to all engagements." :current-value="workspace.notes" :edit-mode="isEditing" v-model="workspaceCopy.notes" :novalidate="true"></textarea-horizontal>

          <div class="row top-30" v-if="isEditing">
            <div class="col-sm-4 col-md-3">
              <button type="button" class="nv-button-blue a-button-size" @click="handleSubmit(save)" :disabled="isSaving">Save</button>
            </div>

            <div class="col-sm-4 col-md-3 top-10-xs">
              <button type="button" class="secondary-btn-blue a-button-size" @click="cancelEdit" :disabled="isSaving">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import FileInput from 'vue-app/marketplace/client/shared/file-input.vue';
import TrueFalseSelect from 'vue-app/shared/components/true-false-select.vue';
import TextareaHorizontal from 'vue-app/shared/components/textarea-horizontal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import InternalDocument from 'resources/internal-document.js';
import FileUploadService from 'services/file-upload-service.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { cloneDeep, filter, indexOf, isBoolean, map } from 'lodash';

export default {
  name: 'WorkspaceDocuments',

  components: {
    FileInput,
    TrueFalseSelect,
    TextareaHorizontal,
    LoadingSection,
    ValidationProvider,
    ValidationObserver
  },

  mixins: [
    interactionModes
  ],

  props: {
    workspace: {
      type: Object,
      default: () => ({})
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isEditing: false,
      isSaving: false,
      documentsToDelete: [],
      backendErrors: [],
      workspaceCopy: cloneDeep(this.workspace),
      loaderNames: [
        'additionalDocument',
        'engagementLetter',
        'guidelines'
      ]
    };
  },

  mounted() {
    this.workspaceCopy = cloneDeep(this.workspace);
  },

  methods: {
    boolOptionLabel(value) {
      if (isBoolean(value)) {
        return value ? 'Yes' : 'No';
      }
      else {
        return '—';
      }
    },

    rulesFor(model) {
      return model?.constructor?.name === 'File' ? 'size:50000|ext:pdf,doc,docx' : '';
    },

    documentUrl(document) {
      return document.file?.url;
    },

    documentName(document) {
      return document?.name;
    },

    startEdit() {
      this.workspaceCopy = cloneDeep(this.workspace);
      this.isEditing = true;
      this.backendErrors = [];
    },

    cancelEdit() {
      this.isEditing = false;
      this.documentsToDelete = [];
      this.backendErrors = [];
    },

    documentSelected(documentType) {
      if (this.workspace[documentType]?.id) {
        this.documentsToDelete.push({ id: this.workspace[documentType].id, documentType });
      }
    },

    deleteDocument(documentType) {
      this.documentSelected(documentType);
      this.workspaceCopy[documentType] = null;
    },

    additionalDocumentsSelected(files) {
      this.workspaceCopy.additionalDocuments.push(...Object.values(files));
    },

    replaceAdditionalDocument(additionalDocument) {
      if (additionalDocument.id) {
        this.documentsToDelete.push({ id: additionalDocument.id, type: 'additionalDocument' });
      }
    },

    deleteAdditionalDocument(additionalDocument) {
      if (additionalDocument.id) {
        this.documentsToDelete.push({ id: additionalDocument.id, type: 'additionalDocument' });
      }

      const index = indexOf(this.workspaceCopy.additionalDocuments, additionalDocument);
      this.workspaceCopy.additionalDocuments.splice(index, 1);
    },

    async save() {
      const vueInstance = this;

      vueInstance.isSaving = true;

      if (vueInstance.workspace.id) {
        vueInstance.setLoading();

        await vueInstance.uploadGuidelines();
        LoadingService.done('guidelinesEditing');

        await vueInstance.uploadEngagementLetter();
        LoadingService.done('engagementLetterEditing');

        await vueInstance.uploadAdditionalDocuments();
        LoadingService.done('additionalDocumentEditing');

        this.onSave({
          workspace: vueInstance.workspaceCopy,
          documentsToDelete: vueInstance.documentsToDelete.map(doc => doc.id),
          errorFn: (backendErrors) => {
            vueInstance.backendErrors = backendErrors;
            vueInstance.isSaving = false;
          },
          successFn: () => {
            vueInstance.isEditing = false;
            vueInstance.isSaving = false;
          }
        });

        this.documentsToDelete = [];
      }
    },

    setLoading() {
      this.loaderNames.forEach((key) => {
        LoadingService.loading(`${key}Editing`);
      });
    },

    uploadGuidelines() {
      const vueInstance = this;
      if (vueInstance.workspaceCopy.guidelines && !vueInstance.workspaceCopy.guidelines.id) {
        return FileUploadService.uploadAsync(
          {
            file: vueInstance.workspaceCopy.guidelines,
            type: 'guidelines',
            entityId: vueInstance.workspaceCopy.id,
            entityType: 'Workspace'
          },
          InternalDocument
        );
      }
    },

    uploadAdditionalDocuments() {
      const docsToUpload = filter(this.workspaceCopy.additionalDocuments, (d) => { return !d.id; });
      const vueInstance = this;

      if (docsToUpload.length) {
        return Promise.all(
          map(docsToUpload, (file) => {
            return FileUploadService.uploadAsync(
              {
                file: file,
                type: 'additional_documents',
                entityId: vueInstance.workspace.id,
                entityType: 'Workspace',
                loadingSectionName: 'additionalDocumentEditing'
              },
              InternalDocument
            );
          })
        );
      }
    },

    uploadEngagementLetter() {
      const vueInstance = this;
      if (vueInstance.workspaceCopy.engagementLetter && !vueInstance.workspaceCopy.engagementLetter.id) {
        return FileUploadService.uploadAsync(
          {
            file: vueInstance.workspaceCopy.engagementLetter,
            type: 'engagement_letter',
            entityId: vueInstance.workspaceCopy.id,
            entityType: 'Workspace'
          },
          InternalDocument
        );
      }
    },

    addDocuments() {
      this.$refs.multipleFileInput.click();
    }
  }
};
</script>

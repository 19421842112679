<template>
  <div v-if="!dismissed" :id="id" class="shadowed-box interface-note-box size-text-13px d-flex">
    <modal-close-button v-if="dismissible" :on-dismiss="dismiss"></modal-close-button>

    <slot>
      {{ message }}
    </slot>
  </div>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'DismissibleCta',

  components: {
    ModalCloseButton
  },

  props: {
    id: {
      type: String,
      required: true
    },

    context: {
      type: String,
      required: false
    },

    dismissible: {
      type: Boolean,
      default: true
    },

    message: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      dismissedCtaIds: []
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    dismissed() {
      return this.dismissedCtaIds.includes(this.fullCtaId);
    },

    dismissedCtaIdsStorageKey() {
      return `${this.currentUser.klass}_${this.currentUser.id}_dismissedCtaIds`;
    },

    fullCtaId() {
      if (this.context) { return `${this.context}-${this.id}`; }

      return this.id;
    }
  },

  mounted() {
    const dismissedCtaIds = JSON.parse(localStorage.getItem(this.dismissedCtaIdsStorageKey));

    if (dismissedCtaIds?.length) {
      this.dismissedCtaIds = dismissedCtaIds;
    }
    else {
      localStorage.setItem(this.dismissedCtaIdsStorageKey, JSON.stringify([]));
    }
  },

  methods: {
    dismiss() {
      if (this.dismissed) { return; }

      this.dismissedCtaIds.push(this.fullCtaId);
      localStorage.setItem(this.dismissedCtaIdsStorageKey, JSON.stringify(this.dismissedCtaIds));
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .shadowed-box {
    min-height: unset;
    width: fit-content;
  }

  .interface-note-box {
    padding: 12px;
    border-left: 7px solid $alt-dark-purple;
  }

  :deep(.close-modal) {
    line-height: 13px;
  }

  :deep(.full-screen) {
    top: -10px;
    right: -10px;
  }
</style>

<template>
  <tr>
    <td>
      {{ sentAt }}
    </td>

    <td>
      <a :href="`/client-app/projects/${invoice.matterId}/invoices/${invoice.id}`">{{ invoice.referenceNumber }}</a>
    </td>

    <td>
      {{ invoice.lawyerName }}
    </td>

    <td>
      <a :href="`/client-app/projects/${invoice.matterId}`">{{ invoice.matterName }}</a>
    </td>

    <td>
      <span class="semibold-weight">{{ totalDollars }}</span>
    </td>

    <td>
      <span :class="{ 'pending-status': canPay, 'rejected-status': !isPaid && isVoid, 'open-status': isPaid }">{{ invoice.clientDisplayStatus() }}</span>
    </td>

    <td>
      <span :class="['less-weight', { 'pending-status': canPay, 'open-status': isPaid }]">{{ dueOrPaidDate }}</span>
    </td>

    <td class="text-right">
      <a :href="`/client-app/projects/${invoice.matterId}/invoices/${invoice.id}`" :class="['smaller', { 'nv-button-white': !canPay, 'nv-button-dark-green': canPay }]">View<span v-if="canPay">/Pay</span></a>
    </td>
  </tr>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';

export default {
  name: 'InvoiceRow',

  props: {
    invoice: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      dateFormat: {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit'
      }
    };
  },

  computed: {
    isPaid() {
      return this.invoice.paid;
    },

    isVoid() {
      return this.invoice.isVoid();
    },

    canPay() {
      return !this.isPaid && !this.isVoid;
    },

    sentAt() {
      return DateFilter.filter(this.invoice.sentAt, this.dateFormat);
    },

    totalDollars() {
      return CurrencyFilter.filter(this.invoice.totalDollars);
    },

    dueOrPaidDate() {
      if (!this.isPaid && this.isVoid) {
        return '—';
      }
      else {
        return DateFilter.filter(this.invoice.clientDueOrPaidAt(), this.dateFormat);
      }
    }
  }
};
</script>

<template>
  <div>
    <edit-modal title="Editing Notes" :input="notes" :on-save="save" @on-modal-open="resetForm">
      <template #default="{ inputCopy: notesCopy }">
        <div class="bottom-30">
          <div class="bottom-20">
            <a href="" class="semibold-weight" @click.prevent="addNote(notesCopy)">+ {{ notesCopy.length ? 'Add Another' : 'Add Note' }}</a>
          </div>

          <div v-for="note in notesCopy" class="field-group bottom-20">
            <div class="bottom-20">
              <div class="semibold-weight bottom-10">
                Note
              </div>

              <textarea :name="`content{index}`" id="`content-${index}`" class="form-control" rows="4" v-model="note.content"></textarea>
            </div>

            <div class="text-right">
              <a href="" class="remove-btn" @click.prevent="showConfirmModal(notesCopy, note)"><svg-icon name="minus-circle" class="base-icon right-10"></svg-icon>Remove</a>
            </div>
          </div>
        </div>
      </template>
    </edit-modal>

    <confirmation-modal
      ref="confirmationModal"
      header="Remove Note"
      message="Please confirm that you would like to remove this note."
      yes-btn-text="Yes, Remove"
      no-btn-text="Cancel"
      :on-confirm-action="removeNote">
    </confirmation-modal>
  </div>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import ConfirmationModal from 'vue-app/shared/components/confirmation-modal';

export default {
  name: 'EditFirmNotes',

  components: {
    EditModal,
    ConfirmationModal
  },

  props: {
    notes: {
      type: Array,
      default: () => []
    },

    editingAllowed: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      notesToDestroy: [],
      confirmRemove: false,
      selectedNote: null
    };
  },

  methods: {
    resetForm() {
      this.notesToDestroy = [];
      this.selectedNote = null;
    },

    addNote(notesCopy) {
      notesCopy.unshift({
        content: ''
      });
    },

    showConfirmModal(notesCopy, note) {
      this.notesCopy = notesCopy;
      this.selectedNote = note;

      this.$refs.confirmationModal.openModal();
    },

    removeNote() {
      const index = this.notesCopy.indexOf(this.selectedNote);

      if (index > -1 && this.selectedNote.id) {
        this.notesToDestroy.push({ id: this.selectedNote.id, '_destroy': true });
        this.notesCopy.splice(index, 1);
      }
      else if (index > -1) {
        this.notesCopy.splice(index, 1);
      }
    },

    save(notesCopy) {
      if (!this.editingAllowed) { return; }

      let notes = notesCopy.map((note) => {
        if (note.content.trim().length > 0) {
          return note;
        }
        else {
          note._destroy = true;
          return note;
        }
      });

      this.onSave([...notes, ...this.notesToDestroy].reverse());
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .remove-btn {
    align-items: center;
    color: $k-darker-gray;
    display: flex;
    justify-content: right;
  }
</style>

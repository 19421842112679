<template>
  <div>
    <div class="notification-header tiny-header-text">
      Your {{ appointmentType }} has been cancelled
    </div>

    <div class="notification-content">
      <div class="text-italic size-text-13px" data-testid="consultation-cancellation-body">
        <div class="bottom-20">
          <span v-if="cancelledByMe">You have</span>

          <span v-else>
            <span class="semibold-weight">{{ cancelledBy.firstName }} {{ cancelledBy.lastName }}</span> has
          </span>

          cancelled your {{ appointmentType }} {{ withParticipantsIfPresent }} for the following reason:
        </div>

        <div class="semibold-weight">
          {{ appointmentMetadata.reasonForCancel || '—' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Appointment from 'resources/marketplace/appointment.js';

export default {
  name: 'ConsultationCancelledNotification',

  props: {
    appointmentMetadata: {
      type: Object,
      required: true
    },

    cancelledBy: {
      type: Object,
      required: true
    },

    cancelledByMe: {
      type: Boolean,
      required: true
    },

    otherParticipantsList: {
      type: String,
      required: true
    }
  },

  computed: {
    withParticipantsIfPresent() {
      return this.cancelledByMe ? `with ${this.otherParticipantsList}` : '';
    },

    appointmentResource() {
      return new Appointment(this.appointmentMetadata);
    },

    appointmentType() {
      return this.appointmentResource.typeText();
    }
  }
};
</script>

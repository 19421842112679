<template>
  <div>
    <div class="titlebar with-button">
      <div class="row">
        <div class="col-sm-8 col-md-9">
          <div class="medium-page-heading top-6">
            Users
          </div>
        </div>

        <div class="col-sm-4 col-md-3 top-20-xs">
          <add-client
            v-if="!currentClient.userManagerEnabled || currentClient.userManager"
            :on-save="createClient"
            :practice-groups="practiceGroups"
            :is-user-manager="currentClient.userManager"
            :is-employee-id-required="currentClient.workspace.employeeIdRequired">
          </add-client>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="bottom-30" v-show="!currentClient.userManager">
            <a href="/scout-company/settings" class="back-link">
              <svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>
              Back to Admin Settings
            </a>
          </div>

          <div class="bottom-30" v-show="searchableTeamMembers.length">
            <typeahead-filter
              :options="searchableTeamMembers"
              option-label-key="fullName"
              option-value-key="id"
              placeholder-text="Search for a user..."
              :results-count="resultsCount"
              :on-apply-filter="applyFilter">
            </typeahead-filter>
          </div>

          <div class="resource-list static-list size-text-12px">
            <div class="list-header">
              <div class="row tight-columns">
                <div class="col-sm-7">
                  <div class="row tight-columns">
                    <div class="col-sm-4">
                      Name
                    </div>

                    <div class="col-sm-5">
                      Email
                    </div>

                    <div class="col-sm-3">
                      Title
                    </div>
                  </div>
                </div>

                <div class="col-sm-5">
                  <div class="row tight-columns">
                    <div class="col-sm-4">
                      User Type
                    </div>

                    <div class="col-sm-5">
                      <svg-icon id="rfq-permission-tooltip" name="info" class="base-icon smaller right-5"></svg-icon>
                      <tooltip
                        target="#rfq-permission-tooltip"
                        placement="bottom"
                        custom-class="tooltip-white"
                        text="&quot;RFP Access&quot; users will be able to create, send, and view their own RFPs. &quot;RFP Manager&quot; users will be able to create and send their own RFPs, and also will be able to view all RFPs submitted by their colleagues.">
                      </tooltip>
                      <span class="top-2">RFP Permission</span>
                    </div>

                    <div v-if="!currentClient.userManagerEnabled || currentClient.userManager" class="col-sm-3 text-right-not-xs">
                      Actions
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <loading-section name="teamMembers">
              <div class="list-item no-hover" v-for="client in teamMembers" :key="client.id">
                <div :class="['row tight-columns list-item-inner vertical-center-not-xs', {'dark-gray-text text-italic': !client.active }]">
                  <div class="col-sm-7">
                    <div class="row tight-columns">
                      <div class="col-sm-4 text-ellipsis">
                        {{ client.fullName }}
                      </div>

                      <div class="col-sm-5 text-ellipsis">
                        {{ client.email }}
                      </div>

                      <div class="col-sm-3 text-ellipsis">
                        {{ client.role || '&mdash;' }}
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                    <div class="row tight-columns vertical-center-not-xs">
                      <div class="col-sm-4 text-ellipsis">
                        {{ !client.active ? "Deactivated" : client.userType }}
                      </div>

                      <div class="col-sm-5 text-ellipsis">
                        {{ !client.hasRfqEnabled ? 'No RFP Access' : (client.hasRfqWorkspaceEnabled ? 'RFP Manager' : 'RFP Access') }}
                      </div>

                      <div v-if="!currentClient.userManagerEnabled || currentClient.userManager" class="col-sm-3 text-right-not-xs text-normal">
                        <dropdown-menu :compact="true">
                          <li class="menu-item" role="menuitem">
                            <a href="" @click.prevent="setActionUser(client.id, 'edit')">Edit User</a>
                          </li>

                          <li v-if="!client.active" class="menu-item" role="menuitem">
                            <a href="" @click.prevent="setActionUser(client.id, 'activate')">Reactivate User</a>
                          </li>

                          <li v-if="client.active" class="menu-item" role="menuitem">
                            <a href="" @click.prevent="setActionUser(client.id, 'deactivate')">Deactivate User</a>
                          </li>
                        </dropdown-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="!teamMembers.length" class="list-item empty gray-text size-text-16px">
                Empty
              </div>

              <edit-client
                :on-save="updateClient"
                :on-close-modal="clearActionUser"
                :show-modal="action === 'edit'"
                :user="actionUser"
                :practice-groups="practiceGroups"
                :is-user-manager="currentClient.userManager"
                :is-employee-id-required="currentClient.workspace.employeeIdRequired">
              </edit-client>

              <deactivate-client
                :selected-client-id="actionUser.id"
                :show-modal="action === 'deactivate'"
                :on-save="updateClient"
                :on-close-modal="clearActionUser">
              </deactivate-client>

              <reactivate-client
                :selected-client-id="actionUser.id"
                :show-modal="action === 'activate'"
                :on-save="updateClient"
                :on-close-modal="clearActionUser">
              </reactivate-client>

              <div class="top-20" v-if="teamMembers.length">
                <priori-pagination
                  class="scout-pagination top-15"
                  :current-page="page"
                  :total-entries="resultsCount"
                  :per-page="10"
                  @change="changePage">
                </priori-pagination>
              </div>
            </loading-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReactivateClient from 'vue-app/scout/client/settings/users/reactivate-client.vue';
import AddClient from 'vue-app/scout/client/settings/users/add-client.vue';
import DeactivateClient from 'vue-app/scout/client/settings/users/deactivate-client.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import EditClient from 'vue-app/scout/client/settings/users/edit-client.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import TypeaheadFilter from 'vue-app/scout/shared/typeahead-filter.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';

import LoadingService from 'vue-app/shared/services/loading-service';
import NotificationService from 'vue-app/shared/services/notification-service';
import ScoutClient from 'resources/scout/client/scout-client.js';
import ScoutWorkspace from 'resources/scout/scout-workspace.js';
import PracticeGroup from 'resources/practice-group.js';

import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'ScoutClientSettingsUsersIndex',

  components: {
    AddClient,
    DeactivateClient,
    DropdownMenu,
    EditClient,
    LoadingSection,
    PrioriPagination,
    ReactivateClient,
    SvgIcon,
    TypeaheadFilter
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      action: null,
      actionUser: {},
      page: 1,
      practiceGroups: [],
      searchableTeamMembers: [],
      teamData: this.initData.teamData
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    resultsCount() {
      return this.teamData.metadata.total;
    },

    teamMembers() {
      return this.teamData.resources;
    }
  },

  mounted() {
    PracticeGroup.query({ view: 'base' }).then((practiceGroups) => {
      this.practiceGroups = practiceGroups;
    });

    ScoutClient.query({ view: 'settings_list_search' }).then((teamMembers) => {
      this.searchableTeamMembers = teamMembers;
    });
  },

  methods: {
    applyFilter(selectedClientId) {
      this.page = 1;
      return this.loadTeamMembers(selectedClientId);
    },

    changePage(page) {
      if (this.page !== page && page >= 1) {
        this.page = page;
        this.loadTeamMembers();
      }
    },

    loadTeamMembers(selectedClientId) {
      const params = { view: 'settings_detail', page: this.page };

      if (selectedClientId) {
        params.clientIds = [selectedClientId];
      }

      LoadingService.loading('teamMembers');
      return ScoutClient.queryData(params).then(teamData => {
        this.teamData = teamData;

        LoadingService.done('teamMembers');
      });
    },

    createClient(client) {
      return ScoutClient.save({ client }).then(response => {
        this.loadTeamMembers();
        ScoutClient.query({ view: 'settings_list_search' }).then(searchableTeamMembers => {
          this.searchableTeamMembers = searchableTeamMembers;
        });

        NotificationService.success('You successfully added a new user.');

        return ScoutWorkspace.sendWorkspaceInvite({ id: response.workspaceId, clientId: response.id });
      });
    },

    updateClient(client) {
      return ScoutClient.update({ id: client.id, client }).then(() => {
        if (this.currentClient.id === client.id) {
          window.location.reload();
          return;
        }

        this.loadTeamMembers();
        ScoutClient.query({ view: 'settings_list_search' }).then(searchableTeamMembers => {
          this.searchableTeamMembers = searchableTeamMembers;

          this.successNotification();
        });
      })
      .catch(() => {
        NotificationService.error('Unable to save changes. Please try again.');
      })
      .finally(() => {
        this.clearActionUser();
      });
    },

    successNotification() {
      let text = 'edited';

      if (this.action === 'activate') {
        text = 'reactivated';
      }

      if (this.action === 'deactivate') {
        text = 'deactivated';
      }

      NotificationService.success(`You successfully ${text} the user.`);
    },

    setActionUser(clientId, action) {
      this.action = action;
      this.actionUser = this.teamMembers.find(teamMember => teamMember.id === clientId);
    },

    clearActionUser() {
      this.action = null;
      this.actionUser = {};
    }
  }
};
</script>

<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a href="/client-app/payment-methods" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>All Payment Methods</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="medium-page-heading bottom-30">
            Add Account
          </div>

          <div class="row bottom-30">
            <div class="col-md-8">
              <payment-method-form
                :account="account"
                :error-message="errorMessage"
                :plaid-link="plaidLink"
                :on-submit="add">
              </payment-method-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentMethodForm from 'vue-app/marketplace/client/settings/payment-methods/payment-method-form.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

import Client from 'resources/client.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import ConfigService from 'vue-app/shared/services/config-service.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';

import { scrollToTop } from 'misc/ui-helpers.js';
import { mapState } from 'pinia';

export default {
  name: 'PaymentMethodNew',

  components: {
    PaymentMethodForm,
    SvgIcon
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      backTo: this.initData.query.backTo,
      account: { accountType: 'bankAccount', address: {}, holderType: 'company' },
      errorMessage: ''
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' })
  },

  methods: {
    plaidLink() {
      const linkHandler = Plaid.create({
        env: ConfigService.plaidEnv,
        key: ConfigService.plaidPublicKey,
        clientName: 'Priori Legal',
        product: 'auth',
        selectAccount: true,
        apiVersion: 'v2',
        onSuccess: (public_token, metadata) => {
          Client.addPlaidBankAccount({ id: this.user.id, public_token: public_token, account_id: metadata.account_id }).then(
            this.success,
            (response) => {
              this.errorMessage = response.response.data.errors.stripeBankAccounts.join(', ');
              scrollToTop();
            }
          );
        },
        onExit: () => {}
      });

      linkHandler.open();
    },

    add() {
      LoadingService.loading('submitAccount');

      if (this.account.accountType === 'card') {
        Stripe.card.createToken(
          {
            number:      this.account.number,
            cvc:         this.account.cvv,
            exp_month:   this.account.expirationMonth,
            exp_year:    this.account.expirationYear,
            name:        this.account.name,
            address_zip: this.account.postal_code
          },
          this.handleResponse
        );
      }
      else {
        Stripe.bankAccount.createToken(
          {
            country:             'US',
            currency:            'USD',
            routing_number:      this.account.routingNumber,
            account_number:      this.account.accountNumber,
            account_holder_name: this.account.name,
            account_holder_type: this.account.holderType
          },
          this.handleResponse
        );
      }
    },

    success(user) {
      LoadingService.done('submitAccount');
      NotificationService.success('You successfully added a payment method.', true);
      useCurrentUserStore().setCurrentUser(user);

      if (this.backTo === undefined) {
        window.location = '/client-app/payment-methods';
      }
      else {
        window.location = this.backTo;
      }
    },

    failure(response) {
      LoadingService.done('submitAccount');
      this.errorMessage = (response.response.data.errors.stripeCards || response.response.data.errors.stripeBankAccounts).join(', ');
      scrollToTop();
    },

    handleResponse(status, response) {
      if (status === 200 || status === '200') {
        if (this.account.accountType === 'card') {
          Client.addStripeCard({ id: this.user.id, token: response.id }).then(this.success, this.failure);
        }
        else {
          Client.addStripeBankAccount({ id: this.user.id, token: response.id }).then(this.success, this.failure);
        }
      }
      else {
        LoadingService.done('submitAccount');
        this.errorMessage = response.error.message;
        scrollToTop();
      }
    }
  }
};
</script>

<template>
  <div id="workspace-billing-info" class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          Billing Information
        </div>

        <div class="col-sm-4 size-text-13px text-right-not-xs">
          <a href="" class="normal-weight" @click.prevent="startEdit" v-if="!isEditing">Edit</a>

          <span v-if="isEditing">
            <a href="" class="normal-weight right-15" @click.prevent="save">Save</a>
            <a href="" class="dark-gray-text normal-weight" @click.prevent="cancelEdit">Cancel</a>
          </span>
        </div>
      </div>
    </div>

    <div class="box-content">
      <form role="form" name="form" class="a-form" novalidate>
        <div class="row bottom-20">
          <div class="col-sm-4 semibold-weight">
            Payment Method
          </div>

          <div class="col-sm-8">
            <div v-if="!isEditing">
              <div class="bottom-5">
                {{ workspace.paymentMethodType || '&mdash;' }}
              </div>

              <a href="/client-app/payment-methods">Personal Payment Methods</a>
            </div>

            <div class="top-10-xs" v-if="isEditing">
              <dropdown-select id-label="payment-method" placeholder="Select One" :initial-label="selectedPaymentMethod" v-model="selectedPaymentMethod" :options="paymentMethods" value-key="paymentMethod" label-key="label" @change="changePaymentMethod"></dropdown-select>

              <input type="text" name="paymentMethodInput" id="payment-method-input" class="form-control top-10" placeholder="Other" v-model="workspaceCopy.paymentMethodType" v-if="selectedPaymentMethod === 'Other'">
            </div>
          </div>
        </div>

        <div class="row bottom-20">
          <div class="col-sm-4 semibold-weight">
            Net Days Payable
          </div>

          <div class="col-sm-8">
            <div v-if="!isEditing">
              {{ dayPayable || '&mdash;' }}
            </div>

            <div class="top-10-xs" v-if="isEditing">
              <dropdown-select id-label="net-days-payable" placeholder="Select One" :initial-label="dayPayable" v-model="workspaceCopy.netDaysPayable" :options="netDaysPayable" value-key="days" label-key="label"></dropdown-select>
            </div>
          </div>
        </div>

        <div class="row bottom-20">
          <div class="col-sm-4 semibold-weight">
            Invoice Delivery Method
          </div>

          <div class="col-sm-8">
            <div v-if="!isEditing">
              {{ invoiceMethodLabel || '&mdash;' }}
            </div>

            <div class="top-10-xs" v-if="isEditing">
              <dropdown-select id-label="invoice-method" :initial-label="workspaceCopy.invoiceMethod" placeholder="Select One" v-model="workspaceCopy.invoiceMethod" :options="invoiceMethods" @change="changeInvoiceMethod"></dropdown-select>

              <input type="text" name="invoiceMethodDetail" id="invoice-method-detail" class="form-control top-10" placeholder="Enter details" v-model="workspaceCopy.invoiceMethodDetail" v-if="invoiceMethodNeedsDetail">
            </div>
          </div>
        </div>

        <div class="bottom-20">
          <textarea-horizontal label="Invoice Delivery Frequency" name="invoiceDeliveryFrequency" :current-value="workspace.invoiceDeliveryFrequency" :edit-mode="isEditing" v-model="workspaceCopy.invoiceDeliveryFrequency" :novalidate="true"></textarea-horizontal>
        </div>

        <div class="row">
          <div class="col-sm-4 semibold-weight">
            Require UTBMS Codes
          </div>

          <div class="col-sm-8">
            <div v-if="!isEditing">
              {{ requireUtbmsCodes }}
            </div>

            <div class="top-10-xs" v-if="isEditing">
              <true-false-select name="abaCodesRequired" id-attr="tf-aba-codes-required" v-model="workspaceCopy.abaCodesRequired"></true-false-select>
            </div>
          </div>
        </div>

        <div class="row top-30" v-if="isEditing">
          <div class="col-sm-4 col-md-3">
            <button type="button" class="nv-button-blue a-button-size" @click="save">Save</button>
          </div>

          <div class="col-sm-4 col-md-3 top-10-xs">
            <button type="button" class="secondary-btn-blue a-button-size" @click="cancelEdit">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import TextareaHorizontal from 'vue-app/shared/components/textarea-horizontal.vue';
import TrueFalseSelect from 'vue-app/shared/components/true-false-select.vue';
import SharedLists from 'vue-app/shared/services/shared-lists-service.js';
import { find, get, isBoolean, some } from 'lodash';

export default {
  name: 'WorkspaceBillingInfo',

  components: {
    DropdownSelect,
    TrueFalseSelect,
    TextareaHorizontal
  },

  props: {
    workspace: {
      type: Object,
      default: () => ({})
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      isEditing: false,
      workspaceCopy: {},
      selectedPaymentMethod: null
    };
  },

  computed: {
    netDaysPayable() {
      return SharedLists.netDaysPayable;
    },

    paymentMethods() {
      return SharedLists.paymentMethods;
    },

    invoiceMethods() {
      return SharedLists.invoiceMethods;
    },

    dayPayable() {
      const obj = find(this.netDaysPayable, { days: this.workspace.netDaysPayable });
      return get(obj, 'label', '');
    },

    requireUtbmsCodes() {
      if (isBoolean(this.workspace.abaCodesRequired)) {
        return this.workspace.abaCodesRequired ? 'Yes' : 'No';
      }
      else {
        return '—';
      }
    },

    invoiceMethodNeedsDetail() {
      return this.workspaceCopy.invoiceMethod === 'E-Billing' || this.workspaceCopy.invoiceMethod === 'Other';
    },

    invoiceMethodLabel() {
      if (this.invoiceMethodNeedsDetail && this.workspace.invoiceMethodDetail) {
        return this.workspace.invoiceMethod + ': ' + this.workspace.invoiceMethodDetail;
      }
      else {
        return this.workspace.invoiceMethod;
      }
    }
  },

  methods: {
    startEdit() {
      this.workspaceCopy = Object.assign({}, this.workspace);

      if (!this.workspace.paymentMethodType) {
        this.selectedPaymentMethod = null;
      }
      else if (some(this.paymentMethods, { paymentMethod: this.workspace.paymentMethodType })) {
        this.selectedPaymentMethod = this.workspace.paymentMethodType;
      }
      else {
        this.selectedPaymentMethod = 'Other';
      }

      this.isEditing = true;
    },

    cancelEdit() {
      this.isEditing = false;
    },

    save() {
      this.isEditing = false;
      this.onSave({ workspace: this.workspaceCopy });
    },

    changePaymentMethod() {
      if (this.selectedPaymentMethod === 'Other') {
        this.workspaceCopy.paymentMethodType = null;
      }
      else {
        this.workspaceCopy.paymentMethodType = this.selectedPaymentMethod;
      }
    },

    changeInvoiceMethod() {
      if (!this.invoiceMethodNeedsDetail) {
        this.workspaceCopy.invoiceMethodDetail = null;
      }
    }
  }
};
</script>

<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-6 text-uppercase">
          Project Invoices
        </div>

        <div class="col-sm-6 text-right-not-xs">
          <invoice-recipients-modal v-if="canAddRecipients" :matter="matter"></invoice-recipients-modal>
        </div>
      </div>
    </div>

    <div class="box-content">
      <loading-section name="invoices">
        <div v-if="invoices.length" class="table-responsive">
          <table id="all-invoices" class="table auto-height no-margin">
            <thead>
              <th>Invoice&nbsp;Date</th>
              <th>Invoice&nbsp;#</th>
              <th>Your&nbsp;Total</th>
              <th>Status</th>
              <th>Due/Paid</th>
              <th></th>
            </thead>

            <tbody>
              <tr v-for="invoice in orderedInvoices" :key="invoice.id">
                <td>
                  {{ parseDate(invoice.sentAt) }}
                </td>
                <td>
                  <a :href="`/client-app/projects/${invoice.matterId}/invoices/${invoice.id}`">{{ invoice.referenceNumber }}</a>
                </td>
                <td>
                  <span class="semibold-weight">{{ formattedCurrency(invoice.totalDollars) }}</span>
                </td>
                <td>
                  <span :class="{ 'pending-status': !invoice.paid && !invoice.isVoid(), 'rejected-status': !invoice.paid && invoice.isVoid(), 'open-status': invoice.paid }">{{ invoice.clientDisplayStatus() }}</span>
                </td>
                <td>
                  <span :class="['less-weight', { 'pending-status': !invoice.paid && !invoice.isVoid(), 'open-status': invoice.paid }]">{{ !invoice.paid && invoice.isVoid() ? '&mdash;' : parseDate(invoice.clientDueOrPaidAt()) }}</span>
                </td>
                <td class="text-right">
                  <a :href="`/client-app/projects/${matter.id}/invoices/${invoice.id}`" :class="['smaller', { 'nv-button-white': invoice.paid || invoice.isVoid(), 'nv-button-dark-green': !invoice.paid && !invoice.isVoid() }]">View<span v-if="!invoice.paid && !invoice.isVoid()">/Pay</span></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-else class="gray-text tight-lines text-center top-20 bottom-20">
          No Project Invoices
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import InvoiceRecipientsModal from 'vue-app/marketplace/client/matters/invoice-recipients-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';
import { orderBy } from 'lodash';

export default {
  name: 'ProjectInvoices',

  components: {
    InvoiceRecipientsModal,
    LoadingSection
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    invoices: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    canAddRecipients() {
      return !this.matter.isClosed() && !this.matter.invoiceToPrioriOnly();
    },

    orderedInvoices() {
      return orderBy(this.invoices, 'sentAt', 'desc');
    }
  },

  methods: {
    parseDate(date) {
      return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(date));
    },

    formattedCurrency(amount) {
      return CurrencyFilter.filter(amount);
    }
  }
};
</script>

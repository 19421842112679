<template>
  <div>
    <slot name="trigger" :open-and-load-firms="openAndLoadFirms">
      <button type="button" class="secondary-btn-blue transparent hidden-xs" @click="openAndLoadFirms">
        Leave a Review
      </button>
    </slot>

    <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" :backdrop="false" append-to-body @hide="reset">
      <modal-close-button :on-dismiss="hideModal"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-header">
          Leave a Review
        </div>

        <div class="notice-regular-text">
          <div class="bottom-20">
            {{ primaryInstructions }}
          </div>

          <div class="bold-weight size-text-14px bottom-5">
            <span v-if="!isFeatureLab">Step One: </span>Select Law Firm
          </div>

          <div class="pill-gray-text text-italic bottom-20">
            {{ stepOneInstructions }}
          </div>

          <loading-section name="reviewableFirms">
            <form role="form" class="a-form" novalidate @submit.prevent>
              <div class="row">
                <div class="col-sm-8">
                  <typeahead-vertical
                    ref="firmTypeAhead"
                    id="firm"
                    rules="required"
                    :force-select="true"
                    :options="firms"
                    option-label-key="name"
                    @input="selectFirm"
                    :initial-value="searchedFirmName">
                  </typeahead-vertical>
                </div>
              </div>

              <div class="top-30" v-if="selectedFirm && !isFeatureLab">
                <div class="bold-weight size-text-14px bottom-10">
                  Step Two: Select Review Type
                </div>

                <div class="left-20">
                  <div class="bottom-5">
                    <label class="check-option medium-large">
                      <input class="radio-btn" type="radio" id="review-type-law-firm" value="firm" v-model="reviewType">
                      <span class="check c-radio"></span>
                      <span class="normal-weight">Law Firm Review</span>
                    </label>
                  </div>

                  <div>
                    <label class="check-option medium-large">
                      <input class="radio-btn" type="radio" id="review-type-lawyer" value="lawyer" v-model="reviewType">
                      <span class="check c-radio"></span>
                      <span class="normal-weight">Lawyer Review</span>
                    </label>
                  </div>
                </div>

                <div id="form-guide-lawyer-selector" class="top-15" v-if="reviewTypeIsLawyer">
                  <loading-section name="reviewableLawyers">
                    <div class="row" v-if="hasSelectableLawyers">
                      <div class="col-sm-8">
                        <label>Choose a lawyer:</label>

                        <typeahead-vertical
                          id="review-form-guide"
                          rules="required"
                          v-model="lawyerQuery"
                          :force-select="true"
                          :options="selectedFirmLawyersByFullName"
                          option-label-key="fullName"
                          @input="selectLawyer">
                        </typeahead-vertical>
                      </div>
                    </div>

                    <div class="red-text semibold-weight" v-else>
                      There are no lawyers available to review for that firm.
                    </div>
                  </loading-section>

                  <div class="top-20" v-if="selectedLawyer">
                    <lawyer-name-plate :lawyer="selectedLawyer"></lawyer-name-plate>
                  </div>
                </div>
              </div>

              <div class="row top-30">
                <div class="col-sm-6">
                  <button type="button" class="primary-btn-night-blue a-button-size" :disabled="!valid" @click.prevent="redirectToReviewForm">Start Review</button>
                </div>

                <div class="col-sm-4 col-sm-offset-2 top-10-xs">
                  <button type="button" class="secondary-btn-blue a-button-size" @click.prevent="hideModal">Cancel</button>
                </div>
              </div>
            </form>
          </loading-section>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { has, orderBy, sortBy } from 'lodash';

export default {
  name: 'ReviewFormGuideModal',

  components: {
    ModalCloseButton,
    LoadingSection,
    LawyerNamePlate,
    TypeaheadVertical
  },

  mixins: [
    modalToggle
  ],

  props: {
    loadFirms: {
      type: Function,
      required: true
    },

    loadFirmLawyers: {
      type: Function,
      required: true
    },

    preselectedFirm: {
      type: Object,
      default: null
    },

    preselectedReviewType: {
      type: String,
      default: null
    },

    matterId: {
      type: Number,
      default: null
    },

    leavingReview: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      firms: [],
      firmsAreLoaded: false,
      firmLawyers: {},
      selectedFirm: this.preselectedFirm,
      reviewType: this.preselectedReviewType,
      lawyerQuery: '',
      selectedLawyer: null,
      searchedFirmName: this.preselectedFirm?.name || ''
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentProduct']),

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    },

    primaryInstructions() {
      if (this.isFeatureLab) {
        return 'Use the questions below to create reviews of your law firms. As with all reviews in Feature Lab, they will only be visible to your law department colleagues.';
      }
      else {
        return 'Use the questions below to create reviews at either lawyer- or law-firm-level. As with all reviews in Scout, they will only be visible to your law department colleagues.';
      }
    },

    stepOneInstructions() {
      if (this.isFeatureLab) {
        return 'Select the Law Firm you wish to review.';
      }
      else {
        return 'For law-firm-level reviews, select the Law Firm you wish to review. For lawyer-level reviews, select the law firm associated with that lawyer.';
      }
    },

    reviewTypeIsLawyer() {
      return this.reviewType === 'lawyer';
    },

    reviewTypeIsFirm() {
      return this.reviewType === 'firm';
    },

    selectedFirmLawyers() {
      return this.firmLawyers[this.selectedFirm.id] || [];
    },

    hasSelectableLawyers() {
      return this.selectedFirm && this.lawyersAreLoaded(this.selectedFirm.id) && this.selectedFirmLawyers.length;
    },

    selectedFirmLawyersByFullName() {
      return sortBy(this.selectedFirmLawyers, 'fullName');
    },

    valid() {
      return this.selectedFirm && (this.reviewTypeIsFirm || this.selectedLawyer);
    }
  },

  watch: {
    reviewType() {
      if (this.reviewTypeIsFirm) {
        this.lawyerQuery = '';
        this.selectedLawyer = null;
      }
    }
  },

  mounted() {
    if (this.leavingReview) {
      this.openAndLoadFirms();
    }
  },

  methods: {
    openAndLoadFirms() {
      this.openModal();

      if (this.firmsAreLoaded) { return; }

      LoadingService.loading('reviewableFirms');
      this.loadFirms()
        .then((firms) => {
          this.firms = orderBy(firms, 'name');
          this.firmsAreLoaded = true;

          if (!this.selectedFirm) { return; }

          this.loadLawyers(this.selectedFirm);
        })
        .finally(() => {
          LoadingService.done('reviewableFirms');
        });
    },

    lawyersAreLoaded(firmId) {
      return has(this.firmLawyers, firmId);
    },

    loadLawyers(firm) {
      if (this.lawyersAreLoaded(firm.id)) { return; }

      LoadingService.loading('reviewableLawyers');
      this.loadFirmLawyers(firm.id)
        .then((lawyers) => {
          this.$set(this.firmLawyers, firm.id, lawyers);
        })
        .finally(() => {
          LoadingService.done('reviewableLawyers');
        });
    },

    selectFirm(firm) {
      this.selectedFirm = firm;
      this.lawyerQuery = '';
      this.selectedLawyer = null;

      if (this.isFeatureLab) {
        this.reviewType = 'firm';
      }
      else {
        this.loadLawyers(this.selectedFirm);
      }
    },

    selectLawyer(lawyer) {
      this.selectedLawyer = lawyer;
    },

    redirectToReviewForm() {
      let url;

      if (this.selectedLawyer) {
        url = `/scout-company/lawyers/${this.selectedLawyer.id}/reviews/new`;
      }
      else {
        url = `/scout-company/law-firms/${this.selectedFirm.id}/reviews/new`;
      }

      if (this.matterId) { url = url + `?matterId=${this.matterId}`; }
      window.location.href = url;
    },

    hideModal() {
      this.reset();
      this.closeModal();
    },

    reset() {
      this.searchedFirmName = this.preselectedFirm?.name || '';
      this.selectedFirm = this.preselectedFirm;
      this.reviewType = this.preselectedReviewType;
      this.lawyerQuery = '';
      this.selectedLawyer = null;
      this.$refs.firmTypeAhead.reset();
    }
  }
};
</script>

<template>
  <priori-modal ref="addFirmModal" modal-id="add-firm-modal" modal-size="md-sm" title="Add Law Firm(s)">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="pseudo-link-blue bold-weight size-text-13px" @click="openModal">+ Add Law Firms</button>
    </template>

    <div class="bottom-30">
      <underlined-header>
        Add Recipients from Your Firms
      </underlined-header>

      <div class="size-text-14px bottom-15">
        Select which of your law firms to add to this RFP. Note that RFPs will be sent to RFP managers designated by each firm.
      </div>

      <loading-section name="rfqAvailableFirms">
        <form role="form" class="a-form" novalidate>
          <typeahead-vertical
            ref="selectFirmTypeahead"
            label="Select a law firm to add to the RFP"
            rules="required"
            id="add-firms"
            :force-select="true"
            placeholder="Search..."
            :options="unselectedFirms"
            option-label-key="name"
            option-value-key="id"
            @input="addFirm">
          </typeahead-vertical>
        </form>
      </loading-section>

      <div v-if="hasAddedFirms" class="top-30">
        <underlined-header>
          Added Law Firms
        </underlined-header>

        <div v-for="(firm, index) in firmsToAdd" class="row tight-columns quote-container list-spacing-10">
          <div class="col-sm-8 size-text-15px bold-weight text-ellipsis">
            {{ firm.firmName }}
          </div>

          <div class="col-sm-4 text-right-not-xs">
            <button type="button" class="pseudo-link-gray pill-gray-text semibold-weight" @click="removeFirm(index)"><svg-icon name="trash" class="base-icon smaller right-5"></svg-icon>Remove Firm</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row tight-columns">
      <div class="col-sm-7">
        <loading-button name="addRecipients" :lb-class="['primary-btn-blue', { 'disabled': !hasAddedFirms }]" @lb-click="proceedToAddFirm">Send RFP to Law Firm(s)</loading-button>
      </div>

      <div class="col-sm-5 top-20-xs">
        <button type="button" class="tertiary-button-compact" @click="closeModal">Cancel</button>
      </div>
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import UnderlinedHeader from 'vue-app/shared/components/underlined-header.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'RfqAddRecipients',

  components: {
    PrioriModal,
    LoadingSection,
    LoadingButton,
    UnderlinedHeader,
    SvgIcon
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    availableFirms: {
      type: Array,
      required: true
    },

    addRecipients: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      firmsToAdd: []
    };
  },

  computed: {
    unselectedFirms() {
      return this.availableFirms.filter(firm => {
        return !this.firmsToAdd.some(quote => quote.lawFirmId === firm.id);
      });
    },

    hasAddedFirms() {
      return this.firmsToAdd.length > 0;
    }
  },

  methods: {
    addFirm(firmId) {
      if (firmId === null || firmId === '') { return; }

      const newFirm = this.availableFirms.find(obj => obj.id === firmId);

      this.firmsToAdd.push(
        {
          id: null,
          firmHasRfqEnabled: true,
          firmName: newFirm.name,
          lawFirmId: newFirm.id,
          suggestedLawyers: []
        }
      );
      this.$refs.selectFirmTypeahead.reset();
    },

    removeFirm(index) {
      this.firmsToAdd.splice(index, 1);
    },

    proceedToAddFirm() {
      if (!this.hasAddedFirms) { return; }

      LoadingService.loading('addRecipients');
      this.addRecipients(this.rfq, this.firmsToAdd, () => {
        LoadingService.done('addRecipients');
        this.closeModal();
      });
    },

    closeModal() {
      this.clearForm();
      this.$refs.addFirmModal.closeModal();
    },

    clearForm() {
      this.firmsToAdd = [];
      this.$refs.selectFirmTypeahead.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .quote-container {
    border: 1px solid $medium-gray;
    border-radius: $border-radius-base;
    padding: 20px;
  }
</style>

<template>
  <div class="dash-totals">
    <div class="row">
      <div class="col-sm-4 dash-totals-cell">
        <div class="tiny-gray-header-text">
          Total Legal Spend
        </div>

        <div class="dash-total-amount">
          {{ totalPaid }}
        </div>

        <div class="dash-total-footnote">
          <total-spend-modal :totals="totals"></total-spend-modal>
        </div>
      </div>

      <div class="col-sm-4 dash-totals-cell">
        <div class="tiny-gray-header-text">
          Unpaid Invoices
        </div>

        <div class="dash-total-amount">
          {{ totalUnpaid }}
        </div>

        <div class="dash-total-footnote">
          <a href="/client-app/invoices/unpaid">View Invoices</a>
        </div>
      </div>

      <div class="col-sm-4 dash-totals-cell">
        <div class="tiny-gray-header-text">
          Credits
        </div>

        <div class="dash-total-amount">
          {{ totalCredits }}
        </div>

        <div class="dash-total-footnote">
          <credits-modal :credits="totals.credits.total"></credits-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TotalSpendModal from 'vue-app/marketplace/client/dashboard/total-spend-modal.vue';
import CreditsModal from 'vue-app/marketplace/client/dashboard/credits-modal.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';

export default {
  name: 'DashTotals',

  components: {
    TotalSpendModal,
    CreditsModal
  },

  props: {
    client: {
      type: Object,
      required: true
    }
  },

  computed: {
    totals() {
      let totals = this.client.dashboardTotals;

      totals.credits.total = totals.credits.credit;

      totals.paid.flatRate = totals.paid.flatRate || 0;
      totals.paid.billedHour = totals.paid.billedHour || 0;
      totals.paid.retainer = totals.paid.retainer || 0;
      totals.paid.prepaidRealization = totals.paid.prepaidRealization || 0;
      totals.paid.management = totals.paid.management || 0;
      totals.paid.disbursement = totals.paid.disbursement || 0;
      totals.paid.transactionFees = totals.paid.transactionFees || 0;
      totals.paid.adjustment = totals.paid.adjustment || 0;
      totals.paid.credits = totals.paid.credits || 0;
      totals.paid.total = totals.paid.flatRate + totals.paid.billedHour +
        totals.paid.retainer + totals.paid.prepaidRealization +
        totals.paid.management + totals.paid.disbursement +
        totals.paid.transactionFees + totals.paid.adjustment +
        totals.paid.credits;

      return totals;
    },

    totalPaid() {
      return CurrencyFilter.filter(this.totals.paid.total / 100.0);
    },

    totalUnpaid() {
      return CurrencyFilter.filter(this.totals.unpaid / 100.0);
    },

    totalCredits() {
      return CurrencyFilter.filter(this.totals.credits.total / 100.0);
    }
  }
};
</script>

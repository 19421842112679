<template>
  <div>
    <div :class="['bottom-5', {'disabled-text': !question.isIncluded}, isAdminForm ? 'semibold-weight' : 'bold-weight']">
      {{ question.questionText }} <span class="inline-help required" v-if="question.isRequired">*</span>
      <scout-tag v-if="showQuestionUpdateTag" :scout-tag="tagData"></scout-tag>
    </div>

    <div class="row tight-columns vertical-center-not-xs">
      <div v-if="question.isIncluded" class="col-sm-3 button-column">
        <file-selector
          :accept="acceptedExtensions"
          :max-size="50"
          :multiple="true"
          @change="addAttachments">
          <div class="secondary-btn-blue smaller vertical-center clickable">
            <span class="left-10 right-10">Upload File(s)</span>
          </div>
        </file-selector>
      </div>

      <div class="col-sm-9 files-column top-20-xs">
        <loading-section name="addAttachments">
          <div class="file-list" v-if="hasAttachments">
            <div class="file-item" v-for="attachment in attachments">
              <file-tag :file="attachment" :on-delete="removeAttachment"></file-tag>
            </div>
          </div>
        </loading-section>
      </div>
    </div>
  </div>
</template>

<script>
import FileSelector from 'vue-app/shared/components/file-selector.vue';
import FileTag from 'vue-app/shared/components/file-tag.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';
import { without, startCase } from 'lodash';

export default {
  name: 'QuoteFormQuestionFile',

  components: {
    FileSelector,
    FileTag,
    LoadingSection,
    ScoutTag
  },

  props: {
    value: {
      type: Array
    },

    question: {
      type: Object,
      required: true
    },

    onChange: {
      type: Function,
      required: true
    },

    showQuestionUpdateTag: {
      type: Boolean,
      default: false
    },

    isAdminForm: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      attachments: this.value,
      acceptedExtensions: '.jpg,.jpeg,.gif,.png,.bmp,.pdf,.doc,.docx,.key,.xls,.xlsx,.csv,.txt,.ppt,.pptx,.pps,.ppsx,.odt,.rtf'
    };
  },

  computed: {
    hasAttachments() {
      return this.attachments && this.attachments.length > 0;
    },

    tagData() {
      return {
        color: 'yellow',
        name: this.activityText
      };
    },

    activityText() {
      return startCase(this.question.latestActivity?.activityType);
    }
  },

  methods: {
    addAttachments(attachments) {
      LoadingService.loading('addAttachments');

      this.onChange(Array.from(attachments))
        .then((response) => {
          this.attachments = response;
        })
        .finally(() => {
          LoadingService.done('addAttachments');
        });
    },

    removeAttachment(attachment) {
      attachment['_destroy'] = true;
      this.onChange([attachment]);

      this.attachments = without(this.attachments, attachment);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .button-column {
    @media (min-width: $screen-sm-min) {
      width: 21%;
    }

    @media (min-width: $screen-lg-min) {
      width: 18%;
    }
  }

  .files-column {
    @media (min-width: $screen-sm-min) {
      width: 79%;
    }

    @media (min-width: $screen-lg-min) {
      width: 82%;
    }
  }

  .file-list {
    margin-bottom: -10px;

    .file-item {
      display: inline-block;
      margin-bottom: 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
</style>

<template>
  <div>
    <input type="file" ref="fileInput" :name="name" :disabled="disabled" @change="fileSelected($event.target.files)" :accept="accept" class="invisible-input">

    <button type="button" :class="buttonClass" @click.prevent="selectFile" v-if="!value">
      {{ buttonText }}
    </button>

    <div v-if="value">
      <div class="bottom-10">
        {{ fileName || '&mdash;' }}

        <div class="error-text">
          <div class="top-5">
            <slot></slot>
          </div>
        </div>
      </div>

      <div>
        <a href="" @click.prevent="selectFile">Replace</a>
        <span class="left-10 right-10">&#8231;</span>
        <a href="" @click.prevent="deleteFile">Delete</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileInput',

  props: {
    value: [File, Object],

    buttonText: {
      type: String,
      default: 'Attach Document'
    },

    buttonClass: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    accept: {
      type: String,
      default: '.pdf,.doc,.docx'
    },

    name: {
      type: String,
      default: 'file-input'
    }
  },

  computed: {
    fileName: function () {
      return this.value?.name;
    }
  },

  methods: {
    selectFile: function () {
      this.$refs.fileInput.click();
    },

    deleteFile: function () {
      this.$emit('delete');
    },

    fileSelected(files) {
      this.$emit('input', files[0]);
      this.$emit('blur');
    }
  }
};
</script>

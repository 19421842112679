<template>
  <div :class="['quote-container', kebabCase(status)]">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-5">
        <div class="vertical-center-not-xs">
          <div class="relative-container inline-block right-10">
            <profile-photo v-if="quote.fromMarketplace && !quote.marketplaceLawyer.addedAsFirm" :user="quote.marketplaceLawyer" :small="true"></profile-photo>
            <svg-icon v-else name="law-firm" class="firm-icon small"></svg-icon>
          </div>

          <div class="size-text-15px semibold-weight text-ellipsis top-10-xs">
            {{ quoteLabel }}
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-md-5 quote-status size-text-12px vertical-center col-gap-5 top-10-xs">
        <div class="updated" v-if="hasRecentUpdates"></div>
        {{ statusText }}
      </div>

      <div v-if="quoteReceived" class="col-sm-3 col-md-2 text-right-not-xs">
        <a :href="`/scout-company/rfps/${rfq.id}/responses/${quote.id}`" class="bold-weight size-text-12px">View Quote</a>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import { kebabCase } from 'lodash';
import ProfilePhoto from 'vue-app/scout/shared/profile-photo.vue';

export default {
  name: 'QuoteItem',

  components: {
    ProfilePhoto
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    quote: {
      type: Object,
      required: true
    },

    status: {
      type: String,
      required: true
    },

    statusLabelMap: {
      type: Object,
      required: true
    }
  },

  computed: {
    quoteLabel() {
      return (this.quote.fromMarketplace && !this.quote.marketplaceLawyer.addedAsFirm) ? this.quote.marketplaceLawyer.fullName : this.nonMarketplaceProviderName;
    },

    nonMarketplaceProviderName() {
      return this.quote.lawFirm?.name || this.quote.lawFirmBridge.name;
    },

    quoteReceived() {
      return this.status === 'receivedScout' || this.status === 'receivedMarketplace';
    },

    statusText() {
      if (this.hasRecentUpdates) {
        return `Quote Updated ${DateFilter.filter(this.quote.lastUpdatedAt)}`;
      }
      else if (this.quoteReceived) {
        return `Quote Received ${DateFilter.filter(this.quote.dateResponded) || ''}`;
      }
      else {
        return this.statusLabelMap[this.status];
      }
    },

    hasRecentUpdates() {
      return this.quoteReceived && this.quote.lastUpdatedAt;
    }
  },

  methods: {
    kebabCase
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .quote-container {
    border: 1px solid $medium-gray;
    border-radius: $border-radius-base;
    padding: 10px 15px;

    &.received-scout {
      border-left: 3px solid $high-match-score-blue;
    }

    &.received-marketplace {
      border-left: 3px solid $k-purple;
    }

    &.pending {
      border-left: 3px solid $scout-gray;

      .quote-status {
        color: $pill-gray;
      }
    }

    &.declined {
      border-left: 3px solid $scout-red;

      .quote-status {
        color: $scout-red;
      }
    }
  }

  .updated {
    height: 6px;
    width: 6px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: $p-yellow;
  }
</style>

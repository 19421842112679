<template>
  <div>
    <div class="titlebar">
      <div class="medium-page-heading">
        RFPs
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <rfq-law-firm-quotes-index-table
            :quotes="quotes"
            :current-page="currentPage"
            :change-page="changePage"
            :on-sort="onSort"
            :total-count="totalCount"
            :per-page="perPage">
          </rfq-law-firm-quotes-index-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfqLawFirmQuotesIndexTable from './rfq-law-firm-quotes-index-table.vue';

export default {
  name: 'RFQLawFirmQuotesIndex',

  components: {
    RfqLawFirmQuotesIndexTable
  },

  props: {
    quotes: {
      type: Array,
      required: true
    },

    changePage: {
      type: Function,
      required: true
    },

    onSort: {
      type: Function,
      required: true
    },

    totalCount: {
      type: Number,
      required: true
    },

    currentPage: {
      type: Number,
      required: true
    },

    perPage: {
      type: Number,
      required: true
    }
  }
};
</script>

<template>
  <section class="uiv">
    <slot name="modal-trigger" :open-modal="openModal">
      <a href="" class="normal-weight size-text-13px" @click.prevent="openModal">{{ prompt }}</a>
    </slot>

    <modal v-model="modalOpen" :size="modalSize" :header="false" :footer="false" :backdrop="backdrop" :keyboard="false" @hide="cancel" append-to-body>
      <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-header">
          {{ title }}
        </div>

        <div class="notice-regular-text">
          <validation-observer v-slot="{ handleSubmit, failed }" ref="validationObserver">
            <form role="form" name="form" class="a-form" novalidate @submit.prevent>
              <div class="bottom-30">
                <div v-if="copyBuilt">
                  <slot name="default"></slot>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4">
                  <button type="button" class="nv-button-blue a-button-size" @click.prevent="handleSubmit(save)">
                    {{ saveButtonText }}
                  </button>
                </div>

                <div class="col-sm-3 top-20-xs">
                  <button type="button" class="secondary-btn-blue a-button-size" @click.prevent="dismissModal">
                    {{ cancelButtonText }}
                  </button>
                </div>
              </div>

              <div class="error-text top-10" v-if="failed && hasErrorSlot">
                <slot name="errorMessage"></slot>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'EditModal',

  components: {
    ModalCloseButton,
    ValidationObserver
  },

  mixins: [
    modalToggle
  ],

  props: {
    input: {
      type: [Object, Array, String, Number],
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    onCancel: {
      type: Function,
      default: () => {}
    },

    prompt: {
      type: String,
      default: 'Edit'
    },

    title: {
      type: String,
      required: true
    },

    backdrop: {
      type: Boolean,
      default: false
    },

    modalSize: {
      type: String,
      default: 'md'
    },

    saveButtonText: {
      type: String,
      default: 'Save'
    },

    cancelButtonText: {
      type: String,
      default: 'Cancel'
    }
  },

  data: function () {
    return {
      copyBuilt: false
    };
  },

  computed: {
    hasErrorSlot: function () {
      return this.$slots.errorMessage;
    }
  },

  watch: {
    modalOpen: function (open) {
      if (open) {
        this.copyBuilt = false;
        this.$emit('input', cloneDeep(this.input));
        this.copyBuilt = true;
      }
    }
  },

  methods: {
    save: function () {
      this.onSave();
      this.closeModal();
    },

    cancel: function () {
      this.closeModal();
      this.$refs.validationObserver.reset();
      this.onCancel();
    }
  }
};
</script>

<template>
  <div :id="`firmCard${quote.id}`" @click="changeSelectedQuote" :class="['vertical-center', 'name-plate', 'clickable', 'small-plate', statusBarClass, { 'selected-firm': isSelected }]">
    <div class="relative-container inline-block right-10 small-container">
      <profile-photo v-if="isMarketplaceLawyer" :user="quote.marketplaceLawyer" :small="true"></profile-photo>
      <svg-icon v-else name="law-firm" class="firm-icon small"></svg-icon>
    </div>

    <div class="text-ellipsis">
      <div class="firm-name size-text-15px text-ellipsis">
        {{ nameCardTitle }}
      </div>

      <div v-if="nameCardSubtitle.length" class="sub-title size-text-12px text-ellipsis">
        {{ nameCardSubtitle }}
      </div>

      <div v-if="showStatusText" :class="['text-uppercase size-text-11px semibold-weight', statusTextClass]">
        {{ formatStatusText }}
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ProfilePhoto from 'vue-app/scout/shared/profile-photo.vue';

export default {
  name: 'NameCard',

  components: {
    ProfilePhoto,
    SvgIcon
  },

  props: {
    quote: {
      type: Object,
      required: true
    },

    selectedQuote: {
      type: Object
    }
  },

  computed: {
    nameCardTitle() {
      return this.isMarketplaceLawyer ? this.quote.marketplaceLawyer.fullName : this.quote.lawFirm.name;
    },

    nameCardSubtitle() {
      return this.isMarketplaceLawyer ? (this.quote.marketplaceLawyer.firmTitle || '') : '';
    },

    isMarketplaceLawyer() {
      return this.quote.fromMarketplace && !this.quote.marketplaceLawyer.addedAsFirm;
    },

    showStatusText() {
      return this.isSelectedByClient || this.isDeclinedByClient || this.interviewRequested;
    },

    isSelected() {
      return this.selectedQuote?.id === this.quote.id;
    },

    isSelectedByClient() {
      return this.quote.status === 'selected_by_client';
    },

    isDeclinedByClient() {
      return this.quote.status === 'declined_by_client';
    },

    isNewRfq() {
      return this.quote.status === 'new_rfq';
    },

    isDraft() {
      return this.quote.status === 'draft';
    },

    isDeclinedByFirm() {
      return this.quote.status === 'declined_by_firm';
    },

    isQuoteSubmitted() {
      return this.quote.status === 'quote_submitted';
    },

    interviewRequested() {
      return this.quote.fromMarketplace && this.quote.interviewRequested;
    },

    statusBarClass() {
      if (this.quote.fromMarketplace) {
        return 'marketplace';
      }
      else if (this.isNewRfq || this.isDraft) {
        return 'pending-response';
      }
      else if (this.isQuoteSubmitted || this.isSelectedByClient || this.isDeclinedByClient) {
        return 'quote-received';
      }
      else if (this.isDeclinedByFirm) {
        return 'declined';
      }
    },

    formatStatusText() {
      if (this.interviewRequested) {
        return 'Interview Requested';
      }
      else if (this.isSelectedByClient) {
        return 'Selected';
      }
      else if (this.isDeclinedByClient) {
        return 'Declined';
      }
      else {
        return '';
      }
    },

    statusTextClass() {
      if (this.isSelectedByClient || this.interviewRequested) {
        return 'green-text';
      }
      else {
        return 'pill-gray-text';
      }
    }
  },

  methods: {
    changeSelectedQuote() {
      this.$emit('change-selected-quote', this.quote.id);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .name-plate.small-plate {
    padding: 10px;
    border: 1px solid $medium-gray;
    border-radius: $border-radius-large;

    &:hover, &.selected-firm {
      background: $k-lighter-gray;
      border-color: $k-dark-gray;
    }

    &.pending-response {
      border-left: 3px solid $scout-gray;
    }

    &.quote-received {
      border-left: 3px solid $high-match-score-blue;
    }

    &.declined {
      border-left: 3px solid $scout-red;
    }

    &.marketplace {
      border-left: 3px solid $k-purple;
    }

    .firm-name {
      font-weight: 600;
      line-height: 1.2;
    }

    &.selected-firm .firm-name {
      font-weight: 700;
    }
  }

  .missing-photo {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $k-light-gray;
    box-shadow: 0 0 8px 0 rgba($black, .1);
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    cursor: default;
    user-select: none;
    vertical-align: middle;
  }
</style>

<template>
  <modal v-model="modalOpen" size="md" :header="false" :footer="false" :backdrop="false" :keyboard="false" append-to-body>
    <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

    <div class="appointment-modal">
      <div class="modal-header">
        <div>
          <div class="bottom-5">
            Cancelling your {{ appointmentTypeText }} with
          </div>

          <div>
            {{ otherParticipantNames }}
          </div>
        </div>
      </div>

      <div class="appointment-container" v-if="!cancellationComplete">
        <validation-observer v-slot="{ handleSubmit }" ref="validationObserver">
          <form role="form" name="cancellationForm" class="a-form" novalidate>
            <div class="form-group">
              <textarea-vertical
                v-model="reason"
                label="Reason you are cancelling:"
                id="reason-for-cancellation"
                name="reasonForCancellation"
                :rows="3"
                rules="required">
              </textarea-vertical>
            </div>

            <div class="row top-30">
              <div class="col-sm-6">
                <loading-button name="cancelling" lb-class="nv-button-blue smaller" @lb-click="handleSubmit(submit)">Cancel {{ appointmentDescription }}</loading-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>

      <div class="appointment-container" v-if="cancellationComplete">
        <div class="row top-10 bottom-50">
          <div class="col-md-10 col-md-offset-1 size-text-base text-center">
            You have successfully cancelled your {{ appointmentTypeText }} with {{ otherParticipantNames }}.
          </div>
        </div>

        <div class="row bottom-10">
          <div class="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
            <button type="button" class="nv-button-white smaller" @click="dismissConfirmation">Close Window</button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import TextareaVertical from 'vue-app/shared/components/textarea-vertical.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import { ValidationObserver } from 'vee-validate';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';

export default {
  name: 'CancellingModal',

  components: {
    TextareaVertical,
    LoadingButton,
    ModalCloseButton,
    ValidationObserver
  },

  mixins: [
    modalToggle
  ],

  props: {
    appointment: {
      type: Object,
      required: false
    },

    otherParticipants: {
      type: Array,
      required: false
    },

    onSubmit: {
      type: Function,
      required: true
    },

    onDismissConfirmation: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      cancellationComplete: false,
      reason: ''
    };
  },

  computed: {
    appointmentDescription() {
      return this.appointment?.type === 'in-person' ? 'Meeting' : 'Call';
    },

    appointmentTypeText() {
      return this.appointment ? this.appointment.typeText() : '';
    },

    otherParticipantNameList() {
      return this.otherParticipants.map(p => this.nameAndTitleFor(p));
    },

    otherParticipantNames() {
      return this.otherParticipantNameList.join(', ');
    }
  },

  methods: {
    initModal() {
      this.$refs.validationObserver.reset();
      this.reason = '';
      this.openModal();
    },

    nameAndTitleFor(participant) {
      const titleComponents = [participant.firstName, participant.lastName];
      if (participant.klass === 'Client' && participant.company && participant.company.length) {
        titleComponents.push(`(${participant.company})`);
      }
      return titleComponents.join(' ');
    },

    submit() {
      LoadingService.around('cancelling', () => {
        return this.onSubmit(this.appointment, this.reason).then(() => {
          this.cancellationComplete = true;
        });
      });
    },

    dismissConfirmation() {
      this.closeModal();
      this.onDismissConfirmation();
    }
  }
};
</script>

<template>
  <modal v-model="modalOpen" size="md" :header="false" :footer="false" :backdrop="true" :keyboard="true" @hide="dismissModal()" append-to-body>
    <div class="notice-modal">
      <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

      <div class="notice-header">
        <div class="text-center bottom-20">
          <div class="circular-gradient">
            <svg-icon name="checkmark-no-circle" class="base-icon checkmark"></svg-icon>
          </div>
        </div>

        <div>
          <div v-if="isLawyer">
            Lawyer successfully added to list
          </div>

          <div v-else>
            Law firm successfully added to list
          </div>
        </div>
      </div>

      <div class="notice-regular-text">
        <div class="size-text-16px bottom-30">
          <div v-if="isLawyer">
            You have successfully added the selected lawyer to the applicable list. To review your list, go to the left navigation bar and select "Lists", which is located below "Search".
          </div>

          <div v-else>
            You have successfully added the selected law firm to the applicable list. To review your list, go to the left navigation bar and select "Lists", which is located below "Search".
          </div>
        </div>

        <div class="text-center">
          <button type="button" class="primary-button-compact" @click.prevent="dismissModal()">Close</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ScoutSuccessListModal',

  components: {
    ModalCloseButton,
    SvgIcon
  },

  mixins: [
    modalToggle
  ],

  props: {
    showSuccessModal: {
      type: Boolean,
      default: false
    },

    isLawyer: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    showSuccessModal(value) {
      this.modalOpen = value;
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .checkmark {
    margin-top: 16px;
    width: 20px;
    height: 20px;
    fill: $white;
  }

  .notice-modal .notice-header {
    height: auto;
  }
</style>

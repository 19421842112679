<template>
  <div class="shadowed-box">
    <div class="box-header notes-header">
      <div class="row tight-columns">
        <div class="col-sm-6 text-uppercase">
          <svg-icon name="note" class="base-icon right-5"></svg-icon>
          Notes
        </div>

        <div class="col-sm-6 size-text-12px normal-weight text-right-not-xs">
          <div v-if="editingAllowed">
            <edit-firm-notes title="Editing Notes" :notes="notes" :on-save="save" :editing-allowed="editingAllowed"></edit-firm-notes>
          </div>
        </div>
      </div>
    </div>

    <div class="notes-content">
      <div class="pre-scrollable">
        <div class="pre-scrollable-content">
          <loading-section name="firm-notes">
            <firm-note v-for="note in stickyNotes" class="list-spacing-10" :key="`sticky-${note.id}`" :note="note"></firm-note>

            <firm-note v-for="note in notes" class="list-spacing-10" :key="note.id" :note="note"></firm-note>

            <div class="gray-text size-text-16px" v-if="!notes.length && !stickyNotes.length">
              No Notes
            </div>
          </loading-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirmNote from './firm-note.vue';
import EditFirmNotes from './edit-firm-notes.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import ScoutLawFirmClientNote from 'resources/scout/scout-law-firm-client-note';

import { uniqueId, map } from 'lodash';

export default {
  name: 'FirmNotes',

  components: {
    FirmNote,
    EditFirmNotes
  },

  props: {
    scoutLawFirmBridge: {
      type: Object,
      required: true
    },

    editingAllowed: {
      type: Boolean,
      default: false
    },

    stickyNoteBodies: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      notes: []
    };
  },

  computed: {
    stickyNotes() {
      return map(this.stickyNoteBodies, (body) => {
        return {
          content: body,
          id: uniqueId()
        };
      });
    }
  },

  mounted() {
    this.loadNotes();
  },

  methods: {
    loadNotes() {
      LoadingService.loading('firm-notes');

      ScoutLawFirmClientNote.query({ lawFirmBridgeId: this.scoutLawFirmBridge.id }).then((response) => {
        this.notes = response;
        LoadingService.done('firm-notes');
      });
    },

    save(notes) {
      LoadingService.loading('firm-notes');

      ScoutLawFirmClientNote.multi(this.scoutLawFirmBridge.id, { notes: notes }).then(() => {
        this.loadNotes();
        LoadingService.done('firm-notes');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .box-header.notes-header {
    background-color: transparent;
  }

  .notes-content {
    padding: 0 10px 20px 30px;
  }

  .pre-scrollable {
    max-height: 220px;
  }

  .pre-scrollable-content {
    padding-right: 20px;
  }
</style>

<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical">
      <div class="row">
        <div class="col-sm-4">
          <review-responses-firm
            v-if="!onlySentToMarketplace"
            :rfq="rfq"
            :selected-quote="currentSelectedQuote"
            :update-selected-quote="changeSelectedQuote">
          </review-responses-firm>

          <review-responses-marketplace
            v-if="rfq.sentToMarketplace"
            :class="{ 'top-30': !onlySentToMarketplace }"
            :rfq="rfq"
            :selected-quote="currentSelectedQuote"
            :update-selected-quote="changeSelectedQuote">
          </review-responses-marketplace>

          <button v-if="submittedQuotes.length" type="button" @click="downloadAllQuotes" class="pseudo-link-blue bold-weight top-40">
            Download All Quotes
          </button>
        </div>

        <div v-if="currentSelectedQuote" class="col-sm-8 top-30-xs">
          <selected-response
            :rfq="rfq"
            :selected-quote="currentSelectedQuote"
            :update-quote="updateQuote"
            :update-selected-quote="changeSelectedQuote">
          </selected-response>
        </div>

        <div v-else :class="['col-sm-8', { 'top-50': !onlySentToMarketplace }]">
          <div class="size-text-15px bold-weight text-center top-40">
            You have not yet received any responses
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ReviewResponsesFirm from 'vue-app/scout/client/rfqs/review-responses/firm-responses.vue';
import ReviewResponsesMarketplace from 'vue-app/scout/client/rfqs/review-responses/marketplace-responses.vue';
import SelectedResponse from 'vue-app/scout/client/rfqs/review-responses/selected-response.vue';
import RfqQuoteToDocx from 'src/services/scout/client/rfq-quote-to-docx';

export default {
  name: 'RfqResponses',

  components: {
    ReviewResponsesFirm,
    ReviewResponsesMarketplace,
    SelectedResponse
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    selectedQuote: {
      type: Object
    },

    updateQuote: {
      type: Function,
      required: true
    },

    onlySentToMarketplace: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      currentSelectedQuote: this.selectedQuote
    };
  },

  computed: {
    submittedQuotes() {
      return this.rfq.lawFirmQuotes.filter(quote => {
        return ['quote_submitted', 'selected_by_client', 'declined_by_client'].includes(quote.status);
      });
    }
  },

  methods: {
    changeSelectedQuote(quote) {
      this.currentSelectedQuote = quote;
    },

    downloadAllQuotes() {
      const rfqQuoteToDocx = new RfqQuoteToDocx(this.rfq, this.submittedQuotes);
      rfqQuoteToDocx.generateDocx();
    }
  }
};
</script>

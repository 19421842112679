<template>
  <div @click="openRehireModal">
    <slot></slot>

    <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" append-to-body>
      <div class="notice-modal">
        <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

        <div v-show="!submitted">
          <div class="notice-header">
            Rehire Lawyer
          </div>

          <div class="notice-regular-text">
            <form role="form" name="rehireForm" class="form a-form" novalidate>
              <div class="tiny-header-text bottom-10">
                Lawyer
              </div>

              <div class="bottom-30">
                <dropdown ref="dropdown" class="k-dropdown" v-model="dropdownOpen">
                  <button type="button" role="button" id="k-toggle-button-rehire" :class="['dropdown-toggle', { 'placeholder': !selectedLawyer }]">
                    <span v-if="!selectedLawyer">Select Lawyer</span>
                    <span class="semibold-weight" v-if="selectedLawyer">{{ selectedLawyer.fullName }}</span>
                    <svg-icon name="caret-down" class="caret-down"></svg-icon>
                    <svg-icon name="caret-up" class="caret-up"></svg-icon>
                  </button>

                  <template #dropdown>
                    <div class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
                      <li role="menuitem" class="menu-item" v-for="l in lawyers" :key="l.id" @click="selectLawyer(l)">
                        <div class="dropdown-row-inner">
                          <profile-photo :user="l" :small="true"></profile-photo>
                          <span class="semibold-weight left-20">{{ l.fullName }}</span>
                        </div>
                      </li>
                    </div>
                  </template>
                </dropdown>
              </div>

              <div class="tiny-header-text bottom-10">
                Project Details
              </div>

              <textarea name="message" id="message" class="form-control" placeholder="Enter project details..." rows="6" v-model="message"></textarea>
            </form>

            <div class="row top-30">
              <div class="col-sm-4">
                <button type="button" class="nv-button-purple-red a-button-size" @click.prevent="submit()" :disabled="!formValid">Send</button>
              </div>
            </div>
          </div>
        </div>

        <div v-show="submitted">
          <div class="notice-header">
            Sent
          </div>

          <div class="notice-text">
            <div class="text-left">
              Your message has been sent successfully. You can access your messages from your dashboard and will receive an email alerting you when you receive a response.
            </div>

            <div class="row top-30">
              <div class="col-sm-6 col-sm-offset-3">
                <button type="button" class="nv-button-white a-button-size" @click="closeModal">Close Window</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import Lawyer from 'resources/lawyer.js';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown.js';

export default {
  name: 'RehireLawyer',

  components: {
    ProfilePhoto,
    ModalCloseButton,
    SvgIcon
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  mixins: [
    modalToggle
  ],

  props: {
    lawyers: {
      type: Array,
      default: () => []
    },

    lawyer: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      selectedLawyer: this.lawyer,
      message: '',
      submitted: false,
      dropdownOpen: false
    };
  },

  computed: {
    formValid() {
      return this.selectedLawyer && this.message.length;
    }
  },

  methods: {
    openRehireModal() {
      this.resetForm();
      this.openModal();
    },

    selectLawyer(lawyer) {
      this.selectedLawyer = lawyer;
    },

    resetForm() {
      this.selectedLawyer = this.lawyer;
      this.message = '';
      this.submitted = false;
    },

    submit() {
      if (this.formValid) {
        Lawyer.rehire(this.selectedLawyer.id, { text: this.message }).then(() => {
          this.resetForm();
          this.submitted = true;
        });
      }
    }
  }
};
</script>

<template>
  <section class="uiv">
    <slot v-if="!promptHidden" name="modal-trigger" :open-modal="openModal">
      <button type="button" :class="[openBtnClass, 'text-ellipsis']" @click="openModal">{{ prompt }}</button>
    </slot>

    <modal :id="modalId" v-model="modalOpen" :size="modalSize" :header="false" :footer="false" :backdrop="backdrop" :keyboard="backdrop" append-to-body>
      <div class="close-modal full-screen" data-toggle="modal" :data-target="modalId" @click="dismissModal()">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <template slot="default">
        <div class="notice-modal">
          <div class="notice-header">
            <slot name="title">
              {{ title }}
            </slot>
          </div>

          <div class="notice-regular-text">
            <slot :close-modal="closeModal"></slot>
          </div>
        </div>
      </template>
    </modal>
  </section>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';

export default {
  name: 'PrioriModal',

  components: {
    SvgIcon
  },

  mixins: [
    modalToggle
  ],

  props: {
    modalId: {
      type: String,
      required: true
    },

    modalSize: {
      type: String,
      default: 'md-sm'
    },

    title: {
      type: String,
      required: false
    },

    prompt: {
      type: String,
      default: 'Open'
    },

    promptHidden: {
      type: Boolean,
      default: false
    },

    backdrop: {
      type: Boolean,
      default: true
    },

    openBtnClass: {
      type: String,
      default: 'secondary-btn-blue'
    }
  }
};
</script>

<style lang="scss" scoped>
  .notice-header {
    height: auto;
  }
</style>

<template>
  <priori-modal
    ref="editClientModal"
    modal-id="edit-client"
    modal-size="md"
    :backdrop="false"
    :prompt-hidden="true"
    title="Edit User"
    @on-modal-close="reset">
    <template #default>
      <client-form
        ref="clientForm"
        :client="client"
        :on-save="save"
        :on-cancel="closeModal"
        :practice-groups="practiceGroups"
        :is-user-manager="isUserManager"
        :is-employee-id-required="isEmployeeIdRequired">
      </client-form>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import ClientForm from 'vue-app/scout/client/settings/users/client-form.vue';

export default {
  name: 'EditClient',

  components: {
    ClientForm,
    PrioriModal
  },

  mixins: [
    interactionModes
  ],

  props: {
    onSave: {
      type: Function,
      default: () => {}
    },

    practiceGroups: {
      type: Array,
      default: () => []
    },

    isUserManager: {
      type: Boolean,
      default: false
    },

    isEmployeeIdRequired: {
      type: Boolean,
      default: true
    },

    user: {
      type: Object
    },

    showModal: {
      type: Boolean,
      default: null
    },

    onCloseModal: {
      type: Function,
      default: () => {}
    }
  },

  data: function () {
    return {
      client: Object.assign({}, this.user)
    };
  },

  watch: {
    showModal(showModal) {
      if (showModal) {
        this.openModal();
      }
    },

    user(user) {
      if (user) {
        this.client = Object.assign({}, user);
      }
    }
  },

  methods: {
    openModal() {
      this.$refs.editClientModal.openModal();
    },

    save() {
      LoadingService.loading('submit');

      if (this.billingIdIsEmployeeId) {
        this.client.billingId = this.client.employeeId;
      }

      this.onSave(this.client).finally(
        () => {
          LoadingService.done('submit');
          this.closeModal();
        }
      );
    },

    closeModal() {
      this.reset();
      this.$refs.editClientModal.closeModal();
    },

    reset() {
      this.billingIdIsEmployeeId = false;
      this.noBillingId = false;
      this.client = {};
      this.onCloseModal();
      this.$refs.clientForm.reset();
    }
  }
};
</script>

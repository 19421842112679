import { compact, filter, get, includes, orderBy, reduce, some } from 'lodash';

function lowerCaseProjectName(project) {
  return project.nameForClientsReference.toLowerCase();
}

function lowerCaseLawyerName(project) {
  return get(project.lawyer, 'fullName', '').toLowerCase();
}

// Default order: RFP Submitted, RFP Pending, Matter Open, Matter Closed, RFP Closed
function projectStatusOrder(project) {
  let orderPriority = 1;

  switch (project.status) {
    case 'matter_opened':
    case 'admin_closed':
      orderPriority = 5;
      break;
    case 'closed':
      orderPriority = 4;
      break;
    case 'open':
      orderPriority = 3;
      break;
    case 'sent_to_client':
      orderPriority = 2;
  }

  return orderPriority;
}

function openDateOrder(project) {
  return project.startedDate || new Date(0).toISOString();
}

export default {
  defaultFilter: 'All',
  defaultSort: 'Recent Activity',

  filterOptions: [
    'All',
    'Matter Open',
    'Matter Closed',
    'RFP Submitted',
    'RFP Pending',
    'RFP Closed'
  ],

  sortOptions: [
    'Recent Activity',
    'Project Name',
    'Lawyer Name',
    'Open Date',
    'Closed Date'
  ],

  normalizedSearchableData(projects) {
    return reduce(projects, (data, project) => {
      data[project.id] = compact([
        project.nameForClientsReference.toLowerCase(),
        project.referenceNumber.toLowerCase(),
        project.lawyer ? project.lawyer.fullName.toLowerCase() : null,
        project.client ? project.client.fullName.toLowerCase() : null,
        project.requester ? project.requester.fullName.toLowerCase() : null
      ]);
      return data;
    }, {});
  },

  filterFunction(projects, configurableFilter) {
    const value = configurableFilter.filterValue;

    if (value === 'All') {
      return projects;
    }
    else if (value === 'Matter Closed') {
      return filter(projects, (p) => { return !p.isRequest && (p.status === 'closed' || p.status === 'admin_closed'); });
    }
    else if (value === 'Matter Open') {
      return filter(projects, (p) => { return p.status === 'open'; });
    }
    else if (value === 'RFP Closed') {
      return filter(projects, (p) => { return p.isRequest && (p.status === 'matter_opened' || p.status === 'admin_closed'); });
    }
    else if (value === 'RFP Pending') {
      return filter(projects, (p) => { return p.status === 'sent_to_client'; });
    }
    else if (value === 'RFP Submitted') {
      return filter(projects, (p) => { return p.status === 'new'; });
    }
    else {
      return [];
    }
  },

  sortFunction(projects, configurableFilter) {
    const value = configurableFilter.sortValue;

    if (value === 'Recent Activity') {
      return orderBy(projects, [projectStatusOrder, 'updatedAt'], ['asc', 'desc']);
    }
    else if (value === 'Project Name') {
      return orderBy(projects, [lowerCaseProjectName], ['asc']);
    }
    else if (value === 'Lawyer Name') {
      return orderBy(projects, [lowerCaseLawyerName], ['asc']);
    }
    else if (value === 'Open Date') {
      return orderBy(projects, [openDateOrder], ['desc']);
    }
    else if (value === 'Closed Date') {
      return orderBy(projects, [projectStatusOrder, 'endedDate', 'startedDate'], ['desc', 'desc', 'desc']);
    }
    else {
      return [];
    }
  },

  searchFunction(projects, configurableFilter) {
    return projects.filter((project) => {
      return some(configurableFilter.normalizedData[project.id], (str) => includes(str, configurableFilter.searchValue.toLowerCase()));
    });
  }
};

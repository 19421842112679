<template>
  <div>
    <button
      type="button"
      :disabled="isDeclined"
      @click.prevent="openDeclineFirmModal"
      :class="['icon-button nv-button-white flex-column', { 'declined': isDeclined }]">
      <svg-icon name="x-circle" class="base-icon"></svg-icon>
      <span class="top-5">{{ isDeclined ? 'Declined' : 'Decline' }}</span>
    </button>

    <modal-wrapper name="decline-firm">
      <template #default="{ closeModal: closeModal }">
        <div class="notice-modal">
          <div class="notice-header text-uppercase">
            {{ noticeHeader }}
          </div>

          <div class="notice-regular-text">
            <div class="semibold-weight size-text-14px bottom-20">
              {{ noticeBody }}
            </div>

            <div class="semibold-weight size-text-14px bottom-30">
              Are you sure you&rsquo;d like to proceed?
            </div>

            <div class="row tight-columns">
              <div class="col-sm-5">
                <button type="button" class="primary-btn-night-blue" @click="declineFirm">Yes, Decline</button>
              </div>

              <div class="col-sm-4 top-20-xs">
                <button type="button" class="secondary-btn-blue" @click="closeModal">No, Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal-wrapper>
  </div>
</template>

<script>
import ModalManager from 'vue-app/shared/services/modal-manager';
import ModalWrapper from 'vue-app/shared/components/modals/wrapper.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'DeclineFirmButton',

  components: {
    ModalWrapper,
    SvgIcon
  },

  props: {
    selectedQuote: {
      type: Object,
      required: true
    },

    isDeclined: {
      type: Boolean,
      default: false
    },

    rfq: {
      type: Object,
      required: true
    },

    isMarketplace: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    noticeHeader() {
      return this.isMarketplace ? `Decline ${this.marketplaceProviderName}` : 'Confirm Declining Firm';
    },

    marketplaceProviderName() {
      return this.selectedQuote.marketplaceLawyer.addedAsFirm ? this.selectedQuote.lawFirm.name : this.selectedQuote.marketplaceLawyer.fullName;
    },

    noticeBody() {
      return this.isMarketplace ? 'Confirm your selection below. Once confirmed, Priori will be notified that you have declined this quote and will handle any required communications with the provider.' : 'By confirming your selection, you will notify the Firm that you have declined their quote for the project.';
    }
  },

  methods: {
    openDeclineFirmModal() {
      ModalManager.open('decline-firm');
    },

    declineFirm() {
      this.$emit('client-declined-firm', this.rfq, this.selectedQuote, 'decline_firm');
      ModalManager.close('decline-firm');
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 105px;
    height: 100%;

    @media (min-width: $screen-sm-min) {
      width: 90px;
    }

    @media (min-width: 1055px) {
      width: 100px;
    }

    @media (min-width: $screen-lg-min) {
      width: 110px;
    }

    &:disabled, &:disabled .base-icon {
      opacity: 1;
    }
  }

  .declined {
    color: $scout-red;
    fill: $scout-red;
    background-color: $red-10;
    border-color: $scout-red;

    svg {
      fill: $scout-red;
    }
  }
</style>

<template>
  <priori-modal ref="modal" modal-id="suggest-firm" title="Suggest a Law Firm" prompt="Suggest Law Firm" @on-modal-close="reset">
    <internal-message-form
      :on-submit="onSubmit"
      :message="internalMessage"
      instructions="Recommend a law firm you would like to have available on Scout:">
    </internal-message-form>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import InternalMessage from 'vue-app/shared/resources/internal-message';
import InternalMessageForm from 'vue-app/shared/components/internal-message-form.vue';

export default {
  name: 'SuggestAFirm',

  components: {
    PrioriModal,
    InternalMessageForm
  },

  data: function () {
    return {
      internalMessage: this.generateMessage()
    };
  },

  methods: {
    generateMessage: function () {
      return new InternalMessage({
        domain: 'scout_firm_suggestion',
        parts: [
          { title: 'Firm Name', required: true },
          { title: 'Note', textarea: true, label: 'Add a note (optional)' }
        ]
      });
    },

    onSubmit: function () {
      this.$refs.modal.dismissModal();
      this.reset();
    },

    reset: function () {
      this.internalMessage = this.generateMessage();
    }
  }
};
</script>

<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a href="/client-app/dashboard" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Dashboard</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="top-10 bottom-30">
            <horizontal-sort-filter
              :sort-filter="sortFilter"
              :filter-config="invoiceFilterConfig"
              @filter="setFilter"
              @sort="setSort"
              @search="setSearch">
            </horizontal-sort-filter>
          </div>

          <div class="shadowed-box">
            <div class="box-content">
              <loading-section name="invoices">
                <div class="table-responsive" v-if="hasInvoices">
                  <table id="all-invoices" class="table auto-height no-margin">
                    <thead>
                      <th>Invoice&nbsp;Date</th>
                      <th>Invoice&nbsp;#</th>
                      <th>Lawyer</th>
                      <th>Project</th>
                      <th>Your&nbsp;Total</th>
                      <th>Status</th>
                      <th>Due/Paid</th>
                      <th></th>
                    </thead>

                    <tbody>
                      <invoice-row
                        v-for="invoice in visibleInvoices"
                        :key="invoice.id"
                        :invoice="invoice">
                      </invoice-row>
                    </tbody>
                  </table>
                </div>

                <div class="top-20 bottom-20 gray-text tight-lines text-center" v-if="!hasInvoices">
                  No Invoices
                </div>
              </loading-section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceRow from 'vue-app/marketplace/client/invoices/invoice-row.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import HorizontalSortFilter from 'vue-app/marketplace/shared/horizontal-sort-filter.vue';

import Invoice from 'resources/marketplace/invoice.js';

import useCurrentUserStore from 'vue-app/stores/current-user.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import ConfigurableFilter from 'services/configurable-filter.js';
import InvoiceFilterConfig from 'services/marketplace/filter-config/invoices.js';

import { mapState } from 'pinia';
import { capitalize } from 'lodash';

export default {
  name: 'InvoicesIndex',

  components: {
    InvoiceRow,
    LoadingSection,
    HorizontalSortFilter
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    const sortFilter = new ConfigurableFilter(InvoiceFilterConfig, { filterValue: capitalize(this.initData.filter) });

    return {
      invoices: [],
      sortFilter: sortFilter,
      invoiceFilterConfig: InvoiceFilterConfig
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    visibleInvoices() {
      return this.sortFilter.filter(this.invoices);
    },

    hasInvoices() {
      return this.visibleInvoices.length > 0;
    }
  },

  mounted() {
    this.loadInvoices();
  },

  methods: {
    loadInvoices() {
      LoadingService.around('invoices', () => {
        return Invoice.query({ clientId: this.user.id, view: 'client_index' }).then((invoices) => {
          this.invoices = invoices.filter((i) => {
            return i.status !== 'written_off' && (i.dueDate || i.status === 'paid');
          });
        });
      });
    },

    setFilter(value) {
      this.sortFilter.filterBy(value);
    },

    setSort(value) {
      this.sortFilter.sortBy(value);
    },

    setSearch(value) {
      this.sortFilter.searchBy(value);
    }
  }
};
</script>

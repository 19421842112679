<template>
  <form class="a-form" novalidate>
    <div v-if="header" class="bold-weight bottom-20">
      {{ header }}
    </div>

    <label v-for="option in options" class="check-option medium-large vertical" :key="option.value">
      <input class="input" type="radio" v-model="value" :value="option.value" @click="onClick(option.value)">
      <span class="normal-weight">{{ option.label }}</span>
      <span class="check c-radio"></span>
    </label>
  </form>
</template>

<script>
export default {
  name: 'FormWithRadioButtons',

  props: {
    header: {
      type: String,
      default: ''
    },

    options: {
      type: Array,
      required: true
    },

    initialSelection: {
      type: String,
      default: null
    },

    onClick: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      value: this.initialSelection
    };
  }
};
</script>

<style scoped lang="scss">
.check-option:not(:last-of-type) {
  margin-bottom: 10px;
}
</style>

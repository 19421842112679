<template>
  <div>
    <div class="notice-regular-text">
      <form role="form" name="addClientForm" class="a-form" v-show="loading || workspaceUsers.length">
        <loading-section name="addClientForm">
          <label>Team Member <span class="inline-help required">*</span></label>

          <dropdown ref="dropdown" class="k-dropdown" v-model="dropdownOpen">
            <button type="button" role="button" id="k-toggle-button-user" :class="['dropdown-toggle', { 'placeholder': !hasSelectedUser }]">
              <span v-if="!hasSelectedUser">Select One</span>
              <span class="semibold-weight" v-if="hasSelectedUser">{{ selectedUser.fullName }}</span>
              <svg-icon name="caret-down" class="caret-down"></svg-icon>
              <svg-icon name="caret-up" class="caret-up"></svg-icon>
            </button>

            <template slot="dropdown" class="dropdown-menu" role="menu" aria-labelledby="k-toggle-button-user">
              <div class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
                <li role="menuitem" class="menu-item" v-for="client in workspaceUsers" :key="client.id" @click="selectUser(client)">
                  <div class="dropdown-row-inner">
                    <profile-photo :user="client" :small="true"></profile-photo><span class="semibold-weight left-20">{{ client.fullName }}</span>
                  </div>
                </li>
              </div>
            </template>
          </dropdown>
        </loading-section>
      </form>

      <div v-show="!loading && !workspaceUsers.length">
        <div class="size-text-base">
          There are no existing team members available to add to this project.
        </div>

        <div class="text-center top-30 bottom-10" v-if="allowAddingWorkspaceUsers">
          <a href="" class="bold-link blue-link" @click.prevent="addWorkspaceUser"><svg-icon name="invite" class="base-icon right-10"></svg-icon>Add New User</a>
        </div>
      </div>
    </div>

    <div v-if="loading || workspaceUsers.length">
      <div class="row bottom-30">
        <div class="col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-3">
          <loading-button name="addClient" lb-class="nv-button-blue a-button-size" @lb-click="addClient" :lb-disabled="!formValid">
            Add to Project
          </loading-button>
        </div>
      </div>

      <div class="notice-action-container semibold-weight size-text-11px bottom-30">
        <span class="bold-weight red-text">IMPORTANT NOTE:</span>
        As you would with any similar disclosure, consider whether any attorney-client privilege issues or waiver could arise from sharing access to this project with a third party. If you have any questions, please consult your project lawyer before proceeding.
      </div>

      <div class="notice-footer" v-if="allowAddingWorkspaceUsers">
        <a href="" class="size-text-13px bold-link blue-link" @click.prevent="addWorkspaceUser"><svg-icon name="invite" class="base-icon right-10"></svg-icon>Add New User</a>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown.js';

export default {
  name: 'ProjectTeamAddForm',

  components: {
    LoadingSection,
    ProfilePhoto,
    SvgIcon,
    LoadingButton
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  props: {
    workspaceUsers: {
      type: Array,
      default: () => []
    },

    allowAddingWorkspaceUsers: {
      type: Boolean,
      default: false
    },

    onAddWorkspaceUser: {
      type: Function
    },

    onAddClient: {
      type: Function
    }
  },

  data() {
    return {
      selectedUser: null,
      dropdownOpen: false
    };
  },

  computed: {
    loading() {
      return LoadingService.isLoading('addClientForm');
    },

    hasSelectedUser() {
      return !!this.selectedUser;
    },

    formValid() {
      return this.hasSelectedUser;
    }
  },

  methods: {
    selectUser(client) {
      this.selectedUser = client;
    },

    addClient() {
      if (!this.formValid) { return; }

      const vueInstance = this;
      LoadingService.loading('addClient');

      this.onAddClient({
        client: this.selectedUser,
        successFn: () => {
          vueInstance.selectedUser = null;
          LoadingService.done('addClient');
        },
        failFn: () => {
          LoadingService.done('addClient');
        }
      });
    },

    addWorkspaceUser() {
      if (!this.allowAddingWorkspaceUsers) { return; }
      this.onAddWorkspaceUser();
    }
  }
};
</script>

<template>
  <div id="workspace-info" class="shadowed-box">
    <validation-observer v-slot="{ handleSubmit }">
      <div class="box-header">
        <div class="row">
          <div class="col-sm-8 text-uppercase">
            Company Information
          </div>

          <div class="col-sm-4 size-text-13px text-right-not-xs">
            <a href="" class="normal-weight" @click.prevent="startEdit" v-if="editingAllowed && !isEditing">Edit</a>

            <span v-if="isEditing">
              <a href="" class="normal-weight right-15" @click.prevent="handleSubmit(save)">Save</a>

              <a href="" class="dark-gray-text normal-weight" @click.prevent="cancelEdit">Cancel</a>
            </span>
          </div>
        </div>
      </div>

      <div class="box-content">
        <form role="form" name="form" class="a-form" novalidate>
          <div class="bottom-20">
            <text-input-horizontal label="Company Name" name="name" :current-value="workspace.name" :edit-mode="isEditing" v-model="workspaceCopy.name"></text-input-horizontal>
          </div>

          <div class="row">
            <div class="semibold-weight" :class="isEditing ? 'col-sm-12 bottom-10' : 'col-sm-4'">
              Company Location
            </div>

            <div :class="isEditing ? 'col-sm-12' : 'col-sm-8'">
              <div v-if="!isEditing">
                {{ location }}
              </div>

              <div v-if="isEditing">
                <div class="row tight-columns">
                  <div class="col-sm-4 bottom-10">
                    <input id="address-country" class="form-control" type="text" placeholder="Country" autocomplete="nope">
                    <typeahead v-model="workspaceCopy.country" target="#address-country" :data="countries" @input="countrySelected" force-select force-clear></typeahead>
                  </div>

                  <div class="col-sm-4 bottom-10">
                    <input id="address-state" class="form-control" type="text" :placeholder="workspaceCopy.country === 'United States' ? 'State' : 'N/A'" autocomplete="nope" :disabled="workspaceCopy.country !== 'United States'">
                    <typeahead v-model="workspaceCopy.state" target="#address-state" :data="states" force-select force-clear></typeahead>
                  </div>

                  <div class="col-sm-4">
                    <input type="text" name="addressCity" id="address-city" class="form-control" placeholder="City" v-model="workspaceCopy.city">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row top-30" v-if="isEditing">
            <div class="col-sm-4 col-md-3">
              <button type="button" class="nv-button-blue a-button-size" @click="handleSubmit(save)">Save</button>
            </div>

            <div class="col-sm-4 col-md-3 top-10-xs">
              <button type="button" class="secondary-btn-blue a-button-size" @click="cancelEdit">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInputHorizontal from 'vue-app/shared/components/text-input-horizontal.vue';
import { ValidationObserver } from 'vee-validate';
import SharedLists from 'vue-app/shared/services/shared-lists-service.js';

export default {
  name: 'WorkspaceInfo',

  components: {
    ValidationObserver,
    TextInputHorizontal
  },

  props: {
    workspace: {
      type: Object,
      default: () => ({})
    },

    onSave: {
      type: Function,
      required: true
    },

    editingAllowed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isEditing: false,
      errors: '',
      workspaceCopy: {}
    };
  },

  computed: {
    countries() {
      return SharedLists.listOfCountries();
    },

    states() {
      return SharedLists.listOfStateObjects().map((stateObject) => stateObject.name);
    },

    location() {
      return this.workspace.location && this.workspace.location();
    }
  },

  methods: {
    countrySelected() {
      if (this.workspaceCopy.country !== 'United States') {
        this.workspaceCopy.state = null;
      }
    },

    startEdit() {
      this.workspaceCopy = Object.assign({}, this.workspace);
      this.isEditing = true;
    },

    cancelEdit() {
      this.isEditing = false;
    },

    save() {
      if (!this.editingAllowed) { return; }

      this.isEditing = false;

      this.onSave({ workspace: this.workspaceCopy });
    }
  }
};
</script>

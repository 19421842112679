<template>
  <div>
    <div class="text-center bottom-20">
      <div class="size-text-base semibold-weight">
        {{ formatDate('EEEE') }}
      </div>

      <div>
        {{ formatDate('MMMM d') }}
      </div>
    </div>

    <div class="bottom-10">
      <button type="button" class="nv-button-white smaller no-transform" @click="selectAllTimes" :disabled="allTimesPast" v-if="!allTimesSelected">Select All</button>

      <button type="button" class="nv-button-white smaller no-transform" @click="deselectAllTimes" v-if="allTimesSelected">Deselect All</button>
    </div>

    <div class="list-spacing-10" v-for="timeslot in timeslotsByTime">
      <appointment-selection-hour
        :timeslot="timeslot"
        :availability="availability">
      </appointment-selection-hour>
    </div>
  </div>
</template>

<script>
import AppointmentSelectionHour from 'vue-app/marketplace/shared/appointments/availability-selection-hour.vue';
import { each, intersection, sortBy } from 'lodash';

export default {
  name: 'AvailabilitySelectionOption',

  components: {
    AppointmentSelectionHour
  },

  props: {
    date: {
      type: Object,
      required: true
    },

    timeslots: {
      type: Array,
      required: true
    },

    availability: {
      type: Object,
      required: true
    }
  },

  computed: {
    timeslotsByTime() {
      return sortBy(this.timeslots, slot => slot.time.ts);
    },

    dateString() {
      return this.timeslots[0].dateStr;
    },

    allTimesPast() {
      return false;
    },

    activeTimeslots() {
      return this.timeslots.filter(timeslot => !timeslot.isPast());
    },

    selectedTimes() {
      return intersection(this.availability.timeslots, this.timeslots);
    },

    allTimesSelected() {
      return this.selectedTimes.length === this.timeslots.length;
    }
  },

  methods: {
    formatDate(luxonFormat) {
      return this.date.toFormat(luxonFormat);
    },

    selectAllTimes() {
      each(this.activeTimeslots, (timeslot) => { this.availability.add(timeslot); });
    },

    deselectAllTimes() {
      each(this.timeslots, (timeslot) => { this.availability.remove(timeslot); });
    }
  }
};
</script>

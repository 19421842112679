<template>
  <div class="clear-panel compact-panel">
    <div class="row tight-columns">
      <div class="col-md-5">
        <profile-photo :user="user" :medium="true"></profile-photo>
      </div>

      <div class="col-md-7 top-10-xs-sm">
        <div class="text-ellipsis">
          {{ user.fullName }}
        </div>

        <div class="size-text-12px dark-gray-text text-ellipsis">
          {{ user.role || '&mdash;' }}
        </div>

        <div class="size-text-12px text-ellipsis">
          <span class="missing-text" v-if="isCurrentUser">Your Account</span>
          <span class="missing-text" v-if="!isCurrentUser && user.deactivatedAt">(Deactivated)</span>
          <a href="" @click.prevent="messageUser" v-if="!isCurrentUser && !user.deactivatedAt">Send Message</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';

export default {
  name: 'WorkspaceUser',

  components: {
    ProfilePhoto
  },

  props: {
    user: Object,
    currentUser: Object,
    onMessageUser: Function
  },

  computed: {
    isCurrentUser() {
      return this.user.klass === this.currentUser.klass && this.user.id === this.currentUser.id;
    }
  },

  methods: {
    messageUser() {
      if (!this.isCurrentUser) { this.onMessageUser(this.user); }
    }
  }
};
</script>

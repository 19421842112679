<template>
  <div class="row tight-columns" @click="viewApplication()">
    <div class="col-md-9 dash-project-container">
      <div class="dash-project-name text-ellipsis">
        {{ application.title }}
        <span v-if="application.location">({{ application.location }})</span>
      </div>

      <div class="text-ellipsis">
        Status:
        <span class="semibold-weight" v-if="application.status.length">{{ applicationStatus }}</span>
        <span v-if="!application.status.length">&mdash;</span>
      </div>
    </div>

    <div class="col-sm-6 col-md-3">
      <div class="dash-btn-container">
        <a :href="detailsPath" class="nv-button-white">View Details</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpportunityApplication',

  props: {
    application: {
      type: Object,
      required: true
    }
  },

  computed: {
    detailsPath() {
      return `/lawyer-app/opportunity-applications/${this.application.requestId}`;
    },

    applicationStatus() {
      if (this.application.additionalStatusDetails && this.application.additionalStatusDetails.length > 0) {
        return `${this.application.status}. ${this.application.additionalStatusDetails}`;
      }
      else {
        return `${this.application.status}`;
      }
    }
  },

  methods: {
    viewApplication() {
      window.location.href = this.detailsPath;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="size-text-13px">
    <div v-if="hasRequestPath" class="bottom-15">
      <div class="semibold-weight">
        Request
      </div>

      <div class="wrap-word">
        <a :href="matter.requestPath">View Request</a>
      </div>
    </div>

    <div class="bottom-15">
      <div class="semibold-weight">
        Project Opened
      </div>

      <div class="wrap-word">
        <span v-if="matter.startedDate">{{ startedDate }}</span>
        <span v-else>&mdash;</span>
      </div>
    </div>

    <div class="bottom-15">
      <div class="semibold-weight">
        Project Closed
      </div>

      <div class="wrap-word">
        <span v-if="matter.endedDate">{{ endedDate }}</span>
        <span v-else>&mdash;</span>
      </div>
    </div>

    <div :class="{ 'bottom-30': matter.isOpen() }">
      <div class="semibold-weight">
        SF Opportunity ID
      </div>

      <div class="wrap-word">
        <a :href="matter.opportunityUrl" v-if="hasOpportunityUrl" target="_blank">{{ matter.opportunityId }}</a>
        <span v-if="!hasOpportunityUrl">&mdash;</span>
      </div>
    </div>

    <div v-if="matter.isOpen()">
      <modal v-model="modalOpen" size="md" :transition="0" :header="false" :footer="false" append-to-body>
        <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

        <div class="notice-modal">
          <div class="notice-header">
            Confirm
          </div>

          <div class="notice-text text-center">
            Are you sure you want to close this project?
          </div>

          <div class="notice-action-container">
            <div class="row">
              <div class="col-sm-6 bottom-10">
                <button type="button" class="nv-button-red a-button-size" @click.prevent="closeProject()">Yes</button>
              </div>

              <div class="col-sm-6 bottom-30">
                <button type="button" class="nv-button-white a-button-size" @click.prevent="dismissModal()">No</button>
              </div>
            </div>
          </div>
        </div>
      </modal>

      <a href="" class="nv-button-white smaller" @click.prevent="openConfirmationModal()">Close Project</a>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import { isNil } from 'lodash';

export default {
  name: 'MatterStatus',

  components: {
    ModalCloseButton
  },

  mixins: [
    modalToggle
  ],

  props: {
    matter: {
      type: Object,
      required: true
    },

    onClosed: {
      type: Function,
      required: true
    }
  },

  computed: {
    hasRequestPath() {
      return !isNil(this.matter.requestPath);
    },

    hasOpportunityUrl() {
      return !isNil(this.matter.opportunityUrl);
    },

    startedDate() {
      return moment(String(this.matter.startedDate)).format('MM/DD/yyyy hh:mm A');
    },

    endedDate() {
      return moment(String(this.matter.endedDate)).format('MM/DD/yyyy hh:mm A');
    }
  },

  methods: {
    openConfirmationModal() {
      this.openModal();
    },

    closeProject() {
      this.onClosed();
    },

    dismissModal() {
      this.closeModal();
    }
  }
};
</script>

<template>
  <section class="uiv">
    <slot name="modalTrigger" :open-modal="openModal">
      <a href="" class="bold-link blue-link size-text-12px" @click.prevent="openModal"><svg-icon name="invite" class="base-icon right-10"></svg-icon>Add New {{ lawFirm.alspAccount ? 'Admin' : 'Firm User' }}</a>
    </slot>

    <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" @hide="closeModal" append-to-body>
      <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-header">
          Add User To <span :class="{ 'purple-text': lawFirm }">{{ lawFirm ? lawFirm.name : 'Firm' }}</span>
        </div>

        <div v-if="showForm" class="notice-regular-text">
          <validation-observer v-slot="{ handleSubmit, failed }" ref="validationObserver">
            <form role="form" name="form" class="a-form" novalidate @submit.prevent>
              <div class="bottom-30">
                <div class="bottom-30">
                  <text-input-vertical
                    v-model="lawyer.email"
                    label="Email Address"
                    id="lawyer-email"
                    input-name="email"
                    placeholder="Ex: jane.doe@company.com"
                    rules="required|email">
                  </text-input-vertical>
                </div>

                <div class="row">
                  <div class="col-sm-6 bottom-30">
                    <text-input-vertical
                      v-model="lawyer.firstName"
                      label="First Name"
                      id="first-name"
                      input-name="firstName"
                      placeholder="Ex: Jane"
                      rules="required">
                    </text-input-vertical>
                  </div>

                  <div class="col-sm-6 bottom-30">
                    <text-input-vertical
                      v-model="lawyer.lastName"
                      label="Last Name"
                      id="last-name"
                      input-name="lastName"
                      placeholder="Ex: Doe"
                      rules="required">
                    </text-input-vertical>
                  </div>
                </div>

                <div class="bottom-30" id="k-accountType">
                  <label for="account-type">Account Type</label>

                  <validation-provider rules="required" v-slot="{ errors }" :mode="passiveAggressive">
                    <dropdown-select
                      id-label="account-type"
                      :options="accountTypeOptions"
                      :initial-label="accountTypeOptions[0].label"
                      label-key="label"
                      value-key="slug"
                      v-model="lawyer.accountType"
                      placeholder="Select an account type"
                      :required="true"
                      :is-disabled="isAlsp"
                      :invalid="errors[0]">
                    </dropdown-select>

                    <div v-if="errors.length" class="error-text top-5">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <div>
                  <text-input-vertical
                    v-model="lawyer.firmTitle"
                    label="Job Title"
                    id="firm-title"
                    name="firmTitle"
                    placeholder="Ex: Partner, Sr. Associate, etc.">
                  </text-input-vertical>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-8 col-sm-6">
                  <loading-button name="sendInvite" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(sendInvite)">Add User</loading-button>
                </div>
              </div>

              <div class="error-text top-10" v-if="failed">
                * One or more required fields are empty. Please fill in the required fields indicated above.
              </div>

              <div v-if="isAlsp" class="size-text-11px semibold-weight top-20">
                <span class="red-text bold-weight">NOTE:</span> This will add an admin user to your account, but will not be shown to clients.
              </div>
            </form>
          </validation-observer>
        </div>

        <div v-if="showSuccessMessage">
          <div class="notice-text">
            You successfully added the user to the firm.
          </div>

          <div class="notice-action-container bottom-30">
            <button type="button" class="nv-button-white smaller" @click="closeModal">Close Window</button>
          </div>
        </div>

        <div v-if="showFailMessage">
          <div class="notice-text">
            The selected attorney already has an account with Priori and cannot be added as a new user. Please contact Priori for further information.
          </div>

          <div class="notice-action-container bottom-30">
            <button type="button" class="nv-button-white smaller" @click="closeModal">Close Window</button>
          </div>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';

export default {
  name: 'FirmInviteModal',

  components: {
    TextInputVertical,
    DropdownSelect,
    ModalCloseButton,
    SvgIcon,
    LoadingButton,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes,
    modalToggle
  ],

  props: {
    lawFirm: {
      type: Object,
      required: false
    },

    onSendInvite: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      lawyer: this.initialLawyerState(),
      showSuccessMessage: false,
      showFailMessage: false
    };
  },

  computed: {
    isAlsp() {
      return this.lawFirm && this.lawFirm.alspAccount;
    },

    showForm() {
      return !this.showSuccessMessage && !this.showFailMessage;
    },

    accountTypeOptions() {
      if (this.isAlsp) {
        return [
          { label: 'Administrator', slug: 'admin' }
        ];
      }
      else {
        return [
          { label: 'Lawyer', slug: 'lawyer' },
          { label: 'Paralegal', slug: 'paralegal' },
          { label: 'Legal Operations Professional', slug: 'legal_operations_professional' },
          { label: 'Firm Administrator', slug: 'admin' }
        ];
      }
    }
  },

  watch: {
    modalOpen: function (open) {
      if (open) {
        this.lawyer             = this.initialLawyerState();
        this.showSuccessMessage = false;
        this.showFailMessage    = false;

        if (this.$refs.validationObserver) {
          this.$refs.validationObserver.reset();
        }
      }
    }
  },

  methods: {
    sendInvite() {
      this.onSendInvite(this.lawyer)
      .then(() => {
        this.showSuccessMessage = true;
      })
      .catch(() => {
        this.showFailMessage = true;
      });
    },

    initialLawyerState() {
      const currentUser       = useCurrentUserStore().currentUser;
      const lawyerAccountType = this.lawFirm && this.lawFirm.alspAccount ? 'admin' : 'lawyer';
      const lawFirmId         = this.lawFirm ? this.lawFirm.id : currentUser.lawFirmId;

      return {
        email: '',
        firstName: '',
        lastName: '',
        accountType: lawyerAccountType,
        firmTitle: '',
        lawFirmId: lawFirmId,
        invitedById: currentUser.id,
        invitedByType: 'Lawyer'
      };
    }
  }
};
</script>

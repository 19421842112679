<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a :href="`/client-app/projects/${matter.id}`" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Cancel & Exit</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="large-page-heading bottom-30">
            Review Your Lawyer & Priori Experience
          </div>

          <div class="shadowed-box bottom-40">
            <loading-section name="lawyerReviewQuestions" :render-after-loading="true">
              <review-lawyer-form
                :lawyer="lawyer"
                :matter="matter"
                :client="matter.client"
                :review="review"
                :questions="questions"
                :on-review-submit="submitReview">
              </review-lawyer-form>
            </loading-section>
          </div>

          <div class="semibold-weight size-text-13px red-text text-center bottom-10">
            * Required Fields
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewLawyerForm from 'vue-app/marketplace/shared/review-lawyer-form.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import NotificationService from 'vue-app/shared/services/notification-service';
import Review from 'resources/marketplace/review.js';
import ReviewQuestion from 'resources/marketplace/review-question.js';

export default {
  name: 'MattersReview',

  components: {
    ReviewLawyerForm,
    LoadingSection,
    SvgIcon
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    const review = new Review({ matterId: this.initData.matter.id });

    return {
      matter: this.initData.matter,
      review: review,
      questions: []
    };
  },

  computed: {
    lawyer() {
      return this.matter.lawyer;
    }
  },

  beforeMount() {
    this.loadReviewQuestions();
  },

  methods: {
    loadReviewQuestions() {
      LoadingService.around('lawyerReviewQuestions', () => {
        return ReviewQuestion.query().then((questions) => {
          this.questions = questions;
        });
      });
    },

    submitReview(failFn) {
      LoadingService.around('submit', () => {
        return this.review.save().then(
          () => {
            NotificationService.success('Thanks for submitting your review.', true);
            window.location.href = `/client-app/projects/${this.matter.id}`;
          },
          (response) => {
            failFn(response.response);
          }
        );
      });
    }
  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <div class="semibold-weight">
          Invoices Due:
        </div>

        <div class="size-text-11px dark-gray-text">
          Number of days from receipt of invoice
        </div>
      </div>

      <div class="col-sm-6 text-right-not-xs">
        Net {{ matter.netDaysPayable }} Days
      </div>
    </div>

    <hr class="kenny-hr">

    <div v-if="!matter.invoiceToPrioriOnly()">
      <div class="row">
        <div class="col-sm-6">
          <div class="semibold-weight">
            Auto-Charge:
          </div>

          <div class="size-text-11px dark-gray-text">
            Auto-charge your default payment method on invoice due dates?
          </div>
        </div>

        <div class="col-sm-6 text-right-not-xs">
          {{ matter.autochargingAllowed ? 'Yes': 'No' }}
        </div>
      </div>

      <hr class="kenny-hr">

      <div class="row">
        <div class="col-sm-6">
          <div class="semibold-weight">
            Credit Cards:
          </div>

          <div class="size-text-11px dark-gray-text">
            Does the lawyer allow credit card payments?
          </div>
        </div>

        <div class="col-sm-6 text-right-not-xs">
          {{ matter.allowCreditCard ? 'Allowed': 'Not Allowed' }}
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div v-if="hasDescription">
      <div class="semibold-weight bottom-5">
        Project Description:
      </div>

      <div>
        {{ matter.description }}
      </div>

      <hr class="kenny-hr">
    </div>

    <div class="semibold-weight bottom-5">
      Invoice Recipients:
    </div>

    <div>
      {{ recipientsText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectSettings',

  props: {
    matter: {
      type: Object,
      required: true
    }
  },

  computed: {
    recipientsText() {
      if (this.matter.invoiceToPrioriOnly()) {
        return 'Per agreement with Priori.';
      }

      return this.matter.allInvoiceRecipients().join(', ');
    },

    hasDescription() {
      return this.matter.description && this.matter.description.length > 0;
    }
  }
};
</script>

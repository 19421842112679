<template>
  <div :class="{ 'tag-list': tags.length }">
    <div :class="['tag-list-item compact', tagClass]" v-for="(tag, index) in tags" :key="tag.id">
      <div class="text-ellipsis list-wrapper">
        {{ getValue(tag) }}

        <div>
          <a href="" role="link" @click.prevent="remove(index)"><svg-icon :name="iconName" class="base-icon remove-icon"></svg-icon></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'TagList',

  components: {
    SvgIcon
  },

  props: {
    tags: {
      type: Array,
      default: () => []
    },

    onRemove: {
      type: Function,
      required: true
    },

    valueFn: {
      type: Function,
      required: false
    },

    tagClass: {
      type: String,
      default: 'blue'
    }
  },

  computed: {
    iconName() {
      return this.tagClass === 'clear' ? 'x-circle' : 'x4';
    }
  },

  methods: {
    getValue(tag) {
      if (this.valueFn) {
        return (this.valueFn(tag));
      }
      else {
        return tag.name;
      }
    },

    remove(index) {
      this.onRemove(index);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .tag-list-item {
    .remove-icon {
      display: block;
      width: 8px;
      height: 8px;
      margin-left: 8px;
      fill: $white;
    }

    &.clear {
      border-color: $medium-gray;

      .remove-icon {
        height: 16px;
        width: 16px;
        fill: $pill-gray;

        &:hover {
          fill: $k-darker-gray;
        }
      }
    }
  }

  .list-wrapper {
    display: flex;
    max-width: fit-content;
    align-items: center;
  }
</style>

<template>
  <div>
    <button v-if="!selected" type="button" class="nv-button-white smaller no-transform" @click="select" :disabled="!isAvailable">{{ timeslot.label({ timeZone: ianaTimeZone }) }}</button>

    <button v-else type="button" class="nv-button-blue smaller no-transform" @click="deselect">{{ timeslot.label({ timeZone: ianaTimeZone }) }}</button>
  </div>
</template>

<script>
import useCurrentUserStore  from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'AvailabilitySelectionHour',

  props: {
    timeslot: {
      type: Object,
      required: true
    },

    availability: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { ianaTimeZone: 'ianaTimeZone' }),

    userSchedule() {
      return this.availability.userSchedule;
    },

    isAvailable() {
      return !this.timeslot.isPast() && this.userSchedule.availableAt(this.timeslot);
    },

    selected() {
      return this.availability.availableTimes(this.userSchedule).includes(this.timeslot);
    }
  },

  methods: {
    select() {
      this.availability.add(this.timeslot);
    },

    deselect() {
      this.availability.remove(this.timeslot);
    }
  }
};
</script>

<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Open Projects
    </div>

    <div class="box-content symmetrical normal-weight">
      <loading-section name="matters">
        <div v-for="(matter) in matters" :key="matter.id">
          <open-project
            :matter="matter"
            :current-user="currentUser"
            @message-lawyer="messageLawyer">
          </open-project>

          <hr class="kenny-hr">
        </div>

        <div v-if="!matters.length">
          <div class="top-30 bottom-50 gray-text tight-lines text-center">
            You have no open projects. <a href="/client-app/requests/new" class="dark-gray-text">Click here</a> to start a new one!
          </div>

          <hr class="kenny-hr">
        </div>
      </loading-section>

      <div class="view-all-container">
        <a href="/client-app/projects" class="purple-text">View All Projects</a>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import OpenProject from 'vue-app/marketplace/client/dashboard/open-project.vue';

export default {
  name: 'OpenProjects',

  components: {
    LoadingSection,
    OpenProject
  },

  props: {
    matters: {
      type: Array,
      default: () => []
    },

    currentUser: {
      type: Object,
      required: true
    }
  },

  emits: ['messageLawyer'],

  methods: {
    messageLawyer(lawyer) {
      this.$emit('message-lawyer', lawyer);
    }
  }
};
</script>

<template>
  <div class="clear-panel">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-4">
        <firm-name-plate :firm="firm" icon-size="small" :italic="firm.isBillingFirm()">
          <template #firm-name-element>
            <a :href="`/scout-company/law-firms/${firm.id}`" :class="['text-link', { 'text-italic': firm.isBillingFirm() }]" target="_blank">{{ firm.name }}</a>
          </template>

          {{ lawFirm.firmSize }}
        </firm-name-plate>
      </div>

      <div class="col-sm-6 top-10-xs">
        <scout-tags-list :scout-tags="lawFirm.scoutTags"></scout-tags-list>
      </div>

      <div class="col-sm-2 text-ellipsis text-right-not-xs">
        <a :href="`/scout-company/law-firms/${lawFirm.id}/reviews`" class="semibold-weight" target="_blank" v-if="reviewCount > 0">{{ reviewCountText }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import ScoutTagsList from 'vue-app/scout/shared/scout-tags/scout-tags-list.vue';
import FirmNamePlate from 'vue-app/scout/shared/firm-name-plate.vue';
import ScoutFirmBridge from 'resources/scout/scout-firm-bridge.js';

export default {
  name: 'MattersListItemLawFirm',

  components: {
    FirmNamePlate,
    ScoutTagsList
  },

  props: {
    lawFirm: {
      type:     Object,
      required: true
    }
  },

  computed: {
    firm() {
      return new ScoutFirmBridge(this.lawFirm);
    },

    reviewCount() {
      return this.lawFirm.reviewCount;
    },

    reviewCountText() {
      return this.$pluralize('review', this.reviewCount, true);
    }
  }
};
</script>

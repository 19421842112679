import { DateTime } from 'luxon';

const formatAsDate = function (time) {
  return time.toFormat('y-LL-dd');
};

const formatAsHour = function (time) {
  return time.toFormat('HH:mm');
};

class Timeslot {
  constructor(time) {
    this.time = time;
    this.utcTime = time.toUTC();
  }

  date(opts = {}) {
    return formatAsDate(opts.utc ? this.utcTime : this.time);
  }

  hour(opts = {}) {
    return formatAsHour(opts.utc ? this.utcTime : this.time);
  }

  asDate() {
    return this.time.toJSDate();
  }

  isPast() {
    return this.time < DateTime.now();
  }

  isTime(localDateStr, localTimeStr) {
    return this.date() === localDateStr && this.hour() === localTimeStr;
  }

  isOnDate(date) {
    return formatAsDate(date) === formatAsDate(this.time);
  }

  inZone(zone) {
    return new this.constructor(this.time.setZone(zone));
  }

  label(opts = {}) {
    if (opts.timeZone) {
      return this.inZone(opts.timeZone).label({ format: opts.format });
    }
    else {
      return opts.format ? this.time.toLocaleString(DateTime[opts.format]) : this.time.toFormat('h:mm a');
    }
  }
}

export default Timeslot;

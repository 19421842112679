import BaseResource from 'resources/base-resource';
import Message from 'resources/marketplace/message.js';

import { compact, find, map, some } from 'lodash';

const postLoadProcessing = function (conversation) {
  conversation.setDraft();
  conversation.formattedRecipients = map(conversation.participants, 'postId').join(',');

  return conversation;
};

const participantNameTemplate = function (participant, isMultiple, isLawyer) {
  const display = { id: participant.id, name: participant.firstName, klass: participant.klass };

  if (!isMultiple) {
    display.name = compact([display.name, participant.lastName]).join(' ');
  }

  if (isLawyer && participant.klass === 'Client') {
    const role = compact([participant.title, participant.company]);

    if (some(role)) { display.role = role.join(', '); }
  }

  if (participant.klass === 'AdminUser') {
    display.role = 'Priori';
  }

  return display;
};

class Conversation extends BaseResource {
  static baseUrl = '/mailboxer/conversations/:id.json';

  static get(data) {
    return super.get.call(this, data).then(postLoadProcessing);
  }

  static save(data) {
    return super.save.call(this, data).then(postLoadProcessing);
  }

  constructor(data) {
    super(data);
    this.participantDisplays = [];
  }

  setDraft() {
    const receipt = find(this.receipts, r => r.message.draft);

    this.draftMessage = new Message(receipt ? receipt.message : { body: '', draft: true });
    this.draftSubject = receipt ? receipt.message.body : '';

    this.draftMessage.attachments = this.draftMessage.attachments || [];
    this.draftMessage.uploading = this.draftMessage.uploading || [];
  }

  displayNames(appContext) {
    const isMultiple = this.participants.length > 1;
    const isLawyer = (appContext === 'lawyer');

    return map(this.participants, (participant) => {
      return participantNameTemplate(participant, isMultiple, isLawyer);
    });
  }

  setParticipantDisplaysFor(userClass) {
    this.participantDisplays = this.displayNames(userClass);
  }

  setMostRecentReceipt(receipt) {
    const existingReceipt = find(this.receipts, (r) => r.id === receipt.id);

    if (existingReceipt) {
      this.receipts.splice(this.receipts.indexOf(existingReceipt), 1);
    }

    this.receipts.push(receipt);

    this.setDraft();
  }
}

export default Conversation;

<template>
  <dropdown-menu>
    <li class="menu-item" role="menuitem">
      <a href="" @click.prevent="onMessageLawyer"><svg-icon name="envelope" class="base-icon"></svg-icon>Message Lawyer</a>
    </li>

    <li class="menu-item" role="menuitem">
      <a :href="`/client-app/projects/${matter.id}/reviews/new`" v-if="!matter.isReviewed()"><svg-icon name="ribbon" class="base-icon"></svg-icon>Review Lawyer</a>
    </li>

    <li class="menu-item" role="menuitem">
      <a :href="`/client-app/requests/${matter.counselRequestId}`" v-if="isProjectOwner && matter.counselRequestId" target="_blank"><svg-icon name="resume" class="base-icon"></svg-icon>View Request</a>
    </li>
  </dropdown-menu>
</template>

<script>
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'OpenProjectDropdown',

  components: {
    DropdownMenu,
    SvgIcon
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    currentUser: {
      type: Object,
      required: true
    },

    onMessageLawyer: {
      type: Function,
      required: true
    }
  },

  computed: {
    isProjectOwner() {
      return this.currentUser.id === this.matter.client.id &&
        this.currentUser.klass === this.matter.client.klass;
    }
  }
};
</script>

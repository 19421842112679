import axios from 'axios';
import BaseResource from 'resources/base-resource';

class InternalDocument extends BaseResource {
  static baseUrl = '/internal_documents/:id.json';

  static deleteBatch(params) {
    return axios.delete('/internal_documents/delete_batch.json', { data: params });
  }
}

export default InternalDocument;

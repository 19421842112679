<template>
  <div class="row tight-columns">
    <div class="col-md-5 col-lg-6 dash-project-container" @click="viewProject">
      <div class="dash-project-name text-ellipsis">
        {{ matter.nameForClientsReference }}
      </div>

      <div class="text-ellipsis">
        <span class="right-5">Opened: <span class="semibold-weight">{{ opened }}</span></span>

        <span class="right-5">&#8231;</span>

        <span>Lawyer: <span class="semibold-weight">{{ matter.lawyer.fullName }}</span></span>
      </div>
    </div>

    <div class="col-sm-6 col-md-3 col-lg-2">
      <project-team
        :project-resource="matter"
        :can-add="false">
      </project-team>
    </div>

    <div class="col-sm-6 col-md-4">
      <div class="row tight-columns dash-btn-container">
        <div class="col-xs-3 col-md-4 top-10">
          <open-project-dropdown
            :matter="matter"
            :current-user="currentUser"
            :on-message-lawyer="messageLawyer">
          </open-project-dropdown>
        </div>

        <div class="col-xs-9 col-md-8 top-10">
          <a :href="`/client-app/projects/${matter.id}`" class="nv-button-white smaller">View Project</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTeam from 'vue-app/marketplace/client/shared/project-team.vue';
import OpenProjectDropdown from 'vue-app/marketplace/client/dashboard/open-project-dropdown.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'OpenProject',

  components: {
    ProjectTeam,
    OpenProjectDropdown
  },

  props: {
    matter: Object,
    currentUser: Object
  },

  emits: ['messageLawyer'],

  computed: {
    opened() {
      return DateFilter.filter(this.matter.startedDate);
    }
  },

  methods: {
    messageLawyer() {
      this.$emit('message-lawyer', this.matter.lawyer);
    },

    viewProject() {
      window.location.href = '/client-app/projects/' + this.matter.id;
    }
  }
};
</script>

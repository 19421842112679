<template>
  <div>
    <div class="titlebar with-button">
      <div class="row vertical-center-not-xs">
        <div class="col-sm-9 medium-page-heading">
          Create New RFP
        </div>

        <div class="col-sm-3 text-right-not-xs">
          <button type="button" class="secondary-btn-blue" @click="saveAndExit">Save and Exit</button>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <loading-section name="rfqForm">
            <rfq-create-form
              :rfq="rfq"
              :resource-lists="resourceLists"
              :available-firms="availableFirms"
              @step-change="handleStepChange"
              @contact-admins="contactScoutAdmins"
              @firms-enabled-changed="onFirmsEnabledChanged"
              :on-update-supporting-documents="onUpdateSupportingDocuments"
              :on-destroy-all-law-firm-quotes="onDestroyAllLawFirmQuotes"
              :on-save="onSave">
            </rfq-create-form>
          </loading-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfqCreateForm from 'vue-app/scout/client/rfqs/create/create-form.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'RfqCreate',

  components: {
    RfqCreateForm,
    LoadingSection
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    },

    availableFirms: {
      type: Array,
      required: true
    },

    onUpdateSupportingDocuments: {
      type: Function,
      required: true
    },

    onDestroyAllLawFirmQuotes: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      activeStep: 1,
      firmsEnabled: true
    };
  },

  methods: {
    saveAndExit() {
      LoadingService.loading('rfqForm');
      this.onSave({ rfq: this.rfq, step: this.activeStep }).then(() => {
        if (!this.firmsEnabled) { this.onDestroyAllLawFirmQuotes(); }
        window.location.href = '/scout-company/rfps';
      });
    },

    contactScoutAdmins(data) {
      this.rfq = data.rfq;
    },

    handleStepChange(params) {
      if (params.newActiveStep === 2) {
        this.activeStep = params.newActiveStep;
      }
      else if (params.newActiveStep && params.newActiveStep !== 1) {
        this.onSave({ rfq: this.rfq, step: this.activeStep })
          .then(() => {
            this.activeStep = params.newActiveStep;
          });
      }
    },

    onFirmsEnabledChanged(firmsEnabled) {
      this.firmsEnabled = firmsEnabled;
    }
  }
};
</script>

<template>
  <div class="row tight-columns">
    <div class="col-xs-8 col-md-3">
      <div class="semibold-weight text-ellipsis top-10">
        {{ displayDoc.name }}
        <svg-icon :id="'document-' + displayDoc.id" name="info" class="base-icon smaller left-5" v-if="document.hasNote()"></svg-icon>
        <tooltip :target="'#document-' + displayDoc.id" placement="right" custom-class="k-tooltip" :text="displayDoc.note"></tooltip>
      </div>
    </div>

    <div class="col-xs-4 col-md-2">
      <div class="semibold-weight top-10">
        {{ document.onGoogleDrive() ? document.permissionDisplay() : '&mdash;' }}
      </div>
    </div>

    <div class="col-xs-12 col-md-3">
      <div class="text-ellipsis top-10">
        By: <span class="semibold-weight">{{ sharedByName }}</span>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4">
      <div class="row tight-columns dash-btn-container">
        <div :class="['col-md-push-3', { 'col-xs-6': showOptions, 'col-xs-9': hideOptions }]">
          <a :href="displayDoc.url" class="nv-button-white smaller" target="_blank">View</a>
        </div>

        <div class="col-xs-3 col-md-push-3" v-if="showOptions">
          <document-options
            :document="document"
            :on-remove="onRemove">
          </document-options>
        </div>

        <div class="col-xs-3 col-md-pull-9">
          <div class="text-center top-10" v-if="document.onGoogleDrive()">
            <svg-icon name="google-drive" class="base-icon medium"></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentOptions from 'vue-app/marketplace/shared/documents/document-options.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ProjectDocument',

  components: {
    DocumentOptions,
    SvgIcon
  },

  props: {
    document: {
      type: Object,
      required: true
    },

    connectedToGoogleDrive: {
      type: Boolean,
      required: true
    },

    onRemove: {
      type: Function,
      required: true
    }
  },

  computed: {
    displayDoc() {
      return this.document.yourFile ? this.document : this.document.document;
    },

    sharedByName() {
      return this.document.yourFile ? 'You' : this.document.document.user.fullName;
    },

    showOptions() {
      return this.document.yourFile && (!this.document.onGoogleDrive() || this.connectedToGoogleDrive);
    },

    hideOptions() {
      return !this.document.yourFile || (this.document.onGoogleDrive() && !this.connectedToGoogleDrive);
    }
  }
};
</script>

<template>
  <opportunities-layout>
    <div class="text-center top-5 bottom-30">
      <div class="size-text-32px bold-weight purple-2 tight-lines bottom-15">
        Current Opportunities
      </div>

      <div class="size-text-15px purple-1">
        Browse open opportunities below. If you decide to apply, the information you submit may later be viewed by a client.
      </div>
    </div>

    <opportunity-filters
      class="bottom-20"
      :search-service="searchService"
      :filter-service="filterService"
      :on-search="search">
    </opportunity-filters>

    <div class="opportunity-listings-container">
      <loading-section name="opportunities">
        <div v-if="opportunities.length">
          <div v-for="opportunity in opportunities" :key="opportunity.id">
            <opportunity-listing
              class="bottom-20"
              :opportunity="opportunity">
            </opportunity-listing>
          </div>
        </div>

        <div v-else class="no-results gray-text size-text-16px bottom-50">
          No opportunities found
        </div>
      </loading-section>
    </div>
  </opportunities-layout>
</template>

<script>
import searchService from 'src/vue-app/shared/services/opportunities-search-service.js';
import filterService from 'src/vue-app/shared/services/opportunities-filter-service.js';
import OpportunitiesLayout from 'vue-app/public/opportunities/opportunities-layout.vue';
import OpportunityFilters from 'vue-app/public/opportunities/opportunity-filters.vue';
import OpportunityListing from 'vue-app/public/opportunities/opportunity-listing.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'LawyerOpportunitiesIndex',

  components: {
    OpportunitiesLayout,
    OpportunityFilters,
    OpportunityListing,
    LoadingSection
  },

  data() {
    return {
      opportunities: [],
      searchService,
      filterService
    };
  },

  mounted() {
    this.search();
  },

  methods: {
    search() {
      LoadingService.loading('opportunities');

      return searchService.applySearch().then(opportunities => {
        this.opportunities = opportunities;
        LoadingService.done('opportunities');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .base-icon {
    &.big {
      width: 48px;
      height: 48px;
    }
  }

  .purple-1 {
    color: $purple-1;
  }

  .purple-2 {
    color: $purple-2;
  }

  .no-results {
    background-color: $white;
    border-radius: $border-radius-large;
    border: 1px solid $gray-7;
    padding: 25px 30px;
  }
</style>

<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :mode="passiveAggressive">
    <label :for="uniqueId" v-if="hasLabel">{{ label }} <span class="red-text" v-if="required && !disabled">*<span class="error-text" v-if="showRequiredLabel"> Required</span></span></label>

    <slot name="description"></slot>

    <div class="keyword-search-container typeahead-container">
      <input
        type="text"
        :data-testid="id"
        :id="uniqueId"
        :name="uniqueName"
        :class="['form-control keyword-search-input', { 'has-error': errors.length, 'disabled': disabled }]"
        :placeholder="placeholder"
        :disabled="disabled"
        autocomplete="nope"
        @input="forceSelect ? () => {} : onInput($event.target.value)"
        @keydown.enter.prevent="allowEnterKeydown ? onEnterKeydown($event.target.value) : () => {}"
        v-model="inputModel">

      <svg-icon v-if="!hideIcon" name="search" class="base-icon keyword-search-icon"></svg-icon>
    </div>

    <div class="error-text top-5" v-if="displayError && errors.length">
      {{ errors[0] }}
    </div>

    <typeahead
      v-model="model"
      :target="`#${uniqueId}`"
      :data="sanitizedOptions"
      :item-key="optionLabelKey"
      :force-select="forceSelect"
      :force-clear="forceSelect">
    </typeahead>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { now, unescape } from 'lodash';

export default {
  name: 'TypeaheadVertical',

  components: {
    SvgIcon,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    label: {
      type: String,
      required: false
    },

    id: {
      type: String,
      required: true
    },

    inputName: {
      type: String,
      required: false
    },

    placeholder: {
      type: String,
      required: false
    },

    value: {
      type: [String, Number, Object],
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    rules: {
      type: [String, Object],
      default: null
    },

    options: {
      type: Array,
      required: true
    },

    optionLabelKey: {
      type: String,
      required: false
    },

    optionValueKey: {
      type: String,
      required: false
    },

    forceSelect: {
      type: Boolean,
      default: true
    },

    showRequiredLabel: {
      type: Boolean,
      required: false
    },

    initialValue: {
      type: String,
      required: false
    },

    displayError: {
      type: Boolean,
      default: true
    },

    hideIcon: {
      type: Boolean,
      default: false
    },

    allowEnterKeydown: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      model: '',
      inputModel: ''
    };
  },

  computed: {
    hasLabel() {
      return this.label && this.label.length;
    },

    uniqueId() {
      // NOTE: this is an attempt at breaking Chrome's autocomplete nonsense
      return `${this.id}-${now()}`;
    },

    uniqueName() {
      return this.inputName ? `${this.inputName}${now()}` : null;
    },

    required() {
      return this.rules?.includes('required');
    },

    sanitizedOptions() {
      return this.options.map((option) => {
        if (this.optionLabelKey) {
          option[this.optionLabelKey] = this.$sanitize(option[this.optionLabelKey], { textFilter: text => unescape(text) });
        }
        return this.optionLabelKey ? option : this.$sanitize(option, { textFilter: text => unescape(text) });
      });
    }
  },

  watch: {
    model(value) {
      this.onInput(value);
      this.onEnterKeydown(value);
    },

    initialValue(value) {
      this.inputModel = value;
    }
  },

  beforeMount() {
    this.inputModel = this.initialValue;
    this.model = this.value;
  },

  methods: {
    onInput(value) {
      if (typeof value === 'undefined') { return; }

      this.inputModel = (this.optionLabelKey && value?.[this.optionLabelKey]) || value;
      this.$emit('input', (this.optionValueKey && value?.[this.optionValueKey]) || value);
    },

    onEnterKeydown(value) {
      if (typeof value === 'undefined') { return; }

      this.inputModel = (this.optionLabelKey && value?.[this.optionLabelKey]) || value;
      this.$emit('enterKeydown', (this.optionValueKey && value?.[this.optionValueKey]) || value);
    },

    reset() {
      this.model = '';
      this.inputModel = '';
    }
  }
};
</script>

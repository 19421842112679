<template>
  <div class="notice-modal">
    <div class="notice-header">
      Suggest a New Client
    </div>

    <div class="notice-regular-text">
      <internal-message-form
        instructions="Recommend a client you would like to have available on Scout:"
        :message="internalMessage"
        :on-submit="onSubmit">
      </internal-message-form>
    </div>
  </div>
</template>

<script>
import InternalMessage from 'vue-app/shared/resources/internal-message';
import InternalMessageForm from 'vue-app/shared/components/internal-message-form.vue';

import ModalManager from 'vue-app/shared/services/modal-manager.js';

export default {
  name: 'SuggestAClient',

  components: {
    InternalMessageForm
  },

  data() {
    return {
      internalMessage: this.generateMessage()
    };
  },

  methods: {
    generateMessage() {
      return new InternalMessage({
        domain: 'scout_client_suggestion',
        parts: [
          { title: 'Client Name', required: true },
          { title: 'Note', textarea: true, label: 'Add a note (optional)' }
        ]
      });
    },

    onSubmit() {
      ModalManager.close('suggest-client');
      this.reset();
    },

    reset() {
      this.internalMessage = this.generateMessage();
    }
  }
};
</script>

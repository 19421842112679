<template>
  <edit-modal :title="editRequestTitle" ref="modalComponent" :input="request" v-model="editedRequest" @on-modal-open="resetForm" :on-save="save" :on-cancel="cancel" :backdrop="true">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openModal">Edit Details</a>
    </template>

    <template #default>
      <div class="small-bold-header">
        Details
      </div>

      <hr class="hr">

      <div class="bottom-20">
        <text-input-vertical label="Project Name" id="project-name" v-model="editedRequest.nameForLawyersReference" rules="required"></text-input-vertical>
      </div>

      <div class="row bottom-20">
        <div class="col-sm-6">
          <dropdown-select
            ref="priorityDropdown"
            id-label="priority"
            v-model="editedRequest.priority"
            :options="priorities"
            :initial-label="priorityName"
            value-key="value"
            label-key="label"
            label="Priority"
            @change="changePriority">
            <template #icon>
              <priority-icon :priority="editedRequest.priority" class="base-icon right-5" v-if="hasSelectedPriority"></priority-icon>
            </template>
          </dropdown-select>
        </div>

        <div class="col-sm-6 top-20-xs">
          <dropdown-select
            ref="goalTypeDropdown"
            id-label="business-goal-type"
            v-model="editedRequest.businessGoalType"
            :options="businessGoalTypes"
            :initial-label="businessGoalName"
            value-key="value"
            label-key="label"
            label="Type"
            @change="changeGoalType">
          </dropdown-select>
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-6">
          <dropdown-select
            ref="requestTypeDropdown"
            id-label="request-type"
            v-model="editedRequest.requestType"
            :options="requestTypes"
            :initial-label="requestType"
            value-key="value"
            label-key="label"
            label="Client Type"
            @change="changeRequestType(editedRequest)">
            <template #icon>
              <svg-icon v-if="editedRequest.requestType === 'custom'" name="gear" class="base-icon right-7"></svg-icon>
            </template>
          </dropdown-select>
        </div>
      </div>

      <div class="small-bold-header">
        Team Members
      </div>

      <hr class="hr">

      <div class="row bottom-20">
        <div class="col-sm-6">
          <dropdown-select
            ref="clientOwnerDropdown"
            id-label="client-owner"
            v-model="editedRequest.prioriOwnerId"
            :options="adminUsersForDropdown"
            :allow-null="true"
            :initial-label="clientOwnerName"
            value-key="value"
            label-key="label"
            label="Client Owner"
            placeholder="Unassigned"
            @change="changeClientOwner">
          </dropdown-select>
        </div>

        <div class="col-sm-6 top-20-xs">
          <dropdown-select
            ref="attorneyOwnerDropdown"
            id-label="attorney-owner"
            v-model="editedRequest.prioriTaskOwnerId"
            :options="adminUsersForDropdown"
            :allow-null="true"
            :initial-label="attorneyOwnerName"
            value-key="value"
            label-key="label"
            label="Attorney Owner"
            placeholder="Unassigned"
            @change="changeAttorneyOwner">
          </dropdown-select>
        </div>
      </div>

      <div>
        <typeahead-vertical
          ref="teamMemberTypeAhead"
          label="Additional Team Member(s)"
          v-model="selectedTeamMember"
          id="team-member-selection"
          :options="availableAdminUserNames"
          placeholder="Search by name..."
          @input="addTeamMember">
        </typeahead-vertical>

        <div class="tag-list top-10" v-if="hasTeamMembers">
          <div class="tag-list-item compact blue" v-for="teamMember in teamMembersWithNames" :key="teamMember.adminUserId">
            <span class="right-5">{{ teamMember.fullName }}</span>
            <a href="" class="tag-list-item-delete" @click.prevent="removeTeamMember(teamMember.adminUserId)"><svg-icon name="x4" class="base-icon smallest"></svg-icon></a>
          </div>
        </div>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import EditModal from 'vue-app/shared/components/edit-modal.vue';
import PriorityIcon from 'vue-app/shared/components/svg-icons/priority-icon.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { cloneDeep, compact, filter, findIndex, find, map } from 'lodash';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'EditRequest',

  components: {
    DropdownSelect,
    EditModal,
    PriorityIcon,
    TypeaheadVertical,
    SvgIcon
  },

  mixins: [
    interactionModes
  ],

  props: {
    request: {
      type: Object
    },

    optionLists: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      editedRequest: null,
      priorities: this.optionLists.priorities,
      requestTypes: this.optionLists.requestTypes,
      businessGoalTypes: this.optionLists.goalTypes,
      adminUsers: this.optionLists.adminUsers,

      selectedPriority: this.request.priority,
      selectedGoalType: this.request.businessGoalType,
      selectedRequestType: this.request.requestType,
      selectedClientOwnerId: this.request.prioriOwnerId,
      selectedAttorneyOwnerId: this.request.prioriTaskOwnerId,

      teamMembers: cloneDeep(this.request.additionalTeamMembers),
      selectedTeamMember: ''
    };
  },

  computed: {
    teamMembersWithNames() {
      return map(this.teamMembers, (teamMember) => {
        return { ...teamMember, fullName: this.fullNameFor(teamMember.adminUserId) };
      });
    },

    teamMemberAdminIds() {
      return map(this.teamMembers, 'adminUserId');
    },

    teamMemberIds() {
      return compact(map(this.teamMembers, 'id'));
    },

    selectedAdminUserIds() {
      return compact(this.teamMemberAdminIds.concat([
        this.selectedClientOwnerId, this.selectedAttorneyOwnerId
      ]));
    },

    availableAdminUsers() {
      return filter(this.adminUsers, (adminUser) => {
        return adminUser.availableForRequests && !this.selectedAdminUserIds.includes(adminUser.value);
      });
    },

    availableAdminUserNames() {
      return map(this.availableAdminUsers, 'label');
    },

    adminUsersForDropdown() {
      return filter(this.adminUsers, (adminUser) => adminUser.availableForRequests);
    },

    hasSelectedPriority() {
      return this.selectedPriority !== null;
    },

    priorityName() {
      return find(this.priorities, { value: this.selectedPriority })?.label;
    },

    businessGoalName() {
      return find(this.businessGoalTypes, { value: this.selectedGoalType })?.label;
    },

    requestType() {
      return find(this.requestTypes, { value: this.selectedRequestType })?.label;
    },

    hasTeamMembers() {
      return this.teamMembers.length > 0;
    },

    editRequestTitle() {
      return `Edit Request -  ${this.request.nameForLawyersReference}`;
    },

    clientOwnerName() {
      return this.fullNameFor(this.selectedClientOwnerId);
    },

    attorneyOwnerName() {
      return this.fullNameFor(this.selectedAttorneyOwnerId);
    },

    additionalTeamMembersAttributes() {
      const existing = map(this.request.additionalTeamMembers, (teamMember) => {
        if (!this.teamMemberIds.includes(teamMember.id)) { teamMember['_destroy'] = 1; }
        return teamMember;
      });
      const added = filter(this.teamMembers, { id: null });

      return [...existing, ...added];
    }
  },

  methods: {
    fullNameFor(adminUserId) {
      return find(this.adminUsers, { value: adminUserId })?.label;
    },

    removeTeamMember(adminUserId) {
      if (!adminUserId) { return; }

      const index = findIndex(this.teamMembers, { adminUserId: adminUserId });
      if (index < 0) { return; }

      this.teamMembers.splice(index, 1);
    },

    addTeamMember(memberFullName) {
      if (!memberFullName) { return; }

      const admin = find(this.adminUsers, { label: memberFullName });
      if (!admin) { return; }

      this.teamMembers.push({ id: null, adminUserId: admin.value });
      this.$refs.teamMemberTypeAhead.reset();
    },

    changePriority() {
      this.selectedPriority = this.editedRequest.priority;
    },

    changeGoalType() {
      this.selectedGoalType = this.editedRequest.businessGoalType;
    },

    changeRequestType() {
      this.selectedRequestType = this.editedRequest.requestType;
    },

    changeAttorneyOwner() {
      this.selectedAttorneyOwnerId = this.editedRequest.prioriTaskOwnerId;
      this.removeTeamMember(this.selectedAttorneyOwnerId);
    },

    changeClientOwner() {
      this.selectedClientOwnerId = this.editedRequest.prioriOwnerId;
      this.removeTeamMember(this.selectedClientOwnerId);
    },

    save() {
      this.editedRequest.counselRequestAdditionalTeamMembersAttributes = this.additionalTeamMembersAttributes;
      this.onSave(this.editedRequest);
    },

    resetForm() {
      if (!this.editedRequest) { return; }

      this.$refs.priorityDropdown.reset();
      this.$refs.goalTypeDropdown.reset();
      this.$refs.clientOwnerDropdown.reset();
      this.$refs.attorneyOwnerDropdown.reset();
      this.$refs.teamMemberTypeAhead.reset();

      this.selectedPriority = this.request.priority;
      this.selectedGoalType = this.request.businessGoalType;
      this.selectedClientOwnerId = this.request.prioriOwnerId;
      this.selectedAttorneyOwnerId = this.request.prioriTaskOwnerId;
      this.teamMembers = cloneDeep(this.request.additionalTeamMembers);
      this.selectedTeamMember = '';
    },

    cancel() {
      this.resetForm();
    }
  }
};
</script>

<style scoped>
  .uiv a {
    display: block;
    clear: both;
  }

  hr {
    margin: 5px 0 20px;
  }

  .base-icon.dormant {
    width: 18px;
    margin-top: 7px;
    margin-bottom: 7px;
  }
</style>

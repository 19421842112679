<template>
  <div>
    <div class="notification-header tiny-header-text">
      You Received An Availability Request
    </div>

    <div class="notification-content">
      <div class="text-italic size-text-13px">
        <span class="semibold-weight">{{ originatorName }}</span> would like to schedule a {{ meetingType }} with you.<span v-if="showButtons"> If {{ appointmentMetadata.originator.firstName }}&rsquo;s proposed times do not suit your schedule, please suggest alternatives.</span>
      </div>

      <div class="top-20" v-if="showButtons">
        <button type="button" class="nv-button-blue smaller" @click.prevent="onUpdateAppointment(appointmentMetadata.id)">Respond To <span :class="{ 'hidden-xs': meetingType === 'video' }">{{ meetingType }}</span> Request</button>
      </div>
    </div>
  </div>
</template>

<script>
import Appointment from 'resources/marketplace/appointment.js';

export default {
  name: 'AppointmentRequestedNotification',

  props: {
    appointmentMetadata: {
      type:     Object,
      required: true
    },

    onUpdateAppointment: {
      type:    Function,
      default: () => {}
    },

    showButtons: {
      type:    Boolean,
      default: false
    }
  },

  computed: {
    originatorName() {
      return [this.appointmentMetadata.originator.firstName, this.appointmentMetadata.originator.lastName].join(' ');
    },

    appointmentResource() {
      return new Appointment(this.appointmentMetadata);
    },

    meetingType() {
      return this.appointmentResource.typeText();
    }
  }
};
</script>

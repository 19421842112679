<template>
  <section class="uiv">
    <slot name="modal-trigger" :open-modal="openModal">
      <a href="" @click.prevent="initModal"><svg-icon name="x-circle" class="base-icon"></svg-icon>Close Request</a>
    </slot>

    <modal v-model="modalOpen" size="md-sm" :transition="0" :header="false" :footer="false" append-to-body>
      <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-header">
          {{ hasScheduledAppointments ? 'Call / Meeting Scheduled' : 'Confirm' }}
        </div>

        <div v-if="!hasScheduledAppointments" class="notice-action-container">
          <div class="notice-text">
            Are you sure you want to close this request?
          </div>

          <validation-observer ref="validator" v-slot="{ handleSubmit }">
            <form role="form" name="requestClosingForm" class="a-form" novalidate>
              <validation-provider rules="required" v-slot="{ errors }" :mode="passiveAggressive">
                <textarea id="description" name="description" :class="['form-control', { 'has-error': errors.length }]" placeholder="Please provide a brief explanation for the closure" rows="4" v-model="description"></textarea>

                <div class="error-text top-5" v-if="errors.length">
                  {{ errors[0] }}
                </div>
              </validation-provider>

              <div class="row top-20 bottom-30">
                <div class="col-sm-6">
                  <button type="button" class="nv-button-red a-button-size" @click="handleSubmit(confirm)">Confirm</button>
                </div>

                <div class="col-sm-6">
                  <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>

        <div v-else class="notice-action-container">
          <div class="notice-text">
            You have a call or meeting scheduled for this project. Please complete or cancel the scheduled call or meeting before closing this request.
          </div>

          <div class="notice-text">
            <div class="row">
              <div class="col-sm-8 col-sm-offset-2">
                <button type="button" class="nv-button-white a-button-size" @click="dismissModal">Close Window</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';

export default {
  name: 'CloseRequestConfirmation',

  components: {
    ModalCloseButton,
    SvgIcon,
    ValidationProvider,
    ValidationObserver
  },

  mixins: [
    modalToggle,
    interactionModes
  ],

  props: {
    hasScheduledAppointments: {
      type: Boolean,
      default: false
    },

    onConfirm: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      description: ''
    };
  },

  methods: {
    initModal() {
      this.description = '';
      this.$refs.validator.reset();
      this.openModal();
    },

    confirm() {
      this.onConfirm(this.description);
      this.closeModal();
    }
  }
};
</script>

<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a href="" class="back-link" @click.prevent="confirmExit"><svg-icon class="base-icon right-10" name="arrow-left"></svg-icon>Cancel & Exit</a>

        <request-confirmation
          ref="confirmExit"
          message="You will lose your progress if you exit. Are you sure?"
          @on-modal-yes="onExit">
        </request-confirmation>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="medium-page-heading bottom-30">
            Submit New RFP
          </div>

          <div class="row bottom-30">
            <div class="col-md-8 bottom-20">
              <div class="shadowed-box">
                <div class="box-content normal-weight size-text-13px">
                  <rfp-full-form-static
                    :request="request"
                    :is-editing="false"
                    :on-submit="submitRequest">
                  </rfp-full-form-static>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RfpFullFormStatic from 'vue-app/marketplace/shared/requests/rfp-full-form-static.vue';
import RequestConfirmation from 'vue-app/shared/components/request-confirmation.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import CounselRequest from 'resources/marketplace/counsel-request.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';

export default {
  name: 'MarketplaceClientRequestsNew',

  components: {
    RfpFullFormStatic,
    RequestConfirmation,
    SvgIcon
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      request: new CounselRequest({
        productInterestedIn: 'Request a Lawyer',
        professionalType: 'Lawyer',
        requestPageUrl: this.initData.route, // TODO: this.$route.fullPath
        referringUrl: document.referrer,
        ipAddress: null,
        country: 'United States',
        durationUnit: 'Weeks',
        timeCommitmentDetailsUnit: 'Hours',
        onsiteDetailsUnit: 'Days',
        barLogic: 'and',
        lawFirmOfficeLogic: 'and',
        requiredBars: [],
        practiceAreas: [],
        lawFirmOfficeLocations: [],
        firmAccreditations: {},
        companyDescription: this.initData.lastRequest?.companyDescription
      })
    };
  },

  methods: {
    confirmExit() {
      this.$refs.confirmExit.openModal();
    },

    onExit() {
      window.location = '/client-app/dashboard';
    },

    submitRequest(request) {
      return CounselRequest.postForm({ counselRequest: request })
        .then(() => {
          NotificationService.success('You successfully submitted your RFP. We will review the RFP and send you a custom proposal with lawyers from our vetted attorney network that match your project requirements. This typically occurs within 1-3 business days and you will be notified by email when your matches are ready.', true);
          window.location = '/client-app/dashboard';
        });
    }
  }
};
</script>

<template>
  <div>
    <div class="size-text-16px">
      {{ heading }}
    </div>

    <div class="top-2 bottom-20">
      <hr class="no-margin">
    </div>

    <div class="bottom-5 bold-weight">
      {{ question }}
    </div>

    <div class="row">
      <div class="col-sm-11">
        <div class="vue-slider-container">
          <vue-slider
            :value="value"
            :adsorb="true"
            :data="options"
            :marks="true"
            :tooltip="'none'"
            :contained="true"
            @change="val => onChange(val)">
            <template #label="{ active, label, value: val }">
              <span :class="['vue-slider-mark-label', 'custom-label', { active }]">
                <div>
                  {{ options[val] || '&nbsp;' }}
                </div>

                <div class="hidden-xs" v-for="string in splitLabelString(label)" :key="`${heading}-${string}`">
                  {{ string }}
                </div>
              </span>
            </template>
          </vue-slider>
        </div>
      </div>
    </div>

    <div v-if="explanation && explanation.length > 0">
      <button type="button" class="pseudo-link-blue semibold-weight" @click="toggleExplanation">Items to Consider</button>

      <div v-show="showExplanation">
        <div class="top-10 dark-gray-text">
          {{ explanation }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
  name: 'QuestionSlider',

  components: {
    VueSlider
  },

  props: {
    heading: {
      type:    String,
      default: ''
    },

    question: {
      type:    String,
      default: ''
    },

    explanation: {
      type:    String,
      default: ''
    },

    options: {
      type:     Object,
      required: true
    },

    value: {
      default: () => 'N/A'
    }
  },

  data() {
    return {
      showExplanation: false
    };
  },

  methods: {
    splitLabelString(label) {
      return label.split(' ');
    },

    toggleExplanation() {
      this.showExplanation = !this.showExplanation;
    },

    onChange(value) {
      this.$emit('change', { value: this.options[value] });
    }
  }
};
</script>

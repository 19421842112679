<template>
  <edit-modal ref="editModal" :title="isEditing ? 'Edit User' : 'Add New User'" :disabled="saving" :input="lawyer" :on-save="save">
    <template #modal-trigger="{ openModal }">
      <slot name="modal-trigger" :open-modal="openModal"></slot>
    </template>

    <template #default="{ inputCopy: lawyerCopy }">
      <div class="small-bold-header">
        User Info
      </div>

      <hr class="top-5 bottom-20">

      <div class="row bottom-20">
        <div class="col-sm-6">
          <text-input-vertical label="First Name" id="first-name" name="firstName" v-model="lawyerCopy.firstName" rules="required"></text-input-vertical>
        </div>

        <div class="col-sm-6 top-20-xs">
          <text-input-vertical label="Last Name" id="last-name" name="lastName" v-model="lawyerCopy.lastName" rules="required"></text-input-vertical>
        </div>
      </div>

      <div class="bottom-20">
        <div v-if="isEditing">
          <label>Email</label>

          <div>
            {{ lawyerCopy.email }}
          </div>
        </div>

        <div v-else>
          <text-input-vertical type="email" label="Email" id="email" name="email" v-model="lawyerCopy.email" rules="required|email"></text-input-vertical>
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-6">
          <text-input-vertical label="Title" id="firm-title" name="firmTitle" v-model="lawyerCopy.firmTitle" rules="required"></text-input-vertical>
        </div>
      </div>

      <div class="small-bold-header">
        Employee ID
      </div>

      <hr class="top-5 bottom-20">

      <div class="row bottom-30">
        <div class="col-sm-6">
          <text-input-vertical label="Employee ID" id="employee-id" name="employeeId" v-model="lawyerCopy.employeeNumber" :rules="employeeIdIsRequired ? 'required' : null"></text-input-vertical>
        </div>
      </div>

      <div class="bottom-30">
        <div class="small-bold-header">
          Billing ID
        </div>

        <hr class="top-5 bottom-5">

        <div class="dark-gray-text text-italic size-text-12px bottom-20">
          Please select a billing system and provide a billing ID. You can input multiple billing system/ID combinations below. If the user does not have a billing ID, please select ‘No’.
        </div>

        <div class="bold-weight bottom-5">
          Does the user have a Billing ID?
        </div>

        <div class="bottom-20">
          <label class="check-option vertical medium-large">
            <span class="normal-weight">Yes</span>
            <input type="radio" v-model="hasBillingId" name="hasBillingId" id="has-billing-type-yes" :value="true" checked>
            <span class="check c-radio"></span>
          </label>

          <label class="check-option vertical medium-large">
            <span class="normal-weight">No</span>
            <input type="radio" v-model="hasBillingId" name="hasBillingId" id="has-billing-type-no" :value="false" @change="resetNewBillingIds">
            <span class="check c-radio"></span>
          </label>
        </div>

        <div v-if="hasBillingId">
          <div v-for="(billingId, index) in [...lawyerCopy.billingSystemTimekeeperIdentifiers, ...newBillingIds]" :key="billingId.id" class="d-flex col-gap-10 row-gap-10 vertical-center-not-xs bottom-15">
            <div class="flex-1 remove-button-container">
              <a href="" @click.prevent="removeBillingId(lawyerCopy.billingSystemTimekeeperIdentifiers, billingId)" :disabled="!canRemoveBillingId(lawyerCopy.billingSystemTimekeeperIdentifiers)">
                <svg-icon name="minus-circle" class="base-icon"></svg-icon>
              </a>
            </div>

            <div class="row tight-columns flex-20">
              <div class="col-sm-6">
                <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
                  <label>
                    Billing System <span class="inline-help required">*</span>
                  </label>

                  <dropdown-select
                    v-model="billingId.billingSystemId"
                    :id-label="`billing-system-id-${index}`"
                    label-key="name"
                    value-key="id"
                    :initial-label="billingId.billingSystemName"
                    :options="filteredBillingSystems"
                    :invalid="errors.length > 0"
                    @change="syncBillingSystemOptions(lawyerCopy.billingSystemTimekeeperIdentifiers)">
                  </dropdown-select>

                  <div class="error-text top-5" v-if="errors.length">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="col-sm-6 top-20-xs">
                <text-input-vertical label="Billing/Timekeeper ID" :id="`billing-id-${index}`" name="billingId" v-model="billingId.externalId" rules="required"></text-input-vertical>
              </div>
            </div>
          </div>

          <div>
            <button type="button" class="pseudo-link-blue semibold-weight" @click="addBillingId(lawyerCopy.billingSystemTimekeeperIdentifiers)">
              + Add Another
            </button>
          </div>
        </div>
      </div>

      <div class="bottom-30">
        <div class="small-bold-header">
          Profile
        </div>

        <hr class="top-5 bottom-20">

        <div class="bold-weight bottom-5">
          Should this user have a lawyer profile?
        </div>

        <label class="check-option vertical medium-large">
          <span class="normal-weight">Yes</span>
          <input type="radio" v-model="lawyerCopy.hasVisibleProfile" name="hasVisibleProfile" :value="true">
          <span class="check c-radio"></span>
        </label>

        <label class="check-option vertical medium-large">
          <span class="normal-weight">No</span>
          <input type="radio" v-model="lawyerCopy.hasVisibleProfile" name="hasVisibleProfile" :value="false">
          <span class="check c-radio"></span>
        </label>
      </div>

      <div class="bottom-30">
        <div class="small-bold-header">
          Editor
        </div>

        <hr class="top-5 bottom-20">

        <div class="bold-weight bottom-5">
          Should this user be able to edit other users’ lawyer profiles and the law firm profile?
        </div>

        <label class="check-option vertical medium-large">
          <span class="normal-weight">Yes</span>
          <input type="radio" v-model="lawyerCopy.isEditor" name="isEditor" :value="true">
          <span class="check c-radio"></span>
        </label>

        <label class="check-option vertical medium-large">
          <span class="normal-weight">No</span>
          <input type="radio" v-model="lawyerCopy.isEditor" name="isEditor" :value="false">
          <span class="check c-radio"></span>
        </label>
      </div>

      <div class="bottom-30">
        <div class="small-bold-header">
          View Client Data
        </div>

        <hr class="top-5 bottom-5">

        <div class="dark-gray-text text-italic size-text-12px bottom-20">
          Selecting ‘Yes’ below will determine whether or not this user has access to the client-specific pages within your law firm’s Scout environment. This may include viewing client-specific rates for lawyers at your law firm.
        </div>

        <div class="bold-weight bottom-5">
          Can this user access client data?
        </div>

        <label class="check-option vertical medium-large">
          <span class="normal-weight">Yes</span>
          <input type="radio" v-model="lawyerCopy.partner" name="partner" :value="true">
          <span class="check c-radio"></span>
        </label>

        <label class="check-option vertical medium-large">
          <span class="normal-weight">No</span>
          <input type="radio" v-model="lawyerCopy.partner" name="partner" :value="false" @change="syncRfqAccessField(lawyerCopy)">
          <span class="check c-radio"></span>
        </label>
      </div>

      <div class="bottom-30">
        <div class="small-bold-header">
          RFP Access
        </div>

        <hr class="top-5 bottom-5">

        <div class="dark-gray-text text-italic size-text-12px bottom-20">
          Note: View Client Data must be enabled for a user in order to turn on RFP access.
        </div>

        <div class="bold-weight bottom-5">
          Can this user view and respond to RFPs?
        </div>

        <label :class="['check-option vertical medium-large', { 'disabled': !lawyerCopy.partner }]">
          <span class="normal-weight">Yes</span>
          <input type="radio" v-model="lawyerCopy.hasRfqEnabled" name="hasRfqEnabled" :value="true" :disabled="!lawyerCopy.partner">
          <span class="check c-radio"></span>
        </label>

        <label :class="['check-option vertical medium-large', { 'disabled': !lawyerCopy.partner }]">
          <span class="normal-weight">No</span>
          <input type="radio" v-model="lawyerCopy.hasRfqEnabled" name="hasRfqEnabled" :value="false" :disabled="!lawyerCopy.partner">
          <span class="check c-radio"></span>
        </label>
      </div>

      <div>
        <div class="small-bold-header">
          Admin
        </div>

        <hr class="top-5 bottom-5">

        <div class="dark-gray-text text-italic size-text-12px bottom-20">
          Selecting ‘Admin’ below will grant the user access to all Admin tools within Scout, including the ability to add, edit, and deactivate users.
        </div>

        <label class="check-option vertical medium-large">
          <span class="normal-weight">Admin</span>
          <input type="radio" v-model="lawyerCopy.isScoutAdmin" name="isScoutAdmin" :value="true">
          <span class="check c-radio"></span>
        </label>

        <label class="check-option vertical medium-large">
          <span class="normal-weight">Non-Admin</span>
          <input type="radio" v-model="lawyerCopy.isScoutAdmin" name="isScoutAdmin" :value="false">
          <span class="check c-radio"></span>
        </label>
      </div>
    </template>
    <template #errorMessage>
      * One or more required fields are empty. Please fill in the required fields indicated above.
    </template>
  </edit-modal>
</template>

<script>
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import { indexOf, uniqueId, omitBy, some, uniq, snakeCase } from 'lodash';
import { ValidationProvider } from 'vee-validate';
import { changes }  from 'misc/resource-helpers';
import DataLock from 'resources/data-lock.js';

export default {
  name: 'EditLawyerPermissions',

  components: {
    EditModal,
    DropdownSelect,
    ValidationProvider
  },

  mixins: [interactionModes],

  props: {
    lawyer: {
      type: Object,
      default: () => ({
        firstName: null,
        lastName: null,
        email: null,
        firmTitle: null,
        employeeNumber: null,
        hasVisibleProfile: true,
        isEditor: false,
        partner: false,
        hasRfqEnabled: false,
        isScoutAdmin: false,
        billingSystemTimekeeperIdentifiers: []
      })
    },

    employeeIdIsRequired: {
      type: Boolean,
      default: false
    },

    billingSystems: {
      type: Array,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      billingIdsToDestroy: [],
      filteredBillingSystems: this.billingSystems,
      hasBillingId: this.lawyer.id ? !!this.lawyer.billingSystemTimekeeperIdentifiers.length : true,
      newBillingIds: this.lawyer.billingSystemTimekeeperIdentifiers.length === 0 ? [{ id: uniqueId('new_billing_id_'), billingSystemId: null, externalId: null }] : []
    };
  },

  computed: {
    saving() {
      return LoadingService.isLoading('lawyer-permissions');
    },

    isEditing() {
      return !!this.lawyer.id;
    }
  },

  mounted() {
    this.syncBillingSystemOptions(this.lawyer.billingSystemTimekeeperIdentifiers);
  },

  methods: {
    addBillingId() {
      this.newBillingIds.push({
        id: uniqueId('new_billing_id_'),
        billingSystemId: null,
        externalId: null
      });
    },

    removeBillingId(editedBillingIds, billingId) {
      if (!this.isNewBillingId(billingId)) {
        this.billingIdsToDestroy = [...this.billingIdsToDestroy, { id: billingId.id, _destroy: true }];
        editedBillingIds.splice(indexOf(editedBillingIds, billingId), 1);
      }
      else {
        this.newBillingIds.splice(indexOf(this.newBillingIds, billingId), 1);
      }

      this.syncBillingSystemOptions(editedBillingIds);
    },

    isNewBillingId(billingId) {
      return this.newBillingIds.includes(billingId);
    },

    canRemoveBillingId(editedBillingIds) {
      return (editedBillingIds.length + this.newBillingIds.length) > 1;
    },

    syncRfqAccessField(lawyerCopy) {
      lawyerCopy.hasRfqEnabled = false;
    },

    resetNewBillingIds() {
      this.newBillingIds = [];

      if (this.lawyer.billingSystemTimekeeperIdentifiers.length === 0) {
        this.addBillingId();
      }
    },

    syncBillingSystemOptions(currentBillingIds) {
      const selectedBillingIds = [...currentBillingIds, ...this.newBillingIds];
      const billingSystemIdsInUseIds = uniq(selectedBillingIds.map(billingId => billingId.billingSystemId));

      this.filteredBillingSystems = this.billingSystems.filter(billingSystem => !billingSystemIdsInUseIds.includes(billingSystem.id));
    },

    dataLockFlags(response) {
      const vueInstance = this;
      const flagFields = [
        'hasVisibleProfile', 'isEditor', 'hasRfqEnabled', 'isScoutAdmin', 'partner'
      ];

      const flags = [];

      flagFields.forEach((field) => {
        if (response[field] !== vueInstance.lawyer[field]) {
          flags.push(snakeCase(field));
        }
      });

      if (!vueInstance.lawyer.id || some(changes(vueInstance.lawyer.billingSystemTimekeeperIdentifiers, response.billingSystemTimekeeperIdentifiers))) {
        flags.push('billing_system_timekeeper_identifiers');
      }

      return flags;
    },

    save(editedLawyer) {
      if (this.hasBillingId) {
        editedLawyer.billingSystemTimekeeperIdentifiersAttributes = [
          ...editedLawyer.billingSystemTimekeeperIdentifiers,
          ...this.newBillingIds,
          ...this.billingIdsToDestroy
        ].map(billingId => (
          omitBy(billingId, (value, key) => (key === 'id' && value.toString().includes('new_billing_id_')))
        ));
      }

      const vueInstance = this;

      this.onSave(editedLawyer).then((response) => {
        if (!vueInstance.lawyer.id || some(changes(vueInstance.lawyer.billingSystemTimekeeperIdentifiers, response.billingSystemTimekeeperIdentifiers))) {
          this.resetNewBillingIds();
          this.billingIdsToDestroy = [];
        }

        const flags = this.dataLockFlags(response);
        if (flags.length > 0) {
          DataLock.save({
            dataLock: {
              flag:         flags,
              resourceId:   vueInstance.lawyer.id || response.id,
              resourceType: 'lawyer'
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .remove-button-container {
    align-self: flex-start;

    @media (min-width: 768px) {
      padding-top: 35px;
    }
  }
</style>

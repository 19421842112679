<template>
  <div class="clear-panel compact-panel">
    <div class="row tight-columns notification-row vertical-center-not-xs">
      <div class="icon-column">
        <div class="notification-icon">
          <svg-icon name="notification" class="base-icon"></svg-icon>
        </div>
      </div>

      <div class="text-column top-20-xs">
        <div class="bold-weight bottom-2">
          RFP #{{ notification.rfqId }}
        </div>

        <div class="pill-gray-text tight-lines">
          {{ notification.message }}
        </div>
      </div>

      <div class="link-column top-20-xs">
        <a :href="notificationLink" class="bold-weight">View RFP</a>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RfqNotification',

  components: {
    SvgIcon
  },

  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  computed: {
    notificationLink() {
      return this.notification.isDraft ? `/scout-company/rfps/${this.notification.rfqId}/edit` : `/scout-company/rfps/${this.notification.rfqId}`;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .notification-row {
    .notification-icon {
      position: relative;
      width: 22px;
      height: 22px;

      .base-icon {
        width: 22px;
        height: 22px;
        fill: $k-dark-gray;
      }

      &::after {
        display: block;
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 1px;
        right: 1px;
        background-color: $scout-red;
        border-radius: 50%;
      }
    }

    .icon-column, .text-column, .link-column {
      width: 100%;
      padding-right: 5px;
      padding-left: 5px;
    }

    @media (min-width: 768px) {
      // col-sm-2 shifting 15px into the text column
      .icon-column {
        width: calc((100% * (2 / 12)) - 15px);
      }

      // col-sm-6 plus 15px
      .text-column {
        width: calc((100% * (6 / 12)) + 15px);
      }

      // col-sm-4
      .link-column {
        width: calc(100% * (4 / 12));
        text-align: right;
      }
    }
  }
</style>

<template>
  <div class="shadowed-box">
    <div class="box-content">
      <div class="size-text-17px bold-weight padding-bottom-10 blue-bottom-border">
        Welcome to Feature Lab!
      </div>

      <div class="top-20 bottom-20 size-text-14px">
        Explore our new tools to better manage your law firm network. Send RFPs to receive competitive quotes on new matters, submit reviews to share your experience with colleagues, and view scorecards to easily compare performance across firms.
      </div>

      <div class="row d-flex-not-xs">
        <div class="col-sm-4 bottom-30">
          <card class="card-outer height-100">
            <template #icon>
              <svg-icon name="check-and-dollar" class="icon-lg"></svg-icon>
            </template>

            <template #message>
              <div class="bold-weight size-text-15px tight-lines">
                Start a New RFP
              </div>
            </template>

            <template #action>
              <a href="/scout-company/rfps/new" :class="['primary-btn-blue', { 'disabled': !hasRfqEnabled }]">Create RFP</a>
            </template>
          </card>
        </div>

        <div class="col-sm-4 bottom-30">
          <card class="card-outer height-100">
            <template #icon>
              <svg-icon name="trophy" class="icon-lg"></svg-icon>
            </template>

            <template #message>
              <div class="bold-weight size-text-15px tight-lines">
                Review Law Firms
              </div>
            </template>

            <template #action>
              <a href="/scout-company/reviews?leavingReview" class="secondary-btn-blue">Leave A Review</a>
            </template>
          </card>
        </div>

        <div class="col-sm-4 bottom-30">
          <card class="card-outer height-100">
            <template #icon>
              <svg-icon name="flying-star" class="icon-lg"></svg-icon>
            </template>

            <template #message>
              <div class="bold-weight size-text-15px tight-lines">
                Compare Performance
              </div>
            </template>

            <template #action>
              <a href="/scout-company/firm-status" class="secondary-btn-blue">Explore Firms</a>
            </template>
          </card>
        </div>
      </div>

      <div class="contact-wrapper">
        <svg-icon name="envelope-alt" class="icon-sm"></svg-icon>

        <div class="contact-content">
          <span class="semibold-weight">If you enjoy these features and would like to learn more about our advanced capabilities, please contact us at </span><a href="mailto:featurelab@priorilegal.com" class="bold-weight">featurelab@priorilegal.com</a>.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import Card from 'vue-app/shared/components/card.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'FeatureLabClientWelcomeBanner',

  components: {
    SvgIcon,
    Card
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    hasRfqEnabled() {
      return this.currentUser.hasRfqEnabled;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .blue-bottom-border {
    border-bottom: 3px solid $newblue-6;
  }

  .icon-sm {
    width: 32px;
    height: 32px;
    padding-top: 7px;
  }

  .icon-lg {
    width: 64px;
    height: 64px;
  }

  .card-outer {
    .primary-btn-blue, .secondary-btn-blue {
      width: 178px;

      @media (min-width: $screen-sm-min) {
        width: 140px;
      }
    }
  }

  .contact-wrapper {
    width: 100%;
    padding: 20px;
    border-radius: $border-radius-base;
    background: $bg-color;

    @media (min-width: $screen-sm-min) {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      padding: 15px 40px;
    }

    .contact-content {
      flex: 1 1 0;
    }
  }
</style>

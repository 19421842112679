<template>
  <div class="editor">
    <div v-if="editor" class="toolbar">
      <button type="button" v-if="showBold" @click="toggleBold()" :disabled="boldDisabled" :class="['button', { 'is-active': editor.isActive('bold') }]" title="Bold">
        <svg-icon name="text-bold" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showItalic" @click="toggleItalic()" :disabled="italicDisabled" :class="['button', { 'is-active': editor.isActive('italic') }]" title="Italic">
        <svg-icon name="text-italic" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showUnderline" @click="toggleUnderline()" :disabled="underlineDisabled" :class="['button', { 'is-active': editor.isActive('underline') }]" title="Underline">
        <svg-icon name="text-underline" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showBulletList" @click="toggleBulletList()" :disabled="bulletListDisabled" :class="['button', { 'is-active': editor.isActive('bulletList') }]" title="Bullets">
        <svg-icon name="list-bullets" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showOrderedList" @click="toggleOrderedList()" :disabled="orderedListDisabled" :class="['button', { 'is-active': editor.isActive('orderedList') }]" title="Numbers">
        <svg-icon name="ordered-list" class="base-icon"></svg-icon>
      </button>

      <button type="button" @click="undoChanges()" :disabled="undoDisabled" class="button" title="Undo">
        <svg-icon name="undo" class="base-icon"></svg-icon>
      </button>

      <button type="button" @click="redoChanges()" :disabled="redoDisabled" class="button" title="Redo">
        <svg-icon name="redo" class="base-icon"></svg-icon>
      </button>
    </div>

    <editor-content class="content" :editor="editor" spellcheck="false"></editor-content>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';

export default {
  name: 'TiptapEditor',

  components: {
    EditorContent
  },

  props: {
    value: {
      type: String,
      default: null
    },

    buttons: {
      type: Array,
      default: () => [
        'bold',
        'italic',
        'underline',
        'bulletList',
        'orderedList'
      ]
    },

    placeholder: {
      type: String,
      default: 'Enter content'
    }
  },

  data() {
    const extensionMap = {
      bold: Bold,
      italic: Italic,
      underline: Underline,
      bulletList: BulletList,
      orderedList: OrderedList
    };

    return {
      editor: new Editor({
        content: this.value,

        // Initialize the editor with just the necessary extensions based on `buttons` prop.
        // Using the extensions bundled into the StarterKit (https://tiptap.dev/api/extensions/starter-kit) would achieve a similar result,
        // but it would add extra hidden formatting options we don't need with their corresponding keyboard shorcuts.
        // We want keyboard shorcuts to mirror the options in the UI.

        extensions: [
          ...this.buttons.map(button => (extensionMap[button])),
          Document,
          ListItem,
          Paragraph,
          Text,
          History,
          Placeholder.configure({
            emptyEditorClass: 'is-editor-empty',
            placeholder: this.placeholder
          })
        ],

        onUpdate: () => {
          this.$emit('input', this.editor.getHTML());
        },

        editorProps: {
          attributes: {
            class: 'edit-area'
          }
        }
      })
    };
  },

  computed: {
    boldDisabled() {
      return !this.editor.can().chain().focus().toggleBold().run();
    },

    italicDisabled() {
      return !this.editor.can().chain().focus().toggleItalic().run();
    },

    underlineDisabled() {
      return !this.editor.can().chain().focus().toggleUnderline().run();
    },

    bulletListDisabled() {
      return !this.editor.can().chain().focus().toggleBulletList().run();
    },

    orderedListDisabled() {
      return !this.editor.can().chain().focus().toggleOrderedList().run();
    },

    undoDisabled() {
      return !this.editor.can().chain().focus().undo().run();
    },

    redoDisabled() {
      return !this.editor.can().chain().focus().redo().run();
    },

    showBold() {
      return this.buttons.includes('bold');
    },

    showItalic() {
      return this.buttons.includes('italic');
    },

    showUnderline() {
      return this.buttons.includes('underline');
    },

    showBulletList() {
      return this.buttons.includes('bulletList');
    },

    showOrderedList() {
      return this.buttons.includes('orderedList');
    }
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) { return; }

      this.editor.commands.setContent(value, false);
    }
  },

  methods: {
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },

    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },

    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run();
    },

    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run();
    },

    toggleOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run();
    },

    undoChanges() {
      this.editor.chain().focus().undo().run();
    },

    redoChanges() {
      this.editor.chain().focus().redo().run();
    }
  }
};

</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  $border-button-color: #e5e9ec;

  .editor {
    .toolbar {
      padding: 15px;
      border: 1px solid $k-gray;
      border-top-left-radius: $border-radius-large;
      border-top-right-radius: $border-radius-large;
      background-color: #f0f4f6;

      .button {
        width: 30px;
        height: 30px;
        border: 1px solid $border-button-color;
        border-radius: $border-radius-base;
        background-color: $white;

        &:hover {
          border: 1px solid $k-blue;
        }

        &.is-active {
          border: 1px solid $k-blue;
        }

        &:disabled {
          &:hover {
            border: 1px solid $border-button-color;
          }

          svg {
            opacity: .2;
          }
        }

        svg {
          display: block;
          width: 14px;
          height: 14px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }

    .content {
      border: 1px solid $k-gray;
      border-top: none;
      border-bottom-left-radius: $border-radius-large;
      border-bottom-right-radius: $border-radius-large;
      padding: 15px;
    }
  }

  :deep(.edit-area) {
    line-height: 20px;
    outline: none;
    min-height: 30px;

    ul, ol {
      margin-top: 0;
      margin-bottom: 9.5px;
      padding-inline-start: 16px;
    }

    p.is-editor-empty:first-child::before {
      color: $k-gray;
      font-size: 14px;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
</style>

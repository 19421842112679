import Message from 'resources/marketplace/message.js';
import Conversation from 'resources/marketplace/conversation.js';

import { each, filter, find, get, map, pull } from 'lodash';

class MessagingService {
  constructor() {
    this.unreadCount     = 0;
    this.conversations   = [];
    this.selectedLoaders = [];

    // temporary attribute until client app converted to Vue
    // see setAngularPiniaStore below
    this.angularPiniaStore = null;

    this.loaders = {
      'unreadCount':           this.loadUnreadCount,
      'conversations':         this.loadConversations,
      'loadConversation':      this.loadConversation,
      'createOrUpdateMessage': this.createOrUpdateMessage
    };

    this.setUserClass('admin');
    this.addLoader('unreadCount');
  }

  setUserClass(userClass) {
    this.userClass = userClass;
  }

  addLoader(loaderLabel) {
    const loader = this.loaders[loaderLabel];

    if (this.selectedLoaders.includes(loader)) { return; }

    this.selectedLoaders.push(loader);
  }

  removeLoader(loaderLabel) {
    const loader = this.loaders[loaderLabel];

    pull(this.selectedLoaders, loader);
  }

  // temporary hack to allow "broadcasting" to an angular controller
  // Once the client app is converted to Vue, it can listen to pinia without
  // having to pass an explicit store
  setAngularPiniaStore(store) {
    this.angularPiniaStore = store;
  }

  loadUnreadCount() {
    return Message.fetchUnreadCount().then((result) => {
      this.unreadCount = result.unreadCount;
    });
  }

  loadConversations() {
    return Conversation.query().then((conversations) => {
      this.conversations = conversations;

      each(this.conversations, (conversation) => {
        conversation.setParticipantDisplaysFor(this.userClass);
      });
    });
  }

  loadConversation(conversation) {
    conversation = conversation || this.selectedConversation;
    this.setConversation(conversation);

    return Conversation.get({ id: conversation.id }).then((respConversation) => {
      conversation.isUnread = false;
      this.setConversation(respConversation);

      return respConversation;
    });
  }

  createOrUpdateMessage(sending = false) {
    if (sending) {
      this.selectedConversation.draftMessage.draft = false;
    }

    const possibleLawyerId = get(this.lastReceivedMessage(), 'message.possibleLawyerId', null);

    return Message.save({
      id:                this.selectedConversation.draftMessage.id,
      conversationId:    this.selectedConversation.id,
      recipients:        this.selectedConversation.formattedRecipients,
      message:           this.selectedConversation.draftMessage,
      attachments:       map(this.selectedConversation.files, 'id').join(','),
      possibleLawyerId:  possibleLawyerId,
      answeringQuestion: possibleLawyerId !== null
    }).then((receipt) => {
      if (this.selectedConversation.draftMessage.id && receipt.message.id !== this.selectedConversation.draftMessage.id) {
        this.loadConversation(this.findConversation(receipt.message.conversationId));
      }
      else if (this.selectedConversation) {
        if (sending) {
          this.selectedConversation.lastMessageSentAt = receipt.message.updatedAt;
        }

        this.afterCreateOrUpdateMessage(receipt, this.selectedConversation);
      }
    });
  }

  lastReceivedMessage() {
    const receivedMessages = filter(this.selectedConversation.receipts, (receipt) => receipt.mailboxType === 'inbox');

    return receivedMessages[receivedMessages.length - 1];
  }

  setConversation(conversation) {
    this.selectedConversation = conversation;
    this.selectedConversation.setParticipantDisplaysFor(this.userClass);
  }

  findConversation(id) {
    return find(this.conversations, { id: id });
  }

  afterCreateOrUpdateMessage(receipt, conversation) {
    if (conversation && this.selectedConversation && (conversation.id === this.selectedConversation.id)) {
      conversation.setMostRecentReceipt(receipt);
    }

    if (receipt.message.draft) {
      conversation.draftSubject = receipt.message.body.substr(0, 256);
    }
    else {
      if (this.angularPiniaStore) { this.angularPiniaStore.triggerUpdate(); }
      conversation.subject = receipt.message.body.substr(0, 256);
    }

    const existingConversation = this.findConversation(conversation.id);

    if (existingConversation) {
      this.conversations.splice(this.conversations.indexOf(existingConversation), 1, conversation);
    }
    else {
      this.conversations.push(conversation);
    }
  }

  refresh() {
    if (this.selectedConversation) {
      this.addLoader('createOrUpdateMessage');
      this.addLoader('loadConversation');
    }
    else {
      this.removeLoader('createOrUpdateMessage');
      this.removeLoader('loadConversation');
    }

    // use `bind` since the detachment of the functions in loaders() ends
    // up invoking functions without the messaging service as a context
    return Promise.all(map(this.selectedLoaders, loader => loader.bind(this)()));
  }

  unloadConversation() {
    this.selectedConversation = null;
  }

  archiveConversation() {
    return Conversation.delete({ id: this.selectedConversation.id }).then(() => {
      const conversation = this.findConversation(this.selectedConversation.id);
      this.conversations.splice(this.conversations.indexOf(conversation), 1);
      this.unloadConversation();
    });
  }

  findOrCreateConversation(conversation) {
    const recipients = map(conversation.recipients, 'postId').join(',');

    return Conversation.save({ recipients: recipients }).then((res) => {
      this.setConversation(res);

      if (this.findConversation(res.id)) { return; }

      this.conversations.push(this.selectedConversation);
    });
  }

  createMessageFromModal(message, recipients, possibleLawyerId, counselRequestId) {
    const formattedRecipients = map(recipients, recipient => `${recipient.klass}-${recipient.id}`).join(',');

    return Message.save({
      message: message,
      recipients: formattedRecipients,
      attachments: map(message.attachments, 'id').join(','),
      possibleLawyerId: possibleLawyerId,
      counselRequestId: counselRequestId
    }).then((res) => {
      this._setupConversationFromResponse(res, recipients);
    });
  }

  _setupConversationFromResponse(respConvo, recipients) {
    let conversation = this.findConversation(respConvo.message.conversationId);

    if (!conversation) {
      conversation = new Conversation({
        id:           respConvo.message.conversationId,
        receipts:     [respConvo],
        participants: recipients
      });

      conversation.setParticipantDisplaysFor(this.userClass);
    }

    this.afterCreateOrUpdateMessage(respConvo, conversation);
  }
}

export default new MessagingService();

<template>
  <validation-observer ref="form" v-slot="{ handleSubmit, invalid }">
    <form role="form" name="form" class="a-form" novalidate>
      <div class="shadowed-box">
        <div class="box-content extra-padded">
          <div class="size-text-17px bold-weight bottom-5">
            Add Details About Your Project
          </div>

          <div class="bottom-30">
            Provide details about your project for the recipients to review. Note that any optional questions left blank will not appear in the Project Overview provided to recipients. Fields marked with <span class="red-text semibold-weight">*</span> are required.
          </div>

          <div class="row bottom-20">
            <div class="col-sm-6">
              <label>RFP Submitted By <span class="red-text">*</span></label>

              <div class="top-2">
                {{ rfq.creatorFullName }}
              </div>
            </div>

            <div class="col-sm-6 top-20-xs">
              <label for="practice-group">Practice Group <span class="red-text">*</span></label>

              <validation-provider rules="required">
                <input id="practice-group" rules="required" class="form-control" v-model="rfq.assignedClientPracticeGroup">
              </validation-provider>
            </div>
          </div>

          <div class="bottom-20">
            <label for="description-of-work">Description of Work <span class="red-text">*</span></label>

            <validation-provider rules="required">
              <textarea id="description-of-work" class="form-control" rows="3" v-model="rfq.descriptionOfWork"></textarea>
            </validation-provider>
          </div>

          <div class="bottom-20">
            <label for="counterparty-details">Any relevant opposing party/counterparty details, including counsel</label>

            <textarea id="counterparty-details" class="form-control" rows="3" v-model="rfq.counterpartyDetails"></textarea>
          </div>

          <div class="bottom-20">
            <label for="estimated-timeline">Estimated Timeline</label>

            <textarea id="estimated-timeline" class="form-control" rows="3" v-model="rfq.estimatedTimeline"></textarea>
          </div>

          <div class="bottom-20">
            <label for="budget-min">Budget (if any)</label>

            <div class="row tight-columns">
              <div class="col-sm-3">
                <currency-input-vertical
                  v-model.number="rfq.budgetMin"
                  id="budget-min"
                  name="budgetMin"
                  placeholder="Bottom of range (if applicable)"
                  :rules="validationRulesMin"
                  validation-mode="eager">
                </currency-input-vertical>
              </div>

              <div class="col-sm-1 text-center top-10 hidden-xs">
                &mdash;
              </div>

              <div class="col-sm-3 top-10-xs">
                <currency-input-vertical
                  v-model.number="rfq.budgetMax"
                  id="budget-max"
                  name="budgetMax"
                  placeholder="Top of range"
                  :rules="validationRulesMax"
                  :custom-error-messages="{ minValue: `* Can't be less than bottom of range` }"
                  validation-mode="eager">
                </currency-input-vertical>
              </div>
            </div>
          </div>

          <div class="bottom-20">
            <label for="rate-structure">Preferred Rate Structure</label>

            <div class="bottom-5">
              Please describe your preferred rate structure (e.g. hourly, fixed, collared, contingent, or another alternative structure).
            </div>

            <textarea id="rate-structure" class="form-control" rows="3" v-model="rfq.preferredRateStructure"></textarea>
          </div>

          <div class="bottom-20">
            <label for="additional-information">Additional Information</label>

            <textarea id="additional-information" class="form-control" rows="3" v-model="rfq.additionalInformation"></textarea>
          </div>

          <div class="bottom-30">
            <div class="bold-weight bottom-10">
              Supporting Documents
            </div>

            <div class="row tight-columns vertical-center-not-xs">
              <div class="col-sm-3 button-column">
                <file-selector
                  :accept="acceptedExtensions"
                  :max-size="50"
                  :multiple="true"
                  @change="addAttachments">
                  <div class="secondary-btn-blue clickable width-max-content">Upload File(s)</div>
                </file-selector>
              </div>

              <div class="col-sm-9 files-column top-20-xs">
                <loading-section name="addAttachments">
                  <div class="file-list" v-if="hasAttachments">
                    <div class="file-item" v-for="attachment in rfq.supportingDocuments">
                      <file-tag :file="attachment" :on-delete="removeAttachment"></file-tag>
                    </div>
                  </div>
                </loading-section>
              </div>
            </div>
          </div>

          <div class="row tight-columns">
            <div class="col-sm-6">
              <label for="due-date">Responses Due By <span class="red-text">*</span></label>

              <div class="date-picker-container">
                <validation-provider rules="required|dateIsInTheFuture" mode="eager" :immediate="hasDueDate" v-slot="{ errors, failed }">
                  <div class="input-group datepicker-group" @click="toggleRenderDatePicker">
                    <input
                      type="text"
                      id="due-date"
                      :class="['form-control', { 'has-error': failed }]"
                      readonly
                      :value="formattedDate(rfq.dueDate)">

                    <div class="input-group-btn">
                      <button type="button" class="btn btn-default">
                        <svg-icon name="calendar" class="base-icon button-icon"></svg-icon>
                      </button>
                    </div>
                  </div>

                  <div v-if="errors.length && !renderDatePicker" class="error-text top-5">
                    <error-message :errors="errors"></error-message>
                  </div>
                </validation-provider>

                <styled-date-picker
                  v-if="renderDatePicker"
                  class="styled-date-picker top-5"
                  :limit-from="limitFrom"
                  :date="formattedDate(rfq.dueDate)"
                  @input="setDueDate">
                </styled-date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row top-30">
        <div class="col-sm-4 col-md-3">
          <button type="button" class="secondary-btn-blue" @click="previous">Previous</button>
        </div>

        <div class="col-sm-4 col-sm-offset-4 col-md-3 col-md-offset-6 top-20-xs">
          <button type="button" :class="['primary-btn-blue', { 'disabled': invalid }]" @click="handleSubmit(next)">Continue</button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import FileSelector from 'vue-app/shared/components/file-selector.vue';
import FileTag from 'vue-app/shared/components/file-tag.vue';
import CurrencyInputVertical from 'vue-app/shared/components/currency-input-vertical.vue';
import StyledDatePicker from 'vue-app/shared/components/styled-date-picker.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import { ACCEPTED_FILE_EXTENSIONS } from 'vue-app/scout/shared/rfqs/constants.js';
import { map, without } from 'lodash';

export default {
  name: 'RfqStepProjectDetails',

  components: {
    FileSelector,
    ValidationObserver,
    ValidationProvider,
    FileTag,
    CurrencyInputVertical,
    StyledDatePicker,
    SvgIcon,
    ErrorMessage
  },

  props: {
    nextStep: {
      type: Object
    },

    prevStep: {
      type: Object
    },

    rfq: {
      type: Object,
      required: true
    },

    onUpdateSupportingDocuments: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      acceptedExtensions: ACCEPTED_FILE_EXTENSIONS,
      limitFrom: this.isPast(this.rfq.dueDate) ? new Date(this.rfq.dueDate) : new Date(),
      renderDatePicker: false
    };
  },

  computed: {
    hasAttachments() {
      return this.rfq.supportingDocuments?.length > 0;
    },

    hasDueDate() {
      return !!this.rfq.dueDate;
    },

    validationRulesMin() {
      return {
        minValue: 0.01
      };
    },

    validationRulesMax() {
      return {
        minValue: '@budgetMin'
      };
    }
  },

  methods: {
    isPast(dateStr) {
      return Date.parse(dateStr) < new Date();
    },

    formattedDate(date) {
      return DateFilter.filter(date);
    },

    setDueDate(date) {
      this.rfq.dueDate = date;
      this.renderDatePicker = false;
    },

    next() {
      this.nextStep.moveToNextStep();
    },

    previous() {
      this.prevStep.moveToPrevStep();
    },

    buildAttachmentsFor(value) {
      // NOTE: value is either an array of attachments (with ids) to be destroyed
      // or an array of file objects to be added as attachments
      if (!value.length || value[0].id) { return value; }
      return map(value, (file) => { return { file: file }; });
    },

    addAttachments(attachments) {
      LoadingService.loading('addAttachments');

      this.onUpdateSupportingDocuments(this.buildAttachmentsFor(Array.from(attachments)), this.rfq.id)
        .then((response) => {
          this.rfq.supportingDocuments = response;
        })
        .finally(() => {
          LoadingService.done('addAttachments');
        });
    },

    removeAttachment(attachment) {
      attachment['_destroy'] = true;

      this.onUpdateSupportingDocuments(this.buildAttachmentsFor([attachment]), this.rfq.id);
      this.rfq.supportingDocuments = without(this.rfq.supportingDocuments, attachment);
    },

    toggleRenderDatePicker() {
      this.renderDatePicker = !this.renderDatePicker;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .date-picker-container {
    max-width: 300px;
  }

  .styled-date-picker {
    position: absolute;
    z-index: 1000;
  }

  .button-column {
    @media (min-width: $screen-sm-min) {
      width: 21%;
    }

    @media (min-width: $screen-lg-min) {
      width: 18%;
    }
  }

  .files-column {
    @media (min-width: $screen-sm-min) {
      width: 79%;
    }

    @media (min-width: $screen-lg-min) {
      width: 82%;
    }
  }

  .file-list {
    margin-bottom: -10px;

    .file-item {
      display: inline-block;
      margin-bottom: 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .width-max-content {
    width: max-content;
  }

  .a-form .form-control[readonly] {
    background: $white;
  }
</style>

import { includes } from 'lodash';

class ConfigurableFilter {
  constructor(config, defaults = {}) {
    this.config = config;

    this.sortValue = includes(config.sortOptions, defaults.sortValue) ? defaults.sortValue : config.defaultSort;
    this.filterValue = includes(config.filterOptions, defaults.filterValue) ? defaults.filterValue : config.defaultFilter;
    this.searchValue = defaults.searchValue || '';
  }

  filterBy(value) {
    this.filterValue = value;
  }

  sortBy(value) {
    this.sortValue = value;
  }

  searchBy(value) {
    this.searchValue = value;
  }

  filter(collection) {
    const operations = [this.config.filterFunction, this.config.searchFunction, this.config.sortFunction];
    this.normalizedData = this.config.normalizedSearchableData(collection);

    return operations.reduce((collectionResults, func) => {
      return func(collectionResults, this);
    }, collection);
  }
}

export default ConfigurableFilter;

<template>
  <div class="impersonate-time text-left">
    <span class="text-italic">
      {{ min }}:{{ sec }} {{ timeLabel }} left
    </span>

    <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" :backdrop="false" append-to-body>
      <modal-close-button :on-dismiss="accept"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-regular-text">
          <div class="yellow-message-box bottom-30">
            <svg-icon name="exclamation" class="base-icon warning-icon right-10"></svg-icon>
            <span class="message">Warning: You are approaching the impersonation time limit.</span>
          </div>

          <div class="size-text-16px bottom-5">
            You will be logged out of impersonation mode in:
          </div>

          <div class="size-text-16px semibold-weight bottom-30">
            {{ min }}:{{ sec }} {{ timeLabel }}
          </div>

          <div class="row">
            <div class="col-sm-4">
              <button type="button" class="nv-button-blue a-button-size" @click.prevent="accept">OK</button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';

export default {
  name: 'ImpersonationTime',

  components: {
    SvgIcon,
    ModalCloseButton
  },

  mixins: [
    modalToggle
  ],

  props: {
    impersonationEndsAt: {
      type: String,
      default: null
    },

    stopImpersonatePath: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      now: new Date(),
      timer: null
    };
  },

  computed: {
    endDate() {
      if (this.impersonationEndsAt) {
        return new Date(this.impersonationEndsAt);
      }
      else {
        return new Date();
      }
    },

    min() {
      return this.minutes > 9 ? this.minutes : `0${this.minutes}`;
    },

    sec() {
      return this.seconds > 9 ? this.seconds : `0${this.seconds}`;
    },

    minutes() {
      return Math.trunc((this.endDate - this.now) / 1000 / 60) % 60;
    },

    seconds() {
      return Math.trunc((this.endDate - this.now) / 1000) % 60;
    },

    timeLabel() {
      return this.minutes > 0 ? 'minutes' : 'seconds';
    }
  },

  watch: {
    endDate: {
      immediate: true,

      handler(value) {
        if (this.timer) {
          clearInterval(this.timer);
        }

        this.timer = setInterval(() => { this.checkTime(value); }, 1000);
      }
    }
  },

  mounted() {
    this.checkTime(this.endDate);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    checkTime(endDate) {
      this.now = new Date();

      if (this.now > endDate) {
        this.now = endDate;

        if (this.timer) {
          clearInterval(this.timer);
        }

        window.location.href = this.stopImpersonatePath;
      }
      else if (this.minutes === 5 && this.seconds === 0) {
        this.openModal();
      }
    },

    accept() {
      this.closeModal();
    }
  }
};
</script>

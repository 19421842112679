<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a href="/client-app/payment-methods" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>All Payment Methods</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="medium-page-heading bottom-30">
            Verify Account
          </div>

          <div class="row bottom-30">
            <div class="col-md-8">
              <div class="bottom-30">
                <bank-verification-info :is-verifying="true"></bank-verification-info>
              </div>

              <div class="shadowed-box">
                <div class="box-content">
                  <validation-observer ref="form" v-slot="{ handleSubmit }">
                    <form role="form" name="form" class="a-form" novalidate>
                      <div id="error-message" :class="['pr-error size-text-13px semibold-weight', { 'bottom-20': errorMessage.length }]">
                        {{ errorMessage }}
                      </div>

                      <div class="row bottom-10">
                        <div class="col-sm-6 bottom-20">
                          <validation-provider rules="required|onlyInteger|betweenOneAndNinetyNine" v-slot="{ errors }">
                            <label for="amount1">Amount 1</label> <span class="inline-help required">*</span>
                            <input type="text" name="amount1" id="amount1" class="form-control" autocomplete="nope" v-model="amounts.amount1">

                            <div class="error-text top-5" v-if="errors.length">
                              <error-message :errors="errors"></error-message>
                            </div>
                          </validation-provider>
                        </div>

                        <div class="col-sm-6 bottom-20">
                          <validation-provider rules="required|onlyInteger|betweenOneAndNinetyNine" v-slot="{ errors }">
                            <label for="amount2">Amount 2</label> <span class="inline-help required">*</span>
                            <input type="text" name="amount2" id="amount2" class="form-control" autocomplete="nope" v-model="amounts.amount2">

                            <div class="error-text top-5" v-if="errors.length">
                              <error-message :errors="errors"></error-message>
                            </div>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-4">
                          <loading-button name="verify" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(verify)">Verify</loading-button>
                        </div>
                      </div>
                    </form>
                  </validation-observer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BankVerificationInfo from 'vue-app/marketplace/client/settings/bank-accounts/bank-verification-info.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import StripeBankAccount from 'resources/marketplace/stripe-bank-account.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'BankAccountsVerify',

  components: {
    BankVerificationInfo,
    SvgIcon,
    ErrorMessage,
    LoadingButton,
    ValidationObserver,
    ValidationProvider
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      paymentMethodId: this.initData.params.id,
      amounts: {},
      errorMessage: ''
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' })
  },

  methods: {
    verify() {
      LoadingService.around('verify', () => {
        return StripeBankAccount.verify({ id: this.paymentMethodId, ...this.amounts }).then(
          (res) => {
            const account = this.user.stripeBankAccounts.find(a => a.id === res.id);
            account.verified = true;

            const paymentMethod = this.user.paymentMethods.find(pm => pm.id === res.id);
            paymentMethod.verified = true;

            window.location = '/client-app/payment-methods';
          },
          (res) => {
            this.errorMessage = res.response.data.errors.verify.join(', ');
          }
        );
      });
    }
  }
};
</script>

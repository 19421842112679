<template>
  <div>
    <div class="row" v-if="!invoice.isLegacyInvoice()">
      <div class="col-md-6 col-md-offset-6">
        <div v-if="invoice.totalHours">
          <div class="row tight-columns semibold-weight bottom-15">
            <div class="col-xs-7">
              Total Hours
            </div>

            <div class="col-xs-5 text-right">
              {{ invoice.totalHours }}
            </div>
          </div>

          <div class="bottom-15">
            <hr class="kenny-hr no-margin">
          </div>
        </div>

        <div class="row tight-columns semibold-weight bottom-5">
          <div class="col-xs-7">
            Subtotal
          </div>

          <div class="col-xs-5 text-right">
            {{ subTotal }}
          </div>
        </div>

        <div class="row tight-columns semibold-weight bottom-15" v-if="invoice.applyDiscount">
          <div class="col-xs-7">
            Discount Applied
          </div>

          <div class="col-xs-5 text-right">
            {{ invoice.discountPercent }}%
          </div>
        </div>

        <div class="row tight-columns semibold-weight bottom-5" v-if="invoice.applyDiscount">
          <div class="col-xs-7">
            Discounted Subtotal
            <span class="tooltip-question left-5" v-tooltip.top="{ text: 'The discount has been applied to the Legal Fees and Priori Management Fee. Disbursements cannot be discounted.', customClass: 'kenny-styles k-tooltip tooltip-white small-text' }">?</span>
          </div>

          <div class="col-xs-5 text-right">
            {{ subTotalDiscounted }}
          </div>
        </div>

        <div class="row tight-columns dark-gray-text size-text-12px">
          <div class="col-xs-7 col-sm-8">
            Legal Fees
          </div>

          <div class="col-xs-5 col-sm-4 text-right">
            {{ legalFees }}
          </div>
        </div>

        <div class="row tight-columns dark-gray-text size-text-12px">
          <div class="col-xs-7 col-sm-8">
            Priori Management Fee
          </div>

          <div class="col-xs-5 col-sm-4 text-right">
            {{ prioriFee }}
          </div>
        </div>

        <div class="row tight-columns dark-gray-text size-text-12px">
          <div class="col-xs-7 col-sm-8">
            Disbursements
          </div>

          <div class="col-xs-5 col-sm-4 text-right">
            {{ disbursements }}
          </div>
        </div>

        <div class="row tight-columns semibold-weight top-5" v-if="!invoice.applyDiscount && invoiceTotals.preRetainer.discount">
          <div class="col-xs-7">
            Discount
          </div>

          <div class="col-xs-5 text-right">
            ({{ discount }})
          </div>
        </div>

        <div class="row tight-columns semibold-weight top-15" v-if="invoiceTotals.retainerApplied">
          <div class="col-xs-7">
            Retainer Applied
          </div>

          <div class="col-xs-5 text-right">
            ({{ retainerApplied }})
          </div>
        </div>

        <div v-if="invoiceTotals.retainerTopUp || invoice.creditLineItem || invoice.transactionFeeLineItem">
          <div class="top-15 bottom-15">
            <hr class="kenny-hr no-margin">
          </div>

          <div class="row tight-columns semibold-weight">
            <div class="col-xs-7">
              Net Total
            </div>

            <div class="col-xs-5 text-right">
              {{ netTotal }}
            </div>
          </div>

          <div class="row tight-columns semibold-weight top-5" v-if="invoiceTotals.retainerTopUp">
            <div class="col-xs-8">
              Retainer Top-Up Payment Required
            </div>

            <div class="col-xs-4 text-right">
              {{ retainerTopUp }}
            </div>
          </div>

          <div class="row tight-columns semibold-weight top-5" v-if="invoice.creditLineItem">
            <div class="col-xs-7">
              Credit Applied
            </div>

            <div class="col-xs-5 text-right">
              ({{ creditTotal }})
            </div>
          </div>

          <div class="row tight-columns semibold-weight top-5" v-if="invoice.transactionFeeLineItem">
            <div class="col-xs-7">
              Processing Fee
            </div>

            <div class="col-xs-5 text-right">
              {{ transactionFees }}
            </div>
          </div>
        </div>

        <div class="top-15 bottom-30">
          <hr class="kenny-hr no-margin">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-md-offset-6">
        <div class="row tight-columns size-text-base semibold-weight">
          <div class="col-xs-7">
            Total Due<span v-if="autoCharging"> *</span>
          </div>

          <div class="col-xs-5 text-right">
            {{ totalDollars }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';
import { each } from 'lodash';

export default {
  name: 'InvoiceTotals',

  props: {
    invoice: {
      type: Object,
      required: true
    },

    autoCharging: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    invoiceTotals() {
      let totals = {
        preRetainer: {
          legalFees: 0,
          legalFeesDiscounted: 0,
          legalFeesDiscount: 0,
          prioriFees: 0,
          prioriFeesDiscounted: 0,
          disbursements: 0,
          total: 0,
          totalDiscounted: 0,
          discount: 0
        },
        retainerApplied: 0,
        workPerformedTotal: 0,
        retainerTopUp: 0
      };

      each(this.invoice.invoiceLineItems, (li) => {
        if (li.renderedService.typePrepaidRealization()) {
          totals.retainerApplied += (li.dollars * -1);
        }
        else if (li.renderedService.typeRetainerTopUp()) {
          totals.retainerTopUp += li.dollars;
        }
        else if (li.renderedService.typePartnerDiscount()) {
          totals.preRetainer.discount += (li.dollars * -1);
          totals.preRetainer.legalFeesDiscount += (li.renderedService.dollars * -1);
        }
        else if (li.renderedService.typeDisbursement()) {
          totals.preRetainer.total += li.dollars;
          totals.preRetainer.disbursements += li.dollars;
        }
        else if (!li.renderedService.free) {
          totals.preRetainer.total += li.dollars;
          totals.preRetainer.legalFees += li.renderedService.dollars;
        }
      });

      totals.preRetainer.prioriFees = totals.preRetainer.total - totals.preRetainer.legalFees - totals.preRetainer.disbursements;
      totals.preRetainer.totalDiscounted = totals.preRetainer.total - totals.preRetainer.discount;
      totals.preRetainer.legalFeesDiscounted = totals.preRetainer.legalFees - totals.preRetainer.legalFeesDiscount;
      totals.preRetainer.prioriFeesDiscounted = totals.preRetainer.totalDiscounted - totals.preRetainer.legalFeesDiscounted - totals.preRetainer.disbursements;
      totals.workPerformedTotal = totals.preRetainer.total - totals.preRetainer.discount - totals.retainerApplied;

      return totals;
    },

    subTotal() {
      return CurrencyFilter.filter(this.invoiceTotals.preRetainer.total);
    },

    subTotalDiscounted() {
      return CurrencyFilter.filter(this.invoiceTotals.preRetainer.totalDiscounted);
    },

    legalFees() {
      return CurrencyFilter.filter(this.invoice.applyDiscount ? this.invoiceTotals.preRetainer.legalFeesDiscounted : this.invoiceTotals.preRetainer.legalFees);
    },

    prioriFee() {
      return CurrencyFilter.filter(this.invoice.applyDiscount ? this.invoiceTotals.preRetainer.prioriFeesDiscounted : this.invoiceTotals.preRetainer.prioriFees);
    },

    disbursements() {
      return CurrencyFilter.filter(this.invoiceTotals.preRetainer.disbursements);
    },

    discount() {
      return CurrencyFilter.filter(this.invoiceTotals.preRetainer.discount);
    },

    retainerApplied() {
      return CurrencyFilter.filter(this.invoiceTotals.retainerApplied);
    },

    netTotal() {
      return CurrencyFilter.filter(this.invoiceTotals.workPerformedTotal);
    },

    retainerTopUp() {
      return CurrencyFilter.filter(this.invoiceTotals.retainerTopUp);
    },

    creditTotal() {
      return CurrencyFilter.filter(this.invoice.creditTotal() * -1);
    },

    transactionFees() {
      return CurrencyFilter.filter(this.invoice.transactionFees());
    },

    totalDollars() {
      return CurrencyFilter.filter(this.invoice.totalDollars);
    }
  }
};
</script>

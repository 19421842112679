<template>
  <div>
    <div v-if="showScheduleCall" :class="['svg-button action-bar-button', { 'disabled': requestFulfilled || !ableToSchedule }]" @click="startScheduling">
      <svg-icon name="calendar" class="base-icon bottom-10"></svg-icon>
      <div>Schedule<br>Call</div>
    </div>

    <div v-if="showRescheduleCall" :class="['svg-button action-bar-button', { 'disabled': !isCurrentClientInteracting }]" @click="startUpdating">
      <svg-icon name="calendar" class="base-icon bottom-10"></svg-icon>
      <div>Reschedule<br>Call</div>
    </div>

    <div v-if="showUpdateCallTimes" :class="['svg-button action-bar-button', { 'disabled': !isCurrentClientInteracting }]" @click="startUpdating">
      <svg-icon name="calendar" class="base-icon bottom-10"></svg-icon>
      <div>Update<br>Call Times</div>
    </div>

    <div v-if="showConfirmCallTime" :class="['svg-button action-bar-button', { 'disabled': !isCurrentClientInteracting }]" @click="startConfirming">
      <svg-icon name="calendar" class="base-icon bottom-10"></svg-icon>
      <div>Confirm<br>Call Time</div>
    </div>

    <div v-if="showCallCancelled" class="svg-button action-bar-button disabled">
      <svg-icon name="calendar" class="base-icon bottom-10"></svg-icon>
      <div>Call<br>Cancelled</div>
    </div>

    <div v-if="showCallScheduled" class="svg-button action-bar-button disabled">
      <svg-icon name="calendar" class="base-icon bottom-10"></svg-icon>
      <div>Call<br>Scheduled</div>
    </div>

    <div v-if="showCallComplete" class="svg-button action-bar-button disabled green">
      <svg-icon name="checkmark" class="base-icon bottom-10"></svg-icon>
      <div>Call<br>Complete</div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'SchedulingButtons',

  components: {
    SvgIcon
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    onScheduleAppointment: {
      type: Function,
      required: true
    },

    onUpdateAppointment: {
      type: Function,
      required: true
    },

    onConfirmAppointment: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    appointment() {
      return this.possibleLawyer.appointment;
    },

    isCurrentClientInteracting() {
      return this.possibleLawyer.isCurrentClientInteracting(this.user);
    },

    requestFulfilled() {
      return this.possibleLawyer.crCompleted();
    },

    ableToSchedule() {
      return this.possibleLawyer.ableToSchedule();
    },

    isScheduling() {
      return this.appointment && !this.requestFulfilled;
    },

    showScheduleCall() {
      return !this.isScheduling;
    },

    showRescheduleCall() {
      return this.isScheduling && this.appointment.needsRescheduling();
    },

    showUpdateCallTimes() {
      return this.isScheduling && this.appointment.needsAvailability();
    },

    showConfirmCallTime() {
      return this.isScheduling && this.appointment.needsConfirmation();
    },

    showCallCancelled() {
      return this.isScheduling && this.appointment.isCancelled();
    },

    showCallScheduled() {
      return this.isScheduling && this.appointment.isScheduled();
    },

    showCallComplete() {
      return this.isScheduling && this.appointment.isCompleted();
    },

    canScheduleAppointment() {
      return !this.isScheduling && this.ableToSchedule && !this.requestFulfilled;
    },

    canUpdateAppointment() {
      return (this.showRescheduleCall || this.showUpdateCallTimes || this.showConfirmCallTime) && this.isCurrentClientInteracting;
    },

    canConfirmAppointment() {
      return this.showConfirmCallTime && this.isCurrentClientInteracting;
    }
  },

  methods: {
    startScheduling() {
      if (!this.canScheduleAppointment) { return; }
      this.onScheduleAppointment();
    },

    startUpdating() {
      if (!this.canUpdateAppointment) { return; }
      this.onUpdateAppointment();
    },

    startConfirming() {
      if (!this.canConfirmAppointment) { return; }
      this.onConfirmAppointment();
    }
  }
};
</script>

<template>
  <div>
    <button
      type="button"
      :disabled="interviewRequested"
      @click.prevent="openSelectFirmModal"
      :class="['icon-button nv-button-white flex-column', { 'selected': interviewRequested }]">
      <svg-icon name="checkmark" class="base-icon"></svg-icon>
      <span class="top-5">{{ interviewRequested ? 'Requested' : 'Interview' }}</span>
    </button>

    <modal-wrapper name="request-interview">
      <template #default="{ closeModal: closeModal }">
        <div class="notice-modal">
          <div class="notice-header text-uppercase">
            Request Interview: {{ providerName }}
          </div>

          <div class="notice-regular-text">
            <div class="semibold-weight size-text-14px bottom-20">
              To interview this Priori Marketplace Network provider, please confirm your selection below. Following confirmation, a Priori representative will reach out with next steps.
            </div>

            <div class="semibold-weight size-text-14px bottom-30">
              Are you sure you&rsquo;d like to proceed?
            </div>

            <div class="row tight-columns">
              <div class="col-sm-6">
                <button type="button" class="primary-btn-night-blue" @click="requestInterview">
                  Yes, Request Interview
                </button>
              </div>

              <div class="col-sm-4 top-20-xs">
                <button type="button" class="secondary-btn-blue" @click="closeModal">No, Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal-wrapper>
  </div>
</template>

<script>
import ModalManager from 'vue-app/shared/services/modal-manager';
import ModalWrapper from 'vue-app/shared/components/modals/wrapper.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'InterviewButton',

  components: {
    ModalWrapper,
    SvgIcon
  },

  props: {
    selectedQuote: {
      type: Object,
      required: true
    },

    interviewRequested: {
      type: Boolean,
      default: false
    },

    rfq: {
      type: Object,
      required: true
    }
  },

  computed: {
    providerName() {
      return this.selectedQuote.fromMarketplace ? this.marketplaceProviderName : this.selectedQuote.lawFirm.name;
    },

    marketplaceProviderName() {
      return this.selectedQuote.marketplaceLawyer.addedAsFirm ? this.selectedQuote.lawFirm.name : this.selectedQuote.marketplaceLawyer.fullName;
    }
  },

  methods: {
    openSelectFirmModal() {
      ModalManager.open('request-interview');
    },

    requestInterview() {
      this.$emit('client-requested-interview', this.rfq, this.selectedQuote, 'requested_interview');

      ModalManager.close('request-interview');
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 105px;
    height: 100%;

    @media (min-width: $screen-sm-min) {
      width: 90px;
    }

    @media (min-width: 1055px) {
      width: 100px;
    }

    @media (min-width: $screen-lg-min) {
      width: 110px;
    }

    &:disabled, &:disabled .base-icon {
      opacity: 1;
    }
  }

  .selected {
    color: $scout-green;
    fill: $scout-green;
    background-color: $green-10;
    border-color: $scout-green;

    svg {
      fill: $scout-green;
    }
  }
</style>

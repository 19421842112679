<template>
  <div class="sidenav-content">
    <div class="container-fluid">
      <div class="padded-container">
        <div class="lawyer-profile">
          <div class="shadowed-box bottom-30">
            <div class="box-content">
              <lawyer-profile-header
                :lawyer="lawyer"
                :on-ask-question="askQuestion">
              </lawyer-profile-header>
            </div>
          </div>

          <div class="bottom-30">
            <loading-section name="lawyerProfile" :render-after-loading="true">
              <lawyer-profile
                :lawyer-profile="profile"
                :lawyer="lawyer"
                :viewing-as-client="true">
              </lawyer-profile>
            </loading-section>
          </div>

          <div class="shadowed-box">
            <div class="box-content">
              <div class="row tight-columns vertical-center-not-xs">
                <div class="col-sm-2">
                  <svg-icon name="ask-lawyer" class="base-icon largest"></svg-icon>
                </div>

                <div class="col-sm-6 col-md-7 size-text-base semibold-weight top-10-xs">
                  Still have questions? Ask the attorney directly.
                </div>

                <div class="col-sm-4 col-md-3 top-20-xs">
                  <button type="button" class="nv-button-white a-button-size" @click="askQuestion">Send Message</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <send-message-modal ref="messageModal" :recipients="messageRecipients"></send-message-modal>
  </div>
</template>

<script>
import LawyerProfile from 'vue-app/marketplace/shared/lawyers/lawyer-profile.vue';
import LawyerProfileHeader from 'vue-app/marketplace/client/lawyers/profile-header.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import SendMessageModal from 'vue-app/shared/components/send-message-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';

import LoadingService from 'vue-app/shared/services/loading-service.js';
import LawyerProfileResource from 'resources/lawyer-profile.js';

export default {
  name: 'LawyersShow',

  components: {
    SvgIcon,
    LawyerProfile,
    LawyerProfileHeader,
    SendMessageModal,
    LoadingSection
  },

  props: {
    initData: {
      type:     Object,
      required: true
    }
  },

  data() {
    return {
      lawyer: this.initData.lawyer,
      profile: {},
      messageRecipients: []
    };
  },

  beforeMount() {
    this.loadProfile();
    this.loadReviews();
  },

  methods: {
    askQuestion() {
      this.messageRecipients = [this.lawyer];
      this.$refs.messageModal.openModal();
    },

    loadProfile() {
      LoadingService.around('lawyerProfile', () => {
        return LawyerProfileResource.get({ id: this.lawyer.lawyerProfileId }).then((profile) => {
          profile.concatRepresentativeClientsAndMatters();
          this.profile = profile;
        });
      });
    },

    loadReviews() {
      this.lawyer.loadReviews().then((lawyerWithReviews) => {
        this.lawyer = lawyerWithReviews;
      });
    }
  }
};
</script>

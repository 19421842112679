<template>
  <div>
    <div class="titlebar">
      <div class="medium-page-heading">
        My Team
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="bottom-30" v-show="searchableTeamMembers.length">
            <typeahead-filter
              :options="searchableTeamMembers"
              option-label-key="fullName"
              option-value-key="id"
              placeholder-text="Search by name"
              :results-count="resultsCount"
              :on-apply-filter="applyFilter">
            </typeahead-filter>
          </div>

          <div class="resource-list">
            <div class="list-header">
              <div class="row vertical-center-not-xs">
                <div class="col-sm-1">
                </div>

                <div class="col-sm-5">
                  Name
                </div>

                <div :class="{ 'col-sm-3': !workspaceHasRfqEnabled, 'col-sm-2': workspaceHasRfqEnabled }">
                  Title
                </div>

                <div :class="{ 'col-sm-3': !workspaceHasRfqEnabled, 'col-sm-2': workspaceHasRfqEnabled }">
                  User Type
                </div>

                <div v-if="workspaceHasRfqEnabled" class="col-sm-2">
                  RFP Permission
                  <span class="info-icon"><svg-icon id="rfq-permission-tooltip" name="info" class="base-icon smaller text-top"></svg-icon></span>
                  <tooltip
                    target="#rfq-permission-tooltip"
                    placement="bottom"
                    custom-class="tooltip-white small-text"
                    text="“RFP Access” users will be able to create, send, and view their own RFPs. “RFP Manager” users will be able to create and send their own RFPs, and also will be able to view all RFPs submitted by their colleagues.">
                  </tooltip>
                </div>
              </div>
            </div>

            <loading-section name="teamMembers">
              <div class="list-item responsive-xs no-hover" v-for="client in teamMembers" :key="client.id">
                <div class="row vertical-center-not-xs">
                  <div class="col-sm-1">
                    <div class="initials-circle large">
                      {{ initials(client) }}
                    </div>
                  </div>

                  <div class="col-sm-5 top-10-xs text-ellipsis size-text-17px">
                    <span class="target">{{ client.fullName }}</span>
                  </div>

                  <div :class="{ 'col-sm-3': !workspaceHasRfqEnabled, 'col-sm-2': workspaceHasRfqEnabled }">
                    {{ client.role || '&mdash;' }}
                  </div>

                  <div :class="{ 'col-sm-3': !workspaceHasRfqEnabled, 'col-sm-2': workspaceHasRfqEnabled }">
                    {{ client.userType || '&mdash;' }}
                  </div>

                  <div v-if="workspaceHasRfqEnabled" class="col-sm-2">
                    {{ rfpPermissionLabel(client) }}
                  </div>
                </div>
              </div>

              <div class="list-item empty gray-text size-text-16px" v-if="!teamMembers.length">
                Empty
              </div>

              <div class="top-20" v-if="teamMembers.length">
                <priori-pagination
                  class="scout-pagination"
                  :current-page="page"
                  :total-entries="resultsCount"
                  :per-page="10"
                  @change="changePage">
                </priori-pagination>
              </div>
            </loading-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import ScoutClient from 'resources/scout/client/scout-client.js';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import TypeaheadFilter from 'vue-app/scout/shared/typeahead-filter.vue';

import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'ScoutClientTeamsIndex',

  components: {
    LoadingSection,
    PrioriPagination,
    SvgIcon,
    TypeaheadFilter
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      page: 1,
      searchableTeamMembers: this.initData.searchableTeamMembers,
      teamData: this.initData.teamData
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    resultsCount() {
      return this.teamData.metadata.total;
    },

    teamMembers() {
      return this.teamData.resources;
    },

    workspaceHasRfqEnabled() {
      return this.currentClient.workspaceHasRfqEnabled;
    }
  },

  methods: {
    applyFilter(selectedClientId) {
      this.page = 1;
      return this.loadTeamMembers(selectedClientId);
    },

    changePage(page) {
      if (this.page !== page && page >= 1) {
        this.page = page;
        this.loadTeamMembers();
      }
    },

    initials(client) {
      if (client.firstName && client.lastName) {
        return (client.firstName[0] + client.lastName[0]).toUpperCase();
      }

      return '@';
    },

    loadTeamMembers(selectedClientId) {
      const params = { view: 'index', page: this.page, active: true };

      LoadingService.loading('teamMembers');

      if (selectedClientId) {
        params.clientIds = [selectedClientId];
      }

      return ScoutClient.queryData(params).then(teamData => {
        this.teamData = teamData;

        LoadingService.done('teamMembers');
      });
    },

    rfpPermissionLabel(client) {
      if (client.hasRfqWorkspaceEnabled) {
        return 'RFP Manager';
      }
      else if (client.hasRfqEnabled) {
        return 'RFP Access';
      }
      else {
        return 'No RFP Access';
      }
    }
  }
};
</script>

<template>
  <section class="uiv">
    <a href="" @click.prevent="openModal()">View Details</a>

    <modal v-model="modalOpen" size="md-sm" :transition="0" :header="false" :footer="false" append-to-body>
      <div class="close-modal" @click="dismissModal()">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <div class="notice-modal">
        <div class="notice-header">
          Total Legal Spend
        </div>

        <div class="notice-regular-text">
          <div class="row bottom-10">
            <div class="col-xs-6 semibold-weight">
              Legal Fees:
            </div>

            <div class="col-xs-6 text-right">
              {{ formattedCurrency(legalFees / 100.0) }}
            </div>
          </div>

          <div class="row bottom-10">
            <div class="col-xs-6 semibold-weight">
              Disbursements:
            </div>

            <div class="col-xs-6 text-right">
              {{ formattedCurrency(totals.paid.disbursement / 100.0) }}
            </div>
          </div>

          <div class="row bottom-10">
            <div class="col-xs-6 semibold-weight">
              Priori Management Fees:
            </div>

            <div class="col-xs-6 text-right">
              {{ formattedCurrency(totals.paid.management / 100.0) }}
            </div>
          </div>

          <div class="row bottom-10">
            <div class="col-xs-6 semibold-weight">
              Transaction Fees:
            </div>

            <div class="col-xs-6 text-right">
              {{ formattedCurrency(totals.paid.transactionFees / 100.0) }}
            </div>
          </div>

          <div class="row bottom-10" v-if="totals.paid.adjustment !== 0">
            <div class="col-xs-6 semibold-weight">
              Adjustment:
            </div>

            <div class="col-xs-6 text-right">
              {{ formattedCurrency(totals.paid.adjustment / 100.0) }}
            </div>
          </div>

          <div class="row bottom-10" v-if="totals.paid.credits !== 0">
            <div class="col-xs-6 semibold-weight">
              Credit Applied:
            </div>

            <div class="col-xs-6 text-right">
              {{ formattedCurrency(totals.paid.credits / 100.0) }}
            </div>
          </div>

          <hr class="kenny-hr">

          <div class="row">
            <div class="col-xs-6 semibold-weight">
              Total Paid:
            </div>

            <div class="col-xs-6 text-right">
              {{ formattedCurrency(totals.paid.total / 100.0) }}
            </div>
          </div>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import currencyFilter from 'vue-app/shared/mixins/currency-filter.js';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';

export default {
  name: 'TotalSpendModal',

  components: {
    SvgIcon
  },

  mixins: [
    currencyFilter,
    modalToggle
  ],

  props: {
    totals: {
      type: Object,
      required: true
    }
  },

  computed: {
    paid() {
      return this.totals.paid;
    },

    legalFees() {
      return this.paid.flatRate + this.paid.billedHour + this.paid.retainer + this.paid.prepaidRealization;
    }
  }
};
</script>

<template>
  <div class="shadowed-box size-text-13px">
    <div class="box-content primary-marketplace-gradient shadowed-box">
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="row tight-columns">
            <div class="col-md-4 col-lg-3 text-uppercase bold-weight top-10 bottom-10">
              {{ filterLabel }}
            </div>

            <div class="col-md-8 col-lg-9 a-form">
              <dropdown ref="dropdown" tag="div" id="k-dropdown-index-filter" class="k-dropdown" keyboard-nav="true">
                <button type="button" id="k-toggle-button-filter" class="dropdown-toggle">
                  <span class="semibold-weight">{{ sortFilter.filterValue }}</span>
                  <svg-icon name="caret-down" class="caret-down"></svg-icon>
                  <svg-icon name="caret-up" class="caret-up"></svg-icon>
                </button>

                <template slot="dropdown" class="dropdown-menu" role="menu" aria-labelledby="k-toggle-button-filter">
                  <li role="menuitem" v-for="(option, index) in filterOptions" :key="index" @click="applyFilter(option)">
                    <div class="dropdown-row-inner semibold-weight">
                      {{ option }}
                    </div>
                  </li>
                </template>
              </dropdown>
            </div>
          </div>

          <button type="button" class="nv-button a-button-size show-filter-btn top-20" @click="toggleFilters()" v-show="!showFilters">Show All Filters</button>
        </div>

        <div :class="['col-sm-6 col-md-4 filter-container', { 'ng-hide': !showFilters }]">
          <div class="row tight-columns">
            <div class="col-md-3 text-uppercase bold-weight top-10 bottom-10">
              Sort
            </div>

            <div class="col-md-9 a-form">
              <dropdown ref="dropdown" tag="div" id="k-dropdown-index-sort" class="k-dropdown" keyboard-nav="true">
                <button type="button" id="k-toggle-button-sort" class="dropdown-toggle">
                  <span class="semibold-weight">{{ sortFilter.sortValue }}</span>
                  <svg-icon name="caret-down" class="caret-down"></svg-icon>
                  <svg-icon name="caret-up" class="caret-up"></svg-icon>
                </button>

                <template slot="dropdown" class="dropdown-menu" role="menu" aria-labelledby="k-toggle-button-sort">
                  <li role="menuitem" v-for="(option, index) in sortOptions" :key="index" @click="applySort(option)">
                    <div class="dropdown-row-inner semibold-weight">
                      {{ option }}
                    </div>
                  </li>
                </template>
              </dropdown>
            </div>
          </div>
        </div>

        <div :class="['col-sm-6 col-md-4 filter-container a-form', { 'ng-hide': !showFilters }]">
          <div class="visible-xs visible-sm text-uppercase bold-weight top-10 bottom-10">
            Search
          </div>

          <input type="text" class="search-input" placeholder="Search..." @input="applySearch">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'src/vue-app/shared/components/svg-icon.vue';

export default {
  name: 'HorizontalSortFilter',
  components: {
    SvgIcon
  },

  props: {
    sortFilter: {
      type: Object,
      required: true
    },

    filterLabel: {
      type: String,
      default: 'Filter'
    },

    filterConfig: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showFilters: false
    };
  },

  computed: {
    filterOptions() {
      return this.filterConfig.filterOptions;
    },

    sortOptions() {
      return this.filterConfig.sortOptions;
    }
  },

  methods: {
    applyFilter(value) {
      this.$emit('filter', value);
    },

    applySort(value) {
      this.$emit('sort', value);
    },

    applySearch(e) {
      this.$emit('search', e.target.value.trim());
    },

    toggleFilters() {
      this.showFilters = !this.showFilters;
    }
  }
};
</script>

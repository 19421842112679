<template>
  <div id="workspace-conflicts" class="shadowed-box">
    <validation-observer v-slot="{ handleSubmit }">
      <div class="box-header">
        <div class="row">
          <div class="col-sm-8 text-uppercase">
            Conflicts
          </div>

          <div class="col-sm-4 size-text-13px text-right-not-xs">
            <a href="" class="normal-weight" @click.prevent="startEdit" v-if="!isEditing">Edit</a>

            <span v-else>
              <a href="" class="normal-weight right-15" @click.prevent="handleSubmit(save)" :disabled="isSaving">Save</a>
              <a href="" class="dark-gray-text normal-weight" @click.prevent="cancelEdit">Cancel</a>
            </span>
          </div>
        </div>
      </div>

      <div class="box-notification-error" v-if="backendErrors.length">
        {{ backendErrors }}
      </div>

      <div class="box-content">
        <form role="form" name="form" class="a-form" novalidate>
          <div class="row">
            <div class="col-sm-4 semibold-weight">
              Prohibited Parties
            </div>

            <div class="col-sm-8">
              <div v-if="!isEditing">
                <div class="bottom-10" v-if="workspace.prohibitedParties">
                  <a :href="prohibitedPartiesUrl" target="_blank">{{ workspace.prohibitedParties.name }}</a>
                </div>

                <span class="simple-text">{{ workspace.prohibitedPartiesNotes || '&mdash;' }}</span>
              </div>

              <div class="top-10-xs" v-if="isEditing">
                <loading-section name="prohibitedPartiesEditing">
                  <validation-provider :rules="validationRules" v-slot="{ errors: fieldErrors }" :mode="passiveAggressive">
                    <file-input v-model="workspaceCopy.prohibitedParties" @input="replaceProhibitedPartiesDocument" @delete="deleteProhibitedParties" accept=".pdf,.doc,.docx" button-class="empty-button smaller" button-text="Attach Document">
                      {{ fieldErrors[0] }}
                    </file-input>
                  </validation-provider>
                </loading-section>

                <textarea name="prohibitedPartiesInput" id="prohibited-parties-input" class="form-control top-10" rows="3" v-model="workspaceCopy.prohibitedPartiesNotes"></textarea>
              </div>
            </div>
          </div>

          <div class="row top-30" v-if="isEditing">
            <div class="col-sm-4 col-md-3">
              <loading-button name="updateWorkspaceButton" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(save)">Save</loading-button>
            </div>

            <div class="col-sm-4 col-md-3 top-10-xs">
              <button type="button" class="secondary-btn-blue a-button-size" @click.prevent="cancelEdit">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import FileInput from 'vue-app/marketplace/client/shared/file-input.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';

import LoadingService from 'vue-app/shared/services/loading-service.js';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import InternalDocument from 'resources/internal-document';
import { get } from 'lodash';

export default {
  name: 'WorkspaceConflicts',

  components: {
    ValidationObserver,
    ValidationProvider,
    FileInput,
    LoadingSection,
    LoadingButton
  },

  mixins: [
    interactionModes
  ],

  props: {
    workspace: {
      type: Object,
      default: () => ({})
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isEditing: false,
      documentsToDelete: [],
      backendErrors: '',
      workspaceCopy: {}
    };
  },

  computed: {
    isSaving() {
      return LoadingService.isLoading('updateWorkspaceButton');
    },

    prohibitedPartiesUrl() {
      return get(this.workspace, 'prohibitedParties.file.url', null);
    },

    documentWasReplaced() {
      return this.workspaceCopy.prohibitedParties && !this.workspaceCopy.prohibitedParties.id;
    },

    validationRules() {
      return this.documentWasReplaced ? 'size:50000|ext:pdf,doc,docx' : '';
    },

    prohibitedPartiesFormData() {
      return {
        internal_document: {
          name: this.workspaceCopy.prohibitedParties.name,
          file: this.workspaceCopy.prohibitedParties,
          document_type: 'prohibited_parties',
          documentable_id: this.workspace.id,
          documentable_type: 'Workspace'
        }
      };
    }
  },

  methods: {
    startEdit() {
      this.workspaceCopy = Object.assign({}, this.workspace);
      this.isEditing = true;
      this.backendErrors = '';
    },

    cancelEdit() {
      this.isEditing = false;
      this.documentsToDelete = [];
      this.backendErrors = '';
    },

    replaceProhibitedPartiesDocument() {
      if (this.workspaceCopy.prohibitedParties?.id) {
        this.documentsToDelete.push(this.workspaceCopy.prohibitedParties.id);
      }
    },

    deleteProhibitedParties() {
      this.replaceProhibitedPartiesDocument();
      this.workspaceCopy.prohibitedParties = null;
    },

    async save() {
      if (!this.workspace.id) { return; }

      try {
        await this.uploadProhibitedParties();
      }
      catch {
        return;
      }

      LoadingService.loading('updateWorkspaceButton');
      this.backendErrors = '';

      this.onSave({
        workspace: this.workspaceCopy,
        documentsToDelete: this.documentsToDelete,
        errorFn: (backendErrors) => {
          this.backendErrors = backendErrors.join('; ');
          LoadingService.done('updateWorkspaceButton');
        },
        successFn: () => {
          this.isEditing = false;
          LoadingService.done('updateWorkspaceButton');
        }
      });

      this.documentsToDelete = [];
    },

    uploadProhibitedParties() {
      if (!this.documentWasReplaced) { return; }

      this.backendErrors = '';
      LoadingService.loading('prohibitedPartiesEditing');

      return InternalDocument.postForm(this.prohibitedPartiesFormData)
        .catch((error) => {
          this.backendErrors = error.response.data.errors.join('; ');
          this.workspaceCopy.prohibitedParties = null;
          throw error;
        })
        .finally(() => {
          LoadingService.done('prohibitedPartiesEditing');
        });
    }
  }
};
</script>

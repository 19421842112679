<template>
  <div class="resource-list">
    <div class="list-header">
      <div class="row tight-columns">
        <div class="col-sm-5">
          <div class="row tight-columns">
            <div
              v-for="(column, index) in columnGroupOne"
              :class="[column.class, 'vertical-center']"
              :key="`col-one-${index}`">
              <dynamic-order-arrows
                :order="order"
                :order-by="column.key"
                :initial-order="initialSortOrder(column.key)"
                :on-sort="sort"
                :reverse="!!column.reverse"
                v-if="column.sortable">
                {{ column.label }}
              </dynamic-order-arrows>
            </div>
          </div>
        </div>

        <div class="col-sm-2">
          <dynamic-order-arrows
            :order="order"
            :order-by="practiceGroupColumn.key"
            :initial-order="initialSortOrder(practiceGroupColumn.key)"
            :on-sort="sort"
            :reverse="!!practiceGroupColumn.reverse">
            {{ practiceGroupColumn.label }}
          </dynamic-order-arrows>
        </div>

        <div class="col-sm-5">
          <div class="row tight-columns">
            <div
              v-for="(column, index) in columnGroupTwo"
              :class="[column.class, 'vertical-center']"
              :key="`col-two-${index}`">
              <dynamic-order-arrows
                :order="order"
                :order-by="column.key"
                :initial-order="initialSortOrder(column.key)"
                :on-sort="sort"
                :reverse="!!column.reverse"
                v-if="column.sortable">
                {{ column.label }}
              </dynamic-order-arrows>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasRFQs">
      <div class="list-item" v-for="rfq in rfqs" :key="rfq.id">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-5">
            <div class="row tight-columns vertical-center-not-xs">
              <div class="col-sm-4">
                {{ formatDate(rfq.dateSent) || '—' }}
              </div>

              <div class="col-sm-3">
                {{ rfq.id }}
              </div>

              <div class="col-sm-5 text-ellipsis">
                {{ rfq.creatorFullName }}
              </div>
            </div>
          </div>

          <div class="col-sm-2 text-ellipsis">
            {{ rfq.assignedClientPracticeGroup || '—' }}
          </div>

          <div class="col-sm-5">
            <div class="row tight-columns vertical-center-not-xs">
              <div :class="['col-sm-4', { 'red-text': isPastDue(rfq) }]">
                {{ formatDate(rfq.dueDate) || '—' }}
              </div>

              <div class="col-sm-5 top-10-xs">
                <status-badge :rfq="rfq" class="bottom-5"></status-badge>
                <response-count v-if="hasStatus(rfq, 'sent')" :rfq="rfq"></response-count>
              </div>

              <div class="col-sm-3 bold-weight text-right-not-xs">
                <div v-if="rfq.status === 'draft'" class="vertical-center flex-end-not-xs">
                  <a :href="`/scout-company/rfps/${rfq.id}/edit`" :class="{ 'right-15': currentUserIsCreator }">Edit</a>
                  <priori-modal :ref="`draftRfqModal${rfq.id}`" modal-id="remove-firm-modal" modal-size="md-sm" title="Delete Draft RFP">
                    <template #modal-trigger="{ openModal }">
                      <button type="button" class="delete-button button-reset" @click="openModal">
                        <svg-icon name="trash" class="base-icon smaller"></svg-icon>
                      </button>
                    </template>

                    <div class="size-text-14px semibold-weight bottom-30">
                      Are you sure you want to delete this draft RFP?
                    </div>

                    <div class="row tight-columns">
                      <div class="col-sm-5">
                        <button type="button" class="primary-btn-night-blue" @click="deleteRFQ(rfq.id)">Yes, Delete</button>
                      </div>

                      <div class="col-sm-4 top-20-xs">
                        <button type="button" class="secondary-btn-blue" @click="cancelDeleteModal(rfq.id)">No, Cancel</button>
                      </div>
                    </div>
                  </priori-modal>
                </div>
                <a :href="`/scout-company/rfps/${rfq.id}`" v-else>View</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <priori-pagination
        class="scout-pagination top-15"
        :current-page="currentPage"
        :total-entries="totalRfqCount"
        :per-page="perPage"
        @change="changeRFQPage">
      </priori-pagination>
    </div>

    <div v-else>
      <div class="list-item empty gray-text size-text-16px">
        No Results
      </div>
    </div>
  </div>
</template>

<script>
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import DateFilter from 'vue-app/shared/lib/date-filter';

import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import StatusBadge from 'vue-app/scout/client/rfqs/status-badge.vue';
import ResponseCount from 'vue-app/scout/client/rfqs/response-count.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ClientRFQIndexTable',

  components: {
    PrioriModal,
    PrioriPagination,
    StatusBadge,
    ResponseCount,
    SvgIcon
  },

  props: {
    rfqs: {
      type: Array,
      required: true
    },

    currentPage: {
      type: Number,
      required: true
    },

    changePage: {
      type: Function,
      required: true
    },

    totalRfqCount: {
      type: Number,
      required: true
    },

    onSort: {
      type: Function,
      required: true
    },

    perPage: {
      type: Number,
      required: true
    },

    onDelete: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      order: { 'date_sent': 'desc' },
      columnGroupOne: [
        { key: 'date_sent', label: 'Sent', class: 'col-sm-4', sortable: true, reverse: false },
        { key: 'id', label: 'Ref #', class: 'col-sm-3', sortable: true, reverse: true },
        { key: 'creator_full_name', label: 'Submitted By', class: 'col-sm-5', sortable: true, reverse: true }
      ],
      practiceGroupColumn: { key: 'assigned_client_practice_group', label: 'Practice Group', class: 'col-sm-2', sortable: true, reverse: true },
      columnGroupTwo: [
        { key: 'due_date', label: 'Due', class: 'col-sm-4', sortable: true, reverse: false },
        { key: 'status', label: 'Status', class: 'col-sm-6', sortable: true, reverse: true },
        { key: 'actions', label: '', class: 'col-sm-2', sortable: false }
      ]
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    hasRFQs() {
      return this.rfqs.length > 0;
    }
  },

  methods: {
    changeRFQPage(page) {
      this.changePage(page);
    },

    formatDate(rfqDate) {
      return DateFilter.filterDate(rfqDate);
    },

    isPastDue(rfq) {
      return moment().isAfter(rfq.dueDate) && ['draft', 'sent'].includes(rfq.status);
    },

    initialSortOrder(sortBy) {
      return {
        'date_sent': 'desc',
        'id': 'asc',
        'creator_full_name': 'asc',
        'assigned_client_practice_group': 'asc',
        'due_date': 'desc',
        'status': 'asc'
      }[sortBy];
    },

    sort(sortBy) {
      let sortOrder;

      if (this.order[sortBy]) {
        sortOrder = this.order[sortBy] === 'asc' ? 'desc' : 'asc';
      }
      else {
        sortOrder = this.initialSortOrder(sortBy);
      }

      this.order = { [sortBy]: sortOrder };
      this.onSort({ sort_by: sortBy, sort_direction: sortOrder });
    },

    currentUserIsCreator(rfq) {
      return this.currentUser.id === rfq.creatorId;
    },

    deleteRFQ(id) {
      this.onDelete({ id: id });
    },

    cancelDeleteModal(id) {
      this.$refs[`draftRfqModal${id}`][0].dismissModal();
    },

    hasStatus(rfq, status) {
      return rfq.status === status;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .delete-button {
    display: block;

    .base-icon {
      display: block;
      fill: $pill-gray;
    }

    &:hover .base-icon {
      fill: $k-darker-gray;
    }
  }
</style>

<template>
  <div>
    <div class="breadcrumbs-container white-bar">
      <div class="container-fluid size-text-13px">
        <a href="/client-app/projects" :class="['switcher', { 'selected': myProjects }]">My Projects</a>

        <a href="/client-app/projects/filter/team" :class="['switcher', { 'selected': !myProjects }]">Other Team Projects</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="top-10 bottom-30" v-show="myProjects">
            <horizontal-sort-filter
              :sort-filter="sortFilter"
              filter-label="Status"
              :filter-config="projectFilterConfig"
              @filter="setFilter"
              @sort="setSort"
              @search="setSearch">
            </horizontal-sort-filter>
          </div>

          <loading-section name="projects">
            <div :class="['shadowed-box', { 'top-10': !hasProjects }]">
              <div class="index-project-container" v-for="(project, index) in visibleProjects" :key="newUniqueId()">
                <project-card
                  :project="project"
                  :my-project="myProjects"
                  :current-user="user"
                  @message-lawyer="messageLawyer"
                  @request-closed="closeRequest"
                  @access-requested="requestAccess">
                </project-card>

                <hr class="kenny-hr no-margin" v-if="!isLastProjectIndex(index)">
              </div>

              <div class="box-content" v-if="!hasProjects">
                <div class="top-30 bottom-30 gray-text text-center">
                  <span v-if="myProjects">You have no projects for the selected filters. You can adjust the filters or <a href="/client-app/requests/new" class="dark-gray-text">submit an RFP</a>.</span>

                  <span v-else>There are no additional team projects.</span>
                </div>
              </div>
            </div>
          </loading-section>
        </div>
      </div>
    </div>

    <send-message-modal ref="messageModal" :recipients="messageRecipients"></send-message-modal>
  </div>
</template>

<script>
import ProjectCard from 'vue-app/marketplace/client/matters/project-card.vue';
import HorizontalSortFilter from 'vue-app/marketplace/shared/horizontal-sort-filter.vue';
import SendMessageModal from 'vue-app/shared/components/send-message-modal.vue';

import Matter from 'resources/marketplace/matter.js';
import CounselRequest from 'resources/marketplace/counsel-request.js';

import useCurrentUserStore from 'vue-app/stores/current-user.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import ConfigurableFilter from 'services/configurable-filter.js';
import ProjectFilterConfig from 'services/marketplace/filter-config/projects.js';

import { mapState } from 'pinia';
import { scrollToTop } from 'misc/ui-helpers.js';
import { capitalize, clone, concat, each, includes, uniqueId } from 'lodash';

export default {
  name: 'MattersIndex',

  components: {
    ProjectCard,
    HorizontalSortFilter,
    SendMessageModal
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    let initialFilterValue;

    switch (this.initData.filter) {
      // Legacy option
      case 'open':
      case 'matter-open':
        initialFilterValue = 'Matter Open';
        break;

      // Legacy option
      case 'closed':
      case 'matter-closed':
        initialFilterValue = 'Matter Closed';
        break;

      case 'rfp-submitted':
        initialFilterValue = 'RFP Submitted';
        break;

      // Legacy option
      case 'pending':
      case 'rfp-pending':
        initialFilterValue = 'RFP Pending';
        break;

      case 'rfp-closed':
        initialFilterValue = 'RFP Closed';
        break;
    }

    const sortFilter = new ConfigurableFilter(ProjectFilterConfig, { filterValue: capitalize(initialFilterValue) });

    return {
      myProjects: this.initData.filter !== 'team',
      projects: [],
      matterFilters: ['All', 'Matter Open', 'Matter Closed'],
      messageRecipients: [],
      sortFilter: sortFilter,
      projectFilterConfig: ProjectFilterConfig
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    visibleProjects() {
      return this.sortFilter.filter(this.projects);
    },

    hasProjects() {
      return this.visibleProjects.length > 0;
    },

    isLastProjectIndex() {
      return (index) => {
        return index === (this.visibleProjects.length - 1);
      };
    }
  },

  mounted() {
    this.loadProjects();
  },

  methods: {
    newUniqueId() {
      return uniqueId('project-');
    },

    loadProjects() {
      this.myProjects ? this.loadMyProjects() : this.loadTeamProjects();
    },

    loadMyProjects() {
      const includesMatters = this.matterFilters.includes(this.sortFilter.filterValue);

      LoadingService.loading('projects');
      this.projects = [];

      Matter.query({ openOrClosed: true }).then((matters) => {
        this.projects = concat(this.projects, matters);

        if (includesMatters) { LoadingService.done('projects'); }
      });

      CounselRequest.query({}).then((counselRequests) => {
        each(counselRequests, (cr) => { cr.isRequest = true; });
        this.projects = concat(this.projects, counselRequests);

        if (!includesMatters) { LoadingService.done('projects'); }
      });
    },

    loadTeamProjects() {
      LoadingService.around('projects', () => {
        return Matter.query({ notAdded: true }).then((matters) => {
          this.projects = matters;
        });
      });
    },

    setFilter(value) {
      this.sortFilter.filterBy(value);
    },

    setSort(value) {
      this.sortFilter.sortBy(value);
    },

    setSearch(value) {
      this.sortFilter.searchBy(value);
    },

    closeRequest(project, reason) {
      CounselRequest.clientClose(project.id, { description: reason }).then(() => {
        NotificationService.success('You successfully closed the counsel request.');
        scrollToTop();
        this.loadProjects();
      });
    },

    messageLawyer(lawyer) {
      this.messageRecipients = [lawyer];
      this.$refs.messageModal.openModal();
    },

    requestAccess(matter) {
      if (matter.isOpen() && !includes(matter.clientRequests, this.user.id)) {
        let requests = clone(matter.clientRequests);
        requests.push(this.user.id);

        Matter.update({ id: matter.id, matter: { clientRequests: requests } }).then(() => {
          matter.clientRequests = requests;
          NotificationService.success('You have successfully requested access to a project. You will be notified by email once access has been granted.');
          scrollToTop();
        })
        .catch(() => {
          NotificationService.error('There was a problem requesting access. Please try again.');
          scrollToTop();
        });
      }
    }
  }
};
</script>

<template>
  <div class="priori-panel size-text-13px">
    <div>
      Priori will deposit two small amounts, each under one dollar, into your
      unverified account within the next two days. Once these two amounts
      arrive, you should return to {{ isVerifying ? 'this form via' : '' }} your
      <a href="/client-app/payment-methods">payment methods page</a>
      and enter the two verification amounts to verify your account.
    </div>

    <div class="top-10" v-if="isVerifying">
      Please provide the two amounts that were recently deposited into your
      account in cents (e.g. if you received a deposit of $0.04, please input
      4 below).
    </div>
  </div>
</template>

<script>
export default {
  name: 'BankVerificationInfo',

  props: {
    isVerifying: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
  <div class="shadowed-box summary-box no-header">
    <priori-modal modal-id="project-settings" title="Project Settings">
      <template #modal-trigger="modalProps">
        <div class="settings-row clickable text-ellipsis" @click="modalProps.openModal()">
          <svg-icon name="settings" class="base-icon right-15"></svg-icon>Project Settings
        </div>
      </template>

      <template #default>
        <project-settings :matter="matter"></project-settings>
      </template>
    </priori-modal>

    <add-team-member
      class="settings-row text-ellipsis"
      :matter="matter"
      :workspace="workspace"
      :can-add="canAddTeamMember"
      :current-user="currentUser"
      :on-add-client="addClient">
    </add-team-member>

    <div class="settings-row clickable text-ellipsis expand-links" v-if="isProjectOwner && matter.counselRequestId">
      <a :href="`/client-app/requests/${matter.counselRequestId}`" class="no-decorate" target="_blank"><svg-icon name="resume" class="base-icon right-15"></svg-icon>View Request</a>
    </div>

    <div class="settings-row clickable text-ellipsis expand-links" v-if="!isProjectOwner || !matter.counselRequestId">
      <a :href="`/client-app/documents/${matter.id}/share`" target="_blank" class="no-decorate"><svg-icon name="documents" class="base-icon right-15"></svg-icon>Share Document</a>
    </div>
  </div>
</template>

<script>
import AddTeamMember from 'vue-app/marketplace/client/matters/add-team-member.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import ProjectSettings from 'vue-app/marketplace/client/matters/project-settings.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

import MatterClient  from 'resources/marketplace/matter-client.js';
import RequestClient from 'resources/marketplace/request-client.js';

export default {
  name: 'ActionCard',

  components: {
    AddTeamMember,
    PrioriModal,
    ProjectSettings,
    SvgIcon
  },

  props: {
    matter: {
      type:     Object,
      required: true
    },

    currentUser: {
      type:     Object,
      required: true
    },

    workspace: {
      type:     Object,
      required: true
    },

    onClientAdded: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      isProjectOwner: (this.currentUser.id === this.matter.client.id),
      isMatter: this.matter.counselRequestId || Object.keys(this.matter).includes('matterClients')
    };
  },

  computed: {
    projectClosed() {
      return this.matter.isClosed();
    },

    canAddTeamMember() {
      return !this.projectClosed;
    }
  },

  methods: {
    addClient({ client, successFn, failFn }) {
      if (this.isMatter && this.canAddTeamMember) {
        this.addMatterClient({ client, successFn, failFn });
      }
      else if (!this.isMatter) {
        this.addRequestClient({ client, successFn, failFn });
      }
    },

    addMatterClient({ client, successFn, failFn }) {
      const matterClient = new MatterClient({
        clientId: client.id
      });

      this.onClientAdded({ matterClient, successFn, failFn });
    },

    addRequestClient({ client, successFn, failFn }) {
      const requestClient = new RequestClient({
        clientId: client.id,
        sendConfirmationMailer: true
      });

      this.onClientAdded({ requestClient, successFn, failFn });
    }
  }
};
</script>

<style lang="scss" scoped>
  .summary-box.no-header .settings-row.expand-links {
    padding: 0;

    a {
      padding: 35px 25px;
      display: inline-block;
      width: 100%;

      &.no-decorate, &.no-decorate:hover {
        text-decoration: none;
      }
    }
  }
</style>
